import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../../Styles/ArgumentPageStyles';
import PropTypes from 'prop-types';

import { addWorkflow } from '../../../../redux/actions/workflows';

import AdvancedParametersModal from '../../../Components/NumericAdvancedParamsModal';

import { getUnitRate } from '../../../../Utils/ReservoirUtils';

import {
  SURVEILLANCE,
  ANGLE_THRESHOLD,
  ML_CONNECTIVITY,
  SYSTEM_OF_UNITS,
  WATER_CUT_THRESHOLD,
  WELL_RATE_THRESHOLD,
} from '../../../../constants/WellConstants';
import {
  ML_CONN_INJECTOR_COLS,
  ML_CONN_PRODUCER_COLS,
} from '../../../../constants/WorkflowComponentConstants';
import * as workflowParameters from '../../../../constants/WorkflowsParameterConstants';

import {
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

const MLConnectivity = ({
  submitWorkflow,
  reduxSelectedDataset,
  reduxSelectedDatasetParams,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [name, setname] = useState('');
  const [description, setdescription] = useState('');
  const [advacedModalState, setadvacedModalState] = useState(false);
  const [prodSignals, setprodSignals] = useState([]);
  const [injSignals, setinjSignals] = useState([]);
  const [advancedParameters, setadvancedParameters] = useState({
    [ANGLE_THRESHOLD]: 3,
    [WELL_RATE_THRESHOLD]: 0.9,
    [WATER_CUT_THRESHOLD]: 0.05,
  });
  const methodTypeButtons = {
    [workflowParameters.RANDOM_FOREST]: useRef(null),
    [workflowParameters.LINEAR_REGRESSION]: useRef(null),
  };
  const [parameters, setparameters] = useState({
    ...workflowParameters.ML_CONNECTIVITY_PARAMS,
    ...reduxSelectedDatasetParams,
    ...advancedParameters,
    stage: workflowParameters.ML_CONN_WORKFLOW_STAGES.CONNECTIVITY,
  });

  // Helpers/Callbacks -->
  const applyAdvancedParameters = newParamsPairs => {
    setadvancedParameters(newParamsPairs);
    setparameters(previous => {
      return {
        ...previous,
        ...newParamsPairs,
      };
    });
  };
  // <-- Helpers/Callbacks

  // Handlers -->
  const onSubmitWorkflow = async () => {
    try {
      await dispatch(
        addWorkflow(reduxSelectedDataset.dataset_id, {
          module_type: SURVEILLANCE,
          workflow_type: ML_CONNECTIVITY,
          name,
          description,
          parameters,
        })
      );
      submitWorkflow(true);
    } catch (error) {
      submitWorkflow(false);
    }
  };

  const onParameterChange = (target, value) => {
    setparameters(previous => {
      return {
        ...previous,
        [target]: value,
      };
    });
  };

  const toggle = () => {
    setadvacedModalState(previous => !previous);
  };

  const selectCorrelationType = (type, ref) => {
    Object.values(methodTypeButtons).forEach(refBt => {
      refBt.current.classList.remove('selectedButton');
    });
    ref.current.classList.add('selectedButton');

    setparameters(previous => {
      return {
        ...previous,
        [workflowParameters.MODEL_TYPE]: type,
      };
    });
  };

  const onProdSignalSelect = event => {
    const signals = [...prodSignals];
    setprodSignals(event.target.value);
    onParameterChange(
      workflowParameters.PRODUCER_SIGNALS,
      signals.includes(event.target.value)
        ? signals.filter(signal => signal !== event.target.value)
        : [...event.target.value]
    );
  };

  const onInjSignalSelect = event => {
    const signals = [...injSignals];
    setinjSignals(event.target.value);
    onParameterChange(
      workflowParameters.INJECTOR_SIGNALS,
      signals.includes(event.target.value)
        ? signals.filter(signal => signal !== event.target.value)
        : [...event.target.value]
    );
  };
  // <-- Handlers

  return (
    <Grid item className={styles.rootContainer}>
      <Grid item className={styles.argumentsContainer}>
        <Typography className={styles.header}>Workflow Details</Typography>
        <Grid item className={styles.argumentRow}>
          <Typography>Name</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '50',
            }}
            onChange={event => {
              setname(event.target.value);
            }}
          />
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Description</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '100',
            }}
            onChange={event => {
              setdescription(event.target.value);
            }}
          />
        </Grid>
        <br />
        <Typography className={styles.header}>Input Parameter</Typography>
        <Grid item className={styles.argumentContainer}>
          <Grid item className={styles.argumentRow}>
            <Typography>
              {`${workflowParameters.RADIUS_OF_INVESTIGATION} (${getUnitRate(
                reduxSelectedDatasetParams[SYSTEM_OF_UNITS],
                workflowParameters.RADIUS_OF_INVESTIGATION
              )})`}
            </Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[workflowParameters.RADIUS_OF_INVESTIGATION]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(
                  workflowParameters.RADIUS_OF_INVESTIGATION,
                  event.target.value
                );
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Time Shift (month)</Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[workflowParameters.TIME_SHIFT]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(
                  workflowParameters.TIME_SHIFT,
                  event.target.value
                );
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Producer Signal</Typography>
          <FormControl
            variant="outlined"
            style={{ minWidth: '10em' }}
            size="small"
          >
            <Select
              multiple={true}
              value={prodSignals}
              onChange={onProdSignalSelect}
              className={styles.selectionInput}
            >
              {ML_CONN_PRODUCER_COLS.map(pair => (
                <MenuItem key={pair} value={pair}>
                  {pair}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Injector Signal</Typography>
          <FormControl
            variant="outlined"
            style={{ minWidth: '10em' }}
            size="small"
          >
            <Select
              multiple={true}
              value={injSignals}
              onChange={onInjSignalSelect}
              className={styles.selectionInput}
            >
              {ML_CONN_INJECTOR_COLS.map(pair => (
                <MenuItem key={pair} value={pair}>
                  {pair}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Model Type</Typography>
          <Grid item className={styles.buttonContainer}>
            {Object.entries(methodTypeButtons).map(button => (
              <Button
                key={'button-' + button[0]}
                ref={button[1]}
                variant="outlined"
                className={
                  parameters[workflowParameters.MODEL_TYPE] == button[0]
                    ? `${styles.button} ${styles.secondaryButton} selectedButton`
                    : `${styles.button} ${styles.secondaryButton}`
                }
                onClick={() => {
                  selectCorrelationType(button[0], button[1]);
                }}
              >
                {button[0]}
              </Button>
            ))}
          </Grid>
        </Grid>
        <Grid container className={styles.argumentRow} direction="row-reverse">
          <Tooltip title="Advanced Settings">
            <IconButton onClick={toggle}>
              <SettingsRoundedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <AdvancedParametersModal
          modalState={advacedModalState}
          toggle={toggle}
          applyAdvancedParameters={applyAdvancedParameters}
          advancedParameterPairs={advancedParameters}
        />
      </Grid>
      <Grid item className={styles.runContainer}>
        <Grid item className={styles.buttonContainer}>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.runButton} ${styles.primaryButton}`}
            onClick={onSubmitWorkflow}
            disabled={prodSignals.length === 0 || injSignals.length === 0}
          >
            Run
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

MLConnectivity.propTypes = {
  submitWorkflow: PropTypes.func,
  reduxSelectedDataset: PropTypes.object,
  reduxSelectedDatasetParams: PropTypes.object,
};

export default MLConnectivity;
