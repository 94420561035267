import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { INJECTOR, PRODUCER } from '../../../../constants/WellConstants';

import FieldMapSection from './FieldMapSection';
import WellRelationPlot from './WellRelationPlot';
import ProducerImportanceAnalysis from './ProducerImportanceAnalysis';
import InjectorsImportanceAnalysis from './InjectorImportanceAnalysis';
import ProducerPerformanceAnalyzer from './ProducerPerformanceAnalyzer';
import WellPairAnalysis from './WellPairAnalysis';

import { retrieveFieldWellNames } from '../../../../API/Functions/MLConnectivity';
import { getCorrelationPairs } from './Utils';

const PlotComponentMapper = ({
  headers,
  selectedHeader,
  workflowParameters,
  reduxSelectedWorkflow,
}) => {
  const [selectedInjector, setselectedInjector] = useState(null);
  const [selectedProducer, setselectedProducer] = useState(null);
  const [selectedCorrPair, setSelectedCorrPair] = useState(null);
  const [availableCorrPairs, setAvailableCorrPairs] = useState(null);

  // Retrieve well names to reduce repetition in children.
  useEffect(() => {
    const fetchFieldWellNames = async () => {
      try {
        const fieldWellNames = await retrieveFieldWellNames(
          reduxSelectedWorkflow['dataset_id']
        );
        setselectedInjector(fieldWellNames[INJECTOR][0]);
        setselectedProducer(fieldWellNames[PRODUCER][0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while fetching well names.', error);
      }
    };
    if (reduxSelectedWorkflow) {
      fetchFieldWellNames();
    }
  }, [reduxSelectedWorkflow]);

  // Retrieve available correlation pairs from parameters and set default.
  useEffect(() => {
    const [availableCorrPairs, selectedCorrPair] =
      getCorrelationPairs(workflowParameters);
    setAvailableCorrPairs(availableCorrPairs);
    setSelectedCorrPair(selectedCorrPair);
  }, [workflowParameters]);

  const componentMap = {
    [headers.FIELD_MAP]: (
      <FieldMapSection
        headers={headers}
        selectedCorrPair={selectedCorrPair}
        availableCorrPairs={availableCorrPairs}
        setSelectedCorrPair={setSelectedCorrPair}
      />
    ),
    [headers.INJECTOR]: (
      <InjectorsImportanceAnalysis
        header={headers.INJECTOR}
        selectedInjector={selectedInjector}
        selectedCorrPair={selectedCorrPair}
        onInjectorSelect={wellName => setselectedInjector(wellName)}
      />
    ),
    [headers.PRODUCER]: (
      <ProducerImportanceAnalysis
        headers={headers}
        selectedProducer={selectedProducer}
        selectedCorrPair={selectedCorrPair}
        onProducerSelect={wellName => setselectedProducer(wellName)}
      />
    ),
    [headers.WELL_PAIR_ANALYSIS]: (
      <WellPairAnalysis
        header={headers.WELL_PAIR_ANALYSIS}
        selectedInjector={selectedInjector}
        selectedProducer={selectedProducer}
        workflowParameters={workflowParameters}
      />
    ),
    [headers.PRODUCER_PERFORMANCE_EVALUATION]: (
      <ProducerPerformanceAnalyzer
        header={headers.PRODUCER_PERFORMANCE_EVALUATION}
        selectedProducer={selectedProducer}
        workflowParameters={workflowParameters}
      />
    ),
    [headers.RELATION_HEATMAP]: (
      <WellRelationPlot
        header={headers.RELATION_HEATMAP}
        selectedCorrPair={selectedCorrPair}
      />
    ),
  };

  return componentMap[selectedHeader ? selectedHeader : headers.FIELD_MAP];
};

PlotComponentMapper.propTypes = {
  headers: PropTypes.object,
  selectedHeader: PropTypes.string,
  workflowParameters: PropTypes.object,
  reduxSelectedWorkflow: PropTypes.object,
};

export default PlotComponentMapper;
