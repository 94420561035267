import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  TOLERANCE_OIL_MATCHING,
  MAX_ITERATION_OIL_MATCHING,
} from '../../../../../../constants/WellConstants';

const useStyles = makeStyles({
  parameters: {
    overflow: 'hidden',
    margin: '0.5em 0em',
    '& .parameterRow': {
      justifyContent: 'space-between',
      margin: '0.2em 0em',
    },
    '& .left': { width: '55%', textAlign: 'left' },
    '& .right': { width: '45%', textAlign: 'right' },
  },
  button: {
    borderRadius: '2em',
    margin: '0 0.5em',
  },
});

const AdvancedParametersModal = ({
  modalState,
  toggle,
  applyAdvancedParameters,
  advancedParameters,
}) => {
  const styles = useStyles();
  const [params, setparams] = useState({
    [MAX_ITERATION_OIL_MATCHING]: 1000,
    [TOLERANCE_OIL_MATCHING]: 10,
  });

  // Callbacks -->
  const saveParams = (name, value) => {
    setparams(prev => ({ ...prev, [name]: value }));
  };
  // <-- Callbacks

  // Handlers -->
  // Save params to redux state
  const onSubmit = () => {
    applyAdvancedParameters(params);
    toggle();
  };
  // <-- Handlers

  useEffect(() => {
    if (advancedParameters) {
      setparams(prev => ({ ...prev, ...advancedParameters }));
    }
  }, [modalState]);

  return (
    <Dialog open={modalState} fullWidth={true} onClose={() => toggle()}>
      <DialogTitle disableTypography={true}>
        <Grid
          container
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">Advanced Settings</Typography>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={() => toggle()}>
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {Object.entries(params).map(([key, value]) => (
          <Grid key={key} container className={styles.parameters}>
            <Grid item container className="parameterRow">
              <Typography>{key}</Typography>
              <TextField
                type="number"
                size="small"
                value={value}
                onChange={e => saveParams(key, parseFloat(e.target.value))}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={styles.button}
          onClick={onSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvancedParametersModal.propTypes = {
  modalState: PropTypes.bool,
  toggle: PropTypes.func,
  applyAdvancedParameters: PropTypes.func,
  advancedParameters: PropTypes.object,
};

export default AdvancedParametersModal;
