import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Results from './Results';
// import useStyles from '../../../../../Styles/WorkflowStyles';

import { updateWorkflow } from '../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../redux/actions/feedback';

// import { Grid, Button } from '@material-ui/core';

import { CRMP_LAYER_WORKFLOW_STAGES } from '../../../../../constants/WorkflowsParameterConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../constants/ComponentConstants';

const OilMatching = ({
  reduxSelectedWorkflow,
  workflowParameters,
  filteredDatasetContent,
  r2Oil,
  oilRateHistory,
  oilRateAll,
  prodWellNames,
  layers,
  header,
  fieldLayerData,
  fieldData,
}) => {
  // const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const runOilMatching = async () => {
    const updatedWorkflowPayload = {
      parameters: {
        ...workflowParameters,
        stage: CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Oil Matching request sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Oil Matching didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };

  runOilMatching;

  return (
    <React.Fragment>
      {workflowParameters.stage == CRMP_LAYER_WORKFLOW_STAGES.FLUID_MATCHING ? (
        // <Grid container className={styles.visualContentFlex}>
        //   <Grid item justifyContent="center" className={styles.inputContentRow}>
        //     <Button variant="outlined" onClick={runOilMatching}>
        //       Run Oil Matching
        //     </Button>
        //   </Grid>
        // </Grid>
        ''
      ) : (
        <Results
          filteredDatasetContent={filteredDatasetContent}
          workflowParameter={workflowParameters}
          r2Oil={r2Oil}
          oilRateAll={oilRateAll}
          oilRateHistory={oilRateHistory}
          prodWellNames={prodWellNames}
          layers={layers}
          header={header}
          fieldLayerData={fieldLayerData}
          fieldData={fieldData}
        />
      )}
    </React.Fragment>
  );
};

OilMatching.propTypes = {
  reduxSelectedWorkflow: PropTypes.object,
  workflowParameters: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  r2Oil: PropTypes.array,
  oilRateHistory: PropTypes.array,
  oilRateAll: PropTypes.array,
  prodWellNames: PropTypes.array,
  layers: PropTypes.array,
  header: PropTypes.string,
  fieldLayerData: PropTypes.array,
  fieldData: PropTypes.array,
};

export default OilMatching;
