import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    flexDirection: 'flex-start',
    backgroundColor: '#f1f4f6',
  },
  navbarGrid: {
    width: '100%',
    height: '10%',
    display: 'flex',
  },
  bodyGrid: {
    height: '90%',
    width: '100%',
  },
  sidebarGrid: {
    width: '20%',
    height: '100%',
    padding: '0em',
    '& hr': {
      border: '1px solid grey',
      marginBottom: '1em',
    },
  },
  contentGrid: {
    width: '80%',
    height: '100%',
    padding: '1em',
  },
  sidebarPaperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: '#003333',
    color: '#c1c5d1',
  },
  paperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  workflowContainerGrid: {
    justifyContent: 'center',
    height: '70%',
    overflow: 'auto',
    paddingLeft: '100px',
  },
  sidebarListItem: {
    '&:hover': {
      color: '#ffffff', // Change this to the desired hover color
    },
  },
  sidebarListItemIcon: {
    color: '#c1c5d1',
  },
  sidebarList: {
    paddingTop: '25px',
  },
  footer: {
    width: '100%',
    height: '10%',
    padding: '1%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& #Help': {
      borderRadius: '2em',
      textTransform: 'capitalize',
    },
  },
  box: {
    border: '2px solid grey',
    borderRadius: '2em',
    margin: '1em',
    width: '10em',
    height: '12em',
    overflow: 'hidden',
    '& .center': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .iconContainer': {
      height: '40%',
    },
    '& .detailsContainer': {
      justifyContent: 'space-between',
      height: '50%',
      padding: '0.25em 0.5em',
    },
    '& .infoContainer': {
      height: '10%',
      backgroundColor: 'grey',
      '& button': { color: 'white' },
    },
    '& .icon': { fontSize: '3em' },
    '& .status': { fontSize: '0.85em', fontWeight: 600 },
    '& .typeName': {
      width: '50%',
      overflow: 'hidden',
      fontSize: '0.75em',
    },
    '& .left': { textAlign: 'left' },
    '& .right': { textAlign: 'right' },
  },
  visualContentRow: {
    width: '90%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    margin: '2% 5%',
    '& .dropdownSelection': {
      width: '35%',
      margin: '1% 0%',
    },
  },
});

export default useStyles;
