import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  setSelectedWorkflow,
  removeWorkflow,
} from '../../redux/actions/workflows';
import { updateSnackBar } from '../../redux/actions/feedback';
import {
  Link,
  Grid,
  Button,
  Dialog,
  Tooltip,
  Typography,
  IconButton,
  makeStyles,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { WORKFLOW_STATUSES } from '../../constants/WorkflowComponentConstants';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';
import { RADIUS_OF_INVESTIGATION } from '../../constants/WorkflowsParameterConstants';
import { getUnitRate } from '../../Utils/ReservoirUtils';
import { SYSTEM_OF_UNITS } from '../../constants/WellConstants';

const useStyles = makeStyles({
  parameters: {
    overflow: 'hidden',
    '& .parameterRow': {
      justifyContent: 'space-between',
      margin: '0.2em 0em',
    },
    '& .left': { width: '35%', textAlign: 'left' },
    '& .right': { width: '65%', textAlign: 'right' },
  },
});

const WorkflowModal = ({ workflow, modalState, toggle }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  /**
   * This function navigates to selected workflow report page, and saves the selected workflow,
   * so that they can be accessed from the workflow report.
   */
  const visualizeWorkflow = async () => {
    // save all the satates to redux.
    try {
      dispatch(setSelectedWorkflow(workflow));
      navigate(`/workflowResult/${workflow['workflow_id']}`);
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while setting selected workflow to redux state.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
    } finally {
      toggle();
    }
  };
  /**
   * Delete selected workflow from redux state.
   * @returns
   */
  const deleteWorkflow = async () => {
    await dispatch(removeWorkflow(workflow.workflow_id));
    toggle();
  };

  const onDownload = params => {
    setencodedUri(
      encodeURI('data:text/json;charset=utf-8,' + JSON.stringify(params))
    );
  };
  // <-- Callbacks/Helpers

  return (
    <Dialog open={modalState} onClose={() => toggle()}>
      <DialogTitle disableTypography={true}>
        <Grid
          container
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">Workflow Details</Typography>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={() => toggle()}>
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={styles.parameters}>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Module Type
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.module_type}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Worklfow ID
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.workflow_id}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Worklfow Type
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.workflow_type}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Workflow Name
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.name}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Workflow Desctription
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.description}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Worklfow Status
            </Typography>
            <Typography variant="body2" className="right">
              {'stage' in workflow.parameters
                ? `${workflow.status} (${workflow.parameters.stage})`
                : workflow.status}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              {`${RADIUS_OF_INVESTIGATION} (${getUnitRate(
                workflow.parameters?.[SYSTEM_OF_UNITS],
                RADIUS_OF_INVESTIGATION
              )})`}
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.parameters[RADIUS_OF_INVESTIGATION]}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Dataset
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.file_name.split('_').slice(1).join('_')}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Worklfow Created
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.time_created}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              Worklfow Updated
            </Typography>
            <Typography variant="body2" className="right">
              {workflow.time_updated}
            </Typography>
          </Grid>
          <Grid item container className="parameterRow">
            <Typography variant="body2" className="left">
              <Link
                download={`${workflow.name}-parameters.json`}
                href={encodedUri}
                onClick={() => {
                  onDownload(workflow.parameters);
                }}
              >
                Download Parameters
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={deleteWorkflow}>
          Delete
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={workflow.status != WORKFLOW_STATUSES.COMPLETED}
          onClick={visualizeWorkflow}
        >
          Visualize
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WorkflowModal.propTypes = {
  workflow: PropTypes.object,
  modalState: PropTypes.bool,
  toggle: PropTypes.func,
};

export default WorkflowModal;
