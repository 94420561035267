import {
  WOR,
  GOR,
  GAS,
  XCOL,
  YCOL,
  DATE,
  GREEN,
  FIELD,
  WELL_ID,
  PRODUCER,
  INJECTOR,
  LIGHTBLUE,
  PER_MONTH,
  RESERVOIR,
  WATER_CUT,
  // UNIT_LENGTH,
  FORMATION_VFW,
  FORMATION_VFO,
  FORMATION_VFG,
  UNIT_STD_LIQUID,
  GAS_INJECTION_RATE,
  GAS_PRODUCTION_RATE,
  OIL_PRODUCTION_RATE,
  WATER_INJECTION_RATE,
  FLUID_INJECTION_RATE,
  FLUID_PRODUCTION_RATE,
  WATER_PRODUCTION_RATE,
  LIQUID_INJECTION_RATE,
  LIQUID_PRODUCTION_RATE,
  COLUMNS_NORMALIZED,
} from '../constants/WellConstants';
import {
  ACTIVE_INJECTOR_COUNT,
  ACTIVE_PRODUCER_COUNT,
  INJECTOR_COUNT,
  PRODUCER_COUNT,
  SURVEILLANCE_DATASET_VISUALIZATION_PARAMS,
} from '../constants/WorkflowsParameterConstants';

import {
  LINES,
  wrapPlots,
  createLinePlotTrace,
  createTraceWithText,
  LINES_WITH_MARKERS,
} from '../Utils/PlotlyUtils/Plots';
import {
  filterAvailaleColNamesFromDataset,
  filterTimelineFromDataset,
  filterWellNamesFromDataset,
  findActiveWellByDate,
  findFieldLevelData,
  findCumulativeUniqueWellCount,
} from './DatasetUtils/DataProcessing';
import { getUnitRate } from './ReservoirUtils';

const generateSurfaceLocationData = (datasetContent, wellNames, wellType) => {
  return wellNames.map(wellName => {
    let wellData = datasetContent.find(row => row[WELL_ID] == wellName);
    let wellColor =
      wellType === PRODUCER && !wellName.endsWith('_P') ? GREEN : LIGHTBLUE;
    let wellSymbol = wellType === INJECTOR ? 'triangle-up' : 'circle-up';

    return {
      'Node Id': wellData[WELL_ID],
      'Node Type': wellType,
      'Node X': wellData[XCOL],
      'Node Y': wellData[YCOL],
      'Node Color': wellColor,
      'Node Symbol': wellSymbol,
    };
  });
};

export const generateWellSurfaceLocationPlot = (
  datasetContent,
  injeWellNames,
  prodWellNames,
  unitSystem = FIELD
) => {
  const node_data = [];
  const traces = [];

  node_data.push(
    ...generateSurfaceLocationData(datasetContent, injeWellNames, INJECTOR)
  );
  node_data.push(
    ...generateSurfaceLocationData(datasetContent, prodWellNames, PRODUCER)
  );

  node_data.forEach(node => {
    traces.push(
      createTraceWithText(
        [node['Node X']],
        [node['Node Y']],
        node['Node Id'],
        node['Node Color'],
        node['Node Symbol'],
        node['Node Id'],
        undefined,
        undefined,
        node['Node Id'],
        undefined,
        'rgba(0, 0, 0, 1)',
        undefined,
        false
      )
    );
  });

  return wrapPlots(
    traces,
    `${XCOL} (${getUnitRate(unitSystem, XCOL)})`,
    `${YCOL} (${getUnitRate(unitSystem, YCOL)})`,
    'Wells'
  );
};

export const generateSchedulePlot = (
  datasetContent,
  injWellNames,
  prodWellNames
) => {
  const fieldTimeLine = filterTimelineFromDataset(datasetContent);
  const allWellNames = injWellNames.concat(prodWellNames);

  // For each well, create line plot trace with Date.
  let index = 0;
  const traces = {};
  const wellDate = {};

  // Filter all well's operating dates
  datasetContent.forEach(row => {
    if (!(row[WELL_ID] in wellDate)) {
      wellDate[row[WELL_ID]] = new Set();
    }
    wellDate[row[WELL_ID]].add(row[DATE]);
  });

  allWellNames.forEach(wellName => {
    let operationDates = wellDate[wellName];
    traces[wellName] = createLinePlotTrace(
      fieldTimeLine,
      [],
      wellName,
      undefined,
      injWellNames.includes(wellName) ? LIGHTBLUE : GREEN
    );
    fieldTimeLine.forEach(date => {
      operationDates.has(date)
        ? traces[wellName]['y'].push(index)
        : traces[wellName]['y'].push('');
    });
    index += 1;
  });

  return wrapPlots(Object.values(traces), DATE, undefined, 'Schedule Plot');
};

const fillInWellMissingRecordsByDate = (
  wellFilteredDataset,
  fieldTimeLine,
  category
) => {
  const enhancedWellData = [];

  let wellIndex = 0;
  fieldTimeLine.forEach(fieldDate => {
    const wellRecord = wellFilteredDataset.at(wellIndex);
    if (wellRecord?.[DATE] === fieldDate) {
      enhancedWellData.push(parseFloat(wellRecord[category]));
      wellIndex++;
    } else {
      enhancedWellData.push(0.0);
    }
  });

  return enhancedWellData;
};

export const generateRatePlot = (
  datasetContent,
  category,
  wellNames,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const fieldTimeLine = filterTimelineFromDataset(datasetContent);

  let traces = wellNames.map(wellName => {
    const populatedWellData = fillInWellMissingRecordsByDate(
      datasetContent.filter(row => row[WELL_ID] === wellName),
      fieldTimeLine,
      category
    );
    return createLinePlotTrace(fieldTimeLine, populatedWellData, wellName);
  });

  return wrapPlots(
    traces,
    DATE,
    `${category} (${getUnitRate(unitSystem, category, timePeriod)})`,
    category
  );
};

export const generateMultiYAxesConfigs = (
  yAxisPairs,
  rangemode = undefined
) => {
  const axesConfigs = {};
  const secondaryAxes = Object.entries(yAxisPairs).filter(
    axisUnitPair => axisUnitPair[1]['axisIndex'] !== 1
  );

  secondaryAxes.forEach(([unit, axisDetailsObj]) => {
    axesConfigs[`yaxis${axisDetailsObj['axisIndex']}`] = {
      title: `${axisDetailsObj['attr']} (${unit})`,
      anchor: 'free',
      overlaying: 'y',
      side: 'right',
      position: 1 - 0.1 * (axisDetailsObj['axisIndex'] - 1),
      rangemode: rangemode,
      linecolor: 'rgba(0, 0, 0, 1)',
    };
  });

  return axesConfigs;
};

export const generateYAxesPairsFromAttributes = (
  attributes,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const yAxisUnits = {};
  let yAxisCounter = 1;

  attributes.forEach(attr => {
    let unit = getUnitRate(unitSystem, attr, timePeriod);

    if (!(unit in yAxisUnits))
      yAxisUnits[getUnitRate(unitSystem, attr, timePeriod)] = {
        attr: attr,
        axisIndex: yAxisCounter++,
      };
  });

  return yAxisUnits;
};

const generateWellAttrPairs = config => {
  const wellNameAttrPairs = [];

  const injNames = Object.entries(config[INJECTOR].wellNamePairs)
    .filter(pair => pair[1])
    .map(pair => pair[0]);
  const injAttr = Object.entries(config[INJECTOR].attrPairs)
    .filter(pair => pair[1])
    .map(pair => pair[0]);
  const prodNames = Object.entries(config[PRODUCER].wellNamePairs)
    .filter(pair => pair[1])
    .map(pair => pair[0]);
  const prodAttr = Object.entries(config[PRODUCER].attrPairs)
    .filter(pair => pair[1])
    .map(pair => pair[0]);

  prodNames.forEach(name =>
    prodAttr.forEach(attr => wellNameAttrPairs.push([name, attr]))
  );
  injNames.forEach(name =>
    injAttr.forEach(attr => wellNameAttrPairs.push([name, attr]))
  );

  return wellNameAttrPairs;
};

const createWellAttrPairTraces = (
  datasetContent,
  wellAttrPairs,
  yAxisUnits,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const fieldTimeLine = filterTimelineFromDataset(datasetContent);

  wellAttrPairs.forEach(([wellName, attr]) => {
    let unit = getUnitRate(unitSystem, attr, timePeriod);
    const populatedWellData = fillInWellMissingRecordsByDate(
      datasetContent.filter(row => row[WELL_ID] === wellName),
      fieldTimeLine,
      attr
    );

    traces.push(
      createLinePlotTrace(
        fieldTimeLine,
        populatedWellData,
        wellName + ' - ' + attr,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        `y${yAxisUnits[unit]['axisIndex']}`
      )
    );
  });

  return traces;
};

export const generateCustomizablePlot = (
  config,
  datasetContent,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const wellNameAttrPairs = generateWellAttrPairs(config);
  const yAxisUnits = generateYAxesPairsFromAttributes(
    wellNameAttrPairs.map(pair => pair[1]),
    unitSystem,
    timePeriod
  );
  const traces = createWellAttrPairTraces(
    datasetContent,
    wellNameAttrPairs,
    yAxisUnits,
    unitSystem,
    timePeriod
  );

  const mainAxisPair = Object.entries(yAxisUnits)
    .filter(axisPair => axisPair[1]['axisIndex'] == 1)
    .pop();
  const mainAxisUnit = mainAxisPair?.[0];
  const mainAxisAttr = mainAxisPair?.[1]?.['attr'];
  const otherAxesConfigs = generateMultiYAxesConfigs(yAxisUnits);

  return wrapPlots(
    traces,
    DATE,
    mainAxisPair ? `${mainAxisAttr} (${mainAxisUnit})` : undefined,
    'Customized Plot',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [0, 1 - 0.1 * (Object.keys(yAxisUnits).length - 1)],
    undefined,
    otherAxesConfigs
  );
};

/**
 * This fucntion filters available column and well names from uplaoded dataset, and generates
 * key value pairs, where keys are available column and well names and pairs are boolean values
 * representing which attributes are enabled. By default all producers and OIL_PRODUCTION_RATE
 * options are pre-selected.
 *
 * @param {*} injWellNames
 * @returns { Object } - {
 * Injector: { wellNamePairs: {}, attrPairs: {} },
 * Producer: { wellNamePairs: {}, attrPairs: {} }
 * }
 */
export const generateCustomizablePlotConfig = datasetContent => {
  const configs = {
    [INJECTOR]: {
      wellNamePairs: {},
      attrPairs: {},
    },
    [PRODUCER]: {
      wellNamePairs: {},
      attrPairs: {},
    },
  };

  const { [INJECTOR]: injColNames, [PRODUCER]: prodColNames } =
    filterAvailaleColNamesFromDataset(Object.keys(datasetContent[0]));

  const { injectors, producers } = filterWellNamesFromDataset(datasetContent);

  injColNames.forEach(
    colName => (configs[INJECTOR].attrPairs[colName] = false)
  );
  injectors.forEach(
    colName => (configs[INJECTOR].wellNamePairs[colName] = false)
  );
  prodColNames.forEach(
    colName => (configs[PRODUCER].attrPairs[colName] = false)
  );
  producers.forEach(
    colName => (configs[PRODUCER].wellNamePairs[colName] = true)
  );

  // Preselect Oil production attribute
  configs[PRODUCER].attrPairs[OIL_PRODUCTION_RATE] = true;

  return configs;
};

// yAxisUnits can be an object that has keys as colNames, values as units.
const getFieldAnalysisPlotTraces = (
  datasetContent,
  colNames,
  yAxisUnits,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const colorCodes = {
    [OIL_PRODUCTION_RATE]: '#19FA00FF',
    [WATER_PRODUCTION_RATE]: '#0046E1FF',
    [GAS_PRODUCTION_RATE]: '#FF0000FF',
    [FLUID_PRODUCTION_RATE]: '#646464FF',
    [WATER_INJECTION_RATE]: '#0046E144',
    [GAS_INJECTION_RATE]: '#FF000044',
    [FLUID_INJECTION_RATE]: '#64646444',
    [LIQUID_PRODUCTION_RATE]: '#2D54A8FF',
    [LIQUID_INJECTION_RATE]: '#2D54A844',
  };

  let traces = colNames.map(colName => {
    const unit = getUnitRate(unitSystem, colName, timePeriod);
    return createLinePlotTrace(
      datasetContent.map(row => row[DATE]),
      datasetContent.map(row => row[colName]),
      colName,
      undefined,
      colorCodes[Object.keys(colorCodes).find(key => colName.includes(key))],
      undefined,
      colName.includes(RESERVOIR) ? LINES : LINES_WITH_MARKERS,
      undefined,
      undefined,
      undefined,
      `y${yAxisUnits[unit]['axisIndex']}`
    );
  });
  return traces;
};

export const generateFieldAnalysisPlot = (
  datasetContent,
  vfoValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFO],
  vfwValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFW],
  vfgValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFG],
  unitSystem = FIELD,
  timePeriod = PER_MONTH,
  showFluids = true
) => {
  const traces = [];

  const fieldLevelRateDataset = findFieldLevelData(datasetContent, [
    ...COLUMNS_NORMALIZED,
    ...COLUMNS_NORMALIZED.map(val => `${val} (${RESERVOIR})`),
  ]);

  fieldLevelRateDataset.forEach(row => {
    const resOilProd = row[OIL_PRODUCTION_RATE] * vfoValue;
    const resWaterProd = row[WATER_PRODUCTION_RATE] * vfwValue;
    const resWaterInj = row[WATER_INJECTION_RATE] * vfwValue;
    const resGasProd = row[GAS_PRODUCTION_RATE] * vfgValue;
    const resGasInj = row[GAS_INJECTION_RATE] * vfgValue;

    row[`${OIL_PRODUCTION_RATE} (${RESERVOIR})`] = resOilProd;
    row[`${WATER_PRODUCTION_RATE} (${RESERVOIR})`] = resWaterProd;
    row[`${WATER_INJECTION_RATE} (${RESERVOIR})`] = resWaterInj;
    row[`${GAS_PRODUCTION_RATE} (${RESERVOIR})`] = resGasProd;
    row[`${GAS_INJECTION_RATE} (${RESERVOIR})`] = resGasInj;
    row[`${FLUID_PRODUCTION_RATE} (${RESERVOIR})`] =
      resOilProd + resWaterProd + resGasProd;
    row[`${FLUID_INJECTION_RATE} (${RESERVOIR})`] = resWaterInj + resGasInj;
    row[`${LIQUID_PRODUCTION_RATE} (${RESERVOIR})`] = resOilProd + resWaterProd;
    row[`${LIQUID_PRODUCTION_RATE}`] =
      row[OIL_PRODUCTION_RATE] + row[WATER_PRODUCTION_RATE];
    row[`${WATER_CUT} (${RESERVOIR})`] =
      resWaterProd / (resOilProd + resWaterProd + resGasProd) || 0;
    row[`${WOR} (${RESERVOIR})`] = resWaterProd / resOilProd || 0;
    row[`${GOR} (${RESERVOIR})`] = resGasProd / resOilProd || 0;
  });

  const colNames = showFluids
    ? [
        ...COLUMNS_NORMALIZED.filter(col => !col.includes(GAS)),
        ...COLUMNS_NORMALIZED.filter(col => !col.includes(GAS)).map(
          val => `${val} (${RESERVOIR})`
        ),
        `${WATER_CUT} (${RESERVOIR})`,
      ]
    : [
        GAS_INJECTION_RATE,
        GAS_PRODUCTION_RATE,
        `${GAS_INJECTION_RATE} (${RESERVOIR})`,
        `${GAS_INJECTION_RATE} (${RESERVOIR})`,
        `${WOR} (${RESERVOIR})`,
        `${GOR} (${RESERVOIR})`,
      ];

  const yAxisUnits = generateYAxesPairsFromAttributes(
    colNames,
    unitSystem,
    timePeriod
  );
  const otherAxesConfigs = generateMultiYAxesConfigs(yAxisUnits, 'tozero');

  traces.push(
    ...getFieldAnalysisPlotTraces(
      fieldLevelRateDataset,
      colNames,
      yAxisUnits,
      unitSystem,
      timePeriod
    )
  );

  return wrapPlots(
    traces,
    DATE,
    `Production/Injection Rate (${UNIT_STD_LIQUID[unitSystem] + timePeriod})`,
    'Field Analysis Plot',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [0, 1 - 0.1 * (Object.keys(yAxisUnits).length - 1)],
    undefined,
    otherAxesConfigs,
    undefined,
    'tozero'
  );
};

export const generateFluidRateWellCountPlot = (
  datasetContent,
  vfoValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFO],
  vfwValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFW],
  vfgValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFG],
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  // TODO: Field analysis contains different categories, should have multiple
  // axes for different units.
  const rates = {
    [FLUID_PRODUCTION_RATE]: '#646464FF',
    [FLUID_INJECTION_RATE]: '#64646444',
  };
  const wellCounts = {
    [PRODUCER_COUNT]: '#00FF00FF',
    [INJECTOR_COUNT]: '#87CEFAFF',
    [ACTIVE_PRODUCER_COUNT]: '#00FF0066',
    [ACTIVE_INJECTOR_COUNT]: '#87CEFA66',
  };
  const cumulativeWellCountDataset =
    findCumulativeUniqueWellCount(datasetContent);
  const fieldLevelRateDataset = findFieldLevelData(datasetContent);
  fieldLevelRateDataset.forEach(row => {
    const resOilProd = row[OIL_PRODUCTION_RATE] * vfoValue;
    const resWaterProd = row[WATER_PRODUCTION_RATE] * vfwValue;
    const resWaterInj = row[WATER_INJECTION_RATE] * vfwValue;
    const resGasProd = row[GAS_PRODUCTION_RATE] * vfgValue;
    const resGasInj = row[GAS_INJECTION_RATE] * vfgValue;

    row[`${FLUID_PRODUCTION_RATE} (${RESERVOIR})`] =
      resOilProd + resWaterProd + resGasProd;
    row[`${FLUID_INJECTION_RATE} (${RESERVOIR})`] = resWaterInj + resGasInj;
  });
  const activeWellDataset = findActiveWellByDate(datasetContent);

  const mergedDatasetContent = fieldLevelRateDataset.map((row, index) => ({
    ...row,
    ...cumulativeWellCountDataset[index],
    ...activeWellDataset[index],
  }));

  const traces = [
    ...Object.entries(rates).map(([colName, color]) =>
      createLinePlotTrace(
        mergedDatasetContent.map(row => row[DATE]),
        mergedDatasetContent.map(row => row[`${colName} (${RESERVOIR})`]),
        `${colName} (${RESERVOIR})`,
        undefined,
        color,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'y1'
      )
    ),
    ...Object.entries(wellCounts).map(([colName, color]) =>
      createLinePlotTrace(
        mergedDatasetContent.map(row => row[DATE]),
        mergedDatasetContent.map(row => row[colName]),
        colName,
        undefined,
        color,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'y2'
      )
    ),
  ];

  const wrapped = wrapPlots(
    traces,
    DATE,
    `Production/Injection Rate (${UNIT_STD_LIQUID[unitSystem] + timePeriod})`,
    'Field FLuid Reservoir Rate Plot',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [0, 0.9],
    undefined,
    {
      yaxis2: {
        anchor: 'free',
        overlaying: 'y',
        position: 0.9,
        side: 'right',
        title: 'Well Count',
      },
    }
  );

  return wrapped;
};

export const generateVRRPlot = (
  datasetContent,
  vfoValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFO],
  vfwValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFW],
  vfgValue = SURVEILLANCE_DATASET_VISUALIZATION_PARAMS[FORMATION_VFG]
) => {
  const fieldLevelRateDataset = findFieldLevelData(datasetContent);

  fieldLevelRateDataset.forEach(row => {
    const resOilProd = row[OIL_PRODUCTION_RATE] * vfoValue;
    const resWaterProd = row[WATER_PRODUCTION_RATE] * vfwValue;
    const resWaterInj = row[WATER_INJECTION_RATE] * vfwValue;
    const resGasInj = row[GAS_INJECTION_RATE] * vfgValue;

    row['VRR'] = (resWaterInj + resGasInj) / (resOilProd + resWaterProd);
  });

  return wrapPlots(
    [
      createLinePlotTrace(
        fieldLevelRateDataset.map(row => row[DATE]),
        fieldLevelRateDataset.map(row => row['VRR']),
        'VRR'
      ),
    ],
    DATE,
    `Dimensionless Quantity`,
    'VRR Plot'
  );
};

// TODO: include volume factors to calculations.
export const generateFieldWellCountAnalysis = (
  datasetContent,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const colNames = {
    [INJECTOR]: [
      `${FLUID_INJECTION_RATE} (${RESERVOIR})`,
      ACTIVE_INJECTOR_COUNT,
    ],
    [PRODUCER]: [
      `${FLUID_PRODUCTION_RATE} (${RESERVOIR})`,
      ACTIVE_PRODUCER_COUNT,
    ],
  };
  const colorMap = {
    [`${FLUID_INJECTION_RATE} (${RESERVOIR})`]: '#64646444',
    [`${FLUID_PRODUCTION_RATE} (${RESERVOIR})`]: '#646464FF',
    [ACTIVE_INJECTOR_COUNT]: '#87CEFAFF',
    [ACTIVE_PRODUCER_COUNT]: '#00FF00FF',
  };

  Object.entries(colNames).forEach(([wellType, colNamePair]) => {
    colNamePair.forEach((colName, index) =>
      traces.push(
        createLinePlotTrace(
          datasetContent[wellType].map(row => row[DATE]),
          datasetContent[wellType].map(row => row[colName]),
          colName,
          undefined,
          colorMap[colName],
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          `y${index + 1}`
        )
      )
    );
  });

  return wrapPlots(
    traces,
    DATE,
    `Production/Injection Rate (${UNIT_STD_LIQUID[unitSystem] + timePeriod})`,
    'Field FLuid Reservoir Rate Plot',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [0, 0.9],
    undefined,
    {
      yaxis2: {
        anchor: 'free',
        overlaying: 'y',
        position: 0.9,
        side: 'right',
        title: 'Well Count',
      },
    }
  );
};

export const generateVRRPlotV2 = datasetContent => {
  return wrapPlots(
    [
      createLinePlotTrace(
        datasetContent.map(row => row[DATE]),
        datasetContent.map(row => row['VRR']),
        'VRR'
      ),
    ],
    DATE,
    `Dimensionless Quantity`,
    'VRR Plot'
  );
};

export const generateFieldReservoirAnalysisPlot = datasetContent => {
  const dates = datasetContent.map(row => row[DATE]);
  const traces = [
    {
      x: dates,
      y: datasetContent.map(row => row[LIQUID_PRODUCTION_RATE]),
      name: LIQUID_PRODUCTION_RATE,
      xaxis: 'x',
      yaxis: 'y',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[OIL_PRODUCTION_RATE]),
      name: OIL_PRODUCTION_RATE,
      xaxis: 'x2',
      yaxis: 'y2',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[GAS_PRODUCTION_RATE]),
      name: GAS_PRODUCTION_RATE,
      xaxis: 'x3',
      yaxis: 'y3',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[ACTIVE_PRODUCER_COUNT]),
      name: ACTIVE_PRODUCER_COUNT,
      xaxis: 'x',
      yaxis: 'y4',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[WATER_CUT]),
      name: WATER_CUT,
      xaxis: 'x2',
      yaxis: 'y5',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[GAS_INJECTION_RATE]),
      name: GAS_INJECTION_RATE,
      xaxis: 'x3',
      yaxis: 'y6',
    },
    {
      x: dates,
      y: datasetContent.map(row => row[GOR]),
      name: GOR,
      xaxis: 'x3',
      yaxis: 'y6',
    },
  ];

  const layout = {
    title: 'Field Reservoir Analysis Plot',
    grid: { rows: 3, columns: 1, pattern: 'independent' },
    yaxis4: { anchor: 'x', overlaying: 'y', side: 'right' },
    yaxis5: { anchor: 'x2', overlaying: 'y2', side: 'right' },
    yaxis6: { anchor: 'x3', overlaying: 'y3', side: 'right' },
  };

  return { data: traces, layout, config: { responsive: true } };
};
