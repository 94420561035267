// Workflow Stage Names
export const STG_ML_CONN = 'ML Connectivity';
export const STG_DIAGNOSTIC_PLOT = 'Diagnostic Plot';
export const STG_FLUID_MATCHING = 'Fluid Matching';
export const STG_OIL_MATCHING = 'Oil Matching';
export const STG_OPTIMIZATION = 'Optimization';
// Result File Names
// |__ ML Connectivity
export const FILTERED_DATASET_CSV = 'filtered_dataset.csv';
export const ADVANCED_ANALYSIS_DATASET_CSV = 'advance_analysis_df.csv';
export const WELL_PAIRED_ML_CONN_RESULT_CSV =
  'ml_connectivity_result_*_VS_*.csv';
export const WELL_PAIRED_ML_CONN_CALC_SCORE_CSV =
  'calculation_scores_*_VS_*.csv';
export const DIAGNOSTIC_RESULTS_JSON = 'diagnostic_results.json';
// |__ CROSS CORRELATION
export const CROSS_CORR_RESULT_CSV = 'cross_correlation_results.csv';
// |__ CRMP
export const CRMP_GAIN_VALUES_CSV = 'crmp_gain_values.csv';
export const FLUID_PRODUCTION_CSV = 'fluid_production.csv';
export const FLUID_MATCHING_CSV = 'fluid_matching_solutions.csv';
export const R2_RMSE_TOTAL_FLUID_CSV = 'r2_rmse_total_fluid.csv';
export const R2_RMSE_OIL_MATCHING_CSV = 'r2_rmse_oil_matching.csv';
export const OIL_MATCHING_CSV = 'oil_matching.csv';
export const MAX_PROD_RES_CSV = 'max_prod_res.csv';
export const MAX_PROD_RES_FACTORS_CSV = 'max_prod_res_factors.csv';
export const FUTURE_MAX_PROD_RES_CSV = 'future_max_prod_res.csv';
export const FUTURE_MAX_PROD_RES_FACTORS_CSV =
  'future_max_prod_res_factors.csv';
// COLUMN NAMES
export const STAGE = 'stage';
export const FLUID = 'Total Well Rate (res)';
export const WATER = 'Water Injection Rate';
export const THP = 'Tubing Head Pressure';
export const OIL = 'Oil Production Rate';
export const WATER_CUT = 'Water cut';
export const CORR_SERIES = 'CORR_SERIES';
export const CORR_TYPE = 'CORR_TYPE';
export const LOWER_LIMIT = 'LOWER_LIMIT';
// "Fluid"= "fluid"
// "Water"= "water"
// "Bottom_Hole_Pressure"= "bhp"
// "Tubing_Head_Pressure"= "thp"
// "Oil"= "oil"
// "Water_Cut"= "wc"
// "Oil_Cut"= "oc"
// "GOR"= "gor"
// "Active_Days"= "active_days"
// "Choke"= "choke"
export const MODEL_TYPE = 'Model Type';
export const I_SHIFT = 'I_SHIFT';
export const X_COL = 'x_column';
export const Y_COL = 'y_column';
export const START_INDEX = 'start_index';
export const END_INDEX = 'end_index';
export const X_SCALE = 'xscale';
export const Y_SCALE = 'yscale';
export const LOG = 'lg';
export const FITTING_SCORE = 'Fitting score';
export const MODEL_COEF = 'Model coef';
export const MODEL_INTERCEPT = 'Model intercept';
export const X_SLICE = 'x_slice';
export const Y_PRED = 'y_pred';
