import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStyles from '../../../Styles/WorkflowSelectionStyle';

import Navbar from '../../Components/NavBar';
import CRMP from './WorkflowArgumentViews/CRMP';
// import CrossCorrelation from './WorkflowArgumentViews/CrossCorrelation';
import MLConnectivity from './WorkflowArgumentViews/MLConnectivity';
// import CrossComparison from './WorkflowArgumentViews/CrossComparison';
// import MLRatePrediction from './WorkflowArgumentViews/MLRatePrediction';
// import TrendAnalysis from './WorkflowArgumentViews/TrendAnalysis';
// import DiagnosticPlots from './WorkflowArgumentViews/DiagnosticPlots';
// import CRMT from './WorkflowArgumentViews/CRMT';
import CRMP_Layer from './WorkflowArgumentViews/CRMP_Layer';
// import Conformance from './WorkflowArgumentViews/Conformance';
// import ArpsDeclineCurve from './WorkflowArgumentViews/ArpsDeclineCurve';
// import PolymerInjection from './WorkflowArgumentViews/PolymerInjection';
// import MultiWorkflowComparison from './WorkflowArgumentViews/MultiWorkflowComparison';

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const selectedDatasetSubscriber = state => state.dataset.selectedDataset;
const selectedDatasetParamsSubscriber = state => state.dataset.parameters;
const allWorkflowsSubscriber = state => state.workflow.allWorkflows;
const injWellNamesSubscriber = state => state.dataset.injWellNames;
const prodWellNamesSubscriber = state => state.dataset.prodWellNames;
// const wellTimeLineSubscriber = state => state.dataset.wellTimeLine;

const SurveillanceWorkflowSelection = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { datasetId: urlDatasetId } = useParams();

  const reduxAllWorkflows = useSelector(allWorkflowsSubscriber);
  const reduxInjWellNames = useSelector(injWellNamesSubscriber);
  const reduxProdWellNames = useSelector(prodWellNamesSubscriber);
  const reduxSelectedDataset = useSelector(selectedDatasetSubscriber);
  // const reduxWellTimeline = useSelector(wellTimeLineSubscriber);
  const reduxSelectedDatasetParams = useSelector(
    selectedDatasetParamsSubscriber
  );

  const [workflowDetails, setworkflowDetails] = useState({
    selectedWorkflow: null,
    isSubmitted: false,
    status: false,
  });

  // Callback/Helpers -->
  const submitWorkflow = status => {
    setworkflowDetails(previous => {
      return { ...previous, isSubmitted: true, status };
    });
  };
  // <-- Callback/Helpers

  // Handlers -->
  const onHome = () => {
    navigate('/homepage');
  };

  /**
   * Navigate to help page.
   */
  const onHelpClick = () => {
    navigate('/help');
  };

  const onSelectWorkflow = workflow => {
    setworkflowDetails({
      selectedWorkflow: workflow,
      isSubmitted: false,
      status: false,
    });
  };
  // <-- Handlers

  // Effects -->
  // Redux state is lost on page refresh, so we need to check if the dataset is still selected.
  useEffect(() => {
    if (reduxSelectedDataset === null) {
      navigate('/uploadSurveillance');
    }
  }, [reduxSelectedDataset, navigate]);
  // <-- Effects

  const WORKFLOW_NAMES = {
    // 'Arps Decline Curve': (
    //   <ArpsDeclineCurve
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //     prodWellNames={reduxProdWellNames}
    //   />
    // ),
    // 'Cross Correlation': (
    //   <CrossCorrelation
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    // 'ML Rate Prediction': (
    //   <MLRatePrediction
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    // 'Diagnostic Plots': (
    //   <DiagnosticPlots
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    'ML Connectivity': (
      <MLConnectivity
        submitWorkflow={submitWorkflow}
        reduxSelectedDataset={reduxSelectedDataset}
        reduxSelectedDatasetParams={reduxSelectedDatasetParams}
      />
    ),
    // 'Trend Analysis': (
    //   <TrendAnalysis
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    // Conformance: (
    //   <Conformance
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //     prodWells={reduxInjWellNames}
    //     injWells={reduxProdWellNames}
    //   />
    // ),
    'CRMP Layer': (
      <CRMP_Layer
        submitWorkflow={submitWorkflow}
        reduxSelectedDataset={reduxSelectedDataset}
        reduxSelectedDatasetParams={reduxSelectedDatasetParams}
        injWells={reduxInjWellNames}
        prodWells={reduxProdWellNames}
      />
    ),
    CRMP: (
      <CRMP
        submitWorkflow={submitWorkflow}
        reduxSelectedDataset={reduxSelectedDataset}
        reduxSelectedDatasetParams={reduxSelectedDatasetParams}
        reduxAllWorkflows={reduxAllWorkflows}
        injWells={reduxInjWellNames}
        prodWells={reduxProdWellNames}
      />
    ),
    // 'Multi Workflow Comparison': (
    //   <MultiWorkflowComparison
    //     submitWorkflow={submitWorkflow}
    //     reduxAllWorkflows={reduxAllWorkflows}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    // CRMT: (
    //   <CRMT
    //     submitWorkflow={submitWorkflow}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //     reduxWellTimeLine={reduxWellTimeline}
    //   />
    // ),
    // 'Cross Workflow Comparison': (
    //   <CrossComparison
    //     submitWorkflow={submitWorkflow}
    //     reduxAllWorkflows={reduxAllWorkflows}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
    // 'Polymer Injection': (
    //   <PolymerInjection
    //     submitWorkflow={submitWorkflow}
    //     reduxAllWorkflows={reduxAllWorkflows}
    //     reduxSelectedDataset={reduxSelectedDataset}
    //     reduxSelectedDatasetParams={reduxSelectedDatasetParams}
    //   />
    // ),
  };

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sideBarGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <List>
              {Object.keys(WORKFLOW_NAMES).map(workflow => (
                <React.Fragment key={'header-' + workflow}>
                  <ListItem
                    button
                    selected={workflow == workflowDetails.selectedWorkflow}
                    key={'listItem-' + workflow}
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      onSelectWorkflow(workflow);
                    }}
                  >
                    <ListItemText>{workflow}</ListItemText>
                  </ListItem>
                  <Divider variant="middle" />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.argumentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <Grid item className={styles.argumentConainer}>
              {workflowDetails.selectedWorkflow ? (
                <React.Fragment>
                  {workflowDetails.isSubmitted ? (
                    <Grid item className={styles.flexCenter}>
                      {workflowDetails.status ? (
                        <React.Fragment>
                          <CheckCircleOutlineRoundedIcon
                            style={{ fontSize: '5em', color: 'green' }}
                          />
                          <Typography variant="h4" style={{ color: 'green' }}>
                            Operation Successful
                          </Typography>
                          <Button
                            variant="contained"
                            className={`${styles.button} xlargeButton`}
                            onClick={onHome}
                          >
                            Back to Home
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <HighlightOffRoundedIcon
                            style={{ fontSize: '5em', color: 'red' }}
                          />
                          <Typography variant="h4" style={{ color: 'red' }}>
                            Operation Unsuccessful
                          </Typography>
                          <Button
                            variant="outlined"
                            className={`${styles.button} xlargeButton`}
                            onClick={onHome}
                          >
                            Back to Home
                          </Button>
                        </React.Fragment>
                      )}
                    </Grid>
                  ) : (
                    WORKFLOW_NAMES[workflowDetails.selectedWorkflow]
                  )}
                </React.Fragment>
              ) : (
                <Grid item className={styles.flexCenter}>
                  <Typography variant="h4" style={{ opacity: '0.5' }}>
                    Choose a Workflow
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item className={styles.footer}>
              <Button variant="contained" id="Help" onClick={onHelpClick}>
                Help
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SurveillanceWorkflowSelection;
