import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../Components/LoadingPage';

import { Grid, TextField, Typography } from '@material-ui/core';
import { INJECTOR, PRODUCER } from '../../../../../constants/WellConstants';
import { generateConnectivityGraph } from '../../../../../Utils/CommonReportUtil';

const ResultAccuracyPlot = ({
  header,
  fieldInjNames,
  fieldProdNames,
  resultFileNames,
  workflowResultFiles,
  filteredDatasetContent,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [networkPlot, setnetworkPlot] = useState(null);
  const [threshold, setthreshold] = useState(0.15);

  // Callbacks -->
  /**
   * This function iterates over each result file and counts number of result files
   * that agrees with given threshold value.
   */
  const generateResultFileAccuracyMatrix = () => {
    const wellGainValueAccuracyMap = [];

    // For each injector, assign counter value of 0 for every other producer, Then for each
    // result file, check if there is a gain value between the two wells that is greated than
    // specified threshold. If so, increase the counter for the two wells.
    fieldInjNames.forEach(injName => {
      const injAccuracyMap = { [INJECTOR]: injName };
      fieldProdNames.forEach(prodName => {
        injAccuracyMap[prodName] = 0;
        resultFileNames.forEach(fileName => {
          const injData = workflowResultFiles[fileName].find(
            row => row[INJECTOR] == injName
          );
          if (
            injData &&
            injData[prodName] != undefined &&
            Number(injData[prodName]) >= threshold
          ) {
            injAccuracyMap[prodName] += 1;
          }
        });
      });
      wellGainValueAccuracyMap.push(injAccuracyMap);
    });

    return wellGainValueAccuracyMap;
  };

  const generateNetworkPlotDataset = gainValueDataset => {
    setnetworkPlot(
      generateConnectivityGraph(
        filteredDatasetContent,
        gainValueDataset,
        'Accuracy',
        undefined,
        undefined,
        undefined,
        undefined,
        header
      )
    );
  };
  // <-- Callbacks

  // Handlers -->
  const onThresholdChange = event => {
    setthreshold(event.target.value);
  };
  // <-- Handlers

  useEffect(() => {
    if (
      fieldInjNames &&
      fieldProdNames &&
      resultFileNames &&
      workflowResultFiles &&
      filteredDatasetContent
    ) {
      const accuracyMatrix = generateResultFileAccuracyMatrix();
      const wellPairAccuracies = [];

      fieldInjNames.forEach(inj => {
        fieldProdNames.forEach(prod => {
          const injGainValues = accuracyMatrix.find(
            row => row[INJECTOR] == inj
          );

          wellPairAccuracies.push({
            [INJECTOR]: inj,
            [PRODUCER]: prod,
            ['Accuracy']: injGainValues[prod],
          });
        });
      });

      generateNetworkPlotDataset(wellPairAccuracies);
    }
  }, [
    threshold,
    fieldInjNames,
    fieldProdNames,
    resultFileNames,
    workflowResultFiles,
    filteredDatasetContent,
  ]);

  return (
    <Grid container className={styles.visualContent} id={header}>
      <Grid container className={styles.visualContentRow}>
        <Typography gutterBottom>{header}</Typography>
      </Grid>
      <Grid container className={styles.inputContentRow}>
        <TextField
          size="small"
          type="number"
          label="Threshold"
          variant="outlined"
          className="numericInput"
          value={threshold}
          onChange={onThresholdChange}
          InputProps={{
            inputProps: {
              min: 0,
              max: 1,
              step: 0.05,
            },
          }}
        />
      </Grid>
      {networkPlot ? (
        <PlotlyComponent
          data={networkPlot.data}
          layout={networkPlot.layout}
          config={networkPlot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Result Accuracy Network Plot is Loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

ResultAccuracyPlot.propTypes = {
  header: PropTypes.string,
  fieldInjNames: PropTypes.array,
  fieldProdNames: PropTypes.array,
  resultFileNames: PropTypes.array,
  workflowResultFiles: PropTypes.array,
  filteredDatasetContent: PropTypes.array,
};

export default ResultAccuracyPlot;
