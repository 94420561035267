import {
  INJECTOR,
  PRODUCER,
  OVERALL_SCORE,
  FEATURE_IMPORTANCE,
} from '../../../../constants/WellConstants';

// Callbacks/Helpers -->
/**
 * From the retrieved Analysis data, filter neccessary parts.
 */
export const analyzeDataSummaryValues = (
  filteredWorkflowResult,
  producerCalcScores = undefined
) => {
  // Filter values
  const impArr = filteredWorkflowResult.map(
    row => Math.round(Number(row[FEATURE_IMPORTANCE]) * 100) / 100
  );
  const wellScoreArr = //optional
    producerCalcScores &&
    producerCalcScores.map(
      row => Math.round(Number(row[OVERALL_SCORE]) * 100) / 100
    );
  const injectors = [
    ...new Set(filteredWorkflowResult.map(row => row[INJECTOR])),
  ];
  const producers = [
    ...new Set(filteredWorkflowResult.map(row => row[PRODUCER])),
  ];

  const injWellNames = injectors.sort();
  const prodWellNames = producers.sort();
  const selectedProducer = producers[0];
  const selectedInjector = injectors[0];
  const minImp = Math.min(...impArr);
  const maxImp = Math.max(...impArr);
  const minWellScore = wellScoreArr && Math.min(...wellScoreArr);
  const maxWellScore = wellScoreArr && Math.max(...wellScoreArr);
  const tableData = [
    {
      'Num. of Injectors': injectors.length,
      'Num. of Producers': producers.length,
      'Min Feature Importance': Math.min(...impArr),
      'Max Feature Importance': Math.max(...impArr),
    },
  ];

  return {
    injWellNames,
    prodWellNames,
    selectedProducer,
    selectedInjector,
    minImp,
    maxImp,
    minWellScore,
    maxWellScore,
    tableData,
  };
};

/**
 * This function finds and sets min/max Feature importance values of the well
 * filtered workflow analysis.
 */
export const findImportanceLagValues = filteredWorkflowAnalysisContent => {
  const impValues = [];

  filteredWorkflowAnalysisContent?.forEach(dataRow => {
    impValues.push(Number(dataRow[FEATURE_IMPORTANCE]));
  });

  return {
    minImp: Math.min(...impValues),
    maxImp: Math.max(...impValues),
  };
};

/**
 * This function saves Importance values for selected Producer well.
 */
export const findInjectorImpData = filteredWorkflowAnalysisContent => {
  const injectorImportances = {};

  filteredWorkflowAnalysisContent?.forEach(row => {
    injectorImportances[row[INJECTOR]] = Number(row[FEATURE_IMPORTANCE]);
  });

  return injectorImportances;
};

/**
 * This function extracts the correlation pairs from the parameters object.
 * @param {Object} params
 * @returns {Object} availableCorrPairs, selectedCorrPair
 *
 */
export function getCorrelationPairs(workflowParameters) {
  if (!workflowParameters) return [null, null];

  const injSignals = workflowParameters?.['Injector Signals'];
  const prodSignals = workflowParameters?.['Producer Signals'];

  const availableCorrPairs =
    injSignals && prodSignals
      ? { [INJECTOR]: [...injSignals], [PRODUCER]: [...prodSignals] }
      : null;

  const selectedCorrPair = [injSignals[0], prodSignals[0]];

  return [availableCorrPairs, selectedCorrPair];
}
