import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../../Styles/UploadStyle';
import Navbar from '../Components/NavBar';
import DropfileZone from '../Components/DropfileZone';

import {
  deleteDataset,
  getAllDatasets,
  setSelectedDataset,
} from '../../redux/actions/dataset';

import { filterRecentWorkflows } from '../../Utils/DatasetUtils/DataProcessing';

import { updateSnackBar } from '../../redux/actions/feedback';

import {
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  ListSubheader,
} from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { SURVEILLANCE } from '../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';
import { getAllWorkflows } from '../../redux/actions/workflows';
import LogoComponent from '../Components/LogoComponent';

const allDatasetsSubscriber = state => state.dataset.datasets;
const allWorkflowsSubscriber = state => state.workflow.allWorkflows;

const UploadSurveillance = () => {
  const isUploadDisabled = false;
  const isRemoveDatasetDisabled = false;

  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxDatasets = useSelector(allDatasetsSubscriber);
  const reduxAllWorkflows = useSelector(allWorkflowsSubscriber);

  const [recentWorkflows, setrecentWorkflows] = useState(null);
  const [selectedDataset, setselectedDataset] = useState('');
  const [expandStates, setexpandStates] = useState({});

  // Callbacks/Helpers -->
  /**
   * Each workflow will be represented as expandable accordion format. This functions sets their expand
   * state to all false, meaning not expanding.
   */
  const setWorkflowExpandStates = recentWorkflows => {
    let expandObject = {};
    recentWorkflows.forEach(workflow => {
      expandObject[workflow.workflow_id] = false;
    });
    setexpandStates(expandObject);
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * Upon dataset selection from dropdown, save selected dataset to useState variable; save selected
   * dataset on redux state and retrieve content of this dataset from database and save it to redux state.
   *
   * @param {Event} event
   */
  const onDatasetSelect = async event => {
    event.stopPropagation();
    const dataset = event.target.value;
    setselectedDataset(dataset);
    try {
      dispatch(setSelectedDataset(dataset.dataset_id));
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while selecting dataset.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
    }
  };

  const onDatasetRemove = async datasetId => {
    await dispatch(deleteDataset(datasetId));
  };
  /**
   * Update selected workflow's expand state.
   * @param {Number} selectedWorkflowId
   */
  const onExpand = selectedWorkflowId => {
    let updatedSelectedWorkflow = {
      [selectedWorkflowId]: !expandStates[selectedWorkflowId],
    };

    setexpandStates(previousState => {
      return { ...previousState, ...updatedSelectedWorkflow };
    });
  };

  /**
   * Procceeds to visualization page of selected dataset.
   */
  const onContinueClick = () => {
    navigate(`/surveillanceDatasetVisualization/${selectedDataset.dataset_id}`);
  };
  // <-- Handlers

  // Fetch all workflows if not saved in redux state already.
  useEffect(() => {
    if (reduxAllWorkflows.length == 0) {
      dispatch(getAllWorkflows());
    }
  }, []);

  // Fetch all datasets if not saved in redux state already.
  useEffect(() => {
    if (reduxDatasets.length == 0) {
      dispatch(getAllDatasets());
    }
  }, []);

  // Fetch all the workflows to populate recent worklfow section.
  useEffect(() => {
    if (reduxAllWorkflows && reduxAllWorkflows.length != 0) {
      const recentWorkflows = filterRecentWorkflows(
        reduxAllWorkflows,
        SURVEILLANCE
      );
      setrecentWorkflows(recentWorkflows);
      setWorkflowExpandStates(recentWorkflows);
    }
  }, [reduxAllWorkflows]);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          <Paper className={styles.sidebarPaper} elevation={20}>
            <List
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={styles.sidebarListSubHeader}
                >
                  Recent Workflows
                </ListSubheader>
              }
              dense={true}
            >
              {recentWorkflows && recentWorkflows.length > 0 ? (
                recentWorkflows.map(workflow => {
                  return (
                    <Fragment
                      key={`${workflow.workflow_id}-${workflow.workflow_type}`}
                    >
                      <ListItem
                        button
                        key={`${workflow.workflow_id}-${workflow.type}-listItem`}
                        onClick={() => onExpand(workflow.workflow_id)}
                      >
                        {expandStates[workflow.workflow_id] ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                        <ListItemText
                          primary={
                            workflow.name
                              ? workflow.name
                              : workflow.workflow_type
                          }
                        />
                      </ListItem>
                      <Collapse
                        in={expandStates[workflow.workflow_id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List dense={true} component="div" disablePadding>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Module Type
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.module_type}
                            </Typography>
                          </ListItem>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Worklfow Type
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.workflow_type}
                            </Typography>
                          </ListItem>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Workflow Name
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.name}
                            </Typography>
                          </ListItem>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Workflow Desctription
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.description}
                            </Typography>
                          </ListItem>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Worklfow Status
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.status}
                            </Typography>
                          </ListItem>
                          <ListItem className={styles.textArgument}>
                            <Typography variant="body2" className="left">
                              Dataset
                            </Typography>
                            <Typography variant="body2" className="right">
                              {workflow.file_name.split('_').slice(1).join('_')}
                            </Typography>
                          </ListItem>
                        </List>
                      </Collapse>
                    </Fragment>
                  );
                })
              ) : (
                <ListItem>No recent workflows available</ListItem>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.uploadGrid}>
          <Paper className={styles.paper} elevation={20}>
            <Grid container className={styles.datasetControlGrid}>
              {!isUploadDisabled && (
                <Grid item className={styles.datasetUploadGrid}>
                  <Typography className={styles.title}>
                    Upload Dataset
                  </Typography>
                  <Grid item className={styles.dropZone}>
                    <DropfileZone datasetType={SURVEILLANCE} />
                  </Grid>
                </Grid>
              )}
              <Grid item className={styles.datasetSelectGrid}>
                <Typography className={styles.title}>Select Dataset</Typography>
                <FormControl variant="filled" className={styles.formControl}>
                  <InputLabel>Dataset</InputLabel>
                  <Select value={selectedDataset} onChange={onDatasetSelect}>
                    {reduxDatasets.length != 0
                      ? reduxDatasets
                          .filter(
                            dataset => dataset['dataset_type'] == SURVEILLANCE
                          )
                          .map(dataset => (
                            <MenuItem
                              key={`${dataset.dataset_id} ${dataset.dataset_name} option`}
                              value={dataset}
                              style={{ justifyContent: 'space-between' }}
                            >
                              {dataset.dataset_name}
                              {!isRemoveDatasetDisabled && (
                                <IconButton
                                  size="small"
                                  onClick={event => {
                                    event.stopPropagation();
                                    onDatasetRemove(dataset.dataset_id);
                                  }}
                                >
                                  <DeleteForeverRoundedIcon />
                                </IconButton>
                              )}
                            </MenuItem>
                          ))
                      : ''}
                  </Select>
                </FormControl>
                <Grid item className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    className={styles.continueBt}
                    disabled={selectedDataset == '' ? true : false}
                    onClick={onContinueClick}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadSurveillance;
