import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../../../Components/LoadingPlot';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import {
  retrieveStaticGainValuesResult,
  retrieveDynamicGainValuesResult,
  retrieveCumulativeFieldRatesResult,
} from '../../../../../../API/Functions/CRMP';
import {
  retrieveFieldTimeline,
  retrieveFieldWellNames,
  retrieveWellSurfaceLocations,
} from '../../../../../../API/Functions/Visualization';

import { genWellConnAnalysisPlot } from '../../../../../../Utils/CommonReportUtil';
import {
  genCumFluidPieChart,
  genDynamicGainValuePlot,
  genGainValuePieChart,
} from '../../../../../../Utils/WorkflowReportUtils/CRMPUtils';

import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';
import {
  GAIN,
  INJECTOR,
  PRODUCER,
  SYSTEM_OF_UNITS,
} from '../../../../../../constants/WellConstants';

import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

export const InjectorGainValueAnalysis = () => {
  return <WellOrientedGainValueAnalysis wellType={INJECTOR} />;
};

export const ProducerGainValueAnalysis = () => {
  return <WellOrientedGainValueAnalysis wellType={PRODUCER} />;
};

const WellOrientedGainValueAnalysis = ({ wellType }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [params, setParams] = useState({
    [wellType]: null,
  });

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchFieldData() {
      try {
        const fieldWellNames = await retrieveFieldWellNames(
          reduxSelectedWorkflow.dataset_id
        );
        const fieldTimeline = await retrieveFieldTimeline(
          reduxSelectedWorkflow.dataset_id
        );
        const wellDetails = await retrieveWellSurfaceLocations(
          reduxSelectedWorkflow.dataset_id,
          reduxSelectedWorkflow.parameters
        );
        if (mounted) {
          setapiRes({
            wellDetails,
            fieldTimeline,
            fieldWellNames,
          });
          setParams({ [wellType]: fieldWellNames[wellType][0] });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchFieldData();
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    async function fetchFieldResults() {
      try {
        const wellStaticGainValues = await retrieveStaticGainValuesResult(
          reduxSelectedWorkflow.workflow_id,
          params
        );
        const wellDynamicGainValues = await retrieveDynamicGainValuesResult(
          reduxSelectedWorkflow.workflow_id,
          { ...params, minGain: 0.01 }
        );
        const cumFluidRates = await retrieveCumulativeFieldRatesResult(
          reduxSelectedWorkflow.workflow_id,
          { ...params, wellType }
        );

        if (mounted) {
          setapiRes(prev => ({
            ...prev,
            wellStaticGainValues,
            wellDynamicGainValues,
            cumFluidRates,
          }));
        }
      } catch (error) {
        dispatch(
          updateSnackBar(error?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    params[wellType] && fetchFieldResults();
    return () => (mounted = false);
  }, [params[wellType]]);

  // hooks -->
  const cachedNetworkPlot = useMemo(() => {
    if (apiRes?.wellDetails && apiRes?.wellStaticGainValues) {
      return genWellConnAnalysisPlot({
        wellsLocationDetails: apiRes.wellDetails,
        wellsGainValues: apiRes.wellStaticGainValues,
        title: `${params[wellType]} ${wellType} ${GAIN} Value Analysis`,
        unitSystem: reduxSelectedWorkflow['parameters'][SYSTEM_OF_UNITS],
      });
    }
  }, [apiRes]);

  const cachedLinePlot = useMemo(() => {
    if (apiRes?.wellDetails && apiRes?.wellStaticGainValues) {
      return genDynamicGainValuePlot({
        primaryWellGroup: wellType,
        selectedWell: params[wellType],
        dynamicGainValues: apiRes.wellDynamicGainValues,
        title: `Connected Well ${GAIN} Values - ${params[wellType]}`,
        yTitle: 'Dynamic Gain Value',
      });
    }
  }, [apiRes]);

  const cachedCumPieChart = useMemo(() => {
    if (apiRes?.cumFluidRates) {
      return genCumFluidPieChart({
        wellType,
        cumFluidRates: apiRes.cumFluidRates,
        unitSystem: reduxSelectedWorkflow['parameters'][SYSTEM_OF_UNITS],
      });
    }
  }, [apiRes]);

  const cachedGainValuePieChart = useMemo(() => {
    if (apiRes?.wellStaticGainValues) {
      return genGainValuePieChart({
        wellType,
        selectedWell: params[wellType],
        gainValues: apiRes.wellStaticGainValues,
        unitSystem: reduxSelectedWorkflow['parameters'][SYSTEM_OF_UNITS],
      });
    }
  }, [apiRes]);

  // <-- hooks

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onWellSelect = event => {
    setParams({
      [wellType]: event.target.value,
    });
    // setSelectedWell(event.target.value);
  };
  // <-- Handlers

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <FormControl size="small" variant="outlined">
            <Select
              value={params[wellType]}
              onChange={onWellSelect}
              className="dropdownSelection"
            >
              {apiRes?.fieldWellNames[wellType].map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {cachedNetworkPlot ? (
          <PlotlyComponent
            data={cachedNetworkPlot.data}
            layout={cachedNetworkPlot.layout}
            config={cachedNetworkPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        {cachedLinePlot ? (
          <PlotlyComponent
            data={cachedLinePlot.data}
            layout={cachedLinePlot.layout}
            config={cachedLinePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Grid container>
          <Grid item xs={6}>
            {cachedCumPieChart ? (
              <PlotlyComponent
                data={cachedCumPieChart.data}
                layout={cachedCumPieChart.layout}
                config={cachedCumPieChart.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPlot />
            )}
          </Grid>
          <Grid item xs={6}>
            {cachedGainValuePieChart ? (
              <PlotlyComponent
                data={cachedGainValuePieChart.data}
                layout={cachedGainValuePieChart.layout}
                config={cachedGainValuePieChart.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPlot />
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

WellOrientedGainValueAnalysis.propTypes = {
  wellType: PropTypes.string,
};
