import React from 'react';
import PropTypes from 'prop-types';

import HistoryShutInAnalysis from './HistoryShutInAnalysis/HistoryShutInAnalysis';
import FuturePrediction from './FuturePrediction';

const Results = ({
  workflowParameters,
  fieldWellLocations,
  staticGainValues,
  injShutOffFluidData,
  injShutOffOilData,
  productionData,
  futureProductionData,
  factors,
  oilContribution,
  oilContrSum,
  futureInjSens,
  injectionRates,
}) => {
  const historyDisabled = false;
  const futureDisabled = false;

  return (
    <React.Fragment>
      {!historyDisabled && (
        <HistoryShutInAnalysis
          fieldWellLocations={fieldWellLocations}
          workflowParameters={workflowParameters}
          injShutOffFluidData={injShutOffFluidData}
          injShutOffOilData={injShutOffOilData}
          factors={factors}
          staticGainValues={staticGainValues}
          oilContribution={oilContribution}
          oilContrSum={oilContrSum}
        />
      )}
      {!futureDisabled && (
        <FuturePrediction
          productionData={productionData}
          futureProductionData={futureProductionData}
          futureInjSens={futureInjSens}
          injectionRates={injectionRates}
          workflowParameters={workflowParameters}
        />
      )}
    </React.Fragment>
  );
};

Results.propTypes = {
  workflowParameters: PropTypes.object,
  fieldWellLocations: PropTypes.array,
  staticGainValues: PropTypes.array,
  injShutOffFluidData: PropTypes.array,
  injShutOffOilData: PropTypes.array,
  productionData: PropTypes.array,
  futureProductionData: PropTypes.array,
  factors: PropTypes.array,
  oilContribution: PropTypes.array,
  oilContrSum: PropTypes.array,
  futureInjSens: PropTypes.array,
  injectionRates: PropTypes.array,
};

export default Results;
