import React from 'react';
import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CheckCircle, Cancel } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const IntegerFieldRuleDialog = ({ open, onClose, fieldName }) => (
  <BootstrapDialog
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <DialogTitle
      style={{ backgroundColor: '#003333', color: '	#ffffff' }}
      sx={{ m: 0, p: 2 }}
      id="customized-dialog-title"
    >
      Rules for {fieldName} field
    </DialogTitle>
    <DialogContent dividers>
      <div style={{ padding: '20px' }}>
        {/* Rule 1 */}
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
          Rule 1: Value should be an Integer
        </Typography>
        <TableContainer
          component={Paper}
          style={{ maxWidth: 400, margin: '20px 0' }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>a</TableCell>
                <TableCell>
                  <Cancel color="error" /> Incorrect value
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>test</TableCell>
                <TableCell>
                  <Cancel color="error" /> Incorrect value
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>124</TableCell>
                <TableCell>
                  <CheckCircle color="success" /> Correct value
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </BootstrapDialog>
);

IntegerFieldRuleDialog.propTypes = {
  open: PropTypes.object,
  onClose: PropTypes.object,
  fieldName: PropTypes.string,
};
