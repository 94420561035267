import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';

import { BasicTable } from '../../../../Components/TableView';
import LoadingPage from '../../../../Components/LoadingPage';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { filterWellNamesByLayer } from '../../../../../Utils/DatasetUtils/DataProcessing';
import {
  generateLayeredRatePlot,
  generateOilProductionRatePlot,
} from '../../../../../Utils/WorkflowReportUtils/CRMPLayerUtils';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Paper,
  FormHelperText,
  Link,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  ALL_LAYERS,
  OIL,
  WELL_ID,
} from '../../../../../constants/WellConstants';

const Results = ({
  filteredDatasetContent,
  workflowParameter,
  r2Oil,
  oilRateAll,
  oilRateHistory,
  prodWellNames,
  layers,
  header,
  fieldLayerData,
  fieldData,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [selectedLayer, setselectedLayer] = useState(ALL_LAYERS);
  const [selectedProdWell, setselectedProdWell] = useState(null);

  const [layerOptions, setlayerOptions] = useState([]);
  const [prodWellOptions, setprodWellOptions] = useState([]);

  // const [wellFilteredDataset, setwellFilteredDataset] = useState(null);
  const [oilProductionRatePlot, setoilProductionRatePlot] = useState(null);
  const [fieldOilRatePlot, setfieldOilRatePlot] = useState(null);

  const [encodedUri, setencodedUri] = useState('');

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onWellSelect = wellName => {
    setselectedProdWell(wellName);
    setoilProductionRatePlot(
      generateOilProductionRatePlot(
        wellName,
        filteredDatasetContent.filter(row => row[WELL_ID] == wellName),
        oilRateHistory,
        oilRateAll,
        layers,
        workflowParameter
      )
    );
  };

  const onLayerSelect = layerName => {
    const { producers } = filterWellNamesByLayer(
      filteredDatasetContent,
      layerName
    );
    setselectedLayer(layerName);
    setprodWellOptions(producers);
    onWellSelect(producers[0]);
  };

  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (
      filteredDatasetContent != null &&
      layers != null &&
      prodWellNames != null
    ) {
      setlayerOptions(layers);
      setselectedLayer(layers[0]);

      setprodWellOptions(prodWellNames);
      setselectedProdWell(prodWellNames[0]);

      setoilProductionRatePlot(
        generateOilProductionRatePlot(
          prodWellNames[0],
          filteredDatasetContent.filter(
            row => row[WELL_ID] == prodWellNames[0]
          ),
          oilRateHistory,
          oilRateAll,
          layers,
          workflowParameter
        )
      );

      setfieldOilRatePlot(
        generateLayeredRatePlot(
          fieldLayerData,
          fieldData,
          workflowParameter,
          OIL
        )
      );
    }
  }, [filteredDatasetContent, layers, prodWellNames]);

  return (
    <React.Fragment>
      <Grid container id={header} className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item container className={styles.inputContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <Select
              value={selectedLayer}
              onChange={event => onLayerSelect(event.target.value)}
            >
              {layerOptions.map(layer => (
                <MenuItem key={layer} value={layer}>
                  {layer}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Layer</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <Select
              value={selectedProdWell}
              onChange={event => onWellSelect(event.target.value)}
            >
              {prodWellOptions.map(well => (
                <MenuItem key={well} value={well}>
                  {well}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Well</FormHelperText>
          </FormControl>
        </Grid>
        {oilProductionRatePlot ? (
          <PlotlyComponent
            data={oilProductionRatePlot.data}
            layout={oilProductionRatePlot.layout}
            config={oilProductionRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Oil Prodcution Rate plot is loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        {fieldOilRatePlot ? (
          <PlotlyComponent
            data={fieldOilRatePlot.data}
            layout={fieldOilRatePlot.layout}
            config={fieldOilRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Field Oil Rate plot is loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        {r2Oil ? (
          <React.Fragment>
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable data={r2Oil} />
            </Paper>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">Download R2</Typography>
                <Link
                  download="R2.csv"
                  href={encodedUri}
                  onClick={() => onDownload(r2Oil)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid container className={styles.visualContentRow}>
            <LoadingPage message="R2 table is loading..." goHome={false} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

Results.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowParameter: PropTypes.object,
  r2Oil: PropTypes.array,
  oilRateAll: PropTypes.array,
  oilRateHistory: PropTypes.array,
  prodWellNames: PropTypes.array,
  layers: PropTypes.array,
  header: PropTypes.string,
  fieldLayerData: PropTypes.array,
  fieldData: PropTypes.array,
};

export default Results;
