import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../redux/actions/user';
import { updateSnackBar } from '../../redux/actions/feedback';

import useStyles from '../../Styles/LoginStyle';
import { Grid, TextField, Typography, Button } from '@material-ui/core';

//import Navbar from '../Components/NavBar';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';
import logo from '../../resermine_logo.png';

const Login = () => {
  const styles = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setusername] = useState();
  const [password, setpassword] = useState();

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const handleUsernameChange = event => {
    setusername(event.target.value);
  };
  const handlePasswordChange = event => {
    setpassword(event.target.value);
  };

  const onSuccess = async () => {
    dispatch(updateSnackBar('Login Successful!', SNACK_BAR_SEVERITY.success));
    await new Promise(resolve => setTimeout(resolve, 1000)); // Human friendly wait.
    navigate('/homepage');
  };

  const onFail = error => {
    try {
      if (error.response.status == 308) {
        dispatch(
          updateSnackBar('Please reset your password', SNACK_BAR_SEVERITY.info)
        );
        navigate(`/resetPassword/${error.response.data.user_id}`);
      } else {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      }
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while logging in.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
  };

  /**
   * Upon user login, this function makes POST request to server with provided credidentials.
   * @param {*} event
   */
  const handleUserLogin = async event => {
    event.preventDefault();
    await dispatch(userLogin({ username, password }, onSuccess, onFail));
  };
  // <-- Handlers

  return (
    <Grid container className={styles.root}>
      {/* <Grid item className={styles.navGrid}>
        <Navbar loggedIn={false} />
      </Grid> */}
      <Grid item container className={styles.body}>
        <form
          className={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handleUserLogin}
        >
          <img className={styles.logo} src={logo} alt="Resermine_Logo" />
          <Typography className={styles.name}>CoreIntellect</Typography>
          <TextField
            className={styles.textField}
            label="Username"
            variant="standard"
            size="small"
            onChange={handleUsernameChange}
          />
          <TextField
            className={styles.textField}
            label="Password"
            type="password"
            variant="standard"
            size="small"
            onChange={handlePasswordChange}
          />
          <Button className={styles.button} type="submit" variant="contained">
            Login
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
