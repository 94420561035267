import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../../../Styles/WorkflowStyles';
import { useDispatch, useSelector } from 'react-redux';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../../../Components/LoadingPlot';
import { BasicTable } from '../../../../../Components/TableView';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import {
  retrieveFluidProdRateResult,
  retrieveR2RMSETotalFluidResult,
} from '../../../../../../API/Functions/CRMP';
import {
  generateFieldProductionData,
  generateFluidProductionPlot,
  getFluidProductionProducers,
} from '../../../../../../Utils/WorkflowReportUtils/CRMPUtils';

import {
  PER_DAY,
  SYSTEM_OF_UNITS,
} from '../../../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';

import { Grid, IconButton, Link, Paper, Typography } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const FluidProductionRateComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * Downloads the r2RMSE result to clients machine.
   */
  const onDowload = () => {
    const content = [];

    apiRes['r2TotalFluidResults'].forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const fluidProdRateResults = await retrieveFluidProdRateResult(
          reduxSelectedWorkflow['workflow_id']
        );

        const r2TotalFluidResults = await retrieveR2RMSETotalFluidResult(
          reduxSelectedWorkflow['workflow_id']
        );
        if (mounted) {
          setapiRes({
            fluidProdRateResults,
            r2TotalFluidResults,
          });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, []);

  const cachedPlotResult = useMemo(() => {
    if (apiRes?.fluidProdRateResults && apiRes?.r2TotalFluidResults) {
      const prods = getFluidProductionProducers(apiRes.fluidProdRateResults);

      return generateFluidProductionPlot({
        fieldFluidProductionData: generateFieldProductionData(
          apiRes.fluidProdRateResults,
          prods
        ),
        workflowParameters: reduxSelectedWorkflow.parameters,
        prods,
        unitSystem: reduxSelectedWorkflow.parameters[SYSTEM_OF_UNITS],
        timePeriod: PER_DAY,
      });
    }
  }, [apiRes, reduxSelectedWorkflow]);

  return (
    <React.Fragment>
      <Grid item container className={styles.visualContent}>
        {cachedPlotResult ? (
          <PlotlyComponent
            data={cachedPlotResult.data}
            layout={cachedPlotResult.layout}
            config={cachedPlotResult.config}
            style={{ flex: 1 }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContentRow}>
        {apiRes?.r2TotalFluidResults ? (
          <Paper elevation={5}>
            <BasicTable data={apiRes.r2TotalFluidResults} />
          </Paper>
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">
            Download R2 RMSE Total Fluid
          </Typography>
          <Link
            download="R2_RMSE_Total_Fluid.csv"
            href={encodedUri}
            onClick={onDowload}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FluidProductionRateComponent;
