import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';

import Login from './views/Login';
import Homepage from './views/Homepage';
import ContactUsPage from './views/ContactUsPage';
import Workspace from './views/Workspace';
import HelpPage from './views/Components/HelpPage';
import ResetPasswordPage from './views/Login/ResetPassword';
import UploadIntegration from './views/Integration/Upload';
import UploadSurveillance from './views/Surveillance/Upload';
import BaseWorkflowPage from './views/Components/BaseWorkflow';
import IntegrationWorkflowSelection from './views/Integration/WorkflowSelection';
import SurveillanceWorkflowSelection from './views/Surveillance/WorkflowSelection';
import IntegrationDatasetVisualization from './views/Integration/DatasetVisualization';
import SurveillanceDatasetVisualization from './views/Surveillance/Visualization';

const authSubscriber = state => state.user.isAuthenticated;

function PrivateRoute({ children }) {
  const authed =
    useSelector(authSubscriber) ||
    localStorage.getItem('x-access-token')?.length > 0;

  return authed ? children : <Navigate to={{ pathname: '/login' }} replace />;
}

PrivateRoute.propTypes = {
  children: PropTypes.object,
};

function PublicRoute({ children }) {
  const authed =
    useSelector(authSubscriber) ||
    localStorage.getItem('x-access-token')?.length > 0;

  return authed ? (
    <Navigate to={{ pathname: '/homepage' }} replace />
  ) : (
    children
  );
}

PublicRoute.propTypes = {
  children: PropTypes.object,
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/resetPassword/:userId"
          element={<ResetPasswordPage />}
        />
        <Route exact path="/help" element={<HelpPage />} />
        <Route exact path="/contactUs" element={<ContactUsPage />} />
        <Route
          exact
          path="/homepage"
          element={
            <PrivateRoute>
              <Homepage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/workspace"
          element={
            <PrivateRoute>
              <Workspace />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/uploadSurveillance"
          element={
            <PrivateRoute>
              <UploadSurveillance />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/uploadIntegration"
          element={
            <PrivateRoute>
              <UploadIntegration />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/surveillanceDatasetVisualization/:datasetId"
          element={
            <PrivateRoute>
              <SurveillanceDatasetVisualization />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/integrationDatasetVisualization/:datasetId"
          element={
            <PrivateRoute>
              <IntegrationDatasetVisualization />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/surveillanceWorkflowSelection/:datasetId"
          element={
            <PrivateRoute>
              <SurveillanceWorkflowSelection />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/integrationWorkflowSelection/:datasetId"
          element={
            <PrivateRoute>
              <IntegrationWorkflowSelection />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/workflowResult/:workflowId"
          element={
            <PrivateRoute>
              <BaseWorkflowPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
