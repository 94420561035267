import {
  GOR,
  WOR,
  RATE,
  DATE,
  LAYER,
  OIL_CUT,
  WELL_ID,
  PRODUCER,
  INJECTOR,
  OIL_LOSS,
  WATER_CUT,
  Y_FUNCTION,
  X_FUNCTION,
  WATER_LOSS,
  COST_SAVINGS,
  UI_DATE_FORMAT,
  TOTAL_WELL_RATE,
  IS_INJECTOR_WELL,
  INJECTOR_RATE_COLS,
  PRODUCER_RATE_COLS,
  GAS_INJECTION_RATE,
  BACKEND_DATE_FORMAT,
  GAS_PRODUCTION_RATE,
  OIL_PRODUCTION_RATE,
  WATER_INJECTION_RATE,
  TOTAL_INJECTION_RATE,
  TOTAL_PRODUCTION_RATE,
  WATER_PRODUCTION_RATE,
  FLUID_PRODUCTION_RATE,
  CUMULATIVE_PRODUCTION_COLS,
  ADDITIONAL_INJECTOR_RATE_COLS,
  ADDITIONAL_PRODUCER_RATE_COLS,
  COLUMNS_NORMALIZED,
} from '../../constants/WellConstants';

import jszip from 'jszip';
import csv from 'csvtojson';
import moment from 'moment';
import {
  ACTIVE_INJECTOR_COUNT,
  ACTIVE_PRODUCER_COUNT,
  INJECTOR_COUNT,
  PRODUCER_COUNT,
} from '../../constants/WorkflowsParameterConstants';

/**
 * This function filters provided dataset by given column name and applies provided reducer function on it.
 *
 * FIXME: For some reason, sometimes column values appear to be out of order, i.e. dates might mix up and
 * cause graph to look incorrect, eventhough filtering process is correct. Make sure to sort after using it.
 *
 * TODO: Looks like not generic enough. Either make it exclusive for Integration, or make it more generic.
 *
 * @param {Array} datasetContent
 * @param {String} filterColumnName
 * @param {Function} reducerCallback
 * @param {String} attributeName
 * @returns Array of objects with specified attribute name-value pairs
 */
export const groupByAndApply = (
  datasetContent,
  filterColumnName,
  reducerCallback,
  attributeName
) => {
  if (
    filterColumnName in datasetContent[0] &&
    attributeName in datasetContent[0]
  ) {
    const columnValues = [
      ...new Set(datasetContent.map(row => row[filterColumnName])),
    ];
    const groupedDatasetContent = [];

    columnValues.forEach(colValue => {
      groupedDatasetContent.push({
        [filterColumnName]: colValue,
        [attributeName]: datasetContent
          .filter(row => row[filterColumnName] == colValue)
          .map(row => row[attributeName])
          .reduce(reducerCallback),
      });
    });

    return groupedDatasetContent;
  } else {
    return null;
  }
};

export const filterAvailaleColNamesFromDataset = colNames => {
  return {
    [INJECTOR]: [
      ...INJECTOR_RATE_COLS,
      ...ADDITIONAL_INJECTOR_RATE_COLS.filter(colName =>
        colNames.includes(colName)
      ),
    ],
    [PRODUCER]: [
      ...PRODUCER_RATE_COLS,
      ...ADDITIONAL_PRODUCER_RATE_COLS.filter(colName =>
        colNames.includes(colName)
      ),
    ],
  };
};

export const filterAvailaleCumulativeColNamesFromDataset = () => {
  return {
    X: [DATE, ...Object.keys(CUMULATIVE_PRODUCTION_COLS)],
    Y: [
      WATER_CUT,
      OIL_CUT,
      Y_FUNCTION,
      X_FUNCTION,
      WOR,
      GOR,
      OIL_PRODUCTION_RATE,
      WATER_PRODUCTION_RATE,
      TOTAL_PRODUCTION_RATE,
      ...Object.keys(CUMULATIVE_PRODUCTION_COLS),
    ],
  };
};

/**
 * Finds the suitable date format for an array of dates.
 *
 * @param {string[]} dates - The array of dates to find the format for.
 * @returns {string} The suitable date format.
 * @throws {Error} If no suitable date format is found.
 */
export function findDateFormat(dates) {
  const delimiters = ['.', '/', '-'];
  const delimiter = delimiters.filter(d => dates[0].includes(d))[0];
  const formats = [
    `M${delimiter}D${delimiter}YYYY`,
    `D${delimiter}M${delimiter}YYYY`,
    `M${delimiter}YYYY${delimiter}D`,
    `D${delimiter}YYYY${delimiter}M`,
    `YYYY${delimiter}M${delimiter}D`,
    `YYYY${delimiter}D${delimiter}M`,
  ];

  for (let format of formats) {
    if (dates.every(date => moment(date, format, true).isValid())) {
      return format;
    }
  }

  throw new Error('No suitable date format found.');
}

/**
 * Fixes the date format of a given date.
 * @param {string} date - The date to fix the format of.
 * @returns {string} The date with the fixed format.
 */
export const fixDateFormat = (date, desiredFormat = UI_DATE_FORMAT) => {
  let givenDateFormat;
  try {
    givenDateFormat = findDateFormat([date]);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while finding date format.', error);
    givenDateFormat = BACKEND_DATE_FORMAT;
  }
  if (givenDateFormat === desiredFormat) return date;
  return moment(date, givenDateFormat).format(desiredFormat);
};

/**
 * Converts the date values in the dataset to ISO format.
 *
 * @param {Array<Object>} datasetContent - The dataset content to be processed.
 * @returns {void}
 */
export const convertToISOFormat = datasetContent => {
  if (!(DATE in datasetContent[0]) || datasetContent.length < 1) return;

  datasetContent.forEach(
    row => (row[DATE] = fixDateFormat(row[DATE], UI_DATE_FORMAT))
  );
};

/**
 * Returns an array of all dates between the given start and end dates.
 *
 * @param {string} startDate - The start date in string format.
 * @param {string} endDate - The end date in string format.
 * @returns {string[]} - An array of dates between the start and end dates.
 */
function getAllDatesBetween(startDate, endDate) {
  const datesArray = [];
  let currentDate = moment(startDate);
  const lastDate = moment(endDate);

  while (currentDate <= lastDate) {
    datesArray.push(currentDate.format(UI_DATE_FORMAT));
    currentDate.add(1, 'M');
  }

  return datesArray;
}

/**
 * Filter each unique date from provied dataset, start to end.
 *
 * @param {Object} datasetContent
 * @returns An array of strings
 */
export const filterTimelineFromDataset = datasetContent => {
  const datasetDateFormat = findDateFormat(
    datasetContent.map(row => row[DATE])
  );
  // If dateset date format is not same as default date format, update them.
  if (datasetDateFormat !== UI_DATE_FORMAT) {
    datasetContent.forEach(
      row =>
        (row[DATE] = moment(row[DATE], datasetDateFormat).format(
          UI_DATE_FORMAT
        ))
    );
  }

  const dates = datasetContent.map(row => row[DATE]);
  dates.sort();

  return getAllDatesBetween(dates.at(0), dates.at(-1));
};

/**
 * Filter dataset by unique well names and separate them by their type, injector or producer.
 * @param {Object} datasetContent
 * @returns {Object} { injectors, producers }
 */
export const filterWellNamesFromDataset = datasetContent => {
  const injectors = [
    ...new Set(
      datasetContent
        .filter(
          row => row[IS_INJECTOR_WELL] == '1' || row[IS_INJECTOR_WELL] == 'True'
        )
        .map(well => well[WELL_ID])
    ),
  ];
  const producers = [
    ...new Set(
      datasetContent
        .filter(
          row =>
            row[IS_INJECTOR_WELL] == '0' || row[IS_INJECTOR_WELL] == 'False'
        )
        .map(well => well[WELL_ID])
    ),
  ];
  return { injectors, producers };
};

/**
 * Filter dataset by unique well names and separate them by their type, injector or producer.
 * @param {Object} datasetContent
 * @returns an object that containts unique injector and producer well names.
 */
export const filterWellContentsFromDataset = datasetContent => {
  const injectors = [
    ...new Set(
      datasetContent.filter(
        row => row[IS_INJECTOR_WELL] == '1' || row[IS_INJECTOR_WELL] == 'True'
      )
    ),
  ];
  const producers = [
    ...new Set(
      datasetContent.filter(
        row => row[IS_INJECTOR_WELL] == '0' || row[IS_INJECTOR_WELL] == 'False'
      )
    ),
  ];
  return { injectors, producers };
};

/**
 * This functions filters all the layers from passed datasetContent. Layer column may contain
 * multiple layer names, thus they need to be seperated and treated equally.
 *
 * @param {Object} datasetContent
 * @returns An array of strings
 */
export const filterDatasetLayers = datasetContent => {
  if (LAYER in datasetContent[0]) {
    const layers = new Set();
    datasetContent.forEach(row => {
      row[LAYER].split(',').forEach(layer => {
        if (layer) layers.add(layer);
      });
    });
    return [...layers];
  } else {
    return [];
  }
};
/**
 * If @datasetContent has any layers, finds them. Wells might appear at multiple layers,
 * hence LAYER field of each datarow assumed to be seperated by comma.
 *
 * @param {Object} datasetContent
 * @returns An array of strings
 */
export const saveDatasetLayers = datasetContent => {
  if (LAYER in datasetContent[0]) {
    const layers = new Set();
    datasetContent.forEach(row => {
      row[LAYER].split(',').forEach(layer => {
        if (layer) layers.add(layer);
      });
    });
    return [...layers];
  } else {
    return [];
  }
};

/**
 * This function accepts a layer and filters workflow analysis content by this layer,
 * and selected injector well.
 * @param {Array} datasetcontent
 * @param {String} selectedLayer
 * @returns {Array} layerFilteredDatasetContent
 */
export const filterDatasetContentByLayer = (datasetContent, selectedLayer) => {
  let filtered = datasetContent.filter(row => {
    let layers = row[LAYER].split(',');
    if (layers.length > 1) {
      return layers.includes(selectedLayer);
    } else {
      return layers[0] == selectedLayer;
    }
  });
  return filtered;
};

/**
 * This function filters selectedWell data from datasetContent, then finds each layer that is
 * connected to selectedLayer.
 * @param {Array} datasetContent
 * @param {String} selectedWell
 * @returns
 */
export const findConnectedLayersOfWell = (
  datasetContent,
  selectedWell,
  gainValues
) => {
  const layers = new Set();
  const datasetLayers = filterDatasetLayers(
    datasetContent.filter(row => row[WELL_ID] == selectedWell)
  );

  gainValues.forEach(row => {
    row[LAYER].split(',').forEach(layer => {
      if (datasetLayers.includes(layer)) layers.add(layer);
    });
  });

  return [...layers];
};

/**
 * This function filters datasetContent by selectedLayer and then filters injector wells in this layer.
 * @param {Array} datasetContent
 * @param {String} selectedLayer
 * @returns {Object} {injectors, producers}
 */
export const filterWellNamesByLayer = (datasetContent, selectedLayer) => {
  const { injectors, producers } = filterWellNamesFromDataset(
    datasetContent.filter(row => {
      let layers = row[LAYER].split(',');

      if (layers.length > 1) {
        return layers.includes(selectedLayer);
      } else {
        return layers[0] == selectedLayer;
      }
    })
  );
  return { injectors, producers };
};

/**
 * This function finds the gain value between provided injector and producer wells from
 * workflowAnalysisContent. This dataset contains gain value between each injector and
 * producer in a matrix form where columns are producer names and rows are injectors.
 *
 * @param {Array} workflowAnalysisContent,
 * @param {String} injectorName
 * @param {String} producerName
 * @returns {Number}
 */
export const findGainValueFromDataset = (
  workflowAnalysisContent,
  injectorName,
  producerName
) => {
  if (workflowAnalysisContent.length > 0)
    if (INJECTOR in workflowAnalysisContent[0]) {
      const wellPair = workflowAnalysisContent.find(
        row => row[INJECTOR] == injectorName
      );
      // If selected injector exist in the given dataset, return the gain value for given producer, else 0.
      return wellPair?.[producerName] || 0;
    } else {
      const wellPair = workflowAnalysisContent.find(
        row => row[PRODUCER] == producerName
      );
      // If selected producer exist in the given dataset, return the gain value for given injector, else 0.
      return wellPair?.[injectorName] || 0;
    }
  else return 0;
};

/**
 * This function filters top specified amount from well data by their value, and joins rest under name others.
 * @param {Object} wellData
 * @param {Number} topLimit
 * @return {Object} shrunkData
 */
export const filterTopWellsByValue = (wellData, topLimit) => {
  const shrunkData = { others: 0 };

  const entries = Object.entries(wellData).sort((a, b) => {
    return b[1] - a[1];
  });

  entries.splice(0, topLimit).forEach(([key, value]) => {
    shrunkData[key] = value;
  });

  entries.splice(topLimit, entries.length).forEach(([key, value]) => {
    shrunkData['others'] += value;
    key;
  });

  return shrunkData;
};

/**
 * Filters the top 5 wells based on a given column name.
 * @param {Array} wells - The array of wells to filter.
 * @param {string} colName - The name of the column to sort the wells by.
 * @returns {Array} - The filtered array of top wells.
 */
export function filterTopWells(wells, wellName, colName) {
  // Sort wells in descending order of fluid rate
  let sortedWells = [...wells].sort(
    (a, b) => Number(b[colName]) - Number(a[colName])
  );

  // Get top 5 wells
  let topWells = sortedWells.slice(0, 5);

  // If there are more than 5 wells, group the rest as 'Others'
  if (sortedWells.length > 5) {
    let others = sortedWells.slice(5).reduce(
      (acc, well) => {
        return {
          [wellName]: 'Others',
          [colName]: Number(acc[colName]) + Number(well[colName]),
        };
      },
      { [wellName]: 'Others', [colName]: 0 }
    );

    // Add 'Others' to the top wells array
    topWells.push(others);
  }

  return topWells;
}

/**
 * This function finds the gain value between provided injector and producer wells from
 * layer filtered workflowAnalysisContent. This dataset contains gain value between each
 * injector and producer in a matrix form where columns are producer names and rows are injectors.
 *
 * @param {Array} layerWorkflowAnalysisContent
 * @param {String} injectorName
 * @param {String} producerName
 * @returns {Number}
 */
export const findGainValue = (
  layerWorkflowAnalysisContent,
  injectorName,
  producerName
) => {
  const wellPair = layerWorkflowAnalysisContent.find(
    row => row[INJECTOR] == injectorName
  );
  // If selected injector exist in the given dataset, return the gain value for given producer, else 0.
  return wellPair?.[producerName] || 0;
};

/**
 * This function finds the Fluid Injection Rate value between provided injector and producer
 * wells from workflowAnalysisContent.
 * @param {Array} layerWorkflowAnalysisContent
 * @param {String} injectorName
 * @returns {Number}
 */
export const findRateValue = (layerworkflowAnalysisContent, injectorName) => {
  const injData = layerworkflowAnalysisContent.find(
    row => row[INJECTOR] == injectorName
  );
  // If selected injector exist in the given dataset, return the injection rate, else 0.
  return injData?.[RATE] || 0;
};

/**
 * This function finds the mean of passed dataset based on specified column name.
 *
 * @param {Array} datasetContent
 * @param {String} columnName
 * @returns
 */
export const findMeanOfDataset = (datasetContent, columnName) => {
  const mean =
    datasetContent
      .map(row => row[columnName])
      .reduce((previous, current) => Number(previous) + Number(current), 0) /
    datasetContent.length;

  return Math.trunc(mean * 1000) / 1000;
};

/**
 * This function finds the sum of passed dataset based on specified column name.
 *
 * @param {Array} datasetContent
 * @param {String} columnName
 * @returns
 */
export const findSumOfDataset = (datasetContent, columnName) => {
  const sum = datasetContent
    .map(row => row[columnName])
    .reduce((previous, current) => Number(previous) + Number(current), 0);

  return Math.trunc(sum * 1000) / 1000;
};

/**
 * This function calculates cost savings efficiency value of each data row in injector efficiency dataset.
 * Cost saving is calculated by, WATER_LOSS * waterCost - OIL_LOSS * oilCost formula.
 * @param {Array} injectorEfficiencyDataset
 * @param {Number} waterHandlingCost
 * @param {Number} oilPrice
 * @returns {Array}
 */
export const calculateInjectorEfficencyCostSavings = (
  injectorEfficiencyDataset,
  waterHandlingCost,
  oilPrice
) => {
  const waterLossColName =
    WATER_LOSS in injectorEfficiencyDataset[0]
      ? WATER_LOSS
      : WATER_LOSS + ', %';
  const oilLossColName =
    OIL_LOSS in injectorEfficiencyDataset[0] ? OIL_LOSS : OIL_LOSS + ', %';

  if (
    injectorEfficiencyDataset.length > 0 &&
    waterLossColName in injectorEfficiencyDataset[0] &&
    oilLossColName in injectorEfficiencyDataset[0]
  ) {
    return injectorEfficiencyDataset.map(dataRow => {
      return {
        ...dataRow,
        [COST_SAVINGS]:
          Number(dataRow[waterLossColName]) * Number(waterHandlingCost) -
          Number(dataRow[oilLossColName]) * Number(oilPrice),
      };
    });
  } else {
    return null;
  }
};

/**
 * This function populates given dataset with total production, injection and well rate data.
 * @param {Array} dataset
 * @returns {Array} - Enhanced dataset
 */
export const calculateTotalWellRates = dataset => {
  return dataset.map(dataRow => {
    return {
      ...dataRow,
      [TOTAL_PRODUCTION_RATE]:
        Number(dataRow[OIL_PRODUCTION_RATE]) +
        Number(dataRow[WATER_PRODUCTION_RATE]),
      [TOTAL_INJECTION_RATE]:
        Number(dataRow[GAS_INJECTION_RATE]) +
        Number(dataRow[WATER_INJECTION_RATE]),
      [TOTAL_WELL_RATE]:
        dataRow[TOTAL_PRODUCTION_RATE] > dataRow[TOTAL_INJECTION_RATE]
          ? dataRow[TOTAL_PRODUCTION_RATE]
          : dataRow[TOTAL_INJECTION_RATE],
    };
  });
};

/**
 * This function smooths all producer's selected category, total production rate by default,
 * by mimicing the numpy's convolve method.
 * All the changes are inplace.
 *
 * @param {Array} datasetContent
 * @param {Number} theta
 * @param {String} category
 */
export const smoothProducerWells = (
  datasetContent,
  theta = 5,
  categories = [
    OIL_PRODUCTION_RATE,
    GAS_PRODUCTION_RATE,
    WATER_PRODUCTION_RATE,
    FLUID_PRODUCTION_RATE,
  ]
) => {
  /**
   * Returns the discrete, linear convolution of two one-dimensional sequences.
   * If v is longer than a, the arrays are swapped before computation.
   *
   * @param {Array} a
   * @param {Array} v
   * @returns {Array} - Convoluded Array
   */
  function convolve(a, v) {
    const arr = a.length >= v.length ? a : v;
    const kernel = a.length >= v.length ? v : a;
    kernel.reverse();

    const padSize = Math.floor(kernel.length / 2);
    const convoludedArr = new Array(arr.length).fill(0);
    const paddedArr = [
      ...new Array(padSize).fill(0),
      ...arr,
      ...new Array(padSize).fill(0),
    ];

    for (let i = 0; i < convoludedArr.length; i++) {
      let kernelTotal = 0;
      for (let j = 0; j < kernel.length; j++) {
        kernelTotal += parseFloat(kernel[j]) * parseFloat(paddedArr[j + i]);
      }

      convoludedArr[i] = kernelTotal;
    }

    return convoludedArr;
  }

  const { producers } = filterWellNamesFromDataset(datasetContent);

  producers.forEach(prod => {
    categories.forEach(category => {
      const producerData = datasetContent.filter(row => row[WELL_ID] === prod);
      const wellCategoryValues = producerData.map(row => row[category]);
      const nonZeroValueCount = wellCategoryValues.filter(
        val => val > 0
      ).length;

      if (nonZeroValueCount > 0) {
        const kernelSize = Math.min(nonZeroValueCount, theta);
        const kernel = new Array(kernelSize).fill(1.0 / kernelSize);

        const convoludedValues = convolve(
          wellCategoryValues.filter(val => parseFloat(val) !== 0),
          kernel
        );

        let prodIndex = 0;
        let convIndex = 0;
        for (prodIndex; prodIndex < producerData.length; prodIndex++) {
          if (parseFloat(producerData[prodIndex][category]) !== 0) {
            producerData[prodIndex][category] = convoludedValues[convIndex];
            convIndex++;
          }
        }
      }
    });
  });
};

/**
 * Filters 6 most recent workflows from the passed workflows array. Filtering prioritizes workflow
 * update time if exist, else workflow creation time.
 *
 * @param {Array} workflows - Workflows to be filtered
 * @param {String} workflowType - Workflow type for extra filtering
 * @returns {Array}
 */
export const filterRecentWorkflows = (workflows, workflowType = undefined) => {
  let filteredWorkflows = workflows;
  if (workflowType) {
    filteredWorkflows = filteredWorkflows.filter(
      workflow => workflow['module_type'] == workflowType
    );
  }
  filteredWorkflows = filteredWorkflows.sort((workflow1, workflow2) => {
    let date1 = workflow1['time_updated']
      ? workflow1['time_updated']
      : workflow1['time_created'];
    let date2 = workflow2['time_updated']
      ? workflow2['time_updated']
      : workflow2['time_created'];
    if (moment(date1).isAfter(moment(date2))) {
      return -1;
    } else {
      return 1;
    }
  });
  return filteredWorkflows.slice(0, 6);
};

/**
 * This function converts API response data to CSV Array, and adjusts date format to year-month-date.
 * If requested CSV file has any specific format, refer to https://www.npmjs.com/package/csvtojson,
 * and provide required parameters as an object.
 *
 * @param {Object} responseData - response object's data field.
 * @param {Object} csvParams - parameters for proper conversion. {} is default.
 * @returns {Array} csvRowArray - Array of objects that represents each row.
 */
export const convertToCSVArray = async (
  responseData,
  csvParams = {},
  withDate = true
) => {
  let csvRowArray = await csv(csvParams).fromString(responseData);
  withDate &&
    csvRowArray.forEach(
      row => (row[DATE] = moment(row[DATE]).format(UI_DATE_FORMAT))
    );
  return csvRowArray;
};

/**
 * This function accepts zipped file and extracts csv files from it. Then it returns zipped files in a
 * object where fileName is the key, and csvArray is the value.
 *
 * csvParams
 *
 * @param {Object} responseData - API response that contains zipped file
 * @param {Object} csvParams - Object that contains file name - csv parameter key-value pairs.
 * @returns {Array} - csvFileContent
 */
export const unzipFiles = async (responseData, csvParams = {}) => {
  const csvFileContent = {};
  const zip = new jszip();

  const zipResponse = await zip.loadAsync(responseData);
  // "__" starting files are metafile, doesn't have any assoication with uploaded csv.
  const fileNames = Object.keys(zipResponse['files']).filter(
    name => !name.startsWith('__')
  );

  for (const fileName of fileNames) {
    let csvParam = csvParams[fileName] ? csvParams[fileName] : {};
    let fileRes = await zipResponse.file(fileName).async('string');
    csvFileContent[fileName] = fileName.includes('.csv')
      ? await csv(csvParam).fromString(fileRes)
      : fileRes;
  }

  return csvFileContent;
};

/**
 * This function moves an element in the array from one location to another.
 *
 * @param {Array} arr
 * @param {Number} fromIndex
 * @param {Number} toIndex
 */
export const arrayMove = (arr, fromIndex = 0, toIndex = 0) => {
  if (fromIndex < 0 || toIndex > arr.length - 1)
    throw new Error('Array indicies are not valid to move the element.');

  const element = arr[Math.floor(fromIndex)];
  arr.splice(Math.floor(fromIndex), 1);
  arr.splice(Math.floor(toIndex), 0, element);
};

/**
 * This function generates colun name ordering map for given dataset, by reordering
 * given column names, @param {columnsToMove}, to appear in front, and applies any
 * name change request based on given @param {namingMap}.
 *
 * @param {Array} dataset
 * @param {Array} columnsToMove
 * @param {Object} namingMap
 * @returns
 */
export const generateColumnOrderMapping = (
  dataset,
  columnsToMove = [],
  namingMap = {}
) => {
  const updatedNamingMap = {};
  let dataColNames = Object.keys(dataset[0]);
  // rearrange column names
  columnsToMove.forEach(colName =>
    arrayMove(dataColNames, dataColNames.indexOf(colName), 0)
  );
  // Apply any column naming change, if exist.
  dataColNames.forEach(
    colName =>
      (updatedNamingMap[colName] =
        colName in namingMap ? namingMap[colName] : colName)
  );

  return updatedNamingMap;
};

/**
 * This function removes wells from the dataset if thet have no total well rate.
 * @param {Array} datasetContent
 * @returns {Array}
 */
export const dropNoFluidRateWells = datasetContent => {
  try {
    const dropWellSet = new Set();
    const { producers: prodNames, injectors: injNames } =
      filterWellNamesFromDataset(datasetContent);

    prodNames.forEach(name => {
      if (
        datasetContent
          .filter(row => row[WELL_ID] == name)
          .map(row => Number(row[TOTAL_WELL_RATE]))
          .filter(value => value != 0).length <= 0
      ) {
        dropWellSet.add(name);
      }
    });
    injNames.forEach(name => {
      if (
        datasetContent
          .filter(row => row[WELL_ID] == name)
          .map(row => Number(row[TOTAL_WELL_RATE]))
          .filter(value => value != 0).length <= 0
      ) {
        dropWellSet.add(name);
      }
    });
    return [...datasetContent.filter(row => !dropWellSet.has(row[WELL_ID]))];
  } catch (error) {
    return datasetContent;
  }
};

/**
 * This function aims to sum all of the given features for eacy well records that occurred on
 * the same date. Essentially, if field data contains layer information, all of them will be
 * added up.
 *
 *
 * @param {Array} datasetContent - Field Data
 * @param {Array} featureList - List of column names that will be summmed.
 * @default featureList - [GAS_PRODUCTION_RATE, OIL_PRODUCTION_RATE, WATER_PRODUCTION_RATE, GAS_INJECTION_RATE, WATER_INJECTION_RATE]
 * @returns {Array} layerSumValues
 */
export const groupByWellAndDateToFindSumFeatures = (
  datasetContent,
  featureList = undefined
) => {
  if (featureList == undefined) {
    const { [INJECTOR]: injCols, [PRODUCER]: prodCols } =
      filterAvailaleColNamesFromDataset(Object.keys(datasetContent[0]));
    featureList = [...new Set([...injCols, ...prodCols])];
  }

  const layerSumValues = [];
  const wellDateDict = {};

  datasetContent.forEach(row => {
    // Add well oriented map where values are empty objects
    if (!(row[WELL_ID] in wellDateDict)) {
      wellDateDict[row[WELL_ID]] = {};
    }
    // Add date oriented map where values are objects with given feature lists being keys.
    if (!(row[DATE] in wellDateDict[row[WELL_ID]])) {
      wellDateDict[row[WELL_ID]][row[DATE]] = featureList.reduce(
        (a, c) => ({ ...a, [c]: 0 }),
        {}
      );
    }
    // Extract each specified feature from the dataset row, and save it.
    featureList.forEach(
      feature =>
        (wellDateDict[row[WELL_ID]][row[DATE]][feature] += Number(row[feature]))
    );

    wellDateDict[row[WELL_ID]][row[DATE]][IS_INJECTOR_WELL] =
      row[IS_INJECTOR_WELL];
  });
  // Flatten nested dicts into 1D array.
  Object.entries(wellDateDict).forEach(([wellName, dateDict]) => {
    Object.entries(dateDict).forEach(([date, featureDict]) => {
      layerSumValues.push({
        [WELL_ID]: wellName,
        [DATE]: date,
        ...featureDict,
      });
    });
  });

  return layerSumValues;
};

export const findFieldLevelData = (
  datasetContent,
  featureList = COLUMNS_NORMALIZED
) => {
  // Passed feature list has to be in the given dataset.
  const featureListNormalized = featureList.filter(feature =>
    Object.keys(datasetContent.at(0)).includes(feature)
  );
  const fieldTimeLine = filterTimelineFromDataset(datasetContent);

  // Construct a nested date-feature object structure where first layer keys are all the available
  // dates in the dataset, and second layer keys are features from featurelist.
  const dateFeatureMap = fieldTimeLine.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: featureListNormalized.reduce(
        (obj, feature) => ({ ...obj, [feature]: 0.0 }),
        {}
      ),
    }),
    {}
  );

  // Save each feature from dataset to our map
  datasetContent.forEach(row => {
    featureListNormalized.forEach(feature => {
      dateFeatureMap[row[DATE]][feature] += parseFloat(row[feature]);
    });
  });

  const flattenFieldData = [];
  Object.entries(dateFeatureMap).forEach(([date, fieldData]) => {
    flattenFieldData.push({
      [DATE]: date,
      ...fieldData,
    });
  });

  return flattenFieldData;
};

export const findCumulativeUniqueWellCount = datasetContent => {
  // Sort the dataset by date in ascending order
  const sortedDatasetContent = datasetContent.sort(
    (a, b) => new Date(a[DATE]) - new Date(b[DATE])
  );

  // Initialize the counters and a set to track unique wells
  let totalProducerCount = 0;
  let totalInjectorCount = 0;
  const uniqueProducers = new Set();
  const uniqueInjectors = new Set();

  // Object to hold the cumulative counts
  const dateWellCountMap = {};

  // Iterate over the sorted dataset
  sortedDatasetContent.forEach(row => {
    const wellId = row[WELL_ID];
    const isInjector =
      String(row[IS_INJECTOR_WELL]) === '1' ||
      String(row[IS_INJECTOR_WELL]) === 'True';

    // Check if the well is already counted; if not, update the counts
    if (isInjector) {
      if (!uniqueInjectors.has(wellId)) {
        uniqueInjectors.add(wellId);
        totalInjectorCount++;
      }
    } else {
      if (!uniqueProducers.has(wellId)) {
        uniqueProducers.add(wellId);
        totalProducerCount++;
      }
    }

    // Update the cumulative count for the current date
    dateWellCountMap[row[DATE]] = {
      [PRODUCER_COUNT]: totalProducerCount,
      [INJECTOR_COUNT]: totalInjectorCount,
    };
  });

  // Flatten the data for output
  const flattenFieldData = [];
  Object.entries(dateWellCountMap).forEach(([date, fieldData]) => {
    flattenFieldData.push({
      [DATE]: date,
      ...fieldData,
    });
  });

  return flattenFieldData;
};

export function findNormalizedUnitValue(rawValues, value2Normalize) {
  if (!Array.isArray(rawValues))
    throw Error('Error normalizing, non-array passed.');
  if (rawValues?.length < 2) return value2Normalize;

  const rawRange = [Math.min(...rawValues), Math.max(...rawValues)];

  return (rawRange[1] - value2Normalize) / (rawRange[1] - rawRange[0]);
}

/**
 * This function finds active wells by checking fluid injection and production data.
 *
 * @param {Array} datasetContent
 * @returns
 */
export function findActiveWellByDate(datasetContent) {
  const fieldTimeLine = filterTimelineFromDataset(datasetContent);
  const wellActivityDataByDate =
    groupByWellAndDateToFindSumFeatures(datasetContent);
  const activeWellMapByDate = fieldTimeLine.reduce((acc, date) => {
    if (!(date in acc)) {
      acc[date] = { [ACTIVE_INJECTOR_COUNT]: 0, [ACTIVE_PRODUCER_COUNT]: 0 };
    }
    return acc;
  }, {});

  wellActivityDataByDate.forEach(row => {
    if (
      row[OIL_PRODUCTION_RATE] +
        row[WATER_PRODUCTION_RATE] +
        row[GAS_PRODUCTION_RATE] >
      0
    )
      activeWellMapByDate[row[DATE]][ACTIVE_PRODUCER_COUNT] += 1;
    if (row[WATER_INJECTION_RATE] + row[GAS_INJECTION_RATE] > 0)
      activeWellMapByDate[row[DATE]][ACTIVE_INJECTOR_COUNT] += 1;
  });

  const flattenFieldData = [];
  Object.entries(activeWellMapByDate).forEach(([date, fieldData]) => {
    flattenFieldData.push({
      [DATE]: date,
      ...fieldData,
    });
  });

  return flattenFieldData;
}
