/**
 * This function converts given @param array, to multi dimensional nested array,
 * based on given @param shape.
 * @param {Array} array
 * @param {Array} shape
 * @returns {Array}
 */
export function reshapeArray(array, shape = []) {
  if (shape.length > 1) {
    const pageCount = shape[0];
    const pageSize = array.length / pageCount;
    const reshapedArray = [];

    if (array.length % pageCount == 0 && pageSize >= 1) {
      for (let index = 0; index < pageCount; index++) {
        reshapedArray.push(
          reshapeArray(
            array.slice(index * pageSize, (index + 1) * pageSize),
            shape.slice(1)
          )
        );
      }
      return reshapedArray;
    } else {
      throw Error('Invalid page count/size');
    }
  } else {
    return array;
  }
}
/**
 * This function creates 3D structured array, with all elements set to given value.
 * @param {Number} dimZ
 * @param {Number} dimX
 * @param {Number} dimY
 * @param {Number} value
 * @returns
 */
export function create3DArray(dimZ, dimX, dimY, value = 0) {
  let arr3 = [];
  for (let i = 0; i < dimZ; i++) {
    let arr2 = [];
    for (let j = 0; j < dimX; j++) {
      let arr1 = [];
      for (let k = 0; k < dimY; k++) {
        arr1.push(value);
      }
      arr2.push(arr1);
    }
    arr3.push(arr2);
  }
  return arr3;
}
/**
 * This function creates 2D structured array, with all elements set to given value.
 * @param {Number} dimX
 * @param {Number} dimY
 * @param {Number} value
 * @returns
 */
export function create2DArray(dimX, dimY, value = 0) {
  let arr2 = [];
  for (let j = 0; j < dimX; j++) {
    let arr1 = [];
    for (let k = 0; k < dimY; k++) {
      arr1.push(value);
    }
    arr2.push(arr1);
  }
  return arr2;
}
/**
 * This function operates 3D subtraction between 2, 3 dimensional nestes array. If
 * arrays ae not same dimensional, throws error.
 * @param {Array} shape1
 * @param {Array} shape2
 */
export function subtract3D(shape1, shape2) {
  if (
    shape1.length != shape2.length ||
    shape1[0].length != shape2[0].length ||
    shape1[0][0].length != shape2[0][0].length
  ) {
    throw Error('shapes are not same size');
  } else {
    const pageCount = shape1.length;
    const rowCount = shape1[0].length;
    const colCount = shape1[0][0].length;

    for (let page = 0; page < pageCount; page++) {
      for (let row = 0; row < rowCount; row++) {
        for (let col = 0; col < colCount; col++) {
          shape1[page][row][col] =
            Number(shape1[page][row][col]) - Number(shape2[page][row][col]);
        }
      }
    }
    return shape1;
  }
}

export function get3DShapeValues(
  rangeZ,
  rangeX = undefined,
  rangeY = undefined,
  shape
) {
  const values = [];
  for (let i = rangeZ[0]; i < rangeZ[1]; i++) {
    if (rangeX) {
      for (let j = rangeX[0]; j < rangeX[1]; j++) {
        if (rangeY) {
          for (let k = rangeY[0]; k < rangeY[1]; k++) {
            values.push(shape[i][j][k]);
          }
        } else {
          values.push(shape[i][j]);
        }
      }
    } else {
      values.push(shape[i]);
    }
  }
  return values;
}

export function arrayZipper() {
  let minValue = Number.MAX_SAFE_INTEGER;
  const zipped = [];

  for (let i = 0; i < arguments.length; i++) {
    if (arguments[i].length < minValue) {
      minValue = arguments[i].length;
    }
  }

  for (let i = 0; i < minValue; i++) {
    let pairs = [];
    for (let j = 0; j < arguments.length; j++) {
      pairs.push(arguments[j][i]);
    }
    zipped.push(pairs);
  }

  return zipped;
}

export function extractLayerFrom3D(dataset3D, layer, normalizeDataset = false) {
  let layerData = reshapeArray(
    get3DShapeValues(
      [0, dataset3D.length],
      [0, dataset3D[0].length],
      [layer, layer + 1],
      dataset3D
    ),
    [1, dataset3D.length * dataset3D[0].length]
  )[0];

  if (normalizeDataset) {
    const minValue = Math.min(...layerData);
    const maxValue = Math.max(...layerData);

    layerData = layerData.map(value =>
      isNaN((value - minValue) / (maxValue - minValue))
        ? 0
        : (value - minValue) / (maxValue - minValue)
    );
  }

  return layerData;
}
