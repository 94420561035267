import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  VISCOSITY_RATIO,
  N_YEARS,
} from '../../../../../constants/WorkflowsParameterConstants';

import useStyles from '../../../../../Styles/WorkflowStyles';
import { Button, Grid, TextField, Typography, Box } from '@material-ui/core';

const Parameters = ({ onSubmit, workflowParameters }) => {
  const styles = useStyles();
  const [viscosity, setViscosity] = useState(
    workflowParameters[VISCOSITY_RATIO]
  );
  const [nYears, setNYears] = useState(workflowParameters[N_YEARS]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      style={{ width: '100%', marginBottom: '2em', gap: '1em' }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        <Typography>Viscosity Ratio: </Typography>
        {/* <Grid item className={styles.rangeInputContainer}> */}
        <TextField
          type="Number"
          variant="outlined"
          size="small"
          disabled={isSubmitted}
          value={viscosity}
          onChange={event => {
            setViscosity(event.target.value);
          }}
        />
      </Box>
      {/* </Grid> */}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        <Typography>N Years: </Typography>
        {/* <Grid item className={styles.rangeInputContainer}> */}
        <TextField
          type="Number"
          variant="outlined"
          size="small"
          disabled={isSubmitted}
          value={nYears}
          onChange={event => {
            setNYears(event.target.value);
          }}
        />
      </Box>
      {/* </Grid> */}
      <Button
        variant="contained"
        className={styles.button + ' selectedButton'}
        disabled={isSubmitted}
        onClick={() => {
          setIsSubmitted(true);
          onSubmit(viscosity, nYears);
        }}
        style={{ textTransform: 'uppercase' }}
      >
        Rank Injectors
      </Button>
    </Grid>
  );
};

Parameters.propTypes = {
  onSubmit: PropTypes.func,
  workflowParameters: PropTypes.object,
};

export default Parameters;
