import {
  ADD_DATASET,
  DELETE_DATASET,
  SET_DATASETS,
  SET_SELECTED_DATASET,
  SET_WELL_TIMELINE,
  SET_INJ_WELLNAMES,
  SET_PROD_WELLNAMES,
  SET_DATASET_LAYERS,
  SET_DATASET_PARAMETERS,
  SET_DATASET_TIME_PERIOD,
} from '../actions/dataset/types';

const initialState = {
  datasets: [],
  selectedDataset: null,
  wellTimeLine: [],
  injWellNames: [],
  prodWellNames: [],
  layers: [],
  parameters: {},
  timePeriod: undefined,
};

export const datasetReducer = (state = initialState, action) => {
  switch (action.type) {
    // Set all the datasets in the redux state
    case SET_DATASETS: {
      return {
        ...state,
        datasets: action.payload,
      };
    }
    // Add dataset to redux state
    case ADD_DATASET: {
      return {
        ...state,
        datasets: state.datasets.concat(action.payload),
      };
    }
    // Remove a dataset from the redux state by filtering out the passed dataset_id
    case DELETE_DATASET: {
      let filtered = state.datasets.filter(
        dataset => dataset.dataset_id != action.payload
      );
      return {
        ...state,
        datasets: filtered,
      };
    }
    // Get a dataset from redux state
    case SET_SELECTED_DATASET: {
      return {
        ...state,
        selectedDataset: state.datasets.find(
          dataset => dataset.dataset_id == action.payload
        ),
      };
    }
    // Save selected dataset's timeline
    case SET_WELL_TIMELINE: {
      return {
        ...state,
        wellTimeLine: action.payload,
      };
    }
    // Save selected dataset's injector wells.
    case SET_INJ_WELLNAMES: {
      return {
        ...state,
        injWellNames: action.payload,
      };
    }
    // Save selected dataset's producer wells
    case SET_PROD_WELLNAMES: {
      return {
        ...state,
        prodWellNames: action.payload,
      };
    }
    // Save selected dataset's layers
    case SET_DATASET_LAYERS: {
      return {
        ...state,
        layers: action.payload,
      };
    }
    // Save selected dataset's parameters
    case SET_DATASET_PARAMETERS: {
      return {
        ...state,
        parameters: action.payload,
      };
    }
    // Save selected dataset's parameters
    case SET_DATASET_TIME_PERIOD: {
      return {
        ...state,
        timePeriod: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
