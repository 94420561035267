export const allDatasetsApiRoute = () => {
  return '/datasets';
};
export const addDatasetApiRoute = () => {
  return '/dataset';
};
export const getDatasetApiRoute = dataset_id => {
  return `/dataset/${dataset_id}`;
};
export const updateDatasetApiRoute = dataset_id => {
  return `/dataset/${dataset_id}`;
};
export const deleteDatasetApiRoute = dataset_id => {
  return `/dataset/${dataset_id}`;
};
