export const allFilesApiRoute = () => {
  return `/files`;
};
export const getFileApiRoute = dataset_id => {
  return `/file/${dataset_id}`;
};
export const getFileContentApiRoute = dataset_id => {
  return `/file/${dataset_id}/file_content`;
};
export const addFileApiRoute = dataset_id => {
  return `/file/${dataset_id}`;
};
export const deleteFileApiRoute = dataset_id => {
  return `/file/${dataset_id}`;
};
export const updateFileApiRoute = dataset_id => {
  return `/file/${dataset_id}`;
};
