import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';

import { BasicTable } from '../../../../Components/TableView';
import LoadingPage from '../../../../Components/LoadingPage';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { generateEfficiencyPlot } from '../../../../../Utils/PlotlyUtils/Plots';
import { getUnitRate } from '../../../../../Utils/ReservoirUtils';

import {
  saveDatasetLayers,
  generateColumnOrderMapping,
  calculateInjectorEfficencyCostSavings,
} from '../../../../../Utils/DatasetUtils/DataProcessing';

import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Link,
  IconButton,
  Paper,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  COST_SAVINGS,
  INJECTOR,
  LAYER,
  SYSTEM_OF_UNITS,
  XCOL,
  YCOL,
} from '../../../../../constants/WellConstants';
import { CRMP_LAYER_WORKFLOW_STAGES } from '../../../../../constants/WorkflowsParameterConstants';

const Results = ({
  filteredDatasetContent,
  workflowParameters,
  injEfficiencyData,
  injEfficiencyLayerData,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const [updateAnalysis, setupdateAnalysis] = useState(true);

  const [selectedLayer, setselectedLayer] = useState('');
  const [layerOptions, setlayerOptions] = useState([]);

  const [oilPrice, setoilPrice] = useState(60);
  const [waterCost, setwaterCost] = useState(1);

  const [proccessedEfficiencyData, setproccessedEfficiencyData] =
    useState(injEfficiencyData);
  const [proccessedEfficiencyLayerData, setproccessedEfficiencyLayerData] =
    useState(injEfficiencyLayerData);

  const [efficiencyColumnNameMap, setefficiencyColumnNameMap] = useState(null);
  const [efficiencyLayerColumnNameMap, setefficiencyLayerColumnNameMap] =
    useState(null);

  const [layerWellPlot, setlayerWellPlot] = useState(null);
  const [wellPlot, setwellPlot] = useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  const generateEfficiencyPlots = efficiencyDatasetContent => {
    setproccessedEfficiencyData(efficiencyDatasetContent);
    setwellPlot(
      generateEfficiencyPlot(
        filteredDatasetContent,
        efficiencyDatasetContent,
        COST_SAVINGS,
        `${XCOL} (${getUnitRate(workflowParameters[SYSTEM_OF_UNITS], XCOL)})`,
        `${YCOL} (${getUnitRate(workflowParameters[SYSTEM_OF_UNITS], YCOL)})`,
        `Injector Efficiency Analysis`
      )
    );
  };

  const generateEfficiencyLayerPlots = (
    efficiencyDatasetContent,
    selectedLayer
  ) => {
    const layerFilteredEfficiencyData = efficiencyDatasetContent.filter(
      row => row[LAYER] == selectedLayer
    );
    setproccessedEfficiencyLayerData(layerFilteredEfficiencyData);
    setlayerWellPlot(
      generateEfficiencyPlot(
        filteredDatasetContent,
        layerFilteredEfficiencyData,
        COST_SAVINGS,
        `${XCOL} (${getUnitRate(workflowParameters[SYSTEM_OF_UNITS], XCOL)})`,
        `${YCOL} (${getUnitRate(workflowParameters[SYSTEM_OF_UNITS], YCOL)})`,
        `Injector Efficiency Analysis (Layer ${selectedLayer})`
      )
    );
  };
  /**
   * This function processes the uploaded data and initializes the analysis report.
   */
  const preprocessDatasets = () => {
    const layers = saveDatasetLayers(filteredDatasetContent);
    const enhancedEfficiencyData = calculateInjectorEfficencyCostSavings(
      injEfficiencyData,
      waterCost,
      oilPrice
    );
    const enhancedEfficiencyLayerData = calculateInjectorEfficencyCostSavings(
      injEfficiencyLayerData,
      waterCost,
      oilPrice
    );

    setlayerOptions(layers);
    setselectedLayer(layers[0]);
    generateEfficiencyPlots(enhancedEfficiencyData);
    generateEfficiencyLayerPlots(enhancedEfficiencyLayerData, layers[0]);

    setefficiencyColumnNameMap(
      generateColumnOrderMapping(injEfficiencyData, [INJECTOR], {
        'water_loss, %': 'Water Loss (%)',
        'oil_loss, %': 'Oil Loss (%)',
        loss_ratio: 'Loss Ratio',
        'water_loss, V': 'Water Loss (V)',
        'oil_loss, V': 'Oil Loss (V)',
        I_ave: 'Injection Average',
      })
    );
    setefficiencyLayerColumnNameMap(
      generateColumnOrderMapping(injEfficiencyLayerData, [INJECTOR, LAYER], {
        'water_loss, %': 'Water Loss (%)',
        'oil_loss, %': 'Oil Loss (%)',
        loss_ratio: 'Loss Ratio',
        'water_loss, V': 'Water Loss (V)',
        'oil_loss, V': 'Oil Loss (V)',
        I_ave: 'Injection Average',
      })
    );
  };

  // <-- Callbacks/Helpers

  // Handlers -->
  const onOilPriceChange = value => {
    setoilPrice(value);
    setupdateAnalysis(false);
  };
  const onWaterCostChange = value => {
    setwaterCost(value);
    setupdateAnalysis(false);
  };
  const onLayerSelect = value => {
    const enhancedEfficiencyLayerData = calculateInjectorEfficencyCostSavings(
      injEfficiencyLayerData,
      waterCost,
      oilPrice
    );

    setselectedLayer(value);
    generateEfficiencyLayerPlots(enhancedEfficiencyLayerData, value);
  };
  const onParameterChange = () => {
    const enhancedEfficiencyLayerData = calculateInjectorEfficencyCostSavings(
      injEfficiencyLayerData,
      waterCost,
      oilPrice
    );
    const enhancedEfficiencyData = calculateInjectorEfficencyCostSavings(
      injEfficiencyData,
      waterCost,
      oilPrice
    );
    generateEfficiencyLayerPlots(enhancedEfficiencyLayerData, selectedLayer);
    generateEfficiencyPlots(enhancedEfficiencyData);
    setupdateAnalysis(true);
  };
  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (filteredDatasetContent && injEfficiencyData && injEfficiencyLayerData) {
      preprocessDatasets();
    }
  }, [filteredDatasetContent, injEfficiencyData, injEfficiencyLayerData]);

  if (
    workflowParameters.stage != CRMP_LAYER_WORKFLOW_STAGES.FLUID_MATCHING ||
    workflowParameters.stage != CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING
  ) {
    return (
      <React.Fragment>
        <Grid container id={header} className={styles.visualContentFlex}>
          <Grid item className={styles.visualContentRow}>
            <Typography variant="h5">{header}</Typography>
          </Grid>
          <Grid item className={`${styles.inputContentRow} multipleContent`}>
            <Typography>Oil Price ($/bbl)</Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={oilPrice}
              className="numericInput"
              onChange={event => onOilPriceChange(event.target.value)}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <Typography>Water Handling Cost($/bbl)</Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={waterCost}
              className="numericInput"
              onChange={event => onWaterCostChange(event.target.value)}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <Button
              disabled={updateAnalysis}
              variant="outlined"
              onClick={onParameterChange}
            >
              Calculate
            </Button>
          </Grid>
        </Grid>
        <Grid container className={styles.visualContentFlex}>
          <Grid item className={styles.visualContentRow}>
            <Typography>Layer Oriented Analysis</Typography>
          </Grid>
          <Grid item container className={styles.inputContentRow}>
            <FormControl
              variant="outlined"
              size="small"
              className="dropdownSelection"
            >
              <Select
                value={selectedLayer}
                onChange={event => onLayerSelect(event.target.value)}
              >
                {layerOptions.map(layer => (
                  <MenuItem key={layer} value={layer}>
                    {layer}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Layer</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container className={styles.visualContent}>
            {layerWellPlot ? (
              <PlotlyComponent
                data={layerWellPlot.data}
                layout={layerWellPlot.layout}
                config={layerWellPlot.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message="Layer well plot is loading..."
                goHome={false}
              />
            )}
          </Grid>
          {proccessedEfficiencyLayerData ? (
            <React.Fragment>
              <Paper elevation={5} className={styles.visualContentRow}>
                <BasicTable
                  data={proccessedEfficiencyLayerData}
                  columnNameMap={efficiencyLayerColumnNameMap}
                />
              </Paper>
              <Grid container className={styles.visualContentRow}>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="overline">Download Results</Typography>
                  <Link
                    download="Processed_Efficiency_Layer_Data.csv"
                    href={encodedUri}
                    onClick={() => onDownload(proccessedEfficiencyLayerData)}
                  >
                    <IconButton>
                      <GetAppRoundedIcon />
                    </IconButton>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item className={styles.visualContentRow}>
              <LoadingPage
                message="Efficiency layer data is loading..."
                goHome={false}
              />
            </Grid>
          )}
        </Grid>
        <Grid container className={styles.visualContentFlex}>
          <Grid item className={styles.visualContentRow}>
            <Typography>Well Oriented Analysis</Typography>
          </Grid>
          <Grid container className={styles.visualContent}>
            {wellPlot ? (
              <PlotlyComponent
                data={wellPlot.data}
                layout={wellPlot.layout}
                config={wellPlot.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage message="Well plot is loading..." goHome={false} />
            )}
          </Grid>
          {proccessedEfficiencyData ? (
            <React.Fragment>
              <Paper elevation={5} className={styles.visualContentRow}>
                <BasicTable
                  data={proccessedEfficiencyData}
                  columnNameMap={efficiencyColumnNameMap}
                />
              </Paper>
              <Grid container className={styles.visualContentRow}>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="overline">Download Results</Typography>
                  <Link
                    download="Processed_Efficiency_Data.csv"
                    href={encodedUri}
                    onClick={() => onDownload(proccessedEfficiencyData)}
                  >
                    <IconButton>
                      <GetAppRoundedIcon />
                    </IconButton>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item className={styles.visualContentRow}>
              <LoadingPage
                message="Efficiency data is loading..."
                goHome={false}
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
};

Results.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowParameters: PropTypes.object,
  injEfficiencyData: PropTypes.array,
  injEfficiencyLayerData: PropTypes.array,
  header: PropTypes.string,
};

export default Results;
