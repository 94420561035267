import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import LoadingPage from '../../../Components/LoadingPage';

import { Button, Grid, Slider, Typography } from '@material-ui/core';

import { CORRELATION } from '../../../../constants/WellConstants';

const FieldNetworkGraph = ({
  filteredDatasetContent,
  workflowAnalysisContent,
  minCC,
  maxCC,
  minLag,
  maxLag,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [corrSliderValue, setcorrSliderValue] = useState(null);
  const [lagSliderValue, setlagSliderValue] = useState(null);
  const [correlationPlot, setcorrelationPlot] = useState(null);

  // Callbacks/Helpers -->
  const updateNetworkGraph = (minCC, maxCC, minLag, maxLag) => {
    setcorrelationPlot(
      generateNetworkGraphWithLags(
        filteredDatasetContent,
        workflowAnalysisContent,
        CORRELATION,
        minCC,
        maxCC,
        minLag,
        maxLag,
        `Injectors-Producers ${CORRELATION}`
      )
    );
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  const corrSliderHandler = newValue => {
    setcorrSliderValue(newValue);
    updateNetworkGraph(
      newValue[0],
      newValue[1],
      lagSliderValue[0],
      lagSliderValue[1]
    );
  };

  const lagSliderHandler = newValue => {
    setlagSliderValue(newValue);
    updateNetworkGraph(
      corrSliderValue[0],
      corrSliderValue[1],
      newValue[0],
      newValue[1]
    );
  };

  const onThiefZoneClick = () => {
    const corrValues = [Math.round(maxCC * 0.66 * 100) / 100, maxCC];
    const lagValues = [minLag, Math.round(maxLag * 0.33)];

    setcorrSliderValue(corrValues);
    setlagSliderValue(lagValues);
    updateNetworkGraph(
      corrValues[0],
      corrValues[1],
      lagValues[0],
      lagValues[1]
    );
  };

  const onEffectiveZoneClick = () => {
    const corrValues = [Math.round(maxCC * 0.66 * 100) / 100, maxCC];
    const lagValues = [Math.round(maxLag * 0.7), maxLag];

    setcorrSliderValue(corrValues);
    setlagSliderValue(lagValues);
    updateNetworkGraph(
      corrValues[0],
      corrValues[1],
      lagValues[0],
      lagValues[1]
    );
  };
  // <-- Handlers

  // Slider value side effect.
  useEffect(() => {
    if (
      filteredDatasetContent &&
      workflowAnalysisContent &&
      minCC != undefined &&
      maxCC != undefined &&
      minLag != undefined &&
      maxLag != undefined
    ) {
      setcorrSliderValue([minCC, maxCC]);
      setlagSliderValue([minLag, maxLag]);
      setcorrelationPlot(
        generateNetworkGraphWithLags(
          filteredDatasetContent,
          workflowAnalysisContent,
          CORRELATION,
          minCC,
          maxCC,
          minLag,
          maxLag,
          `Injectors-Producers ${CORRELATION}`
        )
      );
      return () => {
        setcorrelationPlot(undefined);
      };
    }
  }, [filteredDatasetContent, workflowAnalysisContent]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            variant="outlined"
            style={{ width: '20em' }}
            onClick={onThiefZoneClick}
          >
            Thief Zone
          </Button>
          <Button
            variant="outlined"
            style={{ width: '20em' }}
            onClick={onEffectiveZoneClick}
          >
            Effective Injector-Producer
          </Button>
        </Grid>
      </Grid>
      <Grid item className={styles.visualContentRow}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Correlation Range</Typography>
          <Slider
            min={minCC ? minCC : 0}
            step={0.01}
            max={maxCC ? maxCC : 1}
            value={corrSliderValue}
            onChange={(event, value) => corrSliderHandler(value)}
            valueLabelDisplay="auto"
            style={{ width: '80%' }}
          />
        </Grid>
        <br />
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Lag Range</Typography>
          <Slider
            min={minLag ? minLag : 0}
            step={1}
            max={maxLag ? maxLag : 1}
            value={lagSliderValue}
            onChange={(event, value) => lagSliderHandler(value)}
            valueLabelDisplay="auto"
            style={{ width: '80%' }}
          />
        </Grid>
      </Grid>
      {correlationPlot ? (
        <PlotlyComponent
          data={correlationPlot.data}
          layout={correlationPlot.layout}
          config={correlationPlot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage message="Correlation Plot is Loading..." goHome={false} />
      )}
    </Grid>
  );
};

FieldNetworkGraph.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowAnalysisContent: PropTypes.array,
  minCC: PropTypes.number,
  maxCC: PropTypes.number,
  minLag: PropTypes.number,
  maxLag: PropTypes.number,
  header: PropTypes.string,
};

export default FieldNetworkGraph;
