import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadingPage from '../../../Components/LoadingPage';
import Matching from './Matching';
import OilSaturation from './OilSaturation';
import PolymerInjection from './PolymerInjection';

import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';

import {
  POLY_INJECTION_FILE_NAMES,
  POLY_INJECTION_WORKFLOW_STAGES,
  POLYMER_ITERATION,
} from '../../../../constants/WorkflowsParameterConstants';
// import { PRODUCER } from '../../../../constants/WellConstants';

const PolyInjection = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const [workflowReady, setworkflowReady] = useState(false);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [prodOilSaturation, setprodOilSaturation] = useState(null);
  const [prodOilWaterRates, setprodOilWaterRates] = useState(null);
  const [paramTotals, setParamTotals] = useState(null);
  const [prodRecovery, setprodRecovery] = useState(null);
  const [injPotential, setinjPotential] = useState(null);
  const [gainValues, setgainValues] = useState(null);
  const [polyRecovery, setPolyRecovery] = useState(null);
  const [fieldSum, setFieldSum] = useState(null);

  const [injNames, setinjNames] = useState(null);
  const [prodNames, setprodNames] = useState(null);

  const constructFileName = (fileName, iteration) => {
    const lastIndex = fileName.lastIndexOf('_');
    const base = fileName.substring(0, lastIndex + 1);
    return base + iteration.toString() + '.csv';
  };

  // Callbacks/Helpers -->
  // const findCommonInjectorNames = rawInjNames => {
  //   const injPotentWells = injPotential.map(row => row['Injectors']);

  //   return rawInjNames.filter(wellName => injPotentWells.includes(wellName));
  // };

  // const findCommonProducerNames = rawProdNames => {
  //   const oilSatProds = prodOilSaturation.map(row => row['Producers']);
  //   const oilWaterRateProds = [
  //     ...new Set(prodOilWaterRates.map(row => row[PRODUCER])),
  //   ];

  //   return rawProdNames.filter(
  //     wellName =>
  //       oilSatProds.includes(wellName) && oilWaterRateProds.includes(wellName)
  //   );
  // };

  // const filterCommonWellNames = () => {
  //   const { producers: rawProdNames, injectors: rawInjNames } =
  //     filterWellNamesFromDataset(filteredDatasetContent);

  //   const producers = findCommonProducerNames(rawProdNames);
  //   const injectors = findCommonInjectorNames(rawInjNames);

  //   return { producers, injectors };
  // };
  // <-- Callbacks/Helpers

  // Retrieve the required data to generate workflow model from workflowResultFiles prop.
  useEffect(() => {
    try {
      if (workflowResultFiles && workflowParameters) {
        setfilteredDatasetContent(
          workflowResultFiles[POLY_INJECTION_FILE_NAMES.FILTERED_DATASET]
        );
        setprodOilSaturation(
          workflowResultFiles[POLY_INJECTION_FILE_NAMES.PRODS_SAT_DF]
        );
        setprodOilWaterRates(
          workflowResultFiles[POLY_INJECTION_FILE_NAMES.RATES_DF]
        );
        setParamTotals(
          workflowResultFiles[POLY_INJECTION_FILE_NAMES.PARAM_TOTALS]
        );
        if (
          workflowParameters['stage'] ===
          POLY_INJECTION_WORKFLOW_STAGES.POLY_INJECTION
        ) {
          setprodRecovery(
            workflowResultFiles[
              constructFileName(
                POLY_INJECTION_FILE_NAMES.PRODS_RECOVERY_DF,
                workflowParameters[POLYMER_ITERATION]
              )
            ]
          );
          setinjPotential(
            workflowResultFiles[
              constructFileName(
                POLY_INJECTION_FILE_NAMES.INJ_POTENT,
                workflowParameters[POLYMER_ITERATION]
              )
            ]
          );
          setgainValues(
            workflowResultFiles[POLY_INJECTION_FILE_NAMES.CONNECTIVITY_DF]
          );
          setPolyRecovery(
            workflowResultFiles[
              constructFileName(
                POLY_INJECTION_FILE_NAMES.POLY_RECOVERY,
                workflowParameters[POLYMER_ITERATION]
              )
            ]
          );
          setFieldSum(
            workflowResultFiles[
              constructFileName(
                POLY_INJECTION_FILE_NAMES.FIELD_SUM,
                workflowParameters[POLYMER_ITERATION]
              )
            ]
          );
        }
        setworkflowReady(true);
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setprodOilSaturation(null);
      setprodOilWaterRates(null);
      setParamTotals(null);
      setprodRecovery(null);
      setinjPotential(null);
      setgainValues(null);
      setPolyRecovery(null);
      setFieldSum(null);
    }
  }, [workflowResultFiles, workflowParameters]);

  // perform any pre-processing here.
  useEffect(() => {
    if (filteredDatasetContent && prodOilSaturation && prodOilWaterRates) {
      // const { producers, injectors } = filterCommonWellNames();
      const { producers, injectors } = filterWellNamesFromDataset(
        filteredDatasetContent
      );
      setinjNames(injectors);
      setprodNames(producers);
    }
  }, [
    filteredDatasetContent,
    prodOilSaturation,
    prodOilWaterRates,
    // prodRecovery,
    // injPotential,
    // gainValues,
  ]);

  return workflowReady ? (
    <React.Fragment>
      <Matching
        headers={headers}
        prodOilWaterRates={prodOilWaterRates}
        prodOilSaturation={prodOilSaturation}
        paramTotals={paramTotals}
        workflowParameters={workflowParameters}
      />
      <OilSaturation
        headers={headers}
        filteredDatasetContent={filteredDatasetContent}
        workflowParameters={workflowParameters}
        prodOilSaturation={prodOilSaturation}
        prodNames={prodNames}
        injNames={injNames}
      />
      <PolymerInjection
        headers={headers}
        filteredDatasetContent={filteredDatasetContent}
        prodRecovery={prodRecovery}
        injPotential={injPotential}
        gainValues={gainValues}
        fieldSum={fieldSum}
        polyRecovery={polyRecovery}
        prodNames={prodNames}
        injNames={injNames}
        workflowParameters={workflowParameters}
      />
    </React.Fragment>
  ) : (
    <LoadingPage />
  );
};

PolyInjection.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default PolyInjection;
