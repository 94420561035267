import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import ComponentMapper from './ComponentMapper';

const selectedPlotHeaderSubscriber = state => state.sidebar.selectedPlotHeader;
const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const MLConnectivity = ({ workflowParameters, headers }) => {
  const reduxSelectedPlotHeader = useSelector(selectedPlotHeaderSubscriber);
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // Callbacks/Helpers
  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  return (
    <ComponentMapper
      headers={headers}
      selectedHeader={reduxSelectedPlotHeader}
      workflowParameters={workflowParameters}
      reduxSelectedWorkflow={reduxSelectedWorkflow}
    />
  );
};

MLConnectivity.propTypes = {
  workflowParameters: PropTypes.object,
  headers: PropTypes.object,
};

export default MLConnectivity;
