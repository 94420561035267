import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAllWorkflows,
  getWorkflowStatusUpdates,
} from '../../redux/actions/workflows';
import { getAllDatasets } from '../../redux/actions/dataset';

import Navbar from '../Components/NavBar';
import CardView from '../Components/WorkflowCardView';

import { filterRecentWorkflows } from '../../Utils/DatasetUtils/DataProcessing';

import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CachedIcon from '@material-ui/icons/Cached';

import useStyles from '../../Styles/Workspace';

import {
  WORKSPACE_LIST_HEADER_NAMES,
  WORKSPACE_LIST_HEADER_ICONS,
  WORKFLOW_STATUSES,
} from '../../constants/WorkflowComponentConstants';

import { START_DATE, END_DATE } from '../../constants/WellConstants';
import { useNavigate } from 'react-router-dom';
import LogoComponent from '../Components/LogoComponent';

const allWorkflowsSubscriber = state => state.workflow.allWorkflows;
const allDatasetsSubscriber = state => state.dataset.datasets;

const Workspace = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxAllWorkflows = useSelector(allWorkflowsSubscriber);
  const reduxAllDatasets = useSelector(allDatasetsSubscriber);

  const [workflowTimer, setworkflowTimer] = useState(false);
  const [workflowsToShow, setworkflowsToShow] = useState([]);
  const [datasetOptions, setDatasetOptions] = useState([]);
  const [selectedHeader, setselectedHeader] = useState(
    WORKSPACE_LIST_HEADER_NAMES.Surveillance
  );
  const [searchInput, setSearchInput] = useState('');
  const [selectedDataset, setSelectedDataset] = useState('');
  const [workflowModels, setWorkflowModels] = useState([]);
  const [selectedWorkflowModel, setSelectedWorkflowModel] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedSort, setSelectedSort] = useState('');

  /**
   * Sorts workflows based on user selected sort.
   * @param {*} event - User selected sort event.
   * @param {*} workflows - Optional workflows if needed to sort after filtering.
   * @returns {void}
   */
  const sortHandler = (event, workflows) => {
    workflows = Array.isArray(workflows) ? workflows : workflowsToShow; // Added for bug fix.
    if (!workflows || workflows.length == 0) return;
    const userSelectedSort = event.target.value;
    setSelectedSort(userSelectedSort);

    switch (userSelectedSort) {
      case 'Name (Alphabetical)':
        setworkflowsToShow(
          workflows.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
        );
        break;
      case 'Created Date (Ascending)':
        setworkflowsToShow(
          workflows.sort(
            (a, b) => new Date(a.time_created) - new Date(b.time_created)
          )
        );
        break;
      case 'Created Date (Descending)':
        setworkflowsToShow(
          workflows.sort(
            (a, b) => new Date(b.time_created) - new Date(a.time_created)
          )
        );
        break;
      case 'Last Modified (Ascending)':
        setworkflowsToShow(
          workflows.sort(
            (a, b) => new Date(a.time_updated) - new Date(b.time_updated)
          )
        );
        break;
      case 'Last Modified (Descending)':
        setworkflowsToShow(
          workflows.sort(
            (a, b) => new Date(b.time_updated) - new Date(a.time_updated)
          )
        );
        break;
      default:
        setworkflowsToShow(
          workflows.sort((a, b) => (a.workflow_id < b.workflow_id ? 1 : -1))
        );
        break;
    }
  };

  const filterWorkflows = (
    model = selectedWorkflowModel,
    dataset = selectedDataset,
    keyword = searchInput,
    start = startDate,
    end = endDate
  ) => {
    let filteredWorkflows = reduxAllWorkflows.filter(workflow => {
      const isModelMatch = !model || workflow.workflow_type === model;
      const isDatasetMatch = !dataset || workflow.dataset_id === dataset;
      const isSearchMatch =
        !keyword ||
        workflow.name?.toLowerCase().includes(keyword.toLowerCase());
      const isStartDateMatch =
        !start || workflow.parameters[START_DATE] >= start;
      const isEndDateMatch = !end || workflow.parameters[END_DATE] <= end;

      return (
        isModelMatch &&
        isDatasetMatch &&
        isSearchMatch &&
        isStartDateMatch &&
        isEndDateMatch
      );
    });

    // If in recent tabs, prevent showing all workflows.
    if (selectedHeader == WORKSPACE_LIST_HEADER_NAMES.Recent) {
      filteredWorkflows = filterRecentWorkflows(filteredWorkflows);
    }
    sortHandler({ target: { value: selectedSort } }, filteredWorkflows);
  };

  const onModelSelect = event => {
    const selectedModel = event.target.value;
    setSelectedWorkflowModel(selectedModel);
    filterWorkflows(selectedModel, selectedDataset);
  };

  const onDatasetSelect = event => {
    const selectedDataset = event.target.value;
    setSelectedDataset(selectedDataset);
    filterWorkflows(selectedWorkflowModel, selectedDataset);
  };

  const onSearchChange = event => {
    const searchInput = event.target.value?.toLowerCase();
    setSearchInput(searchInput);
    filterWorkflows(selectedWorkflowModel, selectedDataset, searchInput);
  };

  const onStartDateChange = event => {
    setStartDate(event.target.value);
    filterWorkflows(
      selectedWorkflowModel,
      selectedDataset,
      searchInput,
      event.target.value,
      endDate
    );
  };

  const onEndDateChange = event => {
    setEndDate(event.target.value);
    filterWorkflows(
      selectedWorkflowModel,
      selectedDataset,
      searchInput,
      startDate,
      event.target.value
    );
  };

  /**
   * Resets all filters and sorting and defaults to reduxAllWorkflows.
   * @returns {void}
   * */
  const resetHandler = resetHeader => {
    setSelectedDataset('');
    setSelectedWorkflowModel('');
    setSearchInput('');
    setStartDate('');
    setEndDate('');
    setSelectedSort('');
    resetHeader && setselectedHeader(WORKSPACE_LIST_HEADER_NAMES.Surveillance);
    setworkflowsToShow(reduxAllWorkflows);
    sortHandler({ target: { value: '' } }, reduxAllWorkflows);
  };

  const onHeaderSelect = header => {
    let recentWorkflows;
    let surveillanceWorkflows;
    let integrationWorkflows;

    setselectedHeader(header);
    resetHandler(false);

    switch (header) {
      case WORKSPACE_LIST_HEADER_NAMES.Recent:
        recentWorkflows = filterRecentWorkflows(reduxAllWorkflows);
        setworkflowsToShow(recentWorkflows);
        setWorkflowModels([
          ...new Set(recentWorkflows.map(workflow => workflow.workflow_type)),
        ]);
        break;
      case WORKSPACE_LIST_HEADER_NAMES.Surveillance:
        surveillanceWorkflows = reduxAllWorkflows.filter(
          workflow =>
            workflow.module_type == WORKSPACE_LIST_HEADER_NAMES.Surveillance
        );
        setworkflowsToShow(surveillanceWorkflows);
        setWorkflowModels([
          ...new Set(
            surveillanceWorkflows.map(workflow => workflow.workflow_type)
          ),
        ]);

        break;
      case WORKSPACE_LIST_HEADER_NAMES.Integration:
        integrationWorkflows = reduxAllWorkflows.filter(
          workflow =>
            workflow.module_type == WORKSPACE_LIST_HEADER_NAMES.Integration
        );
        setworkflowsToShow(integrationWorkflows);
        setWorkflowModels([
          ...new Set(
            integrationWorkflows.map(workflow => workflow.workflow_type)
          ),
        ]);
        break;
    }
  };

  /**
   * Navigate to help page.
   */
  const onHelpClick = () => {
    navigate('/help');
  };
  // <-- Handlers

  // Constantly check for workflow statuses.
  useEffect(() => {
    // flag to prevent memory leak.
    let mounted = true;
    /**
     * For every 5 second, update redux workflows if any of the redux workflows
     * has submitted or in progress state.
     */
    let workflowStatusChecker = async () => {
      await new Promise(resolve => setTimeout(resolve, 5000));
      if (mounted) {
        if (
          reduxAllWorkflows.filter(
            workflow =>
              workflow.status == WORKFLOW_STATUSES.SUBMITTED ||
              workflow.status == WORKFLOW_STATUSES.IN_PROGRESS
          ).length > 0
        ) {
          dispatch(getWorkflowStatusUpdates(reduxAllWorkflows));
        }
        setworkflowTimer(status => !status);
      }
    };

    workflowStatusChecker();
    return () => {
      mounted = false;
    };
  }, [workflowTimer]);

  /**
   * This function restores sorting and filtering of workflows when there
   * is an update to reduxAllWorkflows.
   *
   * @param {boolean} defaultSortFlag - If true, then sort workflows with default sort.
   *
   * @returns {void}
   */
  const restoreSortingAndFiltering = (defaultSortFlag = null) => {
    const filteredWorkflows = filterWorkflows(
      selectedWorkflowModel,
      selectedDataset,
      searchInput,
      startDate,
      endDate
    );
    sortHandler(
      { target: { value: defaultSortFlag ? '' : selectedSort } },
      filteredWorkflows
    );
  };

  /**
   * Reset all filters and sorting, then gets the latest workflows from API.
   *
   * Existing workflows' status updates fetched and updated automatically.
   * But in order to see the new workflows that might be added from other tabs,
   * we need to fetch all workflows from API.
   */
  const updateHandler = () => {
    resetHandler(true);
    dispatch(getAllWorkflows());
  };

  // Fetch all workflows and datasets if not saved in redux state already.
  useEffect(() => {
    if (reduxAllWorkflows.length == 0) {
      dispatch(getAllWorkflows());
    }
    if (reduxAllDatasets.length == 0) {
      dispatch(getAllDatasets());
    }
  }, []);

  useEffect(() => {
    if (reduxAllWorkflows.length != 0) {
      restoreSortingAndFiltering();
      setDatasetOptions(reduxAllDatasets);
      setWorkflowModels([
        ...new Set(reduxAllWorkflows.map(workflow => workflow.workflow_type)),
      ]);
    }
  }, [reduxAllWorkflows, reduxAllDatasets]);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          <Paper elevation={20} className={styles.sidebarPaperContainer}>
            <List className={styles.sidebarList}>
              <ListItem>
                <ListItemText disableTypography={true}>
                  <Typography>My Workspace</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
              {Object.entries(WORKSPACE_LIST_HEADER_ICONS).map(header => (
                <React.Fragment key={header[0]}>
                  <ListItem
                    button
                    selected={selectedHeader == header[0]}
                    className={styles.sidebarListItem}
                    key={'listItem-' + header[0]}
                    onClick={() => onHeaderSelect(header[0])}
                  >
                    <ListItemIcon className={styles.sidebarListItemIcon}>
                      {header[1]}
                    </ListItemIcon>
                    <ListItemText primary={header[0]} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.contentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <Grid container className={styles.visualContentRow}>
              <Typography variant="h6">{selectedHeader}</Typography>
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '2em',
                }}
              >
                <TextField
                  variant="standard"
                  label="Search by Name"
                  margin="dense"
                  type="text"
                  value={searchInput}
                  onChange={onSearchChange}
                />
                <FormControl variant="standard" size="small">
                  <InputLabel id="dataset-select">Select Dataset</InputLabel>
                  <Select
                    labelId="dataset-select"
                    value={selectedDataset}
                    onChange={onDatasetSelect}
                    style={{ width: '10em' }}
                  >
                    <MenuItem key={'menu-item-all'} value={''}>
                      All Datasets
                    </MenuItem>
                    <hr style={{ margin: '5px 3px' }} />
                    {datasetOptions &&
                      datasetOptions.map(dataset => (
                        <MenuItem
                          key={'menu-item-' + dataset.dataset_id}
                          value={dataset.dataset_id}
                        >
                          {dataset.dataset_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" size="small">
                  <InputLabel id="workflow-select">Select Workflow</InputLabel>
                  <Select
                    labelId="workflow-select"
                    value={selectedWorkflowModel}
                    onChange={onModelSelect}
                    style={{ width: '10em' }}
                  >
                    <MenuItem key={'menu-item-all'} value={''}>
                      All Workflows
                    </MenuItem>
                    <hr style={{ margin: '5px 3px' }} />
                    {workflowModels &&
                      workflowModels.map(model => (
                        <MenuItem key={'menu-item-' + model} value={model}>
                          {model}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  id="date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={onStartDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={onEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl variant="standard" size="small">
                  <InputLabel id="sort-select">Sort</InputLabel>
                  <Select
                    labelId="sort-select"
                    value={selectedSort}
                    onChange={sortHandler}
                    style={{ width: '5em' }}
                  >
                    <MenuItem key={'menu-item-all'} value={''}>
                      Default
                    </MenuItem>
                    <hr style={{ margin: '5px 3px' }} />
                    {[
                      'Name (Alphabetical)',
                      'Created Date (Ascending)',
                      'Created Date (Descending)',
                      'Last Modified (Ascending)',
                      'Last Modified (Descending)',
                    ].map(option => (
                      <MenuItem key={'menu-item-' + option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={resetHandler}
                  title="Reset Filters"
                  disabled={
                    selectedDataset == '' &&
                    selectedWorkflowModel == '' &&
                    searchInput == '' &&
                    startDate == '' &&
                    endDate == '' &&
                    selectedSort == ''
                  }
                >
                  <RotateLeftIcon />
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={updateHandler}
                  title="Sync Updates"
                >
                  <CachedIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid item container className={styles.workflowContainerGrid}>
              {workflowsToShow && workflowsToShow.length > 0 ? (
                workflowsToShow.map(workflow => (
                  <CardView key={workflow.workflow_id} workflow={workflow} />
                ))
              ) : (
                <Typography
                  variant="h6"
                  style={{ textAlign: 'center', margin: 'auto' }}
                >
                  No workflows to show.
                </Typography>
              )}
            </Grid>
            <Grid item container className={styles.footer}>
              <Button variant="contained" id="Help" onClick={onHelpClick}>
                Help
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Workspace;
