import {
  ARPS_DECLINE_CURVE,
  DATE,
  OIL_PRODUCTION_RATE,
  OIL_RATE,
  RATE,
  UNIT_STD_LIQUID,
  WELL_ID,
} from '../../constants/WellConstants';

import {
  ALL,
  EXPONENTIAL,
  HARMONIC,
  HYPERBOLIC,
} from '../../constants/WorkflowsParameterConstants';

import {
  createLinePlot,
  createLinePlotTraces,
  LINES_WITH_MARKERS,
  wrapPlots,
  plotWithVerticalLine,
  addTrainingLine,
} from '../PlotlyUtils/Plots';

export const filterSDFByWell = (datasetContent, wellName, method) => {
  return datasetContent.map(row =>
    method == ALL
      ? {
          [DATE]: row[DATE],
          [wellName]: row[wellName],
          [EXPONENTIAL]: row[EXPONENTIAL],
          [HYPERBOLIC]: row[EXPONENTIAL],
          [EXPONENTIAL]: row[HARMONIC],
        }
      : {
          [DATE]: row[DATE],
          [method]: row[method],
        }
  );
};

export const filterCurveAnalysisByWell = (datasetContent, wellName, method) => {
  return datasetContent.map(row =>
    method == ALL
      ? {
          Parameters: row['Parameters'],
          [EXPONENTIAL]: row[EXPONENTIAL],
          [HYPERBOLIC]: row[HYPERBOLIC],
          [HARMONIC]: row[HARMONIC],
        }
      : {
          Parameters: row['Parameters'],
          [method]: row[method],
        }
  );
};

export const generateOilRateGraph = (datasetContent, wellName, unit) => {
  const filteredDataset = datasetContent.filter(
    row => row[WELL_ID] == wellName
  );
  const traces = createLinePlotTraces(
    filteredDataset,
    DATE,
    [OIL_PRODUCTION_RATE],
    [],
    [wellName],
    undefined,
    undefined,
    LINES_WITH_MARKERS
  );
  return wrapPlots(
    traces,
    `${OIL_RATE} ${RATE}`,
    UNIT_STD_LIQUID[unit],
    `${OIL_RATE} ${RATE} - ${wellName}`
  );
};

export const generateArpsAnalysisResult = (
  datasetContent,
  wellName,
  method,
  unit
) => {
  const columns =
    method == ALL
      ? [wellName, EXPONENTIAL, HYPERBOLIC, HARMONIC]
      : [wellName, method];
  return createLinePlot(
    datasetContent,
    DATE,
    columns,
    undefined,
    undefined,
    undefined,
    undefined,
    UNIT_STD_LIQUID[unit],
    ARPS_DECLINE_CURVE + ' ' + OIL_RATE
  );
};

export const generateFutureArpsAnalysisResult = (
  datasetContent,
  wellName,
  method,
  unit,
  endDate
) => {
  const columns =
    method == ALL
      ? [wellName, EXPONENTIAL, HYPERBOLIC, HARMONIC]
      : [wellName, method];
  return addTrainingLine(
    plotWithVerticalLine(
      datasetContent,
      DATE,
      columns,
      columns,
      columns[0],
      undefined,
      undefined,
      undefined,
      UNIT_STD_LIQUID[unit],
      ARPS_DECLINE_CURVE + ' ' + OIL_RATE,
      undefined,
      undefined,
      undefined,
      undefined,
      endDate
    ),
    [endDate]
  );
};
