export const fieldWellNamesRoute = dataset_id => {
  return `/visualization/field_well_names/${dataset_id}`;
};

export const fieldActiveAttributesRoute = dataset_id => {
  return `/visualization/field_active_attributes/${dataset_id}`;
};

export const fieldTimelineRoute = dataset_id => {
  return `/visualization/field_timeline/${dataset_id}`;
};

export const fieldLayersRoute = dataset_id => {
  return `/visualization/field_layers/${dataset_id}`;
};

export const wellSurfaceLocationRoute = dataset_id => {
  return `/visualization/well_surface_location/${dataset_id}`;
};

export const wellActivityScheduleRoute = dataset_id => {
  return `/visualization/well_activity_schedule/${dataset_id}`;
};

export const cumFieldAnalysisRoute = dataset_id => {
  return `/visualization/cum_field_analysis/${dataset_id}`;
};

export const fieldProductionDataRoute = dataset_id => {
  return `/visualization/field_production_data/${dataset_id}`;
};

export const fieldInjectionDataRoute = dataset_id => {
  return `/visualization/field_injection_data/${dataset_id}`;
};

export const customWellAnalysisRoute = dataset_id => {
  return `/visualization/custom_well_analysis/${dataset_id}`;
};

export const fieldReservoirAnalysisRoute = dataset_id => {
  return `/visualization/field_reservoir_analysis/${dataset_id}`;
};

export const fieldFluidResRate = dataset_id => {
  return `/visualization/field_fluid_res_rate/${dataset_id}`;
};

export const fieldVRRAnalysisRoute = dataset_id => {
  return `/visualization/field_vrr_analysis/${dataset_id}`;
};
