import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../Styles/WorkflowStyles';

import SideBar from '../SideBar';
import Navbar from '../NavBar';

import {
  getAllWorkflows,
  setSelectedWorkflow,
  retrieveWorkflowParameters,
  retrieveZippedWorkflowContent,
} from '../../../redux/actions/workflows';

import { Grid, Paper } from '@material-ui/core';

import {
  LIST_HEADERS_BY_WORKFLOW_TYPE,
  WORKFLOW_COMPONENTS,
} from '../../../constants/WorkflowComponentConstants';

import {
  CRMP,
  ML_CONNECTIVITY,
  CROSS_WORKFLOW_COMPARISON,
  MULTI_WORKFLOW_COMPARISON,
} from '../../../constants/WellConstants';

import {
  RESULT_MODEL_1,
  RESULT_MODEL_2,
  SELECTED_WORKFLOWS,
  WORKFLOW_RESULTS_FILE_HEADER_FORMATS,
} from '../../../constants/WorkflowsParameterConstants';
import LogoComponent from '../../Components/LogoComponent';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;
const allWorkflowSubscriber = state => state.workflow.allWorkflows;

const BaseWorkflowPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { workflowId: urlWorkflowId } = useParams();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);
  const reduxAllWorkflow = useSelector(allWorkflowSubscriber);

  const [workflowResultData, setWorkflowResultData] = useState({});
  const [workflowParameters, setWorkflowParameters] = useState(null);
  const [configuredComponent, setConfiguredComponent] = useState(null);

  useEffect(() => {
    if (reduxAllWorkflow.length == 0) dispatch(getAllWorkflows());
  }, []);

  useEffect(() => {
    if (reduxAllWorkflow.length > 0 && reduxSelectedWorkflow == undefined) {
      dispatch(
        setSelectedWorkflow(
          reduxAllWorkflow.find(
            workflow => workflow['workflow_id'] == urlWorkflowId
          )
        )
      );
    }
  }, [reduxAllWorkflow]);

  useEffect(() => {
    let mounted = true;

    const fetchWorkflowData = async () => {
      let workflowResultFiles = {};

      // FIXME: workflow comparison reports requires 2 or more workflow results to be able
      // to run properly. This if-else logic statements are temporary and dirty solution.
      // Should be fixed ASAP.
      try {
        if (
          reduxSelectedWorkflow['workflow_type'] == CROSS_WORKFLOW_COMPARISON
        ) {
          const model1 = reduxSelectedWorkflow.parameters[RESULT_MODEL_1];
          const model2 = reduxSelectedWorkflow.parameters[RESULT_MODEL_2];
          const model1Results = await dispatch(
            retrieveZippedWorkflowContent(
              model1.workflow_id,
              WORKFLOW_RESULTS_FILE_HEADER_FORMATS?.[
                reduxSelectedWorkflow.workflow_type
              ]
            )
          );
          const model2Results = await dispatch(
            retrieveZippedWorkflowContent(
              model2.workflow_id,
              WORKFLOW_RESULTS_FILE_HEADER_FORMATS?.[
                reduxSelectedWorkflow.workflow_type
              ]
            )
          );

          workflowResultFiles[RESULT_MODEL_1] = { ...model1Results };
          workflowResultFiles[RESULT_MODEL_2] = { ...model2Results };
        } else if (
          reduxSelectedWorkflow['workflow_type'] == MULTI_WORKFLOW_COMPARISON
        ) {
          const selectedWorkflows =
            reduxSelectedWorkflow.parameters[SELECTED_WORKFLOWS];

          for await (let workflow of selectedWorkflows) {
            const modelResult = await dispatch(
              retrieveZippedWorkflowContent(
                workflow.workflow_id,
                WORKFLOW_RESULTS_FILE_HEADER_FORMATS?.[
                  reduxSelectedWorkflow.workflow_type
                ]
              )
            );
            workflowResultFiles[
              workflow.name
                ? workflow.name
                : `${workflow.workflow_id}_${workflow.workflow_type}`
            ] = { ...modelResult };
          }
        } else {
          workflowResultFiles = await dispatch(
            retrieveZippedWorkflowContent(
              reduxSelectedWorkflow.workflow_id,
              WORKFLOW_RESULTS_FILE_HEADER_FORMATS?.[
                reduxSelectedWorkflow.workflow_type
              ]
            )
          );
        }
        setWorkflowResultData(workflowResultFiles);
      } catch (err) {
        setWorkflowResultData(null);
      }
    };

    const fetchWorkflowParameters = async () => {
      try {
        let parameters = await dispatch(
          retrieveWorkflowParameters(reduxSelectedWorkflow.workflow_id)
        );
        setWorkflowParameters(parameters);
      } catch (error) {
        setWorkflowParameters(null);
      }
    };

    if (mounted && reduxSelectedWorkflow) {
      // FIXME: Temporary solution for CRMP and ML workflow type. These results will be accessed within child components.
      if (
        reduxSelectedWorkflow['workflow_type'] != CRMP &&
        reduxSelectedWorkflow['workflow_type'] != ML_CONNECTIVITY
      ) {
        fetchWorkflowData();
      }
    }

    fetchWorkflowParameters();

    return () => {
      mounted = false;
    };
  }, [reduxSelectedWorkflow]);

  //This useEffect configures the component with the corresponding
  //list headers and fetched workflow result data props.
  useEffect(() => {
    if (workflowResultData && workflowParameters) {
      const selectedWorkflowComponent =
        WORKFLOW_COMPONENTS[reduxSelectedWorkflow['workflow_type']];
      const selectedWorkflowListHeader =
        LIST_HEADERS_BY_WORKFLOW_TYPE[reduxSelectedWorkflow['workflow_type']];

      //clone the workflow component to add workflowResultFiles, workflowParameters and headers props.
      const componentToRender = React.cloneElement(selectedWorkflowComponent, {
        workflowResultFiles: workflowResultData,
        workflowParameters: workflowParameters,
        headers: selectedWorkflowListHeader,
      });
      setConfiguredComponent(componentToRender);
    }
  }, [workflowResultData, workflowParameters]);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          {reduxSelectedWorkflow && (
            <SideBar
              mainHeader={reduxSelectedWorkflow['workflow_type']}
              plotHeaderObject={
                LIST_HEADERS_BY_WORKFLOW_TYPE[
                  reduxSelectedWorkflow['workflow_type']
                ]
              }
            />
          )}
        </Grid>
        <Grid item className={styles.contentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            {configuredComponent}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BaseWorkflowPage;
