import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';
import { useSelector } from 'react-redux';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';

import LoadingPage from '../../../Components/LoadingPage';

import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';
import { wrapPlots } from '../../../../Utils/PlotlyUtils/Plots';
import { getUnitRate } from '../../../../Utils/ReservoirUtils';
import {
  generatePlotComparisonPlotTrace,
  generateCumulativeWellPlotTrace,
  generateFieldLiquidPlotFromColNames,
} from '../../../../Utils/WorkflowReportUtils/DiagnosticPlots';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import {
  DATE,
  FIELD,
  DIAGNOSTIC_PLOTS_FIELD_RATIO_COL_NAMES,
  DIAGNOSTIC_PLOTS_FIELD_TOTAL_RATE_COL_NAMES,
  DIAGNOSTIC_PLOTS_LIQUID_INJ_PROD_RATE_COL_NAMES,
  SYSTEM_OF_UNITS,
} from '../../../../constants/WellConstants';

import {
  CUM_PLOT_TITLES,
  PLOT_TYPE_OBJECTS,
} from '../../../../constants/WorkflowComponentConstants';

import { DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES } from '../../../../constants/WorkflowsParameterConstants';

const datasetTimePeriodSubscriber = state => state.dataset.timePeriod;

const DiagnosticPlot = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxDatasetTimePeriod = useSelector(datasetTimePeriodSubscriber);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [monthlyRatioValues, setmonthlyRatioValues] = useState(null);
  const [injProdLiquidRate, setinjProdLiquidRate] = useState(null);

  const [processedDatasetContent, setprocessedDatasetContent] = useState(null);

  const [prodWells, setprodWells] = useState([]);
  const [cumulativeWellOption, setcumulativeWellOption] = useState(
    CUM_PLOT_TITLES[0]
  );
  const [selectedCompPlot, setselectedCompPlot] = useState(
    PLOT_TYPE_OBJECTS[0]
  );
  const [selectedPlotType, setselectedPlotType] = useState(
    PLOT_TYPE_OBJECTS[0].title
  );
  const [selectedProducer, setselectedProducer] = useState('');
  const [selectedPlotLog, setselectedPlotLog] = useState(0);

  const [cumPlot, setcumPlot] = useState(null);
  const [fieldLiquidInjRatePlot, setfieldLiquidInjRatePlot] = useState(null);
  const [fieldRatioPlot, setfieldRatioPlot] = useState(null);
  const [liquidInjProdRatePlot, setliquidInjProdRatePlot] = useState(null);
  const [plotComparisonPlot, setplotComparisonPlot] = useState(null);

  // Handlers -->
  const onCumulativeWellSelect = plotOption => {
    const trace = generateCumulativeWellPlotTrace(
      processedDatasetContent,
      plotOption
    );
    setcumPlot(
      wrapPlots(
        trace,
        DATE,
        getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          plotOption,
          reduxDatasetTimePeriod
        ),
        plotOption
      )
    );
    setcumulativeWellOption(plotOption);
  };

  const onProdWellSelect = wellSelection => {
    const compPlotTrace = generatePlotComparisonPlotTrace(
      processedDatasetContent,
      wellSelection,
      selectedPlotLog,
      selectedCompPlot
    );
    setplotComparisonPlot(
      wrapPlots(
        compPlotTrace,
        selectedCompPlot.xAxisName,
        selectedCompPlot.yAxisName,
        selectedCompPlot.title
      )
    );
    setselectedProducer(wellSelection);
  };

  const onPlotTypeSelect = plotType => {
    const selectedPlot = PLOT_TYPE_OBJECTS.find(
      object => object.title == plotType
    );
    const compPlotTrace = generatePlotComparisonPlotTrace(
      processedDatasetContent,
      selectedProducer,
      selectedPlotLog,
      selectedPlot
    );

    setplotComparisonPlot(
      wrapPlots(
        compPlotTrace,
        selectedPlot.xAxisName,
        selectedPlot.yAxisName,
        selectedPlot.title
      )
    );

    setselectedPlotType(plotType);
    setselectedCompPlot(selectedPlot);
  };

  const onPlotLogSelect = logValue => {
    const compPlotTrace = generatePlotComparisonPlotTrace(
      processedDatasetContent,
      selectedProducer,
      logValue,
      selectedCompPlot
    );

    setplotComparisonPlot(
      wrapPlots(
        compPlotTrace,
        selectedCompPlot.xAxisName,
        selectedCompPlot.yAxisName,
        selectedCompPlot.title
      )
    );
    setselectedPlotLog(logValue);
  };

  const onPlotlySelect = event => {
    try {
      const selectedPointData = event.points[0].data.selectedpoints.map(
        pointIndex => {
          return {
            plotType: event.points[0].data.name,
            xValue: event.points[0].data.x[pointIndex],
            yValue: event.points[0].data.y[pointIndex],
          };
        }
      );
      selectedPointData;
    } catch (error) {
      return;
    }
  };
  // <-- Handlers

  // Retrieve required workflow analysis data from props to generate workflow report.
  useEffect(() => {
    try {
      if (workflowResultFiles) {
        setfilteredDatasetContent(
          workflowResultFiles[
            DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET
          ]
        );
        setworkflowAnalysisContent(
          workflowResultFiles[
            DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES.WORKFLOW_RESULT
          ]
        );
        setmonthlyRatioValues(
          workflowResultFiles[
            DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES.MONTHLY_RATIO_VALUES
          ]
        );
        setinjProdLiquidRate(
          workflowResultFiles[
            DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES
              .MONTHLY_INJ_PROD_LIQUD_RATE
          ]
        );
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setworkflowAnalysisContent(null);
      setmonthlyRatioValues(null);
      setinjProdLiquidRate(null);
    }
  }, [workflowResultFiles]);

  /**
   * This effect enhances uploaded dataset and generates neccessary plots. Gets
   * invoked each time redux dataset content changes.
   */
  useEffect(() => {
    if (
      filteredDatasetContent &&
      workflowAnalysisContent &&
      monthlyRatioValues &&
      injProdLiquidRate
    ) {
      const { producers } = filterWellNamesFromDataset(filteredDatasetContent);

      // Generate plot data from the enhanced dataset.
      const cumulativePlotTrace = generateCumulativeWellPlotTrace(
        workflowAnalysisContent,
        cumulativeWellOption
      );
      const fieldLiquidInjRatePlotTrace = generateFieldLiquidPlotFromColNames(
        monthlyRatioValues,
        DIAGNOSTIC_PLOTS_FIELD_TOTAL_RATE_COL_NAMES
      );
      const fieldRatioPlotTrace = generateFieldLiquidPlotFromColNames(
        monthlyRatioValues,
        DIAGNOSTIC_PLOTS_FIELD_RATIO_COL_NAMES
      );
      const liquidInjProdRatePlotTrace = generateFieldLiquidPlotFromColNames(
        injProdLiquidRate,
        DIAGNOSTIC_PLOTS_LIQUID_INJ_PROD_RATE_COL_NAMES
      );
      const plotComparisonPlotTrace = generatePlotComparisonPlotTrace(
        workflowAnalysisContent,
        producers[0],
        selectedPlotLog,
        selectedCompPlot
      );

      setcumPlot(
        wrapPlots(
          cumulativePlotTrace,
          DATE,
          getUnitRate(
            workflowParameters[SYSTEM_OF_UNITS],
            cumulativeWellOption,
            reduxDatasetTimePeriod
          ),
          cumulativeWellOption
        )
      );
      setfieldLiquidInjRatePlot(
        wrapPlots(
          fieldLiquidInjRatePlotTrace,
          DATE,
          getUnitRate(
            workflowParameters[SYSTEM_OF_UNITS],
            'Field Liquid/Injection Rate',
            reduxDatasetTimePeriod
          ),
          'Field Liquid/Injection Rate'
        )
      );
      setfieldRatioPlot(
        wrapPlots(fieldRatioPlotTrace, DATE, 'Ratios', 'Field Ratios')
      );
      setliquidInjProdRatePlot(
        wrapPlots(
          liquidInjProdRatePlotTrace,
          DATE,
          getUnitRate(
            workflowParameters[SYSTEM_OF_UNITS],
            'Liquid Injection Production Rate',
            reduxDatasetTimePeriod
          ),
          'Liquid Injection Production Rate'
        )
      );
      setplotComparisonPlot(
        wrapPlots(
          plotComparisonPlotTrace,
          selectedCompPlot.xAxisName,
          selectedCompPlot.yAxisName,
          selectedCompPlot.title
        )
      );
      setprocessedDatasetContent(workflowAnalysisContent);
      setprodWells([FIELD, ...producers]);
      setselectedProducer(producers[0]);
    }
  }, [
    workflowAnalysisContent,
    filteredDatasetContent,
    monthlyRatioValues,
    injProdLiquidRate,
  ]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography id={headers.Plot}>{headers.Plot}</Typography>

          <FormControl variant="outlined" size="small">
            <Select
              value={cumulativeWellOption}
              onChange={event => onCumulativeWellSelect(event.target.value)}
              className="dropdownSelection"
            >
              {CUM_PLOT_TITLES.map(title => (
                <MenuItem key={title + '-MenuItem'} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {cumPlot ? (
          <PlotlyComponent
            data={cumPlot.data}
            layout={cumPlot.layout}
            config={cumPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Cummulative Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography id={headers.FieldLiquidInjectionRate}>
            {headers.FieldLiquidInjectionRate}
          </Typography>
        </Grid>
        {fieldLiquidInjRatePlot ? (
          <PlotlyComponent
            data={fieldLiquidInjRatePlot.data}
            layout={fieldLiquidInjRatePlot.layout}
            config={fieldLiquidInjRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Field Liquid Inj Rate Plot is loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography id={headers.FieldRatio}>{headers.FieldRatio}</Typography>
        </Grid>
        {fieldRatioPlot ? (
          <PlotlyComponent
            data={fieldRatioPlot.data}
            layout={fieldRatioPlot.layout}
            config={fieldRatioPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Field Ratio Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography id={headers.LiquidInjectionProductionRate}>
            {headers.LiquidInjectionProductionRate}
          </Typography>
        </Grid>
        {liquidInjProdRatePlot ? (
          <PlotlyComponent
            data={liquidInjProdRatePlot ? liquidInjProdRatePlot.data : {}}
            layout={liquidInjProdRatePlot ? liquidInjProdRatePlot.layout : {}}
            config={liquidInjProdRatePlot ? liquidInjProdRatePlot.config : {}}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Liquid Injection Production Rate Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography id={headers.PlotComparisson}>
            {headers.PlotComparisson}
          </Typography>
        </Grid>
        <Grid item container className={styles.inputContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel>Producer Well</InputLabel>
            <Select
              value={selectedProducer}
              onChange={event => onProdWellSelect(event.target.value)}
            >
              {prodWells.map(wellName => (
                <MenuItem key={wellName + '-MenuItem'} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel>Plot Type</InputLabel>
            <Select
              value={selectedPlotType}
              onChange={event => onPlotTypeSelect(event.target.value)}
            >
              {PLOT_TYPE_OBJECTS.map(object => (
                <MenuItem key={object.title + '-MenuItem'} value={object.title}>
                  {object.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedPlotType != 'Movable Oil Plot' ? (
            <FormControl
              variant="outlined"
              size="small"
              className="expandInputElement"
            >
              <InputLabel>Plot Log Option</InputLabel>
              <Select
                value={selectedPlotLog}
                onChange={event => onPlotLogSelect(event.target.value)}
              >
                <MenuItem value={0}>
                  {selectedCompPlot.xColName +
                    ' vs ' +
                    selectedCompPlot.yColName}
                </MenuItem>
                <MenuItem value={1}>
                  {selectedCompPlot.xColName +
                    ' vs log ' +
                    selectedCompPlot.yColName}
                </MenuItem>
                {selectedPlotType != 'WOR vs Time' ? (
                  <MenuItem value={2}>
                    {'log ' +
                      selectedCompPlot.xColName +
                      ' vs log ' +
                      selectedCompPlot.yColName}
                  </MenuItem>
                ) : (
                  ''
                )}
              </Select>
            </FormControl>
          ) : (
            ''
          )}
        </Grid>
        {plotComparisonPlot ? (
          <PlotlyComponent
            data={plotComparisonPlot.data}
            layout={plotComparisonPlot.layout}
            config={plotComparisonPlot.config}
            style={{ width: '100%', height: '100%' }}
            onSelected={onPlotlySelect}
          />
        ) : (
          <LoadingPage message="Comparison Plot is Loading..." goHome={false} />
        )}
      </Grid>
    </React.Fragment>
  );
};

DiagnosticPlot.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default DiagnosticPlot;
