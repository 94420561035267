import {
  SET_ALL_WORKFLOWS,
  ADD_WORKFLOW,
  REMOVE_WORKFLOW,
  UPDATE_WORKFLOW,
  SELECT_WORKFLOW,
} from '../actions/workflows/types';

const initialState = {
  allWorkflows: [],
  selectedWorkflow: null,
};

export const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_WORKFLOWS: {
      return {
        ...state,
        allWorkflows: action.payload,
      };
    }
    // Add workflow to redux state
    case ADD_WORKFLOW: {
      return {
        ...state,
        allWorkflows: state.allWorkflows.concat(action.payload),
      };
    }
    // Remove a workflow from redux state
    case REMOVE_WORKFLOW: {
      let filtered = state.allWorkflows.filter(
        workflow => workflow.workflow_id !== action.payload
      );
      return {
        ...state,
        allWorkflows: filtered,
      };
    }
    // Update a workflow from redux state
    case UPDATE_WORKFLOW: {
      let workflows = state.allWorkflows.map(workflow => {
        if (workflow.workflow_id == action.payload.workflow_id) {
          return action.payload;
        } else {
          return workflow;
        }
      });

      return {
        ...state,
        allWorkflows: workflows,
      };
    }
    // Set selected Workflow
    case SELECT_WORKFLOW: {
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    }
    // Default state
    default: {
      return state;
    }
  }
};
