import logo from '../../resermine_logo.png';
import { Grid, Typography, Link, Paper } from '@material-ui/core';
import useStyles from '../../Styles/LogoComponentStyle';
import { useNavigate } from 'react-router-dom';

const LogoComponent = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <Grid item className={styles.root}>
      <Paper elevation={20} className={styles.paperContainer}>
        <Link onClick={() => navigate('/homepage')} underline="none">
          <img className={styles.logo} src={logo} alt="Resermine_Logo" />
        </Link>
        <Typography className={styles.name}>
          <Link
            className={styles.link}
            onClick={() => navigate('/homepage')}
            color="inherit"
            underline="none"
          >
            CoreIntellect
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default LogoComponent;
