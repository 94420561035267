import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ClusterPlot from './ClusterPlot';

import {
  YEARLY_WATER_CUT,
  YEARLY_OIL_CUT,
  YEARLY_WOR,
  YEARLY_GOR,
} from '../../../../constants/WellConstants';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import useStyles from '../../../../Styles/WorkflowStyles';

const ClusterView = ({ workflowAnalysisContent, selectedYear, headerName }) => {
  const styles = useStyles();
  const [clusterOption, setclusterOption] = useState(YEARLY_WATER_CUT);

  return (
    <Grid container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography id={headerName}>{headerName}</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={clusterOption}
            onChange={event => setclusterOption(event.target.value)}
            className="dropdownSelection"
          >
            <MenuItem value={YEARLY_WATER_CUT}>{YEARLY_WATER_CUT}</MenuItem>
            <MenuItem value={YEARLY_OIL_CUT}>{YEARLY_OIL_CUT}</MenuItem>
            <MenuItem value={YEARLY_WOR}>{YEARLY_WOR}</MenuItem>
            <MenuItem value={YEARLY_GOR}>{YEARLY_GOR}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <ClusterPlot
        reportType={clusterOption}
        selectedYear={selectedYear}
        workflowAnalysisContent={workflowAnalysisContent}
      />
    </Grid>
  );
};

ClusterView.propTypes = {
  workflowAnalysisContent: PropTypes.array,
  selectedYear: PropTypes.number,
  headerName: PropTypes.string,
};

export default ClusterView;
