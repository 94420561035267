import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import WellPairAnalysisPlot from '../../../Components/WorkflowAnalysisTools/WellPairAnalysisPlot';
import { useDispatch } from 'react-redux';

import { retrieveMLConnFilteredDataset } from '../../../../API/Functions/MLConnectivity';
import { updateSnackBar } from '../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';
import LoadingPlot from '../../../Components/LoadingPlot';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const WellPairAnalysis = ({
  header,
  selectedInjector,
  selectedProducer,
  workflowParameters,
}) => {
  const dispatch = useDispatch();
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const [apiRes, setApiRes] = useState(null);

  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const datasetContent = await retrieveMLConnFilteredDataset(
          reduxSelectedWorkflow['workflow_id']
        );
        if (mounted) {
          setApiRes(datasetContent);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (selectedInjector && selectedProducer) {
      fetchPlotData();
    }

    return () => (mounted = false);
  }, []);

  return apiRes ? (
    <WellPairAnalysisPlot
      header={header}
      selectedInjector={selectedInjector}
      selectedProducer={selectedProducer}
      workflowParameters={workflowParameters}
      filteredDatasetContent={apiRes}
    />
  ) : (
    <LoadingPlot />
  );
};

export default WellPairAnalysis;

WellPairAnalysis.propTypes = {
  header: PropTypes.string,
  selectedInjector: PropTypes.string,
  selectedProducer: PropTypes.string,
  workflowParameters: PropTypes.object,
};
