import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FieldNetworkGraph from './FieldNetworkGraph';
import WorkflowConfigurationTable from '../../../Components/WorkflowConfigurationTable';
import PairSelector from '../../../Components/WorkflowAnalysisTools/DatasetFeaturePairSelector';
import LoadingPlot from '../../../Components/LoadingPlot';

import { INJECTOR, PRODUCER } from '../../../../constants/WellConstants';

import {
  retrieveAdvancedAnalysisResult,
  retrieveCalculationScoresResult,
  retrieveMLConnFilteredDataset,
  retrieveMLConnWorkflowResult,
} from '../../../../API/Functions/MLConnectivity';

import { analyzeDataSummaryValues } from './Utils';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const FieldMapSection = ({
  headers,
  selectedCorrPair,
  availableCorrPairs,
  setSelectedCorrPair,
}) => {
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const [apiRes, setApiRes] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [calculationScores, setcalculationScores] = useState(null);

  const [minImp, setminImp] = useState(null);
  const [maxImp, setmaxImp] = useState(null);
  const [minWellScore, setminWellScore] = useState(null);
  const [maxWellScore, setmaxWellScore] = useState(null);
  const [tableData, settableData] = useState(null);

  const [workflowReady, setWorkflowReady] = useState(false);

  const processWorkflowAnalysisContent = userSelectedPair => {
    setSelectedCorrPair(userSelectedPair);
  };

  // First time and everytime selectedCorrPair changes.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          workflowResultFile,
          calcResultFile,
          filteredDataset,
          advancedAnalysis,
        ] = await Promise.all([
          retrieveMLConnWorkflowResult(
            reduxSelectedWorkflow.workflow_id,
            selectedCorrPair
          ),
          retrieveCalculationScoresResult(
            reduxSelectedWorkflow.workflow_id,
            selectedCorrPair
          ),
          retrieveMLConnFilteredDataset(reduxSelectedWorkflow.workflow_id),
          retrieveAdvancedAnalysisResult(reduxSelectedWorkflow.workflow_id),
        ]);

        setApiRes({
          workflowResultFile,
          calcResultFile,
          filteredDataset,
          advancedAnalysis,
        });
      } catch (error) {
        // Handle request failure. -- Promise.allSettled() might be useful here.
        setApiRes(null);
      }
    };

    if (selectedCorrPair) {
      fetchData();
    }
  }, [selectedCorrPair]);

  useEffect(() => {
    if (apiRes) {
      try {
        const { minImp, maxImp, minWellScore, maxWellScore, tableData } =
          analyzeDataSummaryValues(
            apiRes.workflowResultFile,
            apiRes.calcResultFile
          );

        setminImp(minImp);
        setmaxImp(maxImp);
        setminWellScore(minWellScore);
        setmaxWellScore(maxWellScore);
        settableData(tableData);
        setworkflowAnalysisContent(apiRes?.workflowResultFile);
        setcalculationScores(apiRes?.calcResultFile);
        setWorkflowReady(true);
      } catch (error) {
        //eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [apiRes]);

  return workflowReady ? (
    <Fragment>
      <WorkflowConfigurationTable
        header={headers.CONFIGURATION}
        tableData={tableData}
      />
      <PairSelector
        injectorPairNames={availableCorrPairs?.[INJECTOR]}
        producerPairNames={availableCorrPairs?.[PRODUCER]}
        filterWorkflowAnalysisContent={processWorkflowAnalysisContent}
      />
      <FieldNetworkGraph
        header={headers.FIELD_MAP}
        filteredDatasetContent={apiRes && apiRes?.filteredDataset}
        workflowAnalysisContent={workflowAnalysisContent}
        calculationScores={calculationScores}
        minImp={minImp}
        maxImp={maxImp}
        minWellScore={minWellScore}
        maxWellScore={maxWellScore}
      />
      <WorkflowConfigurationTable tableData={calculationScores} />
    </Fragment>
  ) : (
    <LoadingPlot />
  );
};

export default FieldMapSection;

FieldMapSection.propTypes = {
  headers: PropTypes.object,
  selectedCorrPair: PropTypes.array,
  availableCorrPairs: PropTypes.object,
  setSelectedCorrPair: PropTypes.func,
};
