import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import ComponentMapper from './ComponentMapper';

const selectedHeaderSubscriber = state => state.sidebar.selectedPlotHeader;

const CRMPReport = ({ headers }) => {
  const reduxSelectedPlotHeader = useSelector(selectedHeaderSubscriber);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  return (
    <ComponentMapper
      headers={headers}
      selectedHeader={reduxSelectedPlotHeader}
    />
  );
};

CRMPReport.propTypes = {
  headers: PropTypes.object,
};

export default CRMPReport;
