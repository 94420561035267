import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import useStyles from '../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../Components/LoadingPage';

import { updateSnackBar } from '../../../redux/actions/feedback';

import {
  integration2DMap,
  processIntegrationDatasetsV2,
} from '../Utils/commonTools';

import {
  Grid,
  Radio,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';

import { SNACK_BAR_SEVERITY } from '../../../constants/ComponentConstants';

const CALCULATION_TYPES = ['Sum', 'Average'];
const WELL_POINTS = {
  x: [],
  y: [],
};

const FieldMap2D = ({ header, uploadedCSVFiles, attributes }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const PlotlyComponent = createPlotlyComponent(Plotly);
  const [analysisReady, setanalysisReady] = useState(false);

  const [plot, setplot] = useState(null);
  const [layers, setlayers] = useState([]);
  const [wellDf, setwellDf] = useState(null);
  const [selectedLayer, setselectedLayer] = useState('');
  const [selectedAttr, setselectedAttr] = useState('');
  const [processedData, setprocessedData] = useState(null);
  const [calculationType, setcalculationType] = useState(null);

  // Callbacks -->
  const updatePlot = (attribute, wellPoints, selectedLayer) => {
    setplot(
      integration2DMap(
        processedData[attribute],
        processedData.perm,
        processedData.xCoords,
        processedData.yCoords,
        wellPoints,
        new Array(wellPoints['x'].length).fill(1),
        selectedLayer,
        attributes['Quality Index'] == attribute ? true : false,
        header,
        true
      )
    );
  };
  // <-- Callbacks

  // Handlers -->
  const onLayerSelect = event => {
    setselectedLayer(event.target.value);
    updatePlot(selectedAttr, wellDf, event.target.value);
  };

  const onAttrSelect = event => {
    setselectedAttr(event.target.value);
    updatePlot(event.target.value, wellDf, selectedLayer);
  };

  const onCalculationTypeSelect = event => {
    setcalculationType(event.target.value);
  };
  // <-- Handlers

  useEffect(() => {
    if (uploadedCSVFiles)
      try {
        setwellDf(WELL_POINTS);
        setcalculationType(CALCULATION_TYPES[0]);
        setlayers([...Array(Number(uploadedCSVFiles.parameters['Nz'])).keys()]);
        setprocessedData(
          processIntegrationDatasetsV2(
            uploadedCSVFiles.actnumFile,
            uploadedCSVFiles.parameters,
            uploadedCSVFiles.permx,
            uploadedCSVFiles.poro,
            uploadedCSVFiles.results,
            uploadedCSVFiles.mgip_21,
            uploadedCSVFiles.mgip_40,
            uploadedCSVFiles.sg_21,
            uploadedCSVFiles.sg_40
          )
        );
      } catch (error) {
        dispatch(
          updateSnackBar(
            error.message
              ? error.message
              : 'Error occurred while processing the datasets.',
            SNACK_BAR_SEVERITY.error
          )
        );
        setanalysisReady(false);
      }
    return () => {
      setanalysisReady(false);
    };
  }, [uploadedCSVFiles]);

  useEffect(() => {
    if (processedData && wellDf) {
      setselectedLayer(layers[0]);
      setselectedAttr(Object.values(attributes).at[1]);
      updatePlot(Object.values(attributes)[1], wellDf, layers[0]);
      setanalysisReady(true);
    }
  }, [processedData, wellDf]);

  return analysisReady ? (
    <React.Fragment>
      <Grid id={header} container className={styles.visualContentFlex}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{header}</Typography>
        </Grid>
        <Grid
          item
          container
          className={styles.inputContentRow + ' multipleContent'}
        >
          <Typography>Calculation Type (Layers)</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={calculationType}
              onChange={onCalculationTypeSelect}
            >
              {CALCULATION_TYPES.map(type => (
                <FormControlLabel
                  key={'form-control-label ' + type}
                  disabled
                  value={type}
                  control={<Radio />}
                  label={type}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className={styles.inputContentRow + ' multipleContent'}>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel>Select Layer</InputLabel>
            <Select value={selectedLayer} onChange={onLayerSelect}>
              {layers.map(layer => (
                <MenuItem key={'menu-item-' + layer} value={layer}>
                  {layer + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel>Select Attribute</InputLabel>
            <Select value={selectedAttr} onChange={onAttrSelect}>
              {Object.entries(attributes).map(
                ([attrName, attrAbbr]) =>
                  attrName != 'ROR' && (
                    <MenuItem key={'menu-item-' + attrName} value={attrAbbr}>
                      {attrName}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Grid>
        {plot ? (
          <PlotlyComponent
            data={plot.data}
            layout={{
              ...plot.layout,
              width: 700,
              height: 900,
            }}
            config={plot.config}
            style={{
              margin: 'auto',
            }}
          />
        ) : (
          <LoadingPage message="2D Field Map is Loading..." goHome={false} />
        )}
      </Grid>
    </React.Fragment>
  ) : (
    <LoadingPage message="Field map is loading..." goHome={false} />
  );
};

FieldMap2D.propTypes = {
  header: PropTypes.string,
  uploadedCSVFiles: PropTypes.object,
  attributes: PropTypes.object,
};

export default FieldMap2D;
