import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { updateWorkflow } from '../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../../constants/ComponentConstants';
import { useNavigate } from 'react-router-dom';

import {
  POLY_INJECTION_WORKFLOW_STAGES,
  VISCOSITY_RATIO,
  N_YEARS,
  POLYMER_ITERATION,
} from '../../../../../constants/WorkflowsParameterConstants';

import Parameters from './Parameters';
import Results from './Results';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;
const PolymerInjection = ({
  headers,
  filteredDatasetContent,
  prodRecovery,
  injPotential,
  gainValues,
  prodNames,
  injNames,
  polyRecovery,
  fieldSum,
  workflowParameters,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);
  const [stage, setStage] = useState(
    POLY_INJECTION_WORKFLOW_STAGES.OIL_MATCHING
  );

  useEffect(() => {
    if (workflowParameters) {
      setStage(workflowParameters['stage']);
    }
  }, [workflowParameters]);

  // Handlers -->
  const onSubmit = async (viscosity_ratio, nYears) => {
    const updatedWorkflowPayload = {
      parameters: {
        ...reduxSelectedWorkflow['parameters'],
        [VISCOSITY_RATIO]: viscosity_ratio,
        [N_YEARS]: nYears,
        [POLYMER_ITERATION]: workflowParameters[POLYMER_ITERATION] + 1,
        stage: POLY_INJECTION_WORKFLOW_STAGES.POLY_INJECTION,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Polymer Injection stage request sent successfully.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          'There was a problem with the server. Please try again later.',
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };
  // <-- Handlers

  return (
    <Fragment>
      <Parameters onSubmit={onSubmit} workflowParameters={workflowParameters} />
      {stage == POLY_INJECTION_WORKFLOW_STAGES.POLY_INJECTION && (
        <Results
          filteredDatasetContent={filteredDatasetContent}
          prodRecovery={prodRecovery}
          injPotential={injPotential}
          polyRecovery={polyRecovery}
          fieldSum={fieldSum}
          gainValues={gainValues}
          prodNames={prodNames}
          injNames={injNames}
          workflowParameters={workflowParameters}
          headers={headers}
        />
      )}
    </Fragment>
  );
};

PolymerInjection.propTypes = {
  headers: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  prodRecovery: PropTypes.array,
  injPotential: PropTypes.array,
  gainValues: PropTypes.array,
  polyRecovery: PropTypes.array,
  fieldSum: PropTypes.array,
  prodNames: PropTypes.array,
  injNames: PropTypes.array,
  workflowParameters: PropTypes.object,
};

export default PolymerInjection;
