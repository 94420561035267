import { reduxAction } from '../base';
import { SET_SELECTED_HEADER, RESET_SELECTED_HEADER } from './types';
/**
 * Basic redux action creator that sets the selected header state.
 * @param {String} header
 * @returns {Object} - reduxAction
 */
export const setSelectedHeader = header => {
  return reduxAction(SET_SELECTED_HEADER, {
    selectedHeader: header,
  });
};
/**
 * Basic redux action creator that resets the selected header state.
 * @returns {Object} - reduxAction
 */
export const resetSelectedHeader = () => {
  return reduxAction(RESET_SELECTED_HEADER, {
    selectedHeader: null,
  });
};
