import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../Components/LoadingPage';

import { generateOilSaturationPlot } from '../../../../../Utils/WorkflowReportUtils/PolyInjection';

import { Grid, Slider, TextField, Typography } from '@material-ui/core';
import {
  CURRENT_OIL_SATURATION,
  INITIAL_OIL_SATURATION,
  R2_WATER,
  SYSTEM_OF_UNITS,
} from '../../../../../constants/WellConstants';

const OilSaturation = ({
  headers,
  filteredDatasetContent,
  workflowParameters,
  prodOilSaturation,
  prodNames,
  injNames,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [initMinR2Value, setinitMinR2Value] = useState(null);
  const [initMaxR2Value, setinitMaxR2Value] = useState(null);
  const [initR2SliderValue, setinitR2SliderValue] = useState(null);
  const [initR2ThresholdValue, setinitR2ThresholdValue] = useState(0.6);

  const [currMinR2Value, setcurrMinR2Value] = useState(null);
  const [currMaxR2Value, setcurrMaxR2Value] = useState(null);
  const [currR2SliderValue, setcurrR2SliderValue] = useState(null);
  const [currR2ThresholdValue, setcurrR2ThresholdValue] = useState(0.6);

  const [initSatPlot, setinitSatPlot] = useState(null);
  const [currSatPlot, setcurrSatPlot] = useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const initR2WaterSliderHandler = value => {
    setinitR2SliderValue(value);

    setinitSatPlot(
      generateOilSaturationPlot(
        filteredDatasetContent,
        prodOilSaturation,
        prodNames,
        injNames,
        value,
        initR2ThresholdValue,
        INITIAL_OIL_SATURATION,
        workflowParameters[SYSTEM_OF_UNITS]
      )
    );
  };

  const currR2WaterSliderHandler = value => {
    setcurrR2SliderValue(value);

    setcurrSatPlot(
      generateOilSaturationPlot(
        filteredDatasetContent,
        prodOilSaturation,
        prodNames,
        injNames,
        value,
        currR2ThresholdValue,
        CURRENT_OIL_SATURATION,
        workflowParameters[SYSTEM_OF_UNITS]
      )
    );
  };

  const initR2WaterThresholdHandler = value => {
    setinitR2ThresholdValue(value);

    setinitSatPlot(
      generateOilSaturationPlot(
        filteredDatasetContent,
        prodOilSaturation,
        prodNames,
        injNames,
        initR2SliderValue,
        value,
        INITIAL_OIL_SATURATION,
        workflowParameters[SYSTEM_OF_UNITS]
      )
    );
  };

  const currR2WaterThresholdHandler = value => {
    setcurrR2ThresholdValue(value);

    setcurrSatPlot(
      generateOilSaturationPlot(
        filteredDatasetContent,
        prodOilSaturation,
        prodNames,
        injNames,
        currR2SliderValue,
        value,
        CURRENT_OIL_SATURATION,
        workflowParameters[SYSTEM_OF_UNITS]
      )
    );
  };
  // <-- Handlers

  useEffect(() => {
    if (filteredDatasetContent && prodOilSaturation && prodNames && injNames) {
      // const min =
      //   Math.round(
      //     Math.min(...prodOilSaturation.map(row => Number(row[R2_WATER]))) * 100
      //   ) / 100;
      const max =
        Math.round(
          Math.max(...prodOilSaturation.map(row => Number(row[R2_WATER]))) * 100
        ) / 100;

      setinitMinR2Value(0);
      setinitMaxR2Value(max);
      setinitR2SliderValue([0.6, max]);

      setcurrMinR2Value(0);
      setcurrMaxR2Value(max);
      setcurrR2SliderValue([0, max]);

      initR2WaterSliderHandler([0.6, max]);
      currR2WaterSliderHandler([0, max]);
    }
  }, [filteredDatasetContent, prodOilSaturation, prodNames, injNames]);

  return (
    <React.Fragment>
      <Grid
        container
        className={styles.visualContent}
        id={headers.INIT_OIL_SAT}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.INIT_OIL_SAT}</Typography>
        </Grid>
        <Grid item className={styles.visualContentRow}>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Typography gutterBottom>R2 Water Filter</Typography>
            <Slider
              min={initMinR2Value}
              step={0.01}
              max={initMaxR2Value}
              value={initR2SliderValue}
              onChange={(event, value) => initR2WaterSliderHandler(value)}
              valueLabelDisplay="auto"
              style={{ width: '80%' }}
            />
          </Grid>
        </Grid>
        <Grid item className={styles.inputContentRow}>
          <Typography>R2 Water Threshold</Typography>
          <Grid container>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              className="numericInput"
              value={initR2ThresholdValue}
              onChange={event =>
                initR2WaterThresholdHandler(event.target.value)
              }
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1,
                  step: 0.05,
                },
              }}
            />
          </Grid>
        </Grid>
        {initSatPlot ? (
          <PlotlyComponent
            data={initSatPlot.data}
            layout={initSatPlot.layout}
            config={initSatPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Initial Oil Saturation Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid
        container
        className={styles.visualContent}
        id={headers.CURRENT_OIL_SAT}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.CURRENT_OIL_SAT}</Typography>
        </Grid>
        <Grid item className={styles.visualContentRow}>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Typography gutterBottom>R2 Water Filter</Typography>
            <Slider
              min={currMinR2Value}
              step={0.01}
              max={currMaxR2Value}
              value={currR2SliderValue}
              onChange={(event, value) => currR2WaterSliderHandler(value)}
              valueLabelDisplay="auto"
              style={{ width: '80%' }}
            />
          </Grid>
        </Grid>
        <Grid item className={styles.inputContentRow}>
          <Typography>R2 Water Threshold</Typography>
          <Grid container>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              className="numericInput"
              value={currR2ThresholdValue}
              onChange={event =>
                currR2WaterThresholdHandler(event.target.value)
              }
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1,
                  step: 0.05,
                },
              }}
            />
          </Grid>
        </Grid>
        {currSatPlot ? (
          <PlotlyComponent
            data={currSatPlot.data}
            layout={currSatPlot.layout}
            config={currSatPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Current Oil Saturation Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

OilSaturation.propTypes = {
  headers: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  workflowParameters: PropTypes.object,
  prodOilSaturation: PropTypes.array,
  prodNames: PropTypes.array,
  injNames: PropTypes.array,
};

export default OilSaturation;
