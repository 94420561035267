import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../Styles/VisualizationPageStyle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';
import WellSelectionModal from '../../../Components/WellSelectionModal';

import { updateSnackBar } from '../../../../redux/actions/feedback';
import { retrieveWellSurfaceLocations } from '../../../../API/Functions/Visualization';

import { generateWellSurfaceLocationPlot } from '../../../../Utils/VisualizationUtils';
import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';

import { Button, Grid } from '@material-ui/core';

import {
  INJECTOR,
  PRODUCER,
  SYSTEM_OF_UNITS,
} from '../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

const selectedDatasetParameters = state => state.dataset.parameters;

const WellSurfaceLocationComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedDatasetParameters = useSelector(selectedDatasetParameters);
  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [modalState, setModalState] = useState(false);
  const [wellNameConfigs, setwellNameConfigs] = useState(undefined);

  // Callback/Helpers -->
  const toggleModal = () => {
    setModalState(prev => !prev);
  };

  const applyConfigurations = configObject => {
    setwellNameConfigs(configObject);
  };

  const saveWellNameConfigs = (wellNames, wellType, initConfig) => {
    wellNames.reduce((acc, curr) => {
      acc[wellType].wellNamePairs[curr] = true;
      return acc;
    }, initConfig);
  };

  const initConfigurations = (injectorNames, producerNames) => {
    const initConfig = {
      [INJECTOR]: {
        wellNamePairs: {},
      },
      [PRODUCER]: {
        wellNamePairs: {},
      },
    };

    saveWellNameConfigs(injectorNames, INJECTOR, initConfig);
    saveWellNameConfigs(producerNames, PRODUCER, initConfig);
    applyConfigurations(initConfig);
  };
  // <-- Callback/Helpers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveWellSurfaceLocations(
          urlDatasetId,
          reduxSelectedDatasetParameters
        );
        if (mounted) {
          const { injectors, producers } = filterWellNamesFromDataset(res);
          initConfigurations(injectors, producers);

          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, [reduxSelectedDatasetParameters]);

  const cachedPlotResult = useMemo(() => {
    if (apiRes && wellNameConfigs) {
      return generateWellSurfaceLocationPlot(
        apiRes,
        Object.entries(wellNameConfigs?.[INJECTOR].wellNamePairs)
          .filter(wellAttrPair => wellAttrPair[1])
          .map(wellAttrPair => wellAttrPair[0]),
        Object.entries(wellNameConfigs?.[PRODUCER].wellNamePairs)
          .filter(wellAttrPair => wellAttrPair[1])
          .map(wellAttrPair => wellAttrPair[0]),
        reduxSelectedDatasetParameters[SYSTEM_OF_UNITS]
      );
    }
  }, [
    apiRes,
    wellNameConfigs?.[INJECTOR].wellNamePairs,
    wellNameConfigs?.[PRODUCER].wellNamePairs,
  ]);

  return (
    <Grid item className={styles.plotContainer}>
      <Grid item container className={styles.buttonContainer}>
        <Button onClick={toggleModal}>Select Wells</Button>
      </Grid>
      {wellNameConfigs && (
        <WellSelectionModal
          toggle={toggleModal}
          modalState={modalState}
          configObject={wellNameConfigs}
          applyConfigurations={applyConfigurations}
        />
      )}
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

export default WellSurfaceLocationComponent;
