import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../Styles/WorkflowStyles';

import { Grid, Paper } from '@material-ui/core';
import { BasicTable } from './TableView';

const WorkflowConfigurationTable = ({ header, tableData }) => {
  const styles = useStyles();

  return (
    <Grid id={header} container className={styles.visualContentFlex}>
      {tableData && (
        <Paper elevation={5} className={styles.visualContentRow}>
          <BasicTable data={tableData} />
        </Paper>
      )}
    </Grid>
  );
};

WorkflowConfigurationTable.propTypes = {
  header: PropTypes.string,
  tableData: PropTypes.array,
};

export default WorkflowConfigurationTable;
