import WellSurfaceLocationComponent from './PlotContainers/WellSurfaceLocation';
import ActiveWellScheduleComponent from './PlotContainers/ActiveWellSchedule';
import CumFieldAnalysisComponent from './PlotContainers/CumFieldAnalysis';
import WellProductionRateAnalysisComponent from './PlotContainers/WellProductionRateAnalysis';
import WellInjectionRateAnalysisComponent from './PlotContainers/WellInjectionRateAnalysis';
import CustomWellRateAnalysisComponent from './PlotContainers/CustomWellRateAnalysis';
import FieldReservoirAnalysisComponent from './PlotContainers/FieldResAnalysis';
import FieldActiveWellCountComponent from './PlotContainers/FieldWellCount';
import FieldVRRAnalysisComponent from './PlotContainers/FieldVRRAnalysis';

import { SURVEILLANCE_DATASET_VISUALIZATION_HEADER_NAMES as PLOT_HEADERS } from '../../../constants/WorkflowComponentConstants';
import { Typography } from '@material-ui/core';

export const PLOT_COMPONENTS = {
  [PLOT_HEADERS.WELLS]: <WellSurfaceLocationComponent />,
  [PLOT_HEADERS.SCHEDULE_PLOT]: <ActiveWellScheduleComponent />,
  [PLOT_HEADERS.FIELD_ANALYSIS]: <CumFieldAnalysisComponent />,
  [PLOT_HEADERS.PRODUCTION]: <WellProductionRateAnalysisComponent />,
  [PLOT_HEADERS.INJECTION]: <WellInjectionRateAnalysisComponent />,
  [PLOT_HEADERS.CUSTOMIZABLE_PLOT]: <CustomWellRateAnalysisComponent />,
  [PLOT_HEADERS.FIELD_RES_ANALYSIS]: <FieldReservoirAnalysisComponent />,
  [PLOT_HEADERS.FIELD_FLUID_RES_RATE]: <FieldActiveWellCountComponent />,
  [PLOT_HEADERS.VRR]: <FieldVRRAnalysisComponent />,
};

export const PLOT_SYNOPSES = {
  [PLOT_HEADERS.WELLS]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays the surface location of the wells in the field. It
          extracts the surface locations of the well and plots them on a map.
        </li>
        <li>
          The map is interactive and can be zoomed in and out of. It can also be
          dragged around to view different areas of the map by using axes. The
          map can be reset to its original position by clicking the reset button
          in the top right corner of the map.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.SCHEDULE_PLOT]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays the active wells in the field within the field
          start and end dates.
        </li>
        <li>
          If an injector well has a non-zero <code>Field Injection Rate</code>{' '}
          value, or a producer well has a non-zero{' '}
          <code>Field Production Rate</code> value, it is considered an active
          well.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.FIELD_ANALYSIS]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays monthly field-level total injection and production
          rates, aggregated by <code>Date</code>.
        </li>
        <li>
          If the uploaded dataset has a <code>Layer</code> column, the plot will
          also aggregate them into a single data point.
        </li>
        <li>
          Reservoir traces <code>(res)</code> are calculated by the
          multiplication of the corresponding{' '}
          <code>Formation Volume Factor</code> parameter and data point. For
          example, if the <code>Formation Volume Factor</code> for oil is 1.2,
          the reservoir trace will have a 20% higher value than the data point.
          One can compare the difference by selecting the base and reservoir
          traces alone by double-clicking on the trace names.
        </li>
        <li>
          <code>Swap Traces</code> button allows you to switch between fluid and
          gas related field rates.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If any <code>Formation Volume Factor</code>parameter is applied,
          reservoir traces will be reflected.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),

  [PLOT_HEADERS.PRODUCTION]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays monthly <code>Production Rates</code>, aggregated
          by <code>date</code>.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.INJECTION]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays monthly <code>Injection Rates</code>, aggregated by{' '}
          <code>date</code>.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.CUSTOMIZABLE_PLOT]: (
    <Typography component="div">
      <ul>
        <li>
          This plot is capable of combining multiple wells and various rates,
          and displaying them in a single plot. <code>Producer Attributes</code>{' '}
          get combined with producer wells, and <code>Injector Attributes</code>{' '}
          get combined with injector wells.
        </li>
        <li>
          For the attributes that have different units, the plot adds additional
          Y Axes.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.FIELD_FLUID_RES_RATE]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays the total number of{' '}
          <code>unique active wells</code> in the field within the field start
          and end dates.
        </li>
        <li>
          If an injector well has a non-zero <code>Field Injection Rate</code>{' '}
          value, or a producer well has a non-zero{' '}
          <code>Field Production Rate</code> value, it is considered an active
          well.
        </li>
        <li>
          Reservoir traces (res) are calculated by the multiplication of the
          corresponding Formation Volume Factor parameter and data point. For
          example, Fluid Injection Rate (res) is the sum of (Fluid Injection
          Rate * Formation Volume Factor: Water) and (Gas Injection Rate *
          Formation Volume Factor: Gas).
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If any <code>Formation Volume Factor</code>parameter is applied,
          reservoir traces will be reflected.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.FIELD_RES_ANALYSIS]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays monthly field-level <code>Liquid Production</code>,{' '}
          <code>Oil Production</code>, <code>Gas Production</code>,{' '}
          <code>Gas Injection</code>, <code>Active Producer</code>,{' '}
          <code>Water Cut</code>, and <code>GOR</code> properties aggregated by{' '}
          <code>Date</code>.
        </li>
        <li>
          If the uploaded dataset has a <code>Layer</code> column, the plot will
          also aggregate them into a single data point.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),
  [PLOT_HEADERS.VRR]: (
    <Typography component="div">
      <ul>
        <li>
          This plot displays the monthly <code>VRR</code> value, which is the
          ratio of <code>VRR</code> to <code>Fluid Production Rate (res)</code>,
          aggregated by <code>Date</code>.
        </li>
        <li>
          If any <code>date filter</code> is applied, only the active wells
          within the date range will be displayed.
        </li>
        <li>
          If any <code>Formation Volume Factor</code>parameter is applied,
          reservoir traces will be reflected.
        </li>
        <li>
          If the <code>Normalize by Active Days</code> option is selected, the
          data points will be normalized by multiplying the{' '}
          <code>Active Days</code> value. If the <code>Active Days</code> value
          doesn't exist, the data point will be multiplied by <code>30.5</code>{' '}
        </li>
        <li>
          If the <code>Data Smoothing</code> option is selected, the data points
          are smoothed by applying a moving average filter. The window size of
          the filter can be adjusted by modifying the{' '}
          <code>Data Smoothing Factor</code>.
        </li>
      </ul>
    </Typography>
  ),
};
