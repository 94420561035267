import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  ADVANCED_SETTINGS_LABELS,
  WELL_RATE_THRESHOLD,
} from '../../constants/WellConstants';

const useStyles = makeStyles({
  parameters: {
    overflow: 'hidden',
    margin: '0.5em 0em',
    '& .parameterRow': {
      justifyContent: 'space-between',
      margin: '0.2em 0em',
      gap: '0.5em',
    },
    '& .left': { width: '55%', textAlign: 'left' },
    '& .right': { width: '45%', textAlign: 'right' },
  },
  button: {
    borderRadius: '2em',
    margin: '0 0.5em',
  },
});

const AdvancedParametersModal = ({
  modalState,
  toggle,
  applyAdvancedParameters,
  advancedParameterPairs,
}) => {
  const styles = useStyles();
  const [params, setparams] = useState(advancedParameterPairs);

  // Callbacks -->
  // <-- Callbacks

  // Handlers -->
  // Save params to redux state
  const onSubmit = () => {
    applyAdvancedParameters(params);
    toggle();
  };
  // <-- Handlers

  const onParamUpdate = (key, value) => {
    setparams(previous => {
      return {
        ...previous,
        [key]: value,
      };
    });
  };

  const onClose = () => {
    setparams({ ...advancedParameterPairs });
    toggle();
  };

  return (
    <Dialog
      open={modalState}
      // fullWidth={true}
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitle disableTypography={true}>
        <Grid
          container
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">Advanced Settings</Typography>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {params &&
          Object.entries(params).map(([key, value]) => (
            <Grid key={key} container className={styles.parameters}>
              {key === WELL_RATE_THRESHOLD && (
                <Grid item container className="parameterRow">
                  <Typography>Excluding production wells with,</Typography>
                  <br />
                </Grid>
              )}
              <Grid item container className="parameterRow">
                <Typography>{ADVANCED_SETTINGS_LABELS[key]}</Typography>
                {(typeof value === 'string' || typeof value === 'number') && (
                  <TextField
                    type="number"
                    size="small"
                    value={value}
                    onChange={event =>
                      onParamUpdate(key, parseFloat(event.target.value))
                    }
                    inputProps={{
                      min: 0,
                      // max: 2000
                      step: 0.05,
                    }}
                  />
                )}
                {typeof value === 'boolean' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={event =>
                          onParamUpdate(key, event.target.checked)
                        }
                      />
                    }
                  />
                )}
              </Grid>
            </Grid>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={styles.button}
          onClick={onSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvancedParametersModal.propTypes = {
  modalState: PropTypes.bool,
  toggle: PropTypes.func,
  applyAdvancedParameters: PropTypes.func,
  advancedParameterPairs: PropTypes.object,
};

export default AdvancedParametersModal;
