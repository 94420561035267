import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { Grid, Paper, Typography, Link, IconButton } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { BasicTableWithDynamicColumns } from '../PolymerInjection/TableViewDynamicHeader';

const R2_Table = ({ headers, prodOilSaturation, paramTotals }) => {
  const styles = useStyles();
  const [tableData, setTableData] = useState(null);
  const [encodedUri, setencodedUri] = useState(null);

  const filterDataForTable = (prodOilSaturation, paramTotals) => {
    let mergedArray = prodOilSaturation.map((item1, index) => {
      return { ...item1, ...paramTotals[index] };
    });

    const columnMapping = {
      Producers: 'Producer',
      'R2 - oil': 'Oil R2',
      'R2 - water': 'Water R2',
      muo: 'Oil Viscosity',
      muw: 'Water Viscosity',
      kroc: 'Oil Relative Permeability',
      krwr: 'Water Relative Permeability',
      Swr: 'Connate Water Saturation',
      Sor: 'Irredicuble Oil Saturation',
      Swi: 'Initial Water Saturation',
      nw: 'Corey Water Exponent',
      no: 'Corey Oil Exponent',
      PV: 'Pore Volume',
    };
    const filteredColumns = Object.keys(columnMapping);
    const filteredData = mergedArray.map(row => {
      const filteredRow = {};
      filteredColumns.forEach(column => {
        filteredRow[columnMapping[column]] = row[column];
      });
      return filteredRow;
    });

    const sortedDatasetByWellNames = filteredData.sort((a, b) => {
      const aNum = parseInt(a['Producer'].split('-')[1]);
      const bNum = parseInt(b['Producer'].split('-')[1]);
      return aNum - bNum;
    });

    return sortedDatasetByWellNames;
  };

  // Handlers -->
  const onDownload = () => {
    const content = [];

    tableData.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (prodOilSaturation && paramTotals) {
      setTableData(filterDataForTable(prodOilSaturation, paramTotals));
    }
  }, [prodOilSaturation, paramTotals]);

  return (
    <Grid container className={styles.visualContentFlex} id={headers.R2_VALUES}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{headers.R2_VALUES}</Typography>
      </Grid>
      <Paper elevation={5} className={styles.visualContentRow}>
        <BasicTableWithDynamicColumns data={tableData} />
      </Paper>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download R2 Values</Typography>
          <Link
            download="R2_Oil_Water.csv"
            href={encodedUri}
            onClick={onDownload}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default R2_Table;

R2_Table.propTypes = {
  headers: PropTypes.object,
  prodOilSaturation: PropTypes.array,
  paramTotals: PropTypes.array,
};
