import {
  DATE,
  FIELD,
  WELL_ID,
  GAS_INJECTION_RATE,
  OIL_PRODUCTION_RATE,
  GAS_PRODUCTION_RATE,
  WATER_INJECTION_RATE,
  TOTAL_INJECTION_RATE,
  CUMULATIVE_INJECTION,
  CUMULATIVE_PRODUCTION,
  TOTAL_PRODUCTION_RATE,
  WATER_PRODUCTION_RATE,
  CUMULATIVE_GAS_INJECTION,
  CUMULATIVE_OIL_PRODUCTION,
  CUMULATIVE_INJECTION_COLS,
  CUMULATIVE_GAS_PRODUCTION,
  CUMULATIVE_WATER_INJECTION,
  CUMULATIVE_PRODUCTION_COLS,
  CUMULATIVE_WATER_PRODUCTION,
} from '../../constants/WellConstants';

import {
  filterWellNamesFromDataset,
  filterTimelineFromDataset,
  filterWellContentsFromDataset,
} from '../DatasetUtils/DataProcessing';

import {
  createLinePlotTrace,
  createTrace,
  LINES_WITH_MARKERS,
} from '../PlotlyUtils/Plots';

/**
 * This function filters the passed dataset by given colName, and generates line
 * plot trace for each well.
 *
 * @param {Array} dataset
 * @param {String} colName
 * @returns {Array} traces
 */
export const generateCumulativeWellPlotTrace = (dataset, colName) => {
  const { injectors, producers } = filterWellNamesFromDataset(dataset);
  const timeline = filterTimelineFromDataset(dataset);
  const wells = colName.includes('Production') ? producers : injectors;
  const traces = [];

  wells.forEach(well => {
    traces.push(
      createLinePlotTrace(
        timeline,
        dataset.filter(row => row[WELL_ID] == well).map(row => row[colName]),
        well
      )
    );
  });

  return traces;
};

export const generateFieldLiquidPlotFromColNames = (dataset, colNames) => {
  const traces = [];
  const valueOrientedObjectData = {};
  const timeline = filterTimelineFromDataset(dataset);

  // Save each key to an object with empty array.
  Object.keys(colNames).forEach(key => {
    valueOrientedObjectData[key] = [];
  });
  // Save each key value from the passed dataset to the value oriented data structure.
  dataset.forEach(row => {
    Object.entries(valueOrientedObjectData).forEach(([key, arr]) => {
      arr.push(row[key]);
    });
  });
  // Create Line plot trace from the value oriented object data.
  Object.entries(colNames).forEach(([key, value]) => {
    traces.push(
      createLinePlotTrace(timeline, valueOrientedObjectData[key], value)
    );
  });
  return traces;
};

/**
 * This function finds the cumulative values of passed dataframe by filtering by date, and using passed column values.
 *
 * @param {Array} df
 * @param {Array} columns
 * @returns {Object}
 */
const findMonthlyCumulativeValues = (df, columns) => {
  const dates = [...new Set(df.map(row => row[DATE]))];
  const cumulativeValues = {};

  dates.forEach(date => {
    const dateFilteredDataset = df.filter(row => row[DATE] == date);

    columns.forEach(colName => {
      if (!(colName in cumulativeValues)) {
        cumulativeValues[colName] = [];
      }

      const { cumDateValue } = dateFilteredDataset.reduce(
        (acc, dataRow) => {
          let { cumDateValue } = acc;
          cumDateValue += isNaN(Number(dataRow[colName]))
            ? 0
            : Number(dataRow[colName]);
          return { cumDateValue };
        },
        { cumDateValue: 1e-6 }
      );

      cumulativeValues[colName].push(cumDateValue);
    });
  });
  return cumulativeValues;
};

/**
 * This function finds monthly total production injection rates for all the wells that are
 * in passed @param dataset. Then it takes the ratio of them between each other and produces
 * more meaningful data.
 *
 * @param {Array} dataset
 * @returns {Object} {q_f, qw_f, qo_f, qg_f, i_f, iw_f, ig_f, ivrr, fw_f, fo_f, Y_f, X_f, WOR_f, GOR_f}
 */
const calculateFieldLiquidInjProdRate = dataset => {
  const { injectors, producers } = filterWellContentsFromDataset(dataset);
  // very small number is assigned to each array to hold total inj/prod values, for
  // safer division.
  let q_f = Array(dataset.length).fill(1e-6),
    qw_f = Array(dataset.length).fill(1e-6),
    qo_f = Array(dataset.length).fill(1e-6),
    qg_f = Array(dataset.length).fill(1e-6),
    i_f = Array(dataset.length).fill(1e-6),
    iw_f = Array(dataset.length).fill(1e-6),
    ig_f = Array(dataset.length).fill(1e-6);

  // Find monthly cumulative values.
  const cumProdValues = findMonthlyCumulativeValues(
    producers,
    Object.values(CUMULATIVE_PRODUCTION_COLS)
  );
  const cumInjValues = findMonthlyCumulativeValues(
    injectors,
    Object.values(CUMULATIVE_INJECTION_COLS)
  );

  // Assign cumulative values to descriptive arrays.
  if (Object.entries(cumProdValues).length > 0) {
    q_f = cumProdValues[TOTAL_PRODUCTION_RATE];
    qw_f = cumProdValues[WATER_PRODUCTION_RATE];
    qo_f = cumProdValues[OIL_PRODUCTION_RATE];
    qg_f = cumProdValues[GAS_PRODUCTION_RATE];
  }
  if (Object.entries(cumInjValues).length > 0) {
    i_f = cumInjValues[TOTAL_INJECTION_RATE];
    iw_f = cumInjValues[WATER_INJECTION_RATE];
    ig_f = cumInjValues[GAS_INJECTION_RATE];
  }

  // Produce meaningful data from Cumulative Values.
  const ivrr = i_f.map((totalInjValue, index) => totalInjValue / q_f[index]);
  const fw_f = qw_f.map(
    (waterValue, index) => waterValue / (qo_f[index] + waterValue)
  );
  const fo_f = fw_f.map(value => 1 - value);
  const Y_f = fw_f.map((value, index) => value * fo_f[index]);
  const inverse_fw_f = fw_f.map(value => 1 / value);
  const reduced_fw_f = fw_f.map(value => Math.log(1 / value - 1));
  const X_f = inverse_fw_f.map((value, index) => value - reduced_fw_f[index]);
  const WOR_f = qw_f.map((value, index) => qo_f[index]);
  const GOR_f = qg_f.map((value, index) => q_f[index]);

  return {
    [DATE]: filterTimelineFromDataset(dataset),
    q_f,
    qw_f,
    qo_f,
    qg_f,
    i_f,
    iw_f,
    ig_f,
    ivrr,
    fw_f,
    fo_f,
    Y_f,
    X_f,
    WOR_f,
    GOR_f,
  };
};

/**
 * This function finds monthly cumulative production injection rates for all the wells that are
 * in passed @param dataset. Then it takes the ratio of them between each other and produces
 * more meaningful data.
 *
 * @param {*} dataset
 * @returns {Object} { N_f, Nw_f, No_f, Ng_f, I_f, Iw_f, Ig_f, cvrr }
 */
const calculateLiquidInjProdRate = dataset => {
  const { injectors, producers } = filterWellContentsFromDataset(dataset);
  // very small number is assigned to each array to hold total inj/prod values, for
  // safer division.
  let N_f = Array(dataset.length).fill(1e-6),
    No_f = Array(dataset.length).fill(1e-6),
    Nw_f = Array(dataset.length).fill(1e-6),
    Ng_f = Array(dataset.length).fill(1e-6),
    I_f = Array(dataset.length).fill(1e-6),
    Iw_f = Array(dataset.length).fill(1e-6),
    Ig_f = Array(dataset.length).fill(1e-6);

  const cumProdValues = findMonthlyCumulativeValues(
    producers,
    Object.keys(CUMULATIVE_PRODUCTION_COLS)
  );
  const cumInjValues = findMonthlyCumulativeValues(
    injectors,
    Object.keys(CUMULATIVE_INJECTION_COLS)
  );

  if (Object.entries(cumProdValues).length > 0) {
    N_f = cumProdValues[CUMULATIVE_PRODUCTION];
    No_f = cumProdValues[CUMULATIVE_OIL_PRODUCTION];
    Nw_f = cumProdValues[CUMULATIVE_WATER_PRODUCTION];
    Ng_f = cumProdValues[CUMULATIVE_GAS_PRODUCTION];
  }
  if (Object.entries(cumInjValues).length > 0) {
    I_f = cumInjValues[CUMULATIVE_INJECTION];
    Iw_f = cumInjValues[CUMULATIVE_WATER_INJECTION];
    Ig_f = cumInjValues[CUMULATIVE_GAS_INJECTION];
  }

  const cvrr = I_f.map((value, index) => value / N_f[index]);

  return {
    [DATE]: filterTimelineFromDataset(dataset),
    N_f,
    Nw_f,
    No_f,
    Ng_f,
    I_f,
    Iw_f,
    Ig_f,
    cvrr,
  };
};

export const generatePlotComparisonPlotTrace = (
  dataset,
  selectedWell,
  logSelection,
  selectedPlot
) => {
  const traces = [];
  const datasetToProcess =
    selectedWell == FIELD
      ? dataset
      : dataset.filter(row => row[WELL_ID] == selectedWell);

  const joinedDataset = {
    ...calculateFieldLiquidInjProdRate(datasetToProcess),
    ...calculateLiquidInjProdRate(datasetToProcess),
  };

  let x = [...joinedDataset[selectedPlot.xColName]];
  let y = [...joinedDataset[selectedPlot.yColName]];
  if (selectedPlot.title != 'Movable Oil Plot') {
    switch (logSelection) {
      case 1:
        y = y.map(value => Math.log(value));
        break;
      case 2:
        x = x.map(value => Math.log(value));
        y = y.map(value => Math.log(value));
        break;
      default:
    }
    traces.push(
      createLinePlotTrace(
        x,
        y,
        selectedPlot.title,
        undefined,
        undefined,
        undefined,
        LINES_WITH_MARKERS
      )
    );
  } else {
    y = y.map((value, index) => value / x[index]);
    x = x.map(value => 1 / value);
    traces.push(
      createTrace(
        x,
        y,
        selectedPlot.title,
        undefined,
        undefined,
        undefined,
        undefined,
        15
      )
    );
  }

  return traces;
};
