import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: '#efefef',
  },
  navGrid: {
    height: '15%',
  },
  body: {
    height: '85%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '20%',
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: 'auto',
    position: 'relative',
  },
  textField: {
    width: '75%',
  },
  button: {
    margin: '0.5em',
    backgroundColor: '#000B46',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000B46',
    },
    width: '35%',
  },
  name: {
    marginLeft: '1em',
    fontSize: '1.75em',
  },
  logo: {
    cursor: 'pointer',
    marginLeft: '1em',
    width: '5em',
  },
});

export default useStyles;
