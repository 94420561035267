import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';

import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import { createHeatmap } from '../../../../Utils/PlotlyUtils/Plots';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Link,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  CORRELATION,
  INJECTOR,
  LAG,
  PRODUCER,
} from '../../../../constants/WellConstants';

const InjectorsCorrelationAnalysis = ({
  datasetContent,
  workflowAnalysisContent,
  minCC,
  maxCC,
  minLag,
  maxLag,
  injWellNames,
  selectedProducer,
  selectedInjector,
  onInjectorSelect,
  headers,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [heatmap, setheatmap] = useState(null);
  const [networkPlot, setnetworkPlot] = useState(null);
  const [filteredWorkflowAnalysisContent, setfilteredWorkflowAnalysisContent] =
    useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  /**
   * This function finds correlation value between selected injector and all other
   * producers, and plots them in a network graph.
   * @param {String} wellName
   */
  const generateInjectorNetworkGraph = () => {
    setnetworkPlot(
      generateNetworkGraphWithLags(
        datasetContent,
        filteredWorkflowAnalysisContent,
        CORRELATION,
        minCC,
        maxCC,
        minLag,
        maxLag,
        `${selectedInjector} Correlation Graph`
      )
    );
  };

  /**
   * This function generates heatmap to show correlation and lag values between selected
   * injector and all other producers.
   */
  const generateHeatmapGraph = () => {
    let heatmap = { y: [], x: [] };

    filteredWorkflowAnalysisContent.forEach(row => {
      if (!(row[PRODUCER] in heatmap)) {
        heatmap[row[PRODUCER]] = [];
      }
      if (!heatmap['y'].includes(row[PRODUCER])) {
        heatmap['y'].push(row[PRODUCER]);
      }
      if (!heatmap['x'].includes(row[LAG])) {
        heatmap['x'].push(row[LAG]);
      }
      heatmap[row[PRODUCER]].push(row[CORRELATION]);
    });

    let z = [];
    heatmap['y'].forEach(wellName => {
      z.push(heatmap[wellName]);
    });

    setheatmap(
      createHeatmap(
        heatmap['x'],
        heatmap['y'],
        z,
        CORRELATION + ' vs ' + LAG,
        LAG,
        PRODUCER,
        CORRELATION
      )
    );
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  const onWellSelect = event => {
    onInjectorSelect(event.target.value);
    setfilteredWorkflowAnalysisContent(
      workflowAnalysisContent.filter(row => row[INJECTOR] == event.target.value)
    );
  };

  /**
   * Downloads the workflow analysis result to clients machine.
   */
  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    // preselect first injector well.
    if (selectedInjector) {
      setfilteredWorkflowAnalysisContent(
        workflowAnalysisContent.filter(row => row[INJECTOR] == selectedInjector)
      );
    }
  }, [workflowAnalysisContent]);

  useEffect(() => {
    if (
      filteredWorkflowAnalysisContent &&
      selectedInjector &&
      selectedProducer
    ) {
      generateInjectorNetworkGraph();
      generateHeatmapGraph();
    }
  }, [filteredWorkflowAnalysisContent, selectedInjector, selectedProducer]);

  return (
    <React.Fragment>
      <Grid id={headers.INJECTOR} container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.INJECTOR}</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedInjector}
              onChange={onWellSelect}
              className="dropdownSelection"
            >
              {injWellNames.map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {networkPlot ? (
          <PlotlyComponent
            data={networkPlot.data}
            layout={networkPlot.layout}
            config={networkPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Injector Network Plot is Loading..."
            goHome={false}
          />
        )}
        <Grid container className={styles.visualContentRow}>
          <Grid
            item
            container
            xs={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="overline">Download Results</Typography>
            <Link
              download={`${selectedInjector}_Corr_Values.csv`}
              href={encodedUri}
              onClick={() => {
                onDownload(filteredWorkflowAnalysisContent);
              }}
            >
              <IconButton>
                <GetAppRoundedIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        id={headers.CORRELATION_LAG}
        container
        className={styles.visualContent}
      >
        {heatmap ? (
          <PlotlyComponent
            data={heatmap.data}
            layout={heatmap.layout}
            config={heatmap.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage message="Injector Heatmap is Loaing..." goHome={false} />
        )}
      </Grid>
    </React.Fragment>
  );
};

InjectorsCorrelationAnalysis.propTypes = {
  datasetContent: PropTypes.array,
  workflowAnalysisContent: PropTypes.array,
  minCC: PropTypes.number,
  maxCC: PropTypes.number,
  minLag: PropTypes.number,
  maxLag: PropTypes.number,
  injWellNames: PropTypes.array,
  selectedProducer: PropTypes.string,
  selectedInjector: PropTypes.string,
  onInjectorSelect: PropTypes.func,
  headers: PropTypes.object,
};

export default InjectorsCorrelationAnalysis;
