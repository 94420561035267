import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import FieldNetworkGraph from './FieldNetworkGraph';
import LoadingPage from '../../../Components/LoadingPage';
import CorrelationPairSelector from './CorrelationPairSelector';
import CumWorkflowAnalysisPlot from './CumWorkflowAnalysisPlot';
import ProducersCorrelationAnalysis from './ProducersCorrelationAnalysis';
import InjectorsCorrelationAnalysis from './InjectorsCorrelationAnalysis';
import WorkflowConfigurationTable from '../../../Components/WorkflowConfigurationTable';
import WellPairAnalysisPlot from '../../../Components/WorkflowAnalysisTools/WellPairAnalysisPlot';

import {
  CORRELATION,
  INJECTOR,
  LAG,
  PRODUCER,
} from '../../../../constants/WellConstants';

import { CROSS_CORR_WORKFLOW_RESULT_FILE_NAMES } from '../../../../constants/WorkflowsParameterConstants';

import {
  CROSS_CORR_INJECTOR_COLS as injCorrPairValues,
  CROSS_CORR_PRODUCER_COLS as prodCorrPairValues,
} from '../../../../constants/WorkflowComponentConstants';
import { CORR_SERIES } from '../../../../constants/WorkflowResultConstants';

const CrossCorrelation = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const [workflowReady, setworkflowReady] = useState(false);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [allWorkflowAnalysisContents, setallWorkflowAnalysisContents] =
    useState(null);

  const [minCC, setminCC] = useState(null);
  const [maxCC, setmaxCC] = useState(null);
  const [minLag, setminLag] = useState(null);
  const [maxLag, setmaxLag] = useState(null);
  const [tableData, settableData] = useState(null);
  const [injWellNames, setinjWellNames] = useState(null);
  const [prodWellNames, setprodWellNames] = useState(null);
  const [selectedProducer, setselectedProducer] = useState(null);
  const [selectedInjector, setselectedInjector] = useState(null);
  const [correlationPairNames, setcorrelationPairNames] = useState(null);

  // Callbacks/Helpers -->
  /**
   * Filter data summary values from the retrieved workflow result analysis, then save
   * these values to useState variables for easily passing as props to child components.
   *
   * @param {Array} workflowAnalysisContent
   */
  const analyzeWorkflowResultSummaryValues = workflowAnalysisContent => {
    // Filter values
    const corrArr = workflowAnalysisContent.map(row =>
      parseFloat(row[CORRELATION])
    );
    const lagArr = workflowAnalysisContent.map(row => parseFloat(row[LAG]));
    const injectors = [
      ...new Set(workflowAnalysisContent.map(row => row[INJECTOR])),
    ];
    const producers = [
      ...new Set(workflowAnalysisContent.map(row => row[PRODUCER])),
    ];
    const corrPairs = allWorkflowAnalysisContents.reduce(
      (acc, dataRow) => {
        const injCorrPair = dataRow[CORR_SERIES].split('_VS_')[0];
        const prodCorrPair = dataRow[CORR_SERIES].split('_VS_')[1];

        acc[INJECTOR].add(injCorrPair);
        acc[PRODUCER].add(prodCorrPair);

        return acc;
      },
      {
        [INJECTOR]: new Set(),
        [PRODUCER]: new Set(),
      }
    );

    // Save these values to usestates.
    setinjWellNames(injectors);
    setprodWellNames(producers);
    setselectedProducer(producers[0]);
    setselectedInjector(injectors[0]);
    setminCC(Math.min(...corrArr));
    setmaxCC(Math.max(...corrArr));
    setminLag(Math.min(...lagArr));
    setmaxLag(Math.max(...lagArr));
    setcorrelationPairNames(corrPairs);
    settableData([
      {
        'Num. of Injectors': injectors.length,
        'Num. of Producers': producers.length,
        'Min Cross Correlation': Math.min(...corrArr),
        'Max Cross Correaltion': Math.max(...corrArr),
      },
    ]);
  };

  /**
   * Updates workflow analysis content, based on selected correlation pair, and triggers
   * entire workflow report to be generated again.
   * @param {Array} selectedCorrPair
   */
  const filterWorkflowAnalysisContentByCorrPair = selectedCorrPair => {
    const filteredWorkflowResult = allWorkflowAnalysisContents.filter(
      row =>
        row[CORR_SERIES].split('_VS_')[0] == selectedCorrPair[0] &&
        row[CORR_SERIES].split('_VS_')[1] == selectedCorrPair[1]
    );
    setworkflowAnalysisContent(filteredWorkflowResult);
    analyzeWorkflowResultSummaryValues(filteredWorkflowResult);
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  // Retrieve required workflow analysis data from props to generate workflow model.
  useEffect(() => {
    let mounted = true;

    try {
      if (mounted && workflowResultFiles) {
        setfilteredDatasetContent(
          workflowResultFiles[
            CROSS_CORR_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET
          ]
        );
        setallWorkflowAnalysisContents(
          workflowResultFiles[
            CROSS_CORR_WORKFLOW_RESULT_FILE_NAMES.WORKFLOW_RESULT
          ]
        );
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setallWorkflowAnalysisContents(null);
    }
    return () => {
      mounted = false;
    };
  }, [workflowResultFiles]);

  // If selected workflow content and it's dataset is ready, analyze the workflow result further.
  useEffect(() => {
    if (filteredDatasetContent && allWorkflowAnalysisContents) {
      filterWorkflowAnalysisContentByCorrPair([
        injCorrPairValues[0],
        prodCorrPairValues[0],
      ]);
      setworkflowReady(true);
    }
    return () => {
      setworkflowReady(false);
    };
  }, [filteredDatasetContent, allWorkflowAnalysisContents]);

  if (workflowReady)
    return (
      <React.Fragment>
        <WorkflowConfigurationTable
          header={headers.CONFIGURATION}
          tableData={tableData}
        />
        <CorrelationPairSelector
          filterWorkflowAnalysisContent={
            filterWorkflowAnalysisContentByCorrPair
          }
          correlationPairNames={correlationPairNames}
        />
        <FieldNetworkGraph
          filteredDatasetContent={filteredDatasetContent}
          workflowAnalysisContent={workflowAnalysisContent}
          minCC={minCC}
          maxCC={maxCC}
          minLag={minLag}
          maxLag={maxLag}
          header={headers.FIELD_MAP}
        />
        <ProducersCorrelationAnalysis
          datasetContent={filteredDatasetContent}
          workflowAnalysisContent={workflowAnalysisContent}
          workflowParameters={workflowParameters}
          minCC={minCC}
          maxCC={maxCC}
          minLag={minLag}
          maxLag={maxLag}
          prodWellNames={prodWellNames}
          selectedProducer={selectedProducer}
          onProducerSelect={wellName => setselectedProducer(wellName)}
          header={headers.PRODUCER}
        />
        <InjectorsCorrelationAnalysis
          datasetContent={filteredDatasetContent}
          workflowAnalysisContent={workflowAnalysisContent}
          minCC={minCC}
          maxCC={maxCC}
          minLag={minLag}
          maxLag={maxLag}
          injWellNames={injWellNames}
          selectedProducer={selectedProducer}
          selectedInjector={selectedInjector}
          onInjectorSelect={wellName => setselectedInjector(wellName)}
          headers={headers}
        />
        <WellPairAnalysisPlot
          header={headers.WELL_PAIR_ANALYSIS} //headers might be delayed?? Undefined component error on console
          filteredDatasetContent={filteredDatasetContent}
          selectedInjector={selectedInjector}
          selectedProducer={selectedProducer}
          workflowParameters={workflowParameters}
        />
        <CumWorkflowAnalysisPlot
          header={headers.WELL_PAIR_CORRELATIONS}
          allWorkflowAnalysisContents={allWorkflowAnalysisContents}
          selectedInjector={selectedInjector}
          selectedProducer={selectedProducer}
        />
      </React.Fragment>
    );
  else return <LoadingPage />;
};

CrossCorrelation.propTypes = {
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
  headers: PropTypes.object,
};

export default CrossCorrelation;
