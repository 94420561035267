import moment from 'moment';
import csv from 'csvtojson';
import * as fileApi from '../../../API/Routes/file';
import { reduxAction, getJWTToken } from '../base';
import * as datasetApi from '../../../API/Routes/dataset';
import { apiClient } from '../../../service/apiClient';
import { updateSnackBar } from '../feedback';
import {
  ADD_DATASET,
  DELETE_DATASET,
  SET_DATASETS,
  SET_SELECTED_DATASET,
  SET_WELL_TIMELINE,
  SET_INJ_WELLNAMES,
  SET_PROD_WELLNAMES,
  SET_DATASET_LAYERS,
  SET_DATASET_PARAMETERS,
  SET_DATASET_TIME_PERIOD,
} from './types';

import { DATE, UI_DATE_FORMAT } from '../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../constants/ComponentConstants';
import { unzipFiles } from '../../../Utils/DatasetUtils/DataProcessing';

/**
 * Redux thunk function that retrieves all of the datasets of the user from the server. Then it dispatches
 * redux action that saves all of those datasets to redux state.
 *
 * @returns
 */
export const getAllDatasets = () => async dispatch => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: datasetApi.allDatasetsApiRoute(),
      headers: getJWTToken(),
    });
    dispatch(reduxAction(SET_DATASETS, response.data));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while retrieving datasets.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
  }
};

/**
 * Redux thunk function that submits the dataset to be added to server via POST request. Upon success, it
 * dispatches redux action that saves the submitted dataset to datasets in redux state.
 *
 * @param {Object} dataset
 * @returns
 */
export const addDataset = (dataset, onUploadProgress) => async dispatch => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: datasetApi.addDatasetApiRoute(),
      headers: getJWTToken(),
      data: dataset,
      onUploadProgress: onUploadProgress,
    });
    dispatch(reduxAction(ADD_DATASET, response.data));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while adding datasets.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw new Error(error.response.data);
  }
};

/**
 * Redux thunk function that deletes the selected dataset from both server, via HTTP Request, and redux state,
 * by dispatching redux action.
 *
 * @param {Number} dataset_id
 * @returns
 */
export const deleteDataset = dataset_id => async dispatch => {
  try {
    await apiClient({
      method: 'DELETE',
      url: datasetApi.deleteDatasetApiRoute(dataset_id),
      headers: getJWTToken(),
    });
    dispatch(reduxAction(DELETE_DATASET, dataset_id));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while removing datasets.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
  }
};

/**
 * Basic redux action creator that sets the selected dataset field in redux state.
 * @param {Number} dataset_id
 * @returns
 */
export const setSelectedDataset = dataset_id => {
  return reduxAction(SET_SELECTED_DATASET, dataset_id);
};

/**
 * Redux thunk function that retrieves selected dataset content if redux state dataset content doesn't exist or
 * redux state selected dataset_id is different than requested one. Upon success, it dispatches redux action that
 * saves the dataset content in redux state.
 *
 * @param {Number} datasetId - Requested dataset's ID
 * @param {Object} csvParams  - CSV Paramenters to convert retrieved file data properly. (More info. at documentation)
 * @returns
 */
export const retrieveSelectedDatasetContent = async (datasetId, csvParams) => {
  let response = await apiClient({
    method: 'GET',
    url: fileApi.getFileContentApiRoute(datasetId),
    headers: getJWTToken(),
  });
  let csvRowArray = await csv(csvParams).fromString(response.data);
  csvRowArray.forEach(
    row => (row[DATE] = moment(row[DATE]).format(UI_DATE_FORMAT))
  );
  return csvRowArray;
};

/**
 * FIXME: This is a temporary solution to integration dataset selection.
 */
export const retrieveIntegrationDatasetContent = async (
  datasetId,
  fileNameCsvParams
) => {
  let response = await apiClient({
    method: 'GET',
    url: fileApi.getFileContentApiRoute(datasetId),
    headers: getJWTToken(),
    responseType: 'arraybuffer',
  });
  let result = await unzipFiles(response.data, fileNameCsvParams);
  return result;
};

/**
 * Basic redux action creator that saves selected dataset's timeline to redux state.
 * @param {Array} timeline
 */
export const setReduxWellTimeLine = timeline => {
  return reduxAction(SET_WELL_TIMELINE, timeline);
};

/**
 * Basic redux action creator that saves selected dataset's injector well names to redux state.
 * @param {Array} wellNames
 */
export const setReduxInjWellNames = wellNames => {
  return reduxAction(SET_INJ_WELLNAMES, wellNames);
};

/**
 * Basic redux action creator that saves selected dataset's producer well names to redux state.
 * @param {Array} wellNames
 */
export const setReduxProdWellNames = wellNames => {
  return reduxAction(SET_PROD_WELLNAMES, wellNames);
};

/**
 * Basic redux action creator that saves selected dataset's layers to redux state.
 * @param {Array} layers
 */
export const setReduxLayers = layers => {
  return reduxAction(SET_DATASET_LAYERS, layers);
};

/**
 * Basic redux action creator that saves selected dataset's parameters to redux state.
 * @param {Object} parameters
 */
export const setReduxParameters = parameters => {
  return reduxAction(SET_DATASET_PARAMETERS, parameters);
};

/**
 * Basic redux action creator that saves selected dataset's time period to redux state.
 * @param {Object} parameters
 */
export const setReduxTimePeriod = timePeriod => {
  return reduxAction(SET_DATASET_TIME_PERIOD, timePeriod);
};
