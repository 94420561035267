import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';

import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Link,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  CORRELATION,
  END_DATE,
  PRODUCER,
  START_DATE,
} from '../../../../constants/WellConstants';
import { RADIUS_OF_INVESTIGATION } from '../../../../constants/WorkflowsParameterConstants';

const ProducersCorrelationAnalysis = ({
  datasetContent,
  workflowAnalysisContent,
  workflowParameters,
  minCC,
  maxCC,
  minLag,
  maxLag,
  prodWellNames,
  selectedProducer,
  onProducerSelect,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [networkPlot, setnetworkPlot] = useState(null);
  const [filteredWorkflowAnalysisContent, setfilteredWorkflowAnalysisContent] =
    useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Handlers -->
  const generateProducerNetworkGraph = wellName => {
    const filteredAnalysisData = workflowAnalysisContent.filter(
      row => row[PRODUCER] == wellName
    );

    setnetworkPlot(
      generateNetworkGraphWithLags(
        datasetContent,
        filteredAnalysisData,
        CORRELATION,
        minCC,
        maxCC,
        minLag,
        maxLag,
        `${wellName} Correlation Graph`
      )
    );
    setfilteredWorkflowAnalysisContent(filteredAnalysisData);
  };

  const onWellSelect = event => {
    onProducerSelect(event.target.value);
    generateProducerNetworkGraph(event.target.value);
  };

  /**
   * Downloads the workflow analysis result to clients machine.
   */
  const onDownload = dataset => {
    dataset.forEach(row => {
      row[START_DATE] = workflowParameters[START_DATE];
      row[END_DATE] = workflowParameters[END_DATE];
      row[RADIUS_OF_INVESTIGATION] =
        workflowParameters[RADIUS_OF_INVESTIGATION];
    });

    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (selectedProducer) {
      generateProducerNetworkGraph(selectedProducer);
    }
    return () => {
      setnetworkPlot(undefined);
    };
  }, [workflowAnalysisContent]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{header}</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={selectedProducer}
            onChange={onWellSelect}
            className="dropdownSelection"
          >
            {prodWellNames.map(wellName => (
              <MenuItem key={'menu-item-' + wellName} value={wellName}>
                {wellName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {networkPlot ? (
        <PlotlyComponent
          data={networkPlot.data}
          layout={networkPlot.layout}
          config={networkPlot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage message="Network Plot is Loading..." goHome={false} />
      )}
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download Results</Typography>
          <Link
            download={`${selectedProducer}_Corr_Values.csv`}
            href={encodedUri}
            onClick={() => {
              onDownload(filteredWorkflowAnalysisContent);
            }}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProducersCorrelationAnalysis.propTypes = {
  datasetContent: PropTypes.array,
  workflowAnalysisContent: PropTypes.array,
  workflowParameters: PropTypes.object,
  minCC: PropTypes.number,
  maxCC: PropTypes.number,
  minLag: PropTypes.number,
  maxLag: PropTypes.number,
  prodWellNames: PropTypes.array,
  selectedProducer: PropTypes.string,
  onProducerSelect: PropTypes.func,
  header: PropTypes.string,
};

export default ProducersCorrelationAnalysis;
