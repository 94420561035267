import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllWorkflows } from '../../redux/actions/workflows';

import { getAllDatasets } from '../../redux/actions/dataset';

// import { SURVEILLANCE, INTEGRATION } from '../../constants/WellConstants';

import useStyles from '../../Styles/HomepageStyle';
import Navbar from '../Components/NavBar';
// import CardView from '../Components/WorkflowCardView';

import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
// import MemoryIcon from '@material-ui/icons/Memory';
//import TrackChangesRoundedIcon from '@material-ui/icons/TrackChangesRounded';
// import { Grid, Paper, Typography, Button } from '@material-ui/core';

// import { filterRecentWorkflows } from '../../Utils/DatasetUtils/DataProcessing';
import { WORKFLOW_STATUSES } from '../../constants/WorkflowComponentConstants';
import LogoComponent from '../Components/LogoComponent';
import logo from '../../resermine_logo.png';

const allWorkflowsSubscriber = state => state.workflow.allWorkflows;

const Homepage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workflowTimer, setworkflowTimer] = useState(false);

  const reduxAllWorkflows = useSelector(allWorkflowsSubscriber);

  // const MODULE_LIST_HEADER_ICONS = {
  //   Surveillance: <TrackChangesRoundedIcon />,
  //   // Integration: <MemoryIcon />,
  // };
  // const MODULE_LIST_HEADER_NAMES = {
  //   Surveillance: 'Surveillance',
  //   // Integration: 'Integration',
  // };
  // const [selectedHeader, setselectedHeader] = useState(
  //   MODULE_LIST_HEADER_NAMES.Surveillance
  // );

  // const [recentWorkflows, setrecentWorkflows] = useState(null);

  // Handlers -->
  /**
   * Navigate to workspace.
   */
  const onExpandClick = () => {
    navigate('/workspace');
  };

  const onContactUsClick = () => {
    navigate('/contactUs');
  };

  const onNewProjectClick = () => {
    navigate('/uploadSurveillance');
  };
  /**
   * Navigate to upload page.
   *
   * TODO: Need to pass selected module down to workflow selection menu.
   *
   * @param {String} moduleType
   */
  // const selectModule = moduleType => {
  //   navigate('/upload' + moduleType);
  // };

  // const onHeaderSelect = header => {
  //   setselectedHeader(header);
  //   if (header === 'Integration') {
  //     alert(`INTEGRATION module not enabled.`);
  //   } else {
  //     selectModule(header);
  //   }
  // };
  /**
   * Navigate to help page.
   */
  // const onHelpClick = () => {
  //   navigate('/help');
  // };
  // <-- Handlers

  // Callback/Helpers -->
  // <-- Callback/Helpers

  // Constantly check for workflow statuses.
  useEffect(() => {
    // flag to prevent memory leak.
    let mounted = true;
    /**
     * For every 5 second, update redux workflows if any of the redux workflows
     * has submitted or in progress state.
     */
    let workflowStatusChecker = async () => {
      await new Promise(resolve => setTimeout(resolve, 5000));
      if (mounted) {
        if (
          reduxAllWorkflows.filter(
            workflow =>
              workflow.status == WORKFLOW_STATUSES.SUBMITTED ||
              workflow.status == WORKFLOW_STATUSES.IN_PROGRESS
          ).length > 0
        ) {
          dispatch(getAllWorkflows());
        }
        setworkflowTimer(status => !status);
      }
    };

    workflowStatusChecker();
    return () => {
      mounted = false;
    };
  }, [workflowTimer]);

  // Fetch all datasets and workflows of user and save them to reduxState.
  useEffect(() => {
    dispatch(getAllDatasets());
    dispatch(getAllWorkflows());
  }, []);

  // Filter recent 6 workflows.
  // Save all the datasets of user to redux state.
  // useEffect(() => {
  //   reduxAllWorkflows.length != 0
  //     ? setrecentWorkflows(filterRecentWorkflows(reduxAllWorkflows))
  //     : setrecentWorkflows(null);
  // }, [reduxAllWorkflows]);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          <Paper elevation={20} className={styles.sidebarPaperContainer}>
            <List className={styles.sidebarList}>
              <ListItem button onClick={() => onExpandClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>Workspace</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
              <ListItem button onClick={() => onNewProjectClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>New Project</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
              {/* {Object.entries(MODULE_LIST_HEADER_ICONS).map(header => (
                <React.Fragment key={header[0]}>
                  <ListItem
                    button
                    selected={selectedHeader == header[0]}
                    className={styles.sidebarListItem}
                    key={'listItem-' + header[0]}
                    onClick={() => onHeaderSelect(header[0])}
                  >
                    <ListItemIcon className={styles.sidebarListItemIcon}>
                      {header[1]}
                    </ListItemIcon>
                    <ListItemText primary={header[0]} />
                  </ListItem>
                </React.Fragment>
              ))} */}
              <ListItem button onClick={() => onContactUsClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>Contact US</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.contentGrid}>
          <Paper elevation={20} className={styles.intro}>
            <Grid container direction="row" className={styles.introGrid}>
              <Grid container direction="column">
                <Typography className={styles.companyName} variant="h1">
                  CoreIntellect
                </Typography>
                <Typography
                  className={styles.companyDescription}
                  variant="subtitle1"
                >
                  AI-ML driven solutions for Subsurface Management of Oil and
                  Gas Industry
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={styles.imageContainer}>
              <img src={logo} alt="Sample" className={styles.logo} />
            </Grid>
          </Paper>
          {/* <Paper elevation={20} className={styles.recentWorkflowsGrid}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item>
                <Typography className={styles.header}>
                  Recent Workflows
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={styles.workflowCardsContainer}>
              {recentWorkflows ? (
                recentWorkflows.map(workflow => (
                  <CardView key={workflow.workflow_id} workflow={workflow} />
                ))
              ) : (
                <Typography>Please create a workflow from below</Typography>
              )}
            </Grid>
            <Grid container style={{ justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                className={styles.button}
                onClick={onExpandClick}
              >
                Expand
              </Button>
            </Grid>
          </Paper> */}
          {/* <Paper elevation={20} className={styles.selectModuleGrid}>
            <Typography className={styles.header}>
              Select Module to Run the Analysis
            </Typography>
            <Grid item container className={styles.centerFlexGrid}>
              <Button
                className={`${styles.button} ${styles.primaryButton} xlargeButton`}
                onClick={() => selectModule(SURVEILLANCE)}
              >
                Surveillance
              </Button>
              <Button
                className={`${styles.button} ${styles.primaryButton} xlargeButton`}
                onClick={() => alert(`${INTEGRATION} module not enabled.`)}
              >
                Integration
              </Button>
            </Grid>
            <Grid item container style={{ justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                className={styles.button}
                onClick={onHelpClick}
              >
                Help
              </Button>
            </Grid>
          </Paper> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Homepage;
