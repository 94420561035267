import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { BasicTableWithDynamicColumns } from './TableViewDynamicHeader';

import { Grid, Paper, Typography, Link, IconButton } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const ProducerSummary = ({ headers, polyRecovery }) => {
  const styles = useStyles();
  const [tableData, setTableData] = useState(null);
  const [encodedUri, setencodedUri] = useState(null);

  const sortDatasetByWellNames = dataset => {
    const sorted = dataset.sort((a, b) => {
      const aNum = parseInt(a['Producer'].split('-')[1]);
      const bNum = parseInt(b['Producer'].split('-')[1]);
      return aNum - bNum;
    });
    return sorted;
  };

  const fixValues = dataset => {
    const fixed = dataset.map(row => {
      const fixedRow = {};
      for (const [key, value] of Object.entries(row)) {
        if (key.includes('Np') || key.includes('RF')) {
          fixedRow[key] = parseFloat(value).toFixed(1);
        } else {
          fixedRow[key] = value;
        }
      }
      return fixedRow;
    });
    return fixed;
  };

  // Handlers -->
  const onDownload = () => {
    const content = [];

    tableData.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (polyRecovery) {
      const fixedDataset = fixValues(polyRecovery);
      setTableData(sortDatasetByWellNames(fixedDataset));
    }
  }, [polyRecovery]);

  return (
    <Grid
      container
      className={styles.visualContentFlex}
      id={headers.PRODUCER_SUMMARY_TABLE}
    >
      <Grid item style={{ margin: '0% 5%' }}>
        <Typography>{headers.PRODUCER_SUMMARY_TABLE}</Typography>
      </Grid>
      <Paper elevation={5} className={styles.visualContentRow}>
        <BasicTableWithDynamicColumns data={tableData} />
      </Paper>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download Producer Summary</Typography>
          <Link
            download="Producer_Summary.csv"
            href={encodedUri}
            onClick={onDownload}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProducerSummary;

ProducerSummary.propTypes = {
  headers: PropTypes.object,
  polyRecovery: PropTypes.array,
};
