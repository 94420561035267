import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BasicTable } from '../../../../Components/TableView';
import LoadingPage from '../../../../Components/LoadingPage';
import useStyles from '../../../../../Styles/WorkflowStyles';

import {
  filterWellNamesFromDataset,
  saveDatasetLayers,
  findMeanOfDataset,
  findSumOfDataset,
  generateColumnOrderMapping,
} from '../../../../../Utils/DatasetUtils/DataProcessing';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Link,
  IconButton,
  Paper,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  FIELD,
  PRODUCER,
  LAYER,
  LIQUID_RATE,
  WATER_RATE,
  WOR,
  CUMULATIVE_OIL,
  CUMULATIVE_WATER,
  OIL_RATE,
  RATE,
} from '../../../../../constants/WellConstants';

const Results = ({
  filteredDatasetContent,
  oilRateHistory,
  liquidRateHistory,
  header,
}) => {
  const styles = useStyles();

  const [selectedLayer, setselectedLayer] = useState(null);
  const [selectedProdWell, setselectedProdWell] = useState(null);

  const [layerOptions, setlayerOptions] = useState(null);
  const [prodWellOptions, setprodWellOptions] = useState(null);

  const [layerSummaryData, setlayerSummaryData] = useState(null);
  const [wellSummaryData, setwellSummaryData] = useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  /**
   * This function filters Oil rate Data by selected layer then joins it with liquid rate data.
   * @param {String} layerName
   */
  const filterAndProcessDatasetbyLayer = layerName => {
    // Add Liquid Rate data to oil rate dataset for ease of use.
    let processedOilRate = oilRateHistory.map((row, index) => {
      return {
        ...row,
        [LIQUID_RATE]: liquidRateHistory[index][LIQUID_RATE],
      };
    });

    // If user wants to see specific layer, filter oil rate data by selected layer.
    if (layerName != FIELD) {
      processedOilRate = processedOilRate.filter(
        row => row[LAYER] == layerName
      );
    }

    const producerSummaryData = [];
    const producers = [...new Set(processedOilRate.map(row => row[PRODUCER]))];
    // For each producer, find mean and sum of OIL and LIQUID RATE values.
    producers.forEach(producer => {
      let filteredProducerData = processedOilRate.filter(
        row => row[PRODUCER] == producer
      );

      const sumOilRate = findSumOfDataset(filteredProducerData, 'Oil rate');
      const sumLiquidRate = findSumOfDataset(filteredProducerData, LIQUID_RATE);
      const meanOilRate = findMeanOfDataset(filteredProducerData, 'Oil rate');
      const meanLiquidRate = findMeanOfDataset(
        filteredProducerData,
        LIQUID_RATE
      );

      producerSummaryData.push({
        [PRODUCER]: producer,
        [`${OIL_RATE} ${RATE}`]: meanOilRate,
        [LIQUID_RATE]: meanLiquidRate,
        [WATER_RATE]: meanLiquidRate - meanOilRate,
        [WOR]: (meanLiquidRate - meanOilRate) / meanOilRate,
        [CUMULATIVE_OIL]: sumOilRate,
        [CUMULATIVE_WATER]: sumLiquidRate - sumOilRate,
      });

      setlayerSummaryData(producerSummaryData);
    });
  };
  /**
   * This function filters Oil Rate Data by selected layer then joins it with liquid rate data.
   * @param {String} wellName
   */
  const filterAndProcessDatasetbyWellName = wellName => {
    // Add Liquid Rate data to oil rate dataset for ease of use.
    let processedOilRate = oilRateHistory.map((row, index) => {
      return {
        ...row,
        [LIQUID_RATE]: liquidRateHistory[index][LIQUID_RATE],
      };
    });

    // If user wants to see specific layer, filter oil rate data by selected layer.
    if (wellName != FIELD) {
      processedOilRate = processedOilRate.filter(
        row => row[PRODUCER] == wellName
      );
    }

    const producerSummaryData = [];
    const layers = [...new Set(processedOilRate.map(row => row[LAYER]))];
    // For each producer, find mean and sum of OIL and LIQUID RATE values.
    layers.forEach(layer => {
      let filteredProducerData = processedOilRate.filter(
        row => row[LAYER] == layer
      );

      const sumOilRate = findSumOfDataset(filteredProducerData, 'Oil rate');
      const sumLiquidRate = findSumOfDataset(filteredProducerData, LIQUID_RATE);
      const meanOilRate = findMeanOfDataset(filteredProducerData, 'Oil rate');
      const meanLiquidRate = findMeanOfDataset(
        filteredProducerData,
        LIQUID_RATE
      );

      producerSummaryData.push({
        [LAYER]: layer,
        [`${OIL_RATE} ${RATE}`]: meanOilRate,
        [LIQUID_RATE]: meanLiquidRate,
        [WATER_RATE]: meanLiquidRate - meanOilRate,
        [WOR]: (meanLiquidRate - meanOilRate) / meanOilRate,
        [CUMULATIVE_OIL]: sumOilRate,
        [CUMULATIVE_WATER]: sumLiquidRate - sumOilRate,
      });

      setwellSummaryData(producerSummaryData);
    });
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * This handler filters and processes datasets based on provided layer name.
   * @param {String} layerName
   */
  const onLayerSelect = layerName => {
    setselectedLayer(layerName);
    filterAndProcessDatasetbyLayer(layerName);
  };

  const onWellSelect = wellName => {
    setselectedProdWell(wellName);
    filterAndProcessDatasetbyWellName(wellName);
  };

  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // Initialize dropdown options and selections.
  useEffect(() => {
    if (
      filteredDatasetContent != null &&
      oilRateHistory != null &&
      liquidRateHistory != null
    ) {
      const { producers } = filterWellNamesFromDataset(filteredDatasetContent);
      const layers = saveDatasetLayers(filteredDatasetContent);

      setprodWellOptions([FIELD, ...producers]);
      setlayerOptions([FIELD, ...layers]);

      onLayerSelect(layers[1]);
      onWellSelect(producers[1]);
    }
  }, [filteredDatasetContent, oilRateHistory, liquidRateHistory]);

  return (
    <React.Fragment>
      <Grid container id={header} className={styles.visualContentFlex}>
        <Grid item className={styles.visualContentRow}>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item className={styles.visualContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="dropdownSelection"
          >
            <Select
              value={selectedLayer}
              onChange={event => onLayerSelect(event.target.value)}
            >
              {layerOptions &&
                layerOptions.map(layer => (
                  <MenuItem key={layer} value={layer}>
                    {layer}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Layer</FormHelperText>
          </FormControl>
        </Grid>

        {layerSummaryData ? (
          <React.Fragment>
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable
                data={layerSummaryData}
                columnNameMap={generateColumnOrderMapping(
                  layerSummaryData,
                  [],
                  {
                    [`${OIL_RATE} ${RATE}`]: `Average ${OIL_RATE} ${RATE}`,
                    [LIQUID_RATE]: `Average ${LIQUID_RATE}`,
                    [WATER_RATE]: `Average ${WATER_RATE}`,
                  }
                )}
              />
            </Paper>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Layer Summary Data
                </Typography>
                <Link
                  download="Layer_Summary_Data.csv"
                  href={encodedUri}
                  onClick={() => onDownload(layerSummaryData)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item className={styles.visualContentRow}>
            <LoadingPage
              message="Layer Summary Data is loading..."
              goHome={false}
            />
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Grid item className={styles.visualContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="dropdownSelection"
          >
            <Select
              value={selectedProdWell}
              onChange={event => onWellSelect(event.target.value)}
            >
              {prodWellOptions &&
                prodWellOptions.map(wellName => (
                  <MenuItem key={wellName} value={wellName}>
                    {wellName}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Well</FormHelperText>
          </FormControl>
        </Grid>

        {wellSummaryData ? (
          <React.Fragment>
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable
                data={wellSummaryData}
                columnNameMap={generateColumnOrderMapping(wellSummaryData, [], {
                  [`${OIL_RATE} ${RATE}`]: `Average ${OIL_RATE} ${RATE}`,
                  [LIQUID_RATE]: `Average ${LIQUID_RATE}`,
                  [WATER_RATE]: `Average ${WATER_RATE}`,
                })}
              />
            </Paper>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Well Summary Data
                </Typography>
                <Link
                  download="Well_Summary_Data.csv"
                  href={encodedUri}
                  onClick={() => onDownload(wellSummaryData)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item className={styles.visualContentRow}>
            <LoadingPage message="Summary Data is loading..." goHome={false} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

Results.propTypes = {
  filteredDatasetContent: PropTypes.array,
  oilRateHistory: PropTypes.array,
  liquidRateHistory: PropTypes.array,
  header: PropTypes.string,
};
export default Results;
