import {
  FEATURE_IMPORTANCE,
  GAIN,
  INJECTOR,
  PRODUCER,
} from '../../constants/WellConstants';

const extractMLConnWellPairFeatureValues = resultFiles => {
  const wellFeatureValues = resultFiles.map(row => {
    return {
      [INJECTOR]: row[INJECTOR],
      [PRODUCER]: row[PRODUCER],
      [FEATURE_IMPORTANCE]: row[FEATURE_IMPORTANCE],
    };
  });

  const minFeatureValue = Math.min(
    ...wellFeatureValues.map(row => row[FEATURE_IMPORTANCE])
  );
  const maxFeatureValue = Math.max(
    ...wellFeatureValues.map(row => row[FEATURE_IMPORTANCE])
  );

  return { wellFeatureValues, minFeatureValue, maxFeatureValue };
};

export const extractWellPairFeatureValues = (
  wellFeatureMatrix,
  injNames,
  prodNames,
  feature = GAIN
) => {
  if (feature != GAIN) {
    return extractMLConnWellPairFeatureValues(wellFeatureMatrix);
  }
  const wellFeatureValues = [];

  injNames.forEach(inj => {
    prodNames.forEach(prod => {
      const injGainValues = wellFeatureMatrix.find(row => row[INJECTOR] == inj);

      wellFeatureValues.push({
        [INJECTOR]: inj,
        [PRODUCER]: prod,
        [feature]: injGainValues?.[prod] || '0.0',
      });
    });
  });

  const minFeatureValue = Math.min(
    ...wellFeatureValues.map(row => row[feature])
  );
  const maxFeatureValue = Math.max(
    ...wellFeatureValues.map(row => row[feature])
  );

  return { wellFeatureValues, minFeatureValue, maxFeatureValue };
};
