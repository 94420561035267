import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveDynamicGainValuesResult } from '../../../../../../API/Functions/CRMP';
import { retrieveWellSurfaceLocations } from '../../../../../../API/Functions/Visualization';

import GainValueAnalysisComponent from './GainValueAnalysisComponent';
import MaterialUIPickers from '../../../../../Components/MonthYearPicker';

import { Grid } from '@material-ui/core';

import {
  WAG,
  GAS,
  WATER,
  END_DATE,
  START_DATE,
  SELECTED_DATE,
} from '../../../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';
import { INJECTION_CALCULATION_TYPE } from '../../../../../../constants/WorkflowsParameterConstants';
import { filterInjectorsByWAG } from '../../../../../../Utils/WorkflowReportUtils/CRMPUtils';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const DynamicGainValuesMap = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [params, setParams] = useState({
    minGain: 0,
    maxGain: 1,
    [START_DATE]: reduxSelectedWorkflow['parameters'][START_DATE],
    [END_DATE]: reduxSelectedWorkflow['parameters'][END_DATE],
    [SELECTED_DATE]: reduxSelectedWorkflow['parameters'][START_DATE],
  });
  const [dateSelection, setdateSelection] = useState(
    reduxSelectedWorkflow['parameters'][START_DATE]
  );

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const wellGainValues = await retrieveDynamicGainValuesResult(
          reduxSelectedWorkflow.workflow_id,
          params
        );

        const wellDetails = await retrieveWellSurfaceLocations(
          reduxSelectedWorkflow.dataset_id,
          reduxSelectedWorkflow.parameters
        );

        if (mounted) {
          setapiRes({ wellGainValues, wellDetails });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();
    return () => (mounted = false);
  }, [params]);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onSliderChange = params => {
    setParams(prev => ({ ...prev, ...params }));
  };

  const onDateSelect = date => {
    setParams(prev => ({ ...prev, [SELECTED_DATE]: date }));
    setdateSelection(date);
  };
  // <-- Handlers

  return (
    <Grid container className={styles.visualContent}>
      <Grid item container className={styles.inputContentRow}>
        {/* FIXME: Buggy component. Should find a simpler and more rigit solution */}
        <MaterialUIPickers
          fieldTimeline={[
            reduxSelectedWorkflow['parameters'][START_DATE],
            reduxSelectedWorkflow['parameters'][END_DATE],
          ]}
          selectedDate={dateSelection}
          callback={onDateSelect}
        />
      </Grid>
      {reduxSelectedWorkflow['parameters'][INJECTION_CALCULATION_TYPE] ==
      WAG ? (
        <Grid container style={{ flex: '1' }}>
          <Grid item container direction="column" xs={6}>
            <GainValueAnalysisComponent
              header={`Static Gain Values (${WATER})`}
              wellsGainValues={filterInjectorsByWAG(
                apiRes?.wellGainValues || [],
                WATER
              )}
              wellsDetails={apiRes?.wellDetails}
              workflowParameters={reduxSelectedWorkflow.parameters}
              apiCallback={onSliderChange}
            />
          </Grid>
          <Grid item container direction="column" xs={6}>
            <GainValueAnalysisComponent
              header={`Static Gain Values (${GAS})`}
              wellsGainValues={filterInjectorsByWAG(
                apiRes?.wellGainValues || [],
                GAS
              )}
              wellsDetails={apiRes?.wellDetails}
              workflowParameters={reduxSelectedWorkflow.parameters}
              apiCallback={onSliderChange}
            />
          </Grid>
        </Grid>
      ) : (
        <GainValueAnalysisComponent
          header="Static Gain Values"
          wellsGainValues={apiRes?.wellGainValues}
          wellsDetails={apiRes?.wellDetails}
          workflowParameters={reduxSelectedWorkflow.parameters}
          apiCallback={onSliderChange}
        />
      )}
    </Grid>
  );
};

export default DynamicGainValuesMap;
