import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../../Components/LoadingPage';

import { generateConnectivityGraph } from '../../../../../../Utils/CommonReportUtil';

import { Grid, Slider, Typography } from '@material-ui/core';

export const DynamicConnectivityPlot = ({
  header,
  focusedFeature,
  datasetContent,
  minFeatureValue,
  maxFeatureValue,
  wellFeatureValues,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);

  const [sliderValue, setsliderValue] = useState([
    minFeatureValue,
    maxFeatureValue,
  ]);

  // Callback -->
  const generateNetworkPlotDataset = (
    filteredwellFeatureValues,
    sliderValue
  ) => {
    setplot(
      generateConnectivityGraph(
        datasetContent,
        filteredwellFeatureValues,
        focusedFeature,
        sliderValue[0],
        sliderValue[1],
        undefined,
        undefined,
        header
      )
    );
  };
  // <-- Callback

  // Handler -->
  const sliderHandler = (event, newValue) => {
    setsliderValue(newValue);
    generateNetworkPlotDataset(wellFeatureValues, newValue);
  };
  // <-- Handler

  useEffect(() => {
    if (datasetContent && wellFeatureValues) {
      generateNetworkPlotDataset(wellFeatureValues, [
        minFeatureValue,
        maxFeatureValue,
      ]);
    }
  }, [datasetContent, wellFeatureValues]);

  return (
    <Grid container className={styles.visualContent} id={header}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{header}</Typography>
      </Grid>
      <Grid item className={styles.visualContentRow}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Feature Value Range</Typography>
          <Slider
            min={minFeatureValue}
            step={0.01}
            max={maxFeatureValue}
            value={sliderValue}
            onChange={sliderHandler}
            valueLabelDisplay="auto"
            style={{ width: '80%' }}
          />
        </Grid>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage message="Network Plot is Loading..." goHome={false} />
      )}
    </Grid>
  );
};

DynamicConnectivityPlot.propTypes = {
  header: PropTypes.string,
  injNames: PropTypes.array,
  prodNames: PropTypes.array,
  focusedFeature: PropTypes.string,
  datasetContent: PropTypes.array,
  minFeatureValue: PropTypes.number,
  maxFeatureValue: PropTypes.number,
  wellFeatureValues: PropTypes.array,
};

export default DynamicConnectivityPlot;
