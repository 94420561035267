import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  box: {
    borderRadius: '2em',
    margin: '1em',
    width: '10em',
    height: '12em',
    backgroundColor: '#020a3b',
    overflow: 'hidden',
    '& .center': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .iconContainer': {
      height: '40%',
    },
    '& .detailsContainer': {
      justifyContent: 'space-between',
      height: '50%',
      padding: '0.25em 0.5em',
    },
    '& .infoContainer': {
      height: '10%',
      backgroundColor: 'grey',
      '& button': { color: 'white' },
    },
    '& .icon': { fontSize: '3em' },
    '& .status': {
      fontSize: '0.85em',
      fontWeight: 600,
      overflow: 'hidden',
    },
    '& .typeName': {
      overflow: 'hidden',
      fontSize: '0.75em',
      color: 'white',
    },
    '& .left': { width: '40%', textAlign: 'left' },
    '& .right': { width: '60%', textAlign: 'right' },
  },
});

export default useStyles;
