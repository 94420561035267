import {
  DATE,
  FIELD,
  FLUID_INJECTION_RATE,
  FLUID_PRODUCTION_RATE,
  FLUID_RATE,
  GAIN,
  GAS_INJECTION_RATE,
  INJECTOR,
  LAYER,
  LIQUID_RATE,
  NET_ALLOCATION,
  OIL,
  OIL_PRODUCTION_RATE,
  PER_MONTH,
  PRODUCER,
  RATE,
  SYSTEM_OF_UNITS,
  TRAIN_TEST_SPLIT,
  WATER_INJECTION_RATE,
  WATER_PRODUCTION_RATE,
  WELL_ID,
} from '../../constants/WellConstants';
import { DEFAULT_VALUE_TRAIN_TEST_SPLIT } from '../../constants/WorkflowsParameterConstants';
import { generateNetworkGraphWithUnit } from '../CommonReportUtil';
import {
  filterDatasetContentByLayer,
  filterTimelineFromDataset,
  filterWellNamesFromDataset,
  findGainValue,
  findGainValueFromDataset,
  findRateValue,
} from '../DatasetUtils/DataProcessing';
import {
  addTrainingLine,
  createLinePlotTrace,
  wrapPlots,
  MARKERS,
  createLinePlotTraces,
} from '../PlotlyUtils/Plots';
import { getUnitRate } from '../ReservoirUtils';

/**
 * This function generates field gain value network graph for selected layer's wells.
 *
 * @param {Array} datasetContent
 * @param {Array} gainValues
 * @param {Array} selectedLayer
 * @returns
 */
export const generateLayerBasedGainValuePlot = (
  datasetContent,
  gainValues,
  selectedLayer,
  unitSystem
) => {
  try {
    const plotDataContent = [];
    const layerFilteredDataset = filterDatasetContentByLayer(
      datasetContent,
      selectedLayer
    );
    const layerFilteredGainValue = filterDatasetContentByLayer(
      gainValues,
      selectedLayer
    );
    const { injectors, producers } =
      filterWellNamesFromDataset(layerFilteredDataset);

    producers.forEach(producer => {
      injectors.forEach(injector => {
        plotDataContent.push({
          [PRODUCER]: producer,
          [GAIN]: findGainValueFromDataset(
            layerFilteredGainValue,
            injector,
            producer
          ),
          [INJECTOR]: injector,
        });
      });
    });
    return generateNetworkGraphWithUnit(
      layerFilteredDataset,
      plotDataContent,
      GAIN,
      undefined,
      undefined,
      `Field ${GAIN} Values By Layer - ${selectedLayer}`,
      undefined,
      unitSystem
    );
  } catch (error) {
    return wrapPlots(
      [],
      undefined,
      undefined,
      `Field ${GAIN} Values By Layer - ${selectedLayer}`
    );
  }
};

/**
 * This function generates gain value network graph between selected layer's injectors and
 * producers.
 */
export const generateGainValuePlot = (
  layerFilteredDataset,
  gainValues,
  connectedLayer,
  selectedWell,
  wellType,
  unitSystem
) => {
  try {
    const { injectors, producers } =
      filterWellNamesFromDataset(layerFilteredDataset);
    const wellNames = wellType == PRODUCER ? injectors : producers;
    const plotDataContent = wellNames.map(wellName => {
      return wellType == PRODUCER
        ? {
            [PRODUCER]: selectedWell,
            [GAIN]: findGainValueFromDataset(
              filterDatasetContentByLayer(gainValues, connectedLayer),
              wellName,
              selectedWell
            ),
            [INJECTOR]: wellName,
          }
        : {
            [INJECTOR]: selectedWell,
            [GAIN]: findGainValueFromDataset(
              filterDatasetContentByLayer(gainValues, connectedLayer),
              selectedWell,
              wellName
            ),
            [PRODUCER]: wellName,
          };
    });

    return generateNetworkGraphWithUnit(
      layerFilteredDataset,
      plotDataContent,
      GAIN,
      undefined,
      undefined,
      selectedWell + ' ' + wellType == PRODUCER
        ? INJECTOR
        : PRODUCER + ' ' + GAIN + ' Values',
      undefined,
      unitSystem
    );
  } catch (error) {
    return wrapPlots(
      [],
      undefined,
      undefined,
      selectedWell + ' ' + wellType == PRODUCER
        ? INJECTOR
        : PRODUCER + ' ' + GAIN + ' Values',
      undefined,
      unitSystem
    );
  }
};

/**
 * This function filters Gain Value, Total Injection Rate, and Net Allocation rates
 * from workflowAnalysisContent for selected layer and selected injector.
 */
export const generateNetAllocationTableData = (
  selectedWell,
  selectedWellType,
  selectedLayer,
  datasetContent,
  gainValues
) => {
  const { injectors, producers } = filterWellNamesFromDataset(datasetContent);
  const wellNames = selectedWellType == PRODUCER ? injectors : producers;

  const tableData = wellNames.map(wellName => {
    const gainValue = findGainValue(
      filterDatasetContentByLayer(gainValues, selectedLayer),
      selectedWellType == PRODUCER ? wellName : selectedWell,
      selectedWellType == PRODUCER ? selectedWell : wellName
    );
    const injectionRateValue = findRateValue(
      filterDatasetContentByLayer(gainValues, selectedLayer),
      selectedWellType == PRODUCER ? wellName : selectedWell
    );
    return {
      [WELL_ID]: wellName,
      [GAIN]: gainValue,
      ['Average Injection ' + RATE]: injectionRateValue,
      [NET_ALLOCATION]: gainValue * injectionRateValue,
    };
  });

  return tableData.sort(
    (data1, data2) => data2[NET_ALLOCATION] - data1[NET_ALLOCATION]
  );
};

/**
 * This function plots selected injectors TOTAL WELL RATE, (total water and oil production),
 * for each layer and plots them by Date.
 */
export const generateFluidProductionPlot = (
  selectedProdWell,
  wellFilteredData,
  wellFilteredliquidRateHistory,
  wellFilteredliquidRateAll,
  layers,
  workflowParameters
) => {
  try {
    const traces = [];
    // Add Total Fluid Rate of the selected producer.
    traces.push(
      createLinePlotTrace(
        filterTimelineFromDataset(wellFilteredData),
        wellFilteredData.map(
          row =>
            Number(row[OIL_PRODUCTION_RATE]) +
            Number(row[WATER_PRODUCTION_RATE])
        ),
        selectedProdWell + ': Data',
        undefined,
        undefined,
        undefined,
        MARKERS
      )
    );

    const timePeriods = [];
    // Well filtered all liquid rate dataset is used to generate total CRM rate.
    if (wellFilteredliquidRateAll.length > 0) {
      const timeLineLiquidRateAll = filterTimelineFromDataset(
        wellFilteredliquidRateAll
      );
      const wellFilteredTotalProduction = timeLineLiquidRateAll.map(date =>
        wellFilteredliquidRateAll
          .filter(row => row[DATE] == date)
          .map(row => row[LIQUID_RATE])
          .reduce((a, b) => Number(a) + Number(b), 0)
      );
      traces.push(
        createLinePlotTrace(
          timeLineLiquidRateAll,
          wellFilteredTotalProduction,
          selectedProdWell + ' - Total' + ': CRM'
        )
      );
      // Well filtered history liquid rate dataset is used to generate layer based CRM rates.
      layers.forEach(layer => {
        const filteredLayer = filterDatasetContentByLayer(
          wellFilteredliquidRateAll,
          layer
        );
        traces.push(
          createLinePlotTrace(
            filteredLayer.map(row => row[DATE]),
            filteredLayer.map(row => Number(row[LIQUID_RATE])),
            selectedProdWell + ' - ' + layer + ': CRM'
          )
        );
      });

      const trainSize =
        workflowParameters?.[TRAIN_TEST_SPLIT] ||
        DEFAULT_VALUE_TRAIN_TEST_SPLIT;
      timePeriods.push(
        timeLineLiquidRateAll.at(
          parseInt(timeLineLiquidRateAll.length * trainSize)
        )
      );
    }

    return addTrainingLine(
      wrapPlots(
        traces,
        DATE,
        `${FLUID_RATE} ${RATE} (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          FLUID_RATE
        )})`,
        FLUID_PRODUCTION_RATE
      ),
      timePeriods
    );
  } catch (error) {
    return wrapPlots(
      [],
      DATE,
      `${FLUID_RATE} ${RATE} (${getUnitRate(
        workflowParameters[SYSTEM_OF_UNITS],
        FLUID_RATE
      )})`,
      FLUID_PRODUCTION_RATE
    );
  }
};

/**
 * This function plots selected injectors TOTAL WELL RATE, (total water and gas injection),
 * for each layer and plots them by Date.
 */
export const generateFluidInjectionPlot = (
  selectedInjWell,
  wellFilteredData,
  workflowParameters
) => {
  try {
    const traces = [];
    const layers = [...new Set(wellFilteredData.map(row => row[LAYER]))];
    const fieldTimeline = filterTimelineFromDataset(wellFilteredData);

    const totalInjectionValues = fieldTimeline.map(date =>
      wellFilteredData
        .filter(row => row[DATE] == date)
        .map(
          row =>
            Number(row[GAS_INJECTION_RATE]) + Number(row[WATER_INJECTION_RATE])
        )
        .reduce((a, b) => a + b, 0)
    );

    traces.push(
      createLinePlotTrace(
        fieldTimeline,
        totalInjectionValues,
        selectedInjWell + ' - Total: Data'
      )
    );

    layers.forEach(layer => {
      const layerFilteredData = wellFilteredData.filter(
        row => row[LAYER] == layer
      );
      traces.push(
        createLinePlotTrace(
          layerFilteredData.map(row => row[DATE]),
          layerFilteredData.map(
            row =>
              Number(row[GAS_INJECTION_RATE]) +
              Number(row[WATER_INJECTION_RATE])
          ),
          selectedInjWell + ' - ' + layer + ': Data'
        )
      );
    });

    return wrapPlots(
      traces,
      DATE,
      `${FLUID_RATE} ${RATE} (${getUnitRate(
        workflowParameters[SYSTEM_OF_UNITS],
        FLUID_RATE
      )})`,
      FLUID_INJECTION_RATE
    );
  } catch (error) {
    wrapPlots([]);
  }
};

/**
 * This function plots selected injectors TOTAL WELL RATE, (total water and oil production),
 * for each layer and plots them by Date.
 */
export const generateOilProductionRatePlot = (
  selectedProdWell,
  wellFilteredDataset,
  oilRateHistory,
  oilRateAll,
  prodLayers,
  workflowParameters
) => {
  try {
    const traces = [];
    const wellOperatingPeriod = filterTimelineFromDataset(wellFilteredDataset);
    const wellFilteredOilRateData = oilRateAll.filter(
      row => row[PRODUCER] == selectedProdWell
    );

    // Add actual Oil Production Rate from dataset content to plot.
    traces.push(
      createLinePlotTrace(
        wellOperatingPeriod,
        wellFilteredDataset.map(row => Number(row[OIL_PRODUCTION_RATE])),
        selectedProdWell + ': Data',
        undefined,
        undefined,
        undefined,
        MARKERS
      )
    );

    // Add total oil rate for selected producer.
    const totalProduction = wellOperatingPeriod.map(date =>
      wellFilteredOilRateData
        .filter(row => row[DATE] == date)
        .map(row => row['Oil rate'])
        .reduce((a, b) => Number(a) + Number(b), 0)
    );

    traces.push(
      createLinePlotTrace(
        wellOperatingPeriod,
        totalProduction,
        selectedProdWell + ' - Total' + ': CRM'
      )
    );

    const trainSize =
      workflowParameters?.[TRAIN_TEST_SPLIT] || DEFAULT_VALUE_TRAIN_TEST_SPLIT;
    const seperationDate = [];
    // For each layer that producer appears in, add oil rate trace.
    prodLayers.forEach(layer => {
      const layerOilProduction = wellFilteredOilRateData.filter(
        row => row[LAYER] == layer
      );
      if (layerOilProduction.length > 0) {
        const timeLine = filterTimelineFromDataset(oilRateAll);
        seperationDate.length === 0 &&
          seperationDate.push(timeLine[parseInt(timeLine.length * trainSize)]);

        traces.push(
          createLinePlotTrace(
            timeLine,
            layerOilProduction.map(row => row['Oil rate']),
            selectedProdWell + ' - ' + layer + ': CRM'
          )
        );
      }
    });

    return addTrainingLine(
      wrapPlots(
        traces,
        DATE,
        `${OIL_PRODUCTION_RATE} (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          OIL_PRODUCTION_RATE
        )})`,
        OIL_PRODUCTION_RATE
      ),
      seperationDate
    );
  } catch (error) {
    return wrapPlots(
      [],
      DATE,
      `${OIL_PRODUCTION_RATE} (${getUnitRate(
        workflowParameters[SYSTEM_OF_UNITS],
        OIL_PRODUCTION_RATE
      )})`,
      OIL_PRODUCTION_RATE
    );
  }
};

/**
 * Generates a layered rate plot based on the provided dataset content.
 * @param {Array} datasetContent - The dataset content to generate the plot from.
 * @param {string} rateType - The type of rate to use (default: OIL, options: [OIl, LIQUID]).
 * @param {string} unitSystem - The unit system to use (default: FIELD).
 * @param {string} timePeriod - The time period to use (default: PER_MONTH).
 * @returns {Object} - The generated layered rate plot.
 */
export const generateLayeredRatePlot = (
  datasetContent,
  workflowAnalysisContent,
  workflowParameters,
  rateType = OIL,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const timeLine = filterTimelineFromDataset(datasetContent);
  const dateOrientedDataset = datasetContent.reduce((acc, row) => {
    acc[row[DATE]] = acc[row[DATE]] || { [DATE]: row[DATE] };
    acc[row[DATE]][row[LAYER]] =
      Number(row[`${rateType} ${RATE.toLowerCase()}`]) || 0;

    return acc;
  }, {});

  createLinePlotTraces(
    workflowAnalysisContent,
    DATE,
    [`${rateType} Production:Data`],
    traces,
    [`${rateType} Production:Data`],
    undefined,
    undefined,
    MARKERS
  );

  createLinePlotTraces(
    workflowAnalysisContent,
    DATE,
    [`${rateType} Production:CRM`],
    traces,
    [`${rateType} Production:CRM`]
  );

  createLinePlotTraces(
    Object.values(dateOrientedDataset),
    DATE,
    Object.keys(Object.values(dateOrientedDataset)[0]).filter(
      key => key !== DATE
    ),
    traces,
    Object.keys(Object.values(dateOrientedDataset)[0]).filter(
      key => key !== DATE
    )
  );

  const trainSize =
    workflowParameters?.[TRAIN_TEST_SPLIT] || DEFAULT_VALUE_TRAIN_TEST_SPLIT;
  const seperationDate = [timeLine[parseInt(timeLine.length * trainSize)]];

  return addTrainingLine(
    wrapPlots(
      traces,
      DATE,
      `${rateType} ${RATE} (${getUnitRate(unitSystem, rateType, timePeriod)})`,
      `${FIELD} ${rateType} ${RATE} by ${LAYER}`
    ),
    seperationDate
  );
};
