import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import LoadingPage from '../../../../../Components/LoadingPage';
import { BasicTable } from '../../../../../Components/TableView';

import {
  generateOilProductionPlot,
  futureOilProdOutputColumnNames,
} from '../../../../../../Utils/WorkflowReportUtils/CRMPUtils';

import { Grid, Paper, Typography, Link, IconButton } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  PER_DAY,
  PRODUCER,
  SYSTEM_OF_UNITS,
} from '../../../../../../constants/WellConstants';

const Results = ({
  workflowParameters,
  oilMatchingData,
  r2RMSEData,
  wellNames,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onDownload = () => {
    const content = [];

    r2RMSEData.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  const cachedPlotResult = useMemo(() => {
    if (workflowParameters && oilMatchingData) {
      const { colors, colsOilRateObserved, colsOilRateCrm } =
        futureOilProdOutputColumnNames(wellNames[PRODUCER], true);
      return generateOilProductionPlot(
        workflowParameters,
        oilMatchingData,
        colsOilRateObserved,
        colsOilRateCrm,
        colors,
        workflowParameters[SYSTEM_OF_UNITS],
        PER_DAY
      );
    }
  }, [workflowParameters, oilMatchingData]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent}>
        {cachedPlotResult ? (
          <PlotlyComponent
            data={cachedPlotResult.data}
            layout={cachedPlotResult.layout}
            config={cachedPlotResult.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Oil Prodcution Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Paper elevation={5} className={styles.visualContentRow}>
          <BasicTable data={r2RMSEData} />
        </Paper>
        <Grid container className={styles.visualContentRow}>
          <Grid
            item
            container
            xs={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="overline">Download R2 RMSE Oil</Typography>
            <Link
              download="R2_RMSE_Oil.csv"
              href={encodedUri}
              onClick={onDownload}
            >
              <IconButton>
                <GetAppRoundedIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Results.propTypes = {
  workflowParameters: PropTypes.object,
  oilMatchingData: PropTypes.array,
  r2RMSEData: PropTypes.array,
  wellNames: PropTypes.object,
};

export default Results;
