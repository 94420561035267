import React from 'react';
import {
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { CheckCircle, Cancel } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const DateDialog = ({ open, onClose }) => (
  <BootstrapDialog
    onClose={onClose}
    aria-labelledby="customized-dialog-title"
    open={open}
  >
    <DialogTitle
      style={{ backgroundColor: '#003333', color: '	#ffffff' }}
      sx={{ m: 0, p: 2 }}
      id="customized-dialog-title"
    >
      Rules for Date field
    </DialogTitle>
    <DialogContent dividers>
      <div style={{ padding: '20px' }}>
        {/* Rule 1 */}
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
          Rule 1: Format should be in MM/dd/yyyy
        </Typography>
        <Typography variant="body1">
          Example: Consider date 2024 March 01
        </Typography>
        <TableContainer
          component={Paper}
          style={{ maxWidth: 400, margin: '20px 0' }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>01/03/2024</TableCell>
                <TableCell>
                  <Cancel color="error" /> Wrong Format
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>03/01/24</TableCell>
                <TableCell>
                  <Cancel color="error" /> Wrong Format
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>03/01/2024</TableCell>
                <TableCell>
                  <CheckCircle color="success" /> Correct Format
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Rule 2 */}
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
          Rule 2: Dates should be in increasing order when grouped by Well Name
        </Typography>
        <Typography variant="body1">
          Example 1 (incorrect order): For PQR_WELL dates are not in increasing
          order
        </Typography>
        <TableContainer
          component={Paper}
          style={{ maxWidth: 500, margin: '20px 0' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Well Name</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>03/01/2000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>04/01/2000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>12/01/2024</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>
                  09/13/2000 <Cancel color="error" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>
                  03/20/2013 <Cancel color="error" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>
                  03/01/2005 <Cancel color="error" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1">
          Example 2 (correct order):
          <CheckCircle color="success" style={{ marginLeft: '10px' }} />
        </Typography>
        <TableContainer
          component={Paper}
          style={{ maxWidth: 500, margin: '20px 0' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Well Name</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>03/01/2000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>04/01/2000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ABC_WELL</TableCell>
                <TableCell>12/01/2024</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>09/13/2000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>03/20/2013</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PQR_WELL</TableCell>
                <TableCell>04/01/2013</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </BootstrapDialog>
);

DateDialog.propTypes = {
  open: PropTypes.object,
  onClose: PropTypes.object,
};
