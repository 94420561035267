import React, { useState, useEffect } from 'react';
import useStyles from '../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';

import { generateOilRateGraph } from '../../../../Utils/WorkflowReportUtils/ArpsDeclineCurve';

import { Grid, Typography } from '@material-ui/core';

import { SYSTEM_OF_UNITS } from '../../../../constants/WellConstants';

const WellOilRatePlot = ({
  filteredDatasetContent,
  parameters,
  selectedWell,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);

  useEffect(() => {
    if (
      filteredDatasetContent != null &&
      parameters != null &&
      selectedWell != null
    )
      setplot(
        generateOilRateGraph(
          filteredDatasetContent,
          selectedWell,
          parameters[SYSTEM_OF_UNITS]
        )
      );
  }, [filteredDatasetContent, parameters, selectedWell]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{header}</Typography>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <LoadingPage message="Oil rate plot is loading..." goHome={false} />
      )}
    </Grid>
  );
};

WellOilRatePlot.propTypes = {
  filteredDatasetContent: PropTypes.array,
  parameters: PropTypes.object,
  selectedWell: PropTypes.string,
  header: PropTypes.string,
};

export default WellOilRatePlot;
