import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DataGrid } from '@material-ui/data-grid';

/** FIXME: This component is nearly identical to BasicTable in frontend/src/views/Components/TableView.js
 * The only difference is that column header widths are calculated dynamically in this component.
 * Source component should be refactored and this component should be removed.
 * */

/**
 * This function expects an array that contains objects as table rows.
 * Each row contains key-value pairs where keys are columns names and
 * values are corresponding value.
 *
 * @param {Array} data
 * @returns JSX.Element
 */
export const BasicTableWithDynamicColumns = ({
  data,
  columnNameMap,
  props,
}) => {
  let rowId = 1;
  const [rows, setrows] = useState(null);
  const [columns, setcolumns] = useState(null);
  // const [colNameMap, setcolNameMap] = useState(null);

  // Callbacks / Helpers -->

  // This function calculates the width of the column based on the length of the column name.
  const dynamicColumnWidth = colName => {
    let colNameLength = colName.length;
    let colWidth = colNameLength * 11 + 50;
    return { width: colWidth, height: 50 };
  };

  const initColumnNames = () => {
    const columnCount = Object.keys(data[0]).length;
    // const columnSize = columnCount > 6 ? { width: '300' } : { flex: 1 };
    const columnNames = columnNameMap
      ? Object.keys(columnNameMap)
      : Object.keys(data[0]);

    if (columnCount > 0) {
      setcolumns(
        columnNames.map(colName => {
          return {
            field: colName,
            headerName: columnNameMap ? columnNameMap[colName] : colName,
            ...dynamicColumnWidth(
              columnNameMap ? columnNameMap[colName] : colName
            ),
          };
        })
      );
    }
  };
  const initRows = () => {
    setrows(
      data.map(dataRow => {
        Object.keys(dataRow).forEach(key => {
          if (!isNaN(Number(dataRow[key]))) {
            dataRow[key] = Math.round(dataRow[key] * 1000) / 1000;
          }
        });
        return { ...dataRow, id: rowId++ };
      })
    );
  };
  // <-- Callbacks / Helpers

  useEffect(() => {
    if (data != undefined && data[0] != undefined) {
      initColumnNames();
      initRows();
    }
  }, [data]);

  if (columns !== null && rows != null) {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={rows.length > 5 ? 5 : rows.length}
        autoHeight={true}
        {...props}
        // getCellClassName={params => {
        //   if (params.field === 'rowId') {
        //     return params.value >= 15 ? 'hot' : 'cold';
        //   } else return '';
        // }}
      />
    );
  } else {
    return '';
  }
};

BasicTableWithDynamicColumns.propTypes = {
  data: PropTypes.array,
  props: PropTypes.object,
  columnNameMap: PropTypes.object,
};
