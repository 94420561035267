import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import useStyles from '../../../../Styles/WorkflowStyles';
import LoadingPage from '../../../Components/LoadingPage';

import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  INJECTOR,
  FEATURE_IMPORTANCE,
} from '../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

import {
  retrieveMLConnFilteredDataset,
  retrieveMLConnWorkflowResult,
} from '../../../../API/Functions/MLConnectivity';

import { updateSnackBar } from '../../../../redux/actions/feedback';

import { analyzeDataSummaryValues } from './Utils';

const reduxSelectedWorkflowSubscriber = state =>
  state.workflow.selectedWorkflow;

const InjectorImportanceAnalysis = ({
  header,
  selectedInjector,
  onInjectorSelect,
  selectedCorrPair,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const reduxSelectedWorkflow = useSelector(reduxSelectedWorkflowSubscriber);

  const [minImp, setminImp] = useState(null);
  const [maxImp, setmaxImp] = useState(null);
  const [injWellNames, setinjWellNames] = useState(null);

  const [apiRes, setapiRes] = useState(null);

  // Handlers -->
  const onWellSelect = event => {
    // This will set the injector on parent, which will trigger prop change re-render, and useMemo will handle it.
    onInjectorSelect(event.target.value);
  };
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const [datasetContent, workflowResultFile] = await Promise.all([
          retrieveMLConnFilteredDataset(reduxSelectedWorkflow['workflow_id']),
          retrieveMLConnWorkflowResult(
            reduxSelectedWorkflow['workflow_id'],
            selectedCorrPair
          ),
        ]);

        if (mounted) {
          setapiRes({
            datasetContent,
            workflowResultFile,
          });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (selectedCorrPair) {
      fetchPlotData();
    }

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (apiRes) {
      const { minImp, maxImp, injWellNames } = analyzeDataSummaryValues(
        apiRes?.workflowResultFile
      );

      setminImp(minImp);
      setmaxImp(maxImp);
      setinjWellNames(injWellNames);
    }
  }, [apiRes]);

  const cachedInjectorNetworkGraph = useMemo(() => {
    if (apiRes?.datasetContent && apiRes?.workflowResultFile) {
      return generateNetworkGraphWithLags(
        apiRes.datasetContent,
        apiRes.workflowResultFile.filter(
          row => row[INJECTOR] == selectedInjector
        ),
        FEATURE_IMPORTANCE,
        minImp,
        maxImp,
        undefined,
        undefined,
        `${selectedInjector} Importance Feature Graph`
      );
    }
  }, [apiRes, selectedInjector]);

  return (
    <React.Fragment>
      <Grid id={header} container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{header}</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedInjector ? selectedInjector : ''}
              onChange={onWellSelect}
              className="dropdownSelection"
            >
              {injWellNames?.map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {cachedInjectorNetworkGraph ? (
          <PlotlyComponent
            data={cachedInjectorNetworkGraph.data}
            layout={cachedInjectorNetworkGraph.layout}
            config={cachedInjectorNetworkGraph.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message={selectedInjector + ' Network Plot is Loading...'}
            goHome={false}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

InjectorImportanceAnalysis.propTypes = {
  header: PropTypes.string,
  selectedInjector: PropTypes.string,
  onInjectorSelect: PropTypes.func,
  selectedCorrPair: PropTypes.array,
};
export default InjectorImportanceAnalysis;
