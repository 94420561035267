import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../redux/actions/user';
import { updateSnackBar } from '../../redux/actions/feedback';

import useStyles from '../../Styles/LoginStyle';
import { Grid, TextField, Button } from '@material-ui/core';

import Navbar from '../Components/NavBar';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';

const ResetPasswordPage = () => {
  const styles = useStyles();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [passwordError, setpasswordError] = useState(false);
  const [password, setpassword] = useState('');
  const [reTypedpassword, setreTypedpassword] = useState('');

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const handlePasswordChange = event => {
    setpassword(event.target.value);
    setpasswordError(event.target.value !== reTypedpassword);
  };

  const handleReTypedPasswordChange = event => {
    setreTypedpassword(event.target.value);
    setpasswordError(event.target.value !== password);
  };

  const onSuccess = async () => {
    dispatch(
      updateSnackBar(
        'Password changed Successfully!',
        SNACK_BAR_SEVERITY.success
      )
    );
    await new Promise(resolve => setTimeout(resolve, 1000)); // Human friendly wait.
    navigate('/login');
  };

  const onFail = error => {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while logging in.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
  };

  /**
   * Upon password change, this function makes POST request to server with provided credidentials.
   * @param {*} event
   */
  const handlePasswordUpdate = async event => {
    event.preventDefault();
    await resetPassword({ userId, password }, onSuccess, onFail);
  };
  // <-- Handlers

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navGrid}>
        <Navbar loggedIn={false} />
      </Grid>
      <Grid item container className={styles.body}>
        <form
          className={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handlePasswordUpdate}
        >
          <TextField
            className={styles.textField}
            label="Password"
            type="password"
            variant="outlined"
            size="small"
            onChange={handlePasswordChange}
          />
          <TextField
            className={styles.textField}
            label="ReType Password"
            type="password"
            variant="outlined"
            size="small"
            error={passwordError}
            onChange={handleReTypedPasswordChange}
          />
          <Button
            className={styles.button}
            type="submit"
            variant="contained"
            disabled={passwordError}
          >
            Reset Password
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
