import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import { setSnackBarVisibility } from '../../redux/actions/feedback';

const snackbarStateSubscriber = state => state.feedback.snackBarOpen;
const snackbarTextSubscriber = state => state.feedback.snackBarText;
const snackbarSeveritySubscriber = state => state.feedback.snackBarSeverity;

const SnackBarFeedback = () => {
  const dispatch = useDispatch();

  const reduxSnackBarState = useSelector(snackbarStateSubscriber);
  const reduxSnackBarText = useSelector(snackbarTextSubscriber);
  const reduxSnackBarSeverity = useSelector(snackbarSeveritySubscriber);

  return (
    <Snackbar
      open={reduxSnackBarState}
      autoHideDuration={10000}
      onClose={() => dispatch(setSnackBarVisibility(false))}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => dispatch(setSnackBarVisibility(false))}
        severity={reduxSnackBarSeverity}
      >
        {reduxSnackBarText}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackBarFeedback;
