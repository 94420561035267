import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';
import { Button, Grid } from '@material-ui/core';

import {
  FEATURE_IMPORTANCE,
  INJECTOR,
  PRODUCER,
} from '../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

import { retrieveMLConnWorkflowResult } from '../../../../API/Functions/MLConnectivity';

import { updateSnackBar } from '../../../../redux/actions/feedback';
import { createHeatmap } from '../../../../Utils/PlotlyUtils/Plots';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const WellRelationPlot = ({ header, selectedCorrPair }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const allButtonRef = useRef(null);
  const disconnectedButtonRef = useRef(null);

  const [plot, setplot] = useState(null);
  const [apiRes, setApiRes] = useState(null);
  const [injWellNames, setInjWellNames] = useState([]);
  const [prodWellNames, setProdWellNames] = useState([]);

  // Callbacks -->
  /**
   * This function generates a heatmap that represents Feature Importance between all injector and producer wells.
   * If user specifies to see disconnected wells, then function filters all the wells that has feature importance
   * less than 0.1,
   */
  const generateHeatmap = () => {
    let heatmapData = apiRes?.workflowResultFile;
    // If disconnected button is clicked, update heatmapData before generating the plot.
    if (
      disconnectedButtonRef.current != null &&
      [...disconnectedButtonRef.current.classList].includes('selectedButton')
    ) {
      heatmapData = heatmapData.map(dataRow => {
        if (Number(dataRow[FEATURE_IMPORTANCE]) <= 0.1) {
          return dataRow;
        } else {
          return {
            ...dataRow,
            ...{ [FEATURE_IMPORTANCE]: '' },
          };
        }
      });
    }

    // Save the injector producer data.
    const z = injWellNames.map(injector =>
      heatmapData
        .filter(dataRow => dataRow[INJECTOR] == injector)
        .map(dataRow => dataRow[FEATURE_IMPORTANCE])
    );

    // Generate the plot.
    setplot(
      createHeatmap(
        prodWellNames,
        injWellNames,
        z,
        `${INJECTOR} - ${PRODUCER} ${header}`,
        PRODUCER,
        INJECTOR,
        FEATURE_IMPORTANCE
      )
    );
  };
  // <-- Callbacks

  // Handlers -->
  /**
   * This handler updates the control buttons on injector-producer heatmap, and updates the heatmap accordingly.
   * @param {String} buttonRef
   */
  const onWellRelationSelect = buttonRef => {
    // Remove selectedButton classname from both of the buttons, and add selectedButton class name to selected buttonRef.
    allButtonRef.current.classList.remove('selectedButton');
    disconnectedButtonRef.current.classList.remove('selectedButton');
    buttonRef.current.classList.add('selectedButton');
    generateHeatmap();
  };
  // <-- Handlers

  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const [workflowResultFile] = await Promise.all([
          retrieveMLConnWorkflowResult(
            reduxSelectedWorkflow['workflow_id'],
            selectedCorrPair
          ),
        ]);

        if (mounted) {
          setApiRes({
            workflowResultFile,
          });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (selectedCorrPair) {
      fetchPlotData();
    }

    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (apiRes) {
      const injectors = [
        ...new Set(apiRes?.workflowResultFile.map(row => row[INJECTOR])),
      ];
      const producers = [
        ...new Set(apiRes?.workflowResultFile.map(row => row[PRODUCER])),
      ];

      setInjWellNames(injectors);
      setProdWellNames(producers);
    }
  }, [apiRes]);

  useEffect(() => {
    if (injWellNames && prodWellNames && apiRes) {
      generateHeatmap();
    }
  }, [injWellNames, prodWellNames, apiRes]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item container style={{ justifyContent: 'center' }}>
        <Button
          ref={allButtonRef}
          variant="outlined"
          className={`${styles.button} largeButton selectedButton`}
          onClick={() => {
            onWellRelationSelect(allButtonRef);
          }}
        >
          All
        </Button>
        <Button
          ref={disconnectedButtonRef}
          variant="outlined"
          className={`${styles.button} largeButton `}
          onClick={() => {
            onWellRelationSelect(disconnectedButtonRef);
          }}
        >
          Disconnected
        </Button>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ height: '100%', width: '100%' }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

WellRelationPlot.propTypes = {
  header: PropTypes.string,
  selectedCorrPair: PropTypes.array,
};

export default WellRelationPlot;
