import PropTypes from 'prop-types';

import FieldGainAnalysisSumTable from './Components/FluidMatchingComponents/WorkflowAnalysisSumTable';
import DynamicGainValuesMap from './Components/FluidMatchingComponents/DynamicGainValuesMap';
import StaticGainValuesMap from './Components/FluidMatchingComponents/StaticGainValuesMap';
import {
  InjectorGainValueAnalysis,
  ProducerGainValueAnalysis,
} from './Components/FluidMatchingComponents/WellOrientedGainValueAnalysis';
import GainValuesTableView from './Components/FluidMatchingComponents/GainValuesTableView';
import CumFieldAnalysisComponent from './Components/FluidMatchingComponents/CumFluidProduction';
import FluidProductionRateComponent from './Components/FluidMatchingComponents/FluidProdRatePlot';
import OilMatchingComponents from './Components/OilMatchingComponents/OilMatching';
import OptimizationComponents from './Components/OptimizationComponents/Optimization';

const PlotComponentMapper = ({ headers, selectedHeader }) => {
  const componentMap = {
    [headers.STATIC_GAIN_VALUES]: (
      <>
        <FieldGainAnalysisSumTable />
        <StaticGainValuesMap />
      </>
    ),
    [headers.DYNAMIC_GAIN_VALUES]: <DynamicGainValuesMap />,
    [headers.INJECTOR]: <InjectorGainValueAnalysis />,
    [headers.PRODUCER]: <ProducerGainValueAnalysis />,
    [headers.GAIN_VALUES]: <GainValuesTableView />,
    [headers.FLUID_PRODUCTION]: (
      <>
        <FluidProductionRateComponent />
        <CumFieldAnalysisComponent />
      </>
    ),
    [headers.OIL_MATCHING]: <OilMatchingComponents />,
    [headers.OPTIMIZATION]: <OptimizationComponents />,
  };

  return componentMap[
    selectedHeader ? selectedHeader : headers.STATIC_GAIN_VALUES
  ];
};

PlotComponentMapper.propTypes = {
  headers: PropTypes.object,
  selectedHeader: PropTypes.string,
};

export default PlotComponentMapper;
