import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  rootContainer: {
    height: '100%',
    width: '100%',
    padding: '3% 3% 0% 3%',
  },
  argumentsContainer: {
    height: '90%',
    width: '100%',
    overflow: 'auto',
  },
  runContainer: {
    height: '10%',
    width: '100%',
    margin: '1%',
  },
  argumentContainer: {
    width: '100%',
  },
  argumentRow: {
    marginTop: '1%',
    maxWidth: '75%',
    maxHeight: '100%',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.smallRow': {
      maxWidth: '50%',
    },
  },
  paperContainer: {
    flex: '1 1 auto',
    height: '30vh',
    overflow: 'auto',
    // margin: '0.25em',
    '&.small': {
      height: 'auto',
      maxHeight: '20vh',
    },
    '&.card': {
      maxWidth: '30%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    fontWeight: 600,
  },
  numericInput: {
    '&.smallInput': {
      borderRadius: '2em',
      width: '5em',
    },
  },
  selectionInput: { width: '10em' },
  button: {
    borderRadius: '2em',
    textTransform: 'capitalize',
    '&.largeButton': {
      width: '20%',
    },
    '&.xlargeButton': {
      width: '25%',
    },
    '&.selectedButton': {
      backgroundColor: '#000B46',
      color: 'white',
    },
  },
  primaryButton: {
    backgroundColor: '#000B46',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000B46',
    },
  },
  secondaryButton: {},
  runButton: {
    paddingLeft: '7em',
    paddingRight: '7em',
  },
  centerFlexGrid: {
    display: 'flex',
    justifyContent: 'center',
    '&.column': {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  rangeInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1em',
  },
});

export default useStyles;
