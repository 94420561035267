import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../../../Styles/VisualizationPageStyle';

import Navbar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import VisualizationParamsModal from '../../Components/VisualizationParamsModal';
import VisualizationSynopsisModal from '../../Components/VisualizationSynopsisModal';

import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import { SURVEILLANCE_DATASET_VISUALIZATION_HEADER_NAMES as PLOT_HEADERS } from '../../../constants/WorkflowComponentConstants';
import { PLOT_COMPONENTS, PLOT_SYNOPSES } from './PlotComponentDetails';
import { setSelectedHeader } from '../../../redux/actions/sidebar';
import LogoComponent from '../../Components/LogoComponent';

const selectedHeaderSubscriber = state => state.sidebar.selectedPlotHeader;

const HeaderController = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { datasetId: urlDatasetId } = useParams();
  const reduxSelectedHeader = useSelector(selectedHeaderSubscriber);

  const [plotInfoModalState, setPlotInfoModalState] = useState(false);
  const [paramsModalState, setParamsModalState] = useState(false);

  // Callback/Helpers -->
  const togglePlotInfoModal = () => {
    setPlotInfoModalState(prev => !prev);
  };

  const toggleParamsModal = () => {
    setParamsModalState(prev => !prev);
  };
  // <-- Callback/Helpers

  // Handlers -->
  const onRunWorkflow = () => {
    navigate(`/surveillanceWorkflowSelection/${urlDatasetId}`);
  };
  // <-- Handlers

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item container xs={6} direction="row" alignItems="center">
          <Typography className={styles.header}>
            {reduxSelectedHeader}
          </Typography>
          <Tooltip title="Info">
            <IconButton onClick={togglePlotInfoModal}>
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
          <VisualizationSynopsisModal
            toggle={togglePlotInfoModal}
            modalState={plotInfoModalState}
            synopsisHeader={reduxSelectedHeader}
            synopsisComponent={PLOT_SYNOPSES[reduxSelectedHeader]}
          />
        </Grid>
        <Grid item container xs={6} className={styles.buttonContainer}>
          <Button
            variant="outlined"
            size="small"
            color="default"
            onClick={toggleParamsModal}
          >
            Parameters
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onRunWorkflow}
          >
            Run Workflow
          </Button>
        </Grid>
      </Grid>
      <VisualizationParamsModal
        toggle={toggleParamsModal}
        modalState={paramsModalState}
      />
    </>
  );
};

const PlotMapper = () => {
  const reduxSelectedHeader = useSelector(selectedHeaderSubscriber);

  return <>{PLOT_COMPONENTS[reduxSelectedHeader]}</>;
};

const DatasetVisualization = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  // Callback/Helpers -->
  // <-- Callback/Helpers

  useEffect(() => {
    dispatch(setSelectedHeader(PLOT_HEADERS.WELLS)); // Commit to redux store.
  }, []);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sideBarGrid}>
          <Paper elevation={20} className={styles.sidebarPaperContainer}>
            <SideBar
              mainHeader="Dataset Visualization"
              plotHeaderObject={PLOT_HEADERS}
            />
          </Paper>
        </Grid>
        <Grid item className={styles.visualizationGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <Grid container className={styles.visualContainer}>
              <HeaderController />
              <PlotMapper />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DatasetVisualization;
