import React from 'react';

import WorkflowHelpBase from './BaseWorkflowHelpComponent';

import { Typography } from '@material-ui/core';

import { HELP_PAGE_LIST_HEADER_NAMES } from '../../../constants/WorkflowComponentConstants';
import CustomStepper from '../CustomStepper';
import WorkflowDataFormat from './WorkflowDataFormat';

const MLConnectivity = () => {
  const workflowSteps = [
    'Go to Surveillance Section.',
    'Upload your csv dataset by clicking to green area.',
    'Select the dataset you want to visualize from the dropdown menu, and click Continue button.',
    'Select filters with different parameters like start/end date range, active days.',
    'For the advanced filtering settings, click Advanced button, then click apply button.',
    'Charts rendered with selected dates like Schedule Plots, Scatter Plots.',
    'You can use left hand side to navigate through the plots faster.',
    'Click Choose Workflow Button to run workflows on selected/filtered dataset.',
    'Select ML Connectivity from the left hand side.',
    'You can provide Name and Description to your workflow.',
    'Click on Run Button to start the workflow with provided settings.',
    'Visualzie your workflow results from the Homepage once it is Completed.',
  ];

  const workflowExplanation = {
    'About ML Connectivity': (
      <React.Fragment>
        <Typography variant="body1">
          ML Connectivity uses state of the art machine learning models and
          algorithms to do random forest computation for producer wells and get
          results.
        </Typography>
        <ul>
          <li>Input Data: Formatted as mentioned in the section below</li>
          <li>
            Data Processing: Before the regression analysis for history
            mathcing, data processing/cleaning is performed in order to improve
            the analysis. Currently, it does: exclude the data outside of
            specified date range multiply 1.1 for oil production rate and 0.01
            for gas production rate to calculate the total production rate
          </li>
        </ul>
      </React.Fragment>
    ),
    'Data Format': <WorkflowDataFormat />,
    'Overall Flow': (
      <CustomStepper orientation="vertical" steps={workflowSteps} />
    ),
  };

  return (
    <WorkflowHelpBase
      workflowName={HELP_PAGE_LIST_HEADER_NAMES.ML_CONNECTIVITY}
      workflowExplanations={workflowExplanation}
    />
  );
};

export default MLConnectivity;
