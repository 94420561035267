import {
  createLinePlotTrace,
  createNetworkGraph,
  DEFAULT_MARKER_SIZE,
  LINES_WITH_MARKERS,
  wrapPlots,
} from './PlotlyUtils/Plots';

import {
  WELL_ID,
  INJECTOR,
  PRODUCER,
  XCOL,
  YCOL,
  LAG,
  GREEN,
  LIGHTBLUE,
  DATE,
  FIELD,
  PER_MONTH,
  Y_FUNCTION,
  GAIN,
} from '../constants/WellConstants';
import { getUnitRate } from './ReservoirUtils';
import {
  generateMultiYAxesConfigs,
  generateYAxesPairsFromAttributes,
} from './VisualizationUtils';
import {
  GAS_PRODUCTION_RATE,
  OIL_PRODUCTION_RATE,
  WATER_PRODUCTION_RATE,
  TOTAL_PRODUCTION_RATE,
} from '../constants/WellConstants';

const COLORS = [
  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

export function set_colors(count) {
  let colors = [];
  const qu = Math.floor(count / COLORS.length);
  const mod = count % COLORS.length;

  for (let i = 0; i < qu; i++) {
    COLORS.forEach(color => colors.push(color));
  }
  for (let i = 0; i < mod; i++) {
    colors.push(COLORS[i]);
  }
  return colors.concat(colors);
}

export function generateNetworkGraphWithLags(
  data,
  overall_analysis_df,
  value_type,
  min_value,
  max_value,
  min_lag,
  max_lag,
  title = undefined,
  if_colored = true,
  unitSystem = FIELD
) {
  const datasetHasLag =
    overall_analysis_df.length > 0 && LAG in overall_analysis_df[0];
  // Due to Pseudo injecotrs, we need to verify that well names in analysis df actually exist in uploaded dataset.
  const injector_wells = [
    ...new Set(overall_analysis_df.map(row => row[INJECTOR])),
  ].filter(wellName => data.map(row => row[WELL_ID]).includes(wellName));
  const producer_wells = [
    ...new Set(overall_analysis_df.map(row => row[PRODUCER])),
  ].filter(wellName => data.map(row => row[WELL_ID]).includes(wellName));

  let positions = {};
  data.forEach(row => {
    positions[row[WELL_ID]] = [parseInt(row[XCOL]), parseInt(row[YCOL])];
  });

  let colors = if_colored ? set_colors(injector_wells.length) : undefined;

  let node_data = [];
  injector_wells.forEach(well => {
    node_data.push({
      'Node Id': well,
      'Node Type': INJECTOR,
      'Node X': positions[well][0],
      'Node Y': positions[well][1],
      'Node Color': LIGHTBLUE,
      'Node Symbol': 'triangle-up',
    });
  });
  producer_wells.forEach(well => {
    node_data.push({
      'Node Id': well,
      'Node Type': PRODUCER,
      'Node X': positions[well][0],
      'Node Y': positions[well][1],
      'Node Color': well?.endsWith('_P') ? LIGHTBLUE : GREEN,
      'Node Symbol': 'circle',
    });
  });

  // data_df filtering by value
  if (min_value != undefined && max_value != undefined) {
    overall_analysis_df = overall_analysis_df.filter(
      row => (row[value_type] >= min_value) & (row[value_type] <= max_value)
    );
  }

  // data_df filtering by LAG if exist.
  if (datasetHasLag) {
    overall_analysis_df = overall_analysis_df.filter(
      row => (row[LAG] >= min_lag) & (row[LAG] <= max_lag)
    );
  }

  let wellPairs = {};
  overall_analysis_df.forEach(row => {
    if (!(row[INJECTOR] in wellPairs) && injector_wells.includes(row[INJECTOR]))
      wellPairs[row[INJECTOR]] = [];
    if (
      !wellPairs[row[INJECTOR]].includes(row[PRODUCER]) &&
      producer_wells.includes(row[PRODUCER])
    )
      wellPairs[row[INJECTOR]].push(row[PRODUCER]);
  });

  const edges = [];
  for (const inj in wellPairs) {
    for (const prod of wellPairs[inj]) {
      edges.push([inj, prod]);
    }
  }

  const edge_data = [];
  edges.forEach(edge => {
    let p1 = positions[edge[0]];
    let p2 = positions[edge[1]];
    let max_row = overall_analysis_df.filter(
      row => row[INJECTOR] == edge[0] && row[PRODUCER] == edge[1]
    );

    max_row = max_row.sort((row1, row2) => {
      return row2[value_type] - row1[value_type];
    })[0];

    let line_width = Math.abs(max_row[value_type] * 10);

    let hoverText =
      `(${p1[0]}, ${p1[1]}), (${p2[0]}, ${p2[1]})` +
      ` = ${Math.round(max_row[value_type] * 100) / 100}`;

    if (LAG in overall_analysis_df[0])
      hoverText += `<br />Lag: ${max_row[LAG]}`;

    const distance =
      Math.round(
        Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)) * 100
      ) / 100;

    hoverText += `<br />Distance: ${
      distance + getUnitRate(unitSystem, 'distance')
    }`;

    const edge_object = {
      'Source Node X': p1[0],
      'Source Node Y': p1[1],
      'Dest Node X': p2[0],
      'Dest Node Y': p2[1],
      'Edge Width': line_width,
      'Edge HoverText': hoverText,
    };

    if (if_colored)
      edge_object['Color'] = colors[injector_wells.indexOf(edge[0])];

    if (line_width > 1e-6) edge_data.push(edge_object);
  });

  return title
    ? createNetworkGraph(node_data, edge_data, title, undefined, unitSystem)
    : createNetworkGraph(
        node_data,
        edge_data,
        'Injectors - Producers ' + value_type,
        undefined,
        unitSystem
      );
}

export function generateNetworkGraphWithUnit(
  datasetContent,
  workflowAnalysisContent,
  valueType,
  minValue = undefined,
  maxValue = undefined,
  title = undefined,
  if_colored = undefined,
  unitSystem = FIELD
) {
  const injectors = [
    ...new Set(workflowAnalysisContent.map(row => row[INJECTOR])),
  ];
  const producers = [
    ...new Set(workflowAnalysisContent.map(row => row[PRODUCER])),
  ];

  let wellLocations = {};
  datasetContent.forEach(
    row =>
      (wellLocations[row[WELL_ID]] = [Number(row[XCOL]), Number(row[YCOL])])
  );

  let wellNodes = [];
  injectors.forEach(well => {
    wellNodes.push({
      'Node Id': well,
      'Node Type': INJECTOR,
      'Node X': wellLocations[well][0],
      'Node Y': wellLocations[well][1],
      'Node Color': LIGHTBLUE,
      'Node Symbol': 'triangle-up',
    });
  });
  producers.forEach(well => {
    wellNodes.push({
      'Node Id': well,
      'Node Type': PRODUCER,
      'Node X': wellLocations[well][0],
      'Node Y': wellLocations[well][1],
      'Node Color': well.endsWith?.('_P') ? LIGHTBLUE : GREEN,
      'Node Symbol': 'circle',
    });
  });

  // datasetContent filtering by value
  if (minValue && maxValue) {
    workflowAnalysisContent = workflowAnalysisContent.filter(
      row => (row[valueType] >= minValue) & (row[valueType] <= maxValue)
    );
  }

  let pairs = {};
  workflowAnalysisContent.forEach(row => {
    if (!(row[INJECTOR] in pairs)) {
      pairs[row[INJECTOR]] = [];
    }
    if (!pairs[row[INJECTOR]].includes(row[PRODUCER])) {
      pairs[row[INJECTOR]].push(row[PRODUCER]);
    }
  });

  const edges = [];
  for (const key in pairs) {
    for (const val of pairs[key]) {
      edges.push([key, val]);
    }
  }

  const edge_data = [];
  edges.forEach(edge => {
    let p1 = wellLocations[edge[0]];
    let p2 = wellLocations[edge[1]];
    let max_row = workflowAnalysisContent.filter(
      row => row[INJECTOR] == edge[0] && row[PRODUCER] == edge[1]
    );

    if (p1 && p2 && max_row) {
      max_row = max_row.sort((row1, row2) => {
        return row2[valueType] - row1[valueType];
      });
      max_row = max_row[0];

      let colors = set_colors(injectors.length);

      let line_width = Math.abs(max_row[valueType] * 10);

      let hoverText =
        `(${p1[0]}, ${p1[1]}), (${p2[0]}, ${p2[1]})` +
        ` = ${Math.round(max_row[valueType] * 100) / 100}`;

      const distance =
        Math.round(
          Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)) *
            100
        ) / 100;

      hoverText += `<br />Distance: ${
        distance + getUnitRate(unitSystem, 'distance')
      }`;

      let edgeObject = {
        'Source Node X': p1[0],
        'Source Node Y': p1[1],
        'Dest Node X': p2[0],
        'Dest Node Y': p2[1],
        'Edge Width': line_width,
        'Edge HoverText': hoverText,
      };

      if (if_colored) {
        edgeObject['Color'] = colors[injectors.indexOf(edge[0])];
      }

      if (line_width > 1e-6) edge_data.push(edgeObject);
    }
  });

  if (title) {
    return createNetworkGraph(
      wellNodes,
      edge_data,
      title,
      undefined,
      unitSystem
    );
  } else {
    return createNetworkGraph(
      wellNodes,
      edge_data,
      `${INJECTOR}-${PRODUCER} ${valueType}`,
      unitSystem
    );
  }
}

const generateWellFeatureAnalysisTrace = (
  filteredDatasetContent,
  selectedWell,
  feature,
  yaxis
) => {
  const featureFilteredDataset = filteredDatasetContent.filter(
    row => row[WELL_ID] == selectedWell
  );

  return createLinePlotTrace(
    featureFilteredDataset.map(row => row[DATE]),
    featureFilteredDataset.map(row => Math.round(row[feature] * 100) / 100),
    `${selectedWell} - ${feature}`,
    undefined,
    undefined,
    undefined,
    LINES_WITH_MARKERS,
    undefined,
    undefined,
    undefined,
    yaxis
  );
};

export const generateWellPairAnalysisGraph = (
  injFeature,
  prodFeature,
  selectedInjector,
  selectedProducer,
  filteredDatasetContent,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const yAxesUnits = generateYAxesPairsFromAttributes(
    [injFeature, prodFeature],
    unitSystem,
    timePeriod
  );

  traces.push(
    generateWellFeatureAnalysisTrace(
      filteredDatasetContent,
      selectedInjector,
      injFeature,
      `y${
        yAxesUnits[getUnitRate(unitSystem, injFeature, timePeriod)]['axisIndex']
      }`
    )
  );
  traces.push(
    generateWellFeatureAnalysisTrace(
      filteredDatasetContent,
      selectedProducer,
      prodFeature,
      `y${
        yAxesUnits[getUnitRate(unitSystem, prodFeature, timePeriod)][
          'axisIndex'
        ]
      }`
    )
  );

  let mainAxisPair = Object.entries(yAxesUnits)
    .filter(axisPair => axisPair[1]['axisIndex'] == 1)
    .pop();
  let mainAxisUnit = mainAxisPair[0];
  let mainAxisAttr = mainAxisPair[1]['attr'];
  let otherAxesConfigs = generateMultiYAxesConfigs(yAxesUnits);

  return wrapPlots(
    traces,
    DATE,
    mainAxisPair ? `${mainAxisAttr} (${mainAxisUnit})` : undefined,
    `${selectedInjector} - ${selectedProducer} Analysis Plot`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [0, 1 - 0.1 * (Object.keys(yAxesUnits).length - 1)],
    undefined,
    otherAxesConfigs
  );
};

export const generateEnhancedProducerAnalysisGraph = (
  dataset,
  xAxisSelection,
  yAxisSelection,
  isXAxisLog = false,
  isYAxisLog = false,
  title = undefined,
  chokeSizeCorrection = false,
  xAxisSlicedData = null,
  yAxisSlicedData = null,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  let shapes;
  const traces = [];
  let chokeSizeCorrectionCols = [
    GAS_PRODUCTION_RATE,
    OIL_PRODUCTION_RATE,
    WATER_PRODUCTION_RATE,
    TOTAL_PRODUCTION_RATE,
  ];
  if (chokeSizeCorrection && chokeSizeCorrectionCols.includes(yAxisSelection)) {
    yAxisSelection = 'Choke ' + yAxisSelection;
  }
  const xCoords = dataset.map(row => row[xAxisSelection]);
  const yCoords = dataset.map(row => row[yAxisSelection]);

  traces.push(
    createLinePlotTrace(
      xCoords,
      yCoords,
      dataset[0][WELL_ID],
      undefined,
      undefined,
      undefined,
      LINES_WITH_MARKERS
    )
  );

  if (yAxisSlicedData != null && xAxisSlicedData != null) {
    traces.push(
      createLinePlotTrace(
        xAxisSlicedData,
        yAxisSlicedData,
        'Prediction',
        undefined,
        'orange',
        undefined,
        LINES_WITH_MARKERS
      )
    );
  }

  if (yAxisSelection == Y_FUNCTION) {
    shapes = [
      {
        type: 'line',
        x0: xCoords.at(0),
        y0: 0.25,
        x1: xCoords.at(-1),
        y1: 0.25,
        line: {
          color: 'red',
          dash: 'dashdot',
        },
      },
    ];
  }

  return wrapPlots(
    traces,
    `${xAxisSelection} (${getUnitRate(
      unitSystem,
      xAxisSelection,
      timePeriod
    )})`,
    `${yAxisSelection} (${getUnitRate(
      unitSystem,
      yAxisSelection,
      timePeriod
    )})`,
    title,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    isXAxisLog ? 'log' : undefined,
    isYAxisLog ? 'log' : undefined,
    shapes
  );
};

export function generateConnectivityGraph(
  data,
  overall_analysis_df,
  value_type,
  min_value,
  max_value,
  title = undefined,
  if_colored = true,
  unitSystem = FIELD
) {
  try {
    const positions = {};
    const edges = [];
    const edge_data = [];
    // Due to Pseudo injectors, we need to verify that well names in analysis df actually exist in uploaded dataset.
    const dataWellNames = new Set(data.map(row => row[WELL_ID]));
    const injNames = [
      ...new Set(
        overall_analysis_df
          .map(row => row[INJECTOR])
          .filter(name => dataWellNames.has(name))
      ),
    ];
    const prodNames = [
      ...new Set(
        overall_analysis_df
          .map(row => row[PRODUCER])
          .filter(name => dataWellNames.has(name))
      ),
    ];

    data.forEach(row => {
      positions[row[WELL_ID]] = [parseInt(row[XCOL]), parseInt(row[YCOL])];
    });

    let colors = if_colored ? set_colors(injNames.length) : undefined;

    let node_data = [];
    injNames.forEach(well => {
      node_data.push({
        'Node Id': well,
        'Node Type': INJECTOR,
        'Node X': positions[well][0],
        'Node Y': positions[well][1],
        'Node Color': LIGHTBLUE,
        'Node Symbol': 'triangle-up',
      });
    });
    prodNames.forEach(well => {
      node_data.push({
        'Node Id': well,
        'Node Type': PRODUCER,
        'Node X': positions[well][0],
        'Node Y': positions[well][1],
        'Node Color': well.endsWith?.('_P') ? LIGHTBLUE : GREEN,
        'Node Symbol': 'circle',
      });
    });

    if (min_value != undefined && max_value != undefined) {
      overall_analysis_df = overall_analysis_df.filter(
        row => (row[value_type] >= min_value) & (row[value_type] <= max_value)
      );
    }

    // For every injector save every other producer as edge.
    injNames.forEach(inj => {
      prodNames.forEach(prod => {
        edges.push([inj, prod]);
      });
    });

    const minValue = Math.min(
      ...overall_analysis_df.map(row => row[value_type])
    );
    const maxValue = Math.max(
      ...overall_analysis_df.map(row => row[value_type])
    );

    edges.forEach(edge => {
      const p1 = positions[edge[0]];
      const p2 = positions[edge[1]];
      const wellPairData = overall_analysis_df.find(
        row => row[INJECTOR] == edge[0] && row[PRODUCER] == edge[1]
      );

      if (p1 && p2 && wellPairData && wellPairData[value_type] > 0) {
        let line_width =
          ((wellPairData[value_type] - minValue) / (maxValue - minValue)) * 10;

        let hoverText =
          `(${p1[0]}, ${p1[1]}), (${p2[0]}, ${p2[1]})` +
          ` = ${Math.round(wellPairData[value_type] * 100) / 100}`;

        const distance =
          Math.round(
            Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)) *
              100
          ) / 100;

        hoverText += `<br />Distance: ${
          distance + getUnitRate(unitSystem, 'distance')
        }`;

        const edge_object = {
          'Source Node X': p1[0],
          'Source Node Y': p1[1],
          'Dest Node X': p2[0],
          'Dest Node Y': p2[1],
          'Edge Width': line_width,
          'Edge HoverText': hoverText,
        };

        if (if_colored)
          edge_object['Color'] = colors[injNames.indexOf(edge[0])];

        // Ignore edges with 0 strength, eats up a lot of memory for no reason.
        if (line_width > 1e-6) edge_data.push(edge_object);
      }
    });

    return createNetworkGraph(
      node_data,
      edge_data,
      title ? title : 'Injectors - Producers ' + value_type,
      undefined,
      unitSystem
    );
  } catch (error) {
    return wrapPlots(
      [],
      undefined,
      undefined,
      title ? title : 'Injectors - Producers ' + value_type
    );
  }
}

export function generateOilContrGraph(
  data,
  overall_analysis_df,
  oil_contribution,
  value_type,
  title = undefined,
  if_colored = true,
  unitSystem = FIELD
) {
  const positions = {};
  const edges = [];
  const edge_data = [];
  // Due to Pseudo injectors, we need to verify that well names in analysis df actually exist in uploaded dataset.
  const dataWellNames = new Set(data.map(row => row[WELL_ID]));
  const injNames = [
    ...new Set(
      overall_analysis_df
        .map(row => row[INJECTOR])
        .filter(name => dataWellNames.has(name))
    ),
  ];
  const prodNames = [
    ...new Set(
      overall_analysis_df
        .map(row => row[PRODUCER])
        .filter(name => dataWellNames.has(name))
    ),
  ];

  data.forEach(row => {
    positions[row[WELL_ID]] = [parseInt(row[XCOL]), parseInt(row[YCOL])];
  });

  let colors = if_colored ? set_colors(injNames.length) : undefined;

  let node_data = [];
  injNames.forEach(well => {
    const oil_contr_actual =
      oil_contribution.find(row => row[WELL_ID] == well)?.['Actual Value'] || 0;
    const oil_contr_norm = oil_contribution.find(row => row[WELL_ID] == well)?.[
      'Value'
    ];
    node_data.push({
      'Node Id': `Oil Contr: %${oil_contr_actual}`,
      'Node Type': INJECTOR,
      'Node X': positions[well][0],
      'Node Y': positions[well][1],
      'Node Color': LIGHTBLUE,
      'Node Symbol': 'circle',
      'Node Size': oil_contr_norm
        ? oil_contr_norm * 20 + 20
        : DEFAULT_MARKER_SIZE,
      'Node Text': well,
    });
  });
  prodNames.forEach(well => {
    const oil_contr_actual =
      oil_contribution.find(row => row[WELL_ID] == well)?.['Actual Value'] || 0;
    const oil_contr_norm = oil_contribution.find(row => row[WELL_ID] == well)?.[
      'Value'
    ];
    node_data.push({
      'Node Id': `Total Oil: ${oil_contr_actual}`,
      'Node Type': PRODUCER,
      'Node X': positions[well][0],
      'Node Y': positions[well][1],
      'Node Color': well.endsWith?.('_P') ? LIGHTBLUE : GREEN,
      'Node Symbol': 'circle',
      'Node Size': oil_contr_norm
        ? oil_contr_norm * 20 + 20
        : DEFAULT_MARKER_SIZE,
      'Node Text': well,
    });
  });

  // For every injector save every other producer as edge.
  injNames.forEach(inj => {
    prodNames.forEach(prod => {
      edges.push([inj, prod]);
    });
  });

  const minValue = Math.min(...overall_analysis_df.map(row => row[value_type]));
  const maxValue = Math.max(...overall_analysis_df.map(row => row[value_type]));

  edges.forEach(edge => {
    const p1 = positions[edge[0]];
    const p2 = positions[edge[1]];
    const wellPairData = overall_analysis_df.find(
      row => row[INJECTOR] == edge[0] && row[PRODUCER] == edge[1]
    );

    if (p1 && p2 && wellPairData && wellPairData[value_type] > 0) {
      let line_width =
        ((wellPairData[value_type] - minValue) / (maxValue - minValue)) * 10;

      let hoverText =
        `(${p1[0]}, ${p1[1]}), (${p2[0]}, ${p2[1]})` +
        ` = ${Math.round(wellPairData[value_type] * 100) / 100}`;

      const distance =
        Math.round(
          Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)) *
            100
        ) / 100;

      hoverText += `<br />Distance: ${
        distance + getUnitRate(unitSystem, 'distance')
      }`;

      const edge_object = {
        'Source Node X': p1[0],
        'Source Node Y': p1[1],
        'Dest Node X': p2[0],
        'Dest Node Y': p2[1],
        'Edge Width': line_width,
        'Edge HoverText': hoverText,
      };

      if (if_colored) edge_object['Color'] = colors[injNames.indexOf(edge[0])];

      // Ignore edges with 0 strength, eats up a lot of memory for no reason.
      if (line_width > 1e-6) edge_data.push(edge_object);
    }
  });

  return createNetworkGraph(
    node_data,
    edge_data,
    title ? title : 'Injectors - Producers ' + value_type,
    undefined,
    unitSystem
  );
}

export function genWellConnAnalysisPlot({
  wellsLocationDetails = [],
  wellsGainValues = [],
  wellConnType = GAIN,
  title = `Injectors - Producers ${GAIN} Values`,
  isColored = true,
  unitSystem = FIELD,
}) {
  function findConnectedWellNames() {
    const dataWellNames = new Set(
      wellsLocationDetails.map(row => row[WELL_ID])
    );

    return {
      [INJECTOR]: [
        ...new Set(
          wellsGainValues
            .map(row => row[INJECTOR])
            .filter(name => dataWellNames.has(name))
        ),
      ],
      [PRODUCER]: [
        ...new Set(
          wellsGainValues
            .map(row => row[PRODUCER])
            .filter(name => dataWellNames.has(name))
        ),
      ],
    };
  }

  function generateWellPositions() {
    return wellsLocationDetails.reduce((acc, curr) => {
      acc[curr[WELL_ID]] = [curr[XCOL], curr[YCOL]];
      return acc;
    }, {});
  }

  function generateNodes(wellPositions, connectedWellNames) {
    return [
      ...connectedWellNames[INJECTOR].map(well => ({
        'Node Id': well,
        'Node Type': INJECTOR,
        'Node X': wellPositions[well][0],
        'Node Y': wellPositions[well][1],
        'Node Color': LIGHTBLUE,
        'Node Symbol': 'triangle-up',
      })),
      ...connectedWellNames[PRODUCER].map(well => ({
        'Node Id': well,
        'Node Type': PRODUCER,
        'Node X': wellPositions[well][0],
        'Node Y': wellPositions[well][1],
        'Node Color': well.endsWith?.('_P') ? LIGHTBLUE : GREEN,
        'Node Symbol': 'circle',
      })),
    ];
  }

  function generateEdges(wellPositions, connectedWellNames) {
    const edges = [];
    const { [INJECTOR]: injNames, [PRODUCER]: prodNames } = connectedWellNames;
    const colors = isColored ? set_colors(injNames.length) : [];

    injNames.forEach(injName => {
      prodNames.forEach(prodName => {
        const gainData = wellsGainValues.find(
          row => row[INJECTOR] == injName && row[PRODUCER] == prodName
        );

        if (gainData && gainData[wellConnType] > 1e-6) {
          const p1 = wellPositions[injName];
          const p2 = wellPositions[prodName];
          const distance =
            Math.round(
              Math.sqrt(
                Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)
              ) * 100
            ) / 100;

          edges.push({
            'Source Node X': p1[0],
            'Source Node Y': p1[1],
            'Dest Node X': p2[0],
            'Dest Node Y': p2[1],
            'Edge Width': gainData[wellConnType] * 10,
            'Edge HoverText':
              `(${p1[0]}, ${p1[1]}), (${p2[0]}, ${p2[1]})` +
              ` = ${Math.round(gainData[wellConnType] * 100) / 100}` +
              `<br />Distance: ${
                distance + getUnitRate(unitSystem, 'distance')
              }`,
            Color: colors[injNames.indexOf(injName)],
          });
        }
      });
    });

    return edges;
  }

  try {
    const wellPositions = generateWellPositions();
    const connectedWellNames = findConnectedWellNames();
    const nodes = generateNodes(wellPositions, connectedWellNames);
    const edges = generateEdges(wellPositions, connectedWellNames);

    return createNetworkGraph(nodes, edges, title, undefined, unitSystem);
  } catch (error) {
    return wrapPlots([], undefined, undefined, title);
  }
}
