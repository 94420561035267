import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../Styles/WorkflowStyles';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

const PairSelector = ({
  injectorPairNames,
  producerPairNames,
  filterWorkflowAnalysisContent,
}) => {
  const styles = useStyles();

  const [injPairValue, setinjPairValue] = useState(injectorPairNames[0]);
  const [prodPairValue, setprodPairValue] = useState(producerPairNames[0]);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * Based on selected correlation pairs, invokes entire report to be regenerated based
   * on correlation pair selection by calling filterWorkflowAnalysisContent function.
   *
   * @param {String} newInjPair
   * @param {String} newProdPair
   */
  const onCorrPairSelect = (newInjPair, newProdPair) => {
    injPairValue != newInjPair && setinjPairValue(newInjPair);
    prodPairValue != newProdPair && setprodPairValue(newProdPair);
    filterWorkflowAnalysisContent([newInjPair, newProdPair]);
  };
  // <-- Handlers

  return (
    <Grid container className={styles.visualContentFlex}>
      <Grid item className={styles.visualContentRow}>
        <Typography>Pairs Names</Typography>
      </Grid>
      <Grid item container className={styles.inputContentRow}>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <InputLabel>Injector Feature Value</InputLabel>
          <Select
            value={injPairValue}
            onChange={event =>
              onCorrPairSelect(event.target.value, prodPairValue)
            }
          >
            {injectorPairNames.map(pair => (
              <MenuItem key={'menu-item-' + pair} value={pair}>
                {pair}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <InputLabel>Producer Feature Value</InputLabel>
          <Select
            value={prodPairValue}
            onChange={event =>
              onCorrPairSelect(injPairValue, event.target.value)
            }
          >
            {producerPairNames.map(pair => (
              <MenuItem key={'menu-item-' + pair} value={pair}>
                {pair}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

PairSelector.propTypes = {
  injectorPairNames: PropTypes.array,
  producerPairNames: PropTypes.array,
  filterWorkflowAnalysisContent: PropTypes.func,
};

export default PairSelector;
