import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';

import { BasicTable } from '../../../../Components/TableView';
import LoadingPage from '../../../../Components/LoadingPage';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Paper,
  FormHelperText,
  Link,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  ALL_LAYERS,
  INJECTOR,
  LIQUID,
  PRODUCER,
  SYSTEM_OF_UNITS,
  WELL_ID,
} from '../../../../../constants/WellConstants';

import {
  filterDatasetContentByLayer,
  filterWellContentsFromDataset,
  filterWellNamesFromDataset,
  filterWellNamesByLayer,
  findConnectedLayersOfWell,
} from '../../../../../Utils/DatasetUtils/DataProcessing';
import {
  generateFluidInjectionPlot,
  generateFluidProductionPlot,
  generateGainValuePlot,
  generateLayeredRatePlot,
  generateNetAllocationTableData,
} from '../../../../../Utils/WorkflowReportUtils/CRMPLayerUtils';
import {
  generateContributionPieChart,
  generateCumFluidPieChart,
} from '../../../../../Utils/WorkflowReportUtils/CRMPUtils';

const FluidMatching = ({
  filteredDatasetContent,
  workflowParameter,
  gainValues,
  r2History,
  liquidRateHistory,
  liquidRateAll,
  analyzedWellNames,
  analyzedWellType,
  layers,
  header,
  fieldLayerData,
  fieldData,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [networkPlot, setnetworkPlot] = useState(null);
  const [cumFluidPieChart, setcumFluidPieChart] = useState(null);
  const [contributionPieChart, setcontributionPieChart] = useState(null);
  const [fluidRatePlot, setfluidRatePlot] = useState(null);
  const [allocationTableData, setallocationTableData] = useState(null);
  const [liquidRatePlot, setliquidRatePlot] = useState(null);

  const [fluidLayerOptions, setfluidLayerOptions] = useState([]);
  const [wellOptions, setwellOptions] = useState([]);
  const [connectedLayerOptions, setconnectedLayerOptions] = useState([]);

  const [selectedFluidLayer, setselectedFluidLayer] = useState(null);
  const [selectedWell, setselectedWell] = useState(null);
  const [connectedLayer, setconnectedLayer] = useState(null);

  const [encodedUri, setencodedUri] = useState('');

  // Helpers/Callbacks -->
  const renderGainValuePlot = (
    selectedWell,
    selectedLayer,
    layerFilteredDataset
  ) => {
    setnetworkPlot(
      generateGainValuePlot(
        layerFilteredDataset,
        gainValues,
        selectedLayer,
        selectedWell,
        analyzedWellType,
        workflowParameter[SYSTEM_OF_UNITS]
      )
    );
  };

  const renderCumPieChart = layerFilteredDataset => {
    setcumFluidPieChart(
      generateCumFluidPieChart(
        analyzedWellType == PRODUCER
          ? filterWellContentsFromDataset(layerFilteredDataset).producers
          : filterWellContentsFromDataset(layerFilteredDataset).injectors,
        analyzedWellType
      )
    );
  };

  const renderContributionPieChart = (
    selectedWell,
    selectedLayer,
    layerFilteredDataset
  ) => {
    setcontributionPieChart(
      generateContributionPieChart(
        filterDatasetContentByLayer(gainValues, selectedLayer),
        selectedWell,
        analyzedWellType == PRODUCER
          ? filterWellNamesFromDataset(layerFilteredDataset).injectors
          : filterWellNamesFromDataset(layerFilteredDataset).producers,
        analyzedWellType
      )
    );
  };

  const renderAllocationDataTable = (
    selectedWell,
    selectedLayer,
    layerFilteredDataset
  ) => {
    setallocationTableData(
      generateNetAllocationTableData(
        selectedWell,
        analyzedWellType,
        selectedLayer,
        layerFilteredDataset,
        gainValues
      )
    );
  };

  const renderFluidRatePlot = (selectedWell, connectedLayers) => {
    switch (analyzedWellType) {
      case PRODUCER:
        setfluidRatePlot(
          generateFluidProductionPlot(
            selectedWell,
            filteredDatasetContent.filter(row => row[WELL_ID] == selectedWell),
            liquidRateHistory.filter(row => row[PRODUCER] == selectedWell),
            liquidRateAll.filter(row => row[PRODUCER] == selectedWell),
            connectedLayers,
            workflowParameter
          )
        );
        break;
      case INJECTOR:
        setfluidRatePlot(
          generateFluidInjectionPlot(
            selectedWell,
            filteredDatasetContent.filter(row => row[WELL_ID] == selectedWell),
            workflowParameter
          )
        );
        break;
    }
  };

  const renderLiquidRatePlot = () => {
    if (
      analyzedWellType == PRODUCER &&
      fieldLayerData != null &&
      fieldData != null
    ) {
      setliquidRatePlot(
        generateLayeredRatePlot(
          fieldLayerData,
          fieldData,
          workflowParameter,
          LIQUID
        )
      );
    }
  };

  /**
   * This function generates all of the plots and datasets of the fluid matching report.
   *
   * @param {Stirng} fluidLayer
   * @param {String} well
   * @param {String} connectedLayer
   * @param {Array} layerFilteredDataset
   */
  const renderAllPlots = (selectedLayer, selectedWell, connectedLayers) => {
    const layerFilteredDataset = filterDatasetContentByLayer(
      filteredDatasetContent,
      selectedLayer
    );

    renderGainValuePlot(selectedWell, selectedLayer, layerFilteredDataset);
    renderCumPieChart(layerFilteredDataset);
    renderContributionPieChart(
      selectedWell,
      selectedLayer,
      layerFilteredDataset
    );
    renderAllocationDataTable(
      selectedWell,
      selectedLayer,
      layerFilteredDataset
    );
    renderFluidRatePlot(selectedWell, connectedLayers);
  };
  // <-- Helpers/Callbacks

  // Handlers -->
  /**
   * Event handler for Fluid Layer selection. All Layer option enables user to select any well from
   * @prop {analyzedWellNames}, and finds all connected layer of this well.
   *
   * @param {Event} event
   */
  const onLayerSelect = event => {
    let selectedLayer = event.target.value;
    let layerFilteredWells;
    let connectedLayerOptions;

    switch (selectedLayer) {
      case ALL_LAYERS:
        layerFilteredWells = analyzedWellNames;
        connectedLayerOptions = findConnectedLayersOfWell(
          filteredDatasetContent,
          layerFilteredWells[0],
          gainValues
        );

        // render all plots accordingly.
        renderAllPlots(
          connectedLayerOptions[0],
          layerFilteredWells[0],
          connectedLayerOptions
        );
        break;
      default:
        layerFilteredWells =
          analyzedWellType == INJECTOR
            ? filterWellNamesByLayer(filteredDatasetContent, selectedLayer)
                .injectors
            : filterWellNamesByLayer(filteredDatasetContent, selectedLayer)
                .producers;
        connectedLayerOptions = findConnectedLayersOfWell(
          filteredDatasetContent,
          layerFilteredWells[0],
          gainValues
        );

        // render all plots accordingly.
        renderAllPlots(
          selectedLayer,
          layerFilteredWells[0],
          connectedLayerOptions
        );
        break;
    }
    // reset all state vars. accordingly.
    setselectedFluidLayer(selectedLayer);
    setwellOptions(layerFilteredWells);
    setselectedWell(layerFilteredWells[0]);
    setconnectedLayerOptions(connectedLayerOptions);
    setconnectedLayer(connectedLayerOptions[0]);
  };

  const onWellSelect = event => {
    let connectedLayers = [...connectedLayerOptions];
    let selectedLayer = selectedFluidLayer;
    let layerFilteredDataset;

    // If All layers are considered, reset connected layers since selected well might have new connections.
    if (selectedFluidLayer == ALL_LAYERS) {
      connectedLayers = findConnectedLayersOfWell(
        filteredDatasetContent,
        event.target.value,
        gainValues
      );
      selectedLayer = connectedLayers[0];
      setconnectedLayerOptions(connectedLayers);
      setconnectedLayer(connectedLayers[0]);
    }

    layerFilteredDataset = filterDatasetContentByLayer(
      filteredDatasetContent,
      selectedLayer
    );
    setselectedWell(event.target.value);
    // Render plots and tables that depend on well selection.
    renderGainValuePlot(
      event.target.value,
      selectedLayer,
      layerFilteredDataset
    );
    renderContributionPieChart(
      event.target.value,
      selectedLayer,
      layerFilteredDataset
    );
    renderAllocationDataTable(
      event.target.value,
      selectedLayer,
      layerFilteredDataset
    );
    renderFluidRatePlot(event.target.value, connectedLayers);
  };

  const onConnectedLayerSelect = event => {
    setconnectedLayer(event.target.value);
    renderAllPlots(event.target.value, selectedWell, connectedLayerOptions);
  };

  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  /**
   * This effect initializes dropdown options and their selection, and renders all of the plots.
   */
  useEffect(() => {
    if (
      filteredDatasetContent != null &&
      gainValues != null &&
      r2History != null &&
      analyzedWellNames != null &&
      analyzedWellType != null &&
      layers != null &&
      workflowParameter != null
    ) {
      // Find connected layers for the first well.
      const connectedLayers = findConnectedLayersOfWell(
        filteredDatasetContent,
        analyzedWellNames[0],
        gainValues
      );

      setfluidLayerOptions(layers);
      setwellOptions(analyzedWellNames);
      setconnectedLayerOptions(connectedLayers);

      setselectedFluidLayer(layers[0]);
      setselectedWell(analyzedWellNames[0]);
      setconnectedLayer(connectedLayers[0]);

      renderAllPlots(connectedLayers[0], analyzedWellNames[0], connectedLayers);
      renderLiquidRatePlot();
    }
  }, [
    filteredDatasetContent,
    gainValues,
    r2History,
    liquidRateHistory,
    liquidRateAll,
    analyzedWellNames,
    analyzedWellType,
    layers,
    workflowParameter,
    fieldLayerData,
    fieldData,
  ]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent} id={header}>
        <Grid item className={styles.visualContentRow}>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item container className={styles.inputContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <Select value={selectedFluidLayer} onChange={onLayerSelect}>
              {fluidLayerOptions.map(layer => (
                <MenuItem key={layer} value={layer}>
                  {layer}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Fluid Layer</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <Select value={selectedWell} onChange={onWellSelect}>
              {wellOptions.map(well => (
                <MenuItem key={well} value={well}>
                  {well}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Well</FormHelperText>
          </FormControl>
          {selectedFluidLayer == ALL_LAYERS ? (
            <FormControl
              variant="outlined"
              size="small"
              className="expandInputElement"
            >
              <Select value={connectedLayer} onChange={onConnectedLayerSelect}>
                {connectedLayerOptions.map(layer => (
                  <MenuItem key={layer} value={layer}>
                    {layer}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Connected Layer</FormHelperText>
            </FormControl>
          ) : (
            ''
          )}
        </Grid>
        {networkPlot ? (
          <PlotlyComponent
            data={networkPlot.data}
            layout={networkPlot.layout}
            config={networkPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage message="Network plot is loading..." goHome={false} />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Grid container>
          <Grid item xs={6}>
            {cumFluidPieChart ? (
              <PlotlyComponent
                data={cumFluidPieChart.data}
                layout={cumFluidPieChart.layout}
                config={cumFluidPieChart.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message={`Cumulative Fluid ${analyzedWellType} chart is loading...`}
                goHome={false}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {contributionPieChart ? (
              <PlotlyComponent
                data={contributionPieChart.data}
                layout={contributionPieChart.layout}
                config={contributionPieChart.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message={`${analyzedWellType} Contribution chart is loading...`}
                goHome={false}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        {allocationTableData ? (
          <React.Fragment>
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable data={allocationTableData} />
            </Paper>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Allocation Data
                </Typography>
                <Link
                  download="Allocation_Data.csv"
                  href={encodedUri}
                  onClick={() => onDownload(allocationTableData)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <LoadingPage
            message={`${analyzedWellType} Allocation table is loading...`}
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        {fluidRatePlot ? (
          <PlotlyComponent
            data={fluidRatePlot.data}
            layout={fluidRatePlot.layout}
            config={fluidRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message={`Fluid ${analyzedWellType} Rate plot is loading...`}
            goHome={false}
          />
        )}
      </Grid>
      {analyzedWellType == PRODUCER && (
        <Grid container className={styles.visualContent}>
          {liquidRatePlot ? (
            <PlotlyComponent
              data={liquidRatePlot.data}
              layout={liquidRatePlot.layout}
              config={liquidRatePlot.config}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <LoadingPage
              message={`Fluid ${analyzedWellType} Rate plot is loading...`}
              goHome={false}
            />
          )}
        </Grid>
      )}
      <Grid container className={styles.visualContentFlex}>
        {gainValues ? (
          <React.Fragment>
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable data={gainValues} />
            </Paper>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">Download Gain Values</Typography>
                <Link
                  download="Gain_Values.csv"
                  href={encodedUri}
                  onClick={() => onDownload(gainValues)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid container className={styles.visualContentRow}>
            <LoadingPage
              message="Gain Value table is loading..."
              goHome={false}
            />
          </Grid>
        )}
        {analyzedWellType == PRODUCER &&
          (r2History ? (
            <React.Fragment>
              <Paper elevation={5} className={styles.visualContentRow}>
                <BasicTable data={r2History} />
              </Paper>
              <Grid container className={styles.visualContentRow}>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="overline">Download R2</Typography>
                  <Link
                    download="R2_History.csv"
                    href={encodedUri}
                    onClick={() => onDownload(r2History)}
                  >
                    <IconButton>
                      <GetAppRoundedIcon />
                    </IconButton>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item className={styles.visualContentRow}>
              <LoadingPage message="R2 table is loading..." goHome={false} />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
};

FluidMatching.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowParameter: PropTypes.object,
  gainValues: PropTypes.array,
  r2History: PropTypes.array,
  liquidRateHistory: PropTypes.array,
  liquidRateAll: PropTypes.array,
  analyzedWellNames: PropTypes.array,
  analyzedWellType: PropTypes.string,
  layers: PropTypes.array,
  header: PropTypes.string,
  fieldLayerData: PropTypes.array,
  fieldData: PropTypes.array,
};

export default FluidMatching;
