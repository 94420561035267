import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
  },
  navbarGrid: {
    height: '15%',
    width: '100%',
  },
  bodyGrid: {
    height: '85%',
    width: '100%',
  },
  sideBarGrid: {
    display: 'flex',
    width: '20%',
    height: '100%',
    padding: '1em',
    '& a': {
      color: 'black',
      '&:hover': {
        color: 'black',
        textDecoration: 'none',
      },
    },
    '& hr': {
      border: '1px solid grey',
    },
  },
  sidebarHeader: {
    fontSize: '1.5em',
    textAlign: 'center',
  },
  argumentGrid: {
    display: 'flex',
    width: '80%',
    height: '100%',
    padding: '1em',
  },
  paperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  argumentConainer: {
    width: '100%',
    height: '90%',
  },
  footer: {
    width: '100%',
    height: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& #Help': {
      borderRadius: '2em',
      textTransform: 'capitalize',
      margin: '1%',
    },
  },
  flexCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: '5%',
    borderRadius: '2em',
    textTransform: 'capitalize',
    '&.largeButton': {
      width: '20%',
    },
    '&.xlargeButton': {
      width: '25%',
    },
    '&.selectedButton': {
      backgroundColor: '#000B46',
      color: 'white',
    },
  },
});

export default useStyles;
