import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { BasicTableWithDynamicColumns } from './TableViewDynamicHeader';

import { Grid, Paper, Typography } from '@material-ui/core';

const FieldSummary = ({ headers, fieldSum }) => {
  const styles = useStyles();
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    if (fieldSum) {
      setTableData(fieldSum);
    }
  }, [fieldSum]);

  return (
    <Grid
      container
      className={styles.visualContentFlex}
      id={headers.FIELD_SUMMARY}
    >
      <Grid item style={{ margin: '0% 5%' }}>
        <Typography>{headers.FIELD_SUMMARY}</Typography>
      </Grid>
      <Paper elevation={5} className={styles.visualContentRow}>
        <BasicTableWithDynamicColumns data={tableData} />
      </Paper>
    </Grid>
  );
};

export default FieldSummary;

FieldSummary.propTypes = {
  headers: PropTypes.object,
  fieldSum: PropTypes.array,
};
