import {
  SOI,
  FMM,
  RATE,
  CRMP,
  CRMT,
  WATER,
  OIL_CUT,
  OIL_RATE,
  WATER_CUT,
  FLUID_RATE,
  CRMP_LAYER,
  GREEN_FIELD,
  BROWN_FIELD,
  // CONFORMANCE,
  HYBRID_MODEL,
  POLY_INJECTION,
  TREND_ANALYSIS,
  ML_CONNECTIVITY,
  DIAGNOSTIC_PLOT,
  CROSS_CORRELATION,
  ML_RATE_PREDICTION,
  ARPS_DECLINE_CURVE,
  TUBING_HEAD_PRESSURE,
  CUMULATIVE_GAS_INJECTION,
  CROSS_WORKFLOW_COMPARISON,
  MULTI_WORKFLOW_COMPARISON,
  CUMULATIVE_OIL_PRODUCTION,
  CUMULATIVE_GAS_PRODUCTION,
  CUMULATIVE_WATER_INJECTION,
  CUMULATIVE_WATER_PRODUCTION,
} from './WellConstants';

import CRMP_Layer from '../views/Surveillance/Workflow_Models/CRMP_Layer';
import CRMP_Report from '../views/Surveillance/Workflow_Models/CRMP_Updated';
import CRMT_Report from '../views/Surveillance/Workflow_Models/CRMT';
import PolyInjection from '../views/Surveillance/Workflow_Models/PolyInjection';
import TrendAnalysis from '../views/Surveillance/Workflow_Models/TrendAnalysis';
import DiagnosticPlot from '../views/Surveillance/Workflow_Models/DiagnosticPlot';
import MLConnectivity from '../views/Surveillance/Workflow_Models/MLConnectivity';
// import BaseHessReport from '../views/Integration/WorkflowModels/HessBaseWorkflow';
// import GreenFieldReport from '../views/Integration/WorkflowModels/GreenField';
// import BrownFieldReport from '../views/Integration/WorkflowModels/BrownField';
import CrossCorrelation from '../views/Surveillance/Workflow_Models/CrossCorrelation';
import ArpsDeclineReport from '../views/Surveillance/Workflow_Models/ArpsDecline';
import ML_Rate_Prediction from '../views/Surveillance/Workflow_Models/ML_Rate_Prediction';
import CrossComparisonReport from '../views/Surveillance/Workflow_Models/CrossWorkflowComparison';
import MultiWorkflowComparison from '../views/Surveillance/Workflow_Models/MultiWorkflowComparison';

import MemoryIcon from '@material-ui/icons/Memory';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TrackChangesRoundedIcon from '@material-ui/icons/TrackChangesRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

export const WORKFLOW_STATUSES = {
  SUBMITTED: 'Submitted',
  IN_PROGRESS: 'In_Progress',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
};

export const WORKFLOW_STATUS_COLORS = {
  [WORKFLOW_STATUSES.SUBMITTED]: '#457b9d',
  [WORKFLOW_STATUSES.IN_PROGRESS]: '#f4a261',
  [WORKFLOW_STATUSES.COMPLETED]: '#55a630',
  [WORKFLOW_STATUSES.FAILED]: '#e63946',
};

export const WORKFLOW_STATUS_ICONS = {
  [WORKFLOW_STATUSES.SUBMITTED]: (
    <PlaylistAddCheckRoundedIcon className="icon" />
  ),
  [WORKFLOW_STATUSES.IN_PROGRESS]: (
    <HourglassEmptyRoundedIcon className="icon" />
  ),
  [WORKFLOW_STATUSES.COMPLETED]: (
    <CheckCircleOutlineRoundedIcon className="icon" />
  ),
  [WORKFLOW_STATUSES.FAILED]: <ErrorOutlineRoundedIcon className="icon" />,
};

export const WORKSPACE_LIST_HEADER_NAMES = {
  Recent: 'Recent',
  Surveillance: 'Surveillance',
  Integration: 'Integration',
};

export const WORKSPACE_LIST_HEADER_ICONS = {
  Recent: <AccessTimeIcon />,
  Surveillance: <TrackChangesRoundedIcon />,
  Integration: <MemoryIcon />,
};

export const INTEGRATION_DATASET_VISUALIZATION_HEADER_NAMES = {
  // FIELD_MAP: 'Field Map',
  ANALYSIS_MODEL: 'Analysis Model Map',
  // OPTIMIZATION: 'Optimization Map',
  // SIMULATION: 'Simulation Map',
  CHOOSE_WORKFLOW: 'Choose Workflow',
};

export const SURVEILLANCE_DATASET_VISUALIZATION_HEADER_NAMES = {
  WELLS: 'Wells',
  SCHEDULE_PLOT: 'Schedule Plot',
  FIELD_ANALYSIS: 'Field Analysis',
  PRODUCTION: 'Production',
  INJECTION: 'Injection',
  CUSTOMIZABLE_PLOT: 'Customizable Plot',
  FIELD_RES_ANALYSIS: 'Field Reservoir Analysis',
  FIELD_FLUID_RES_RATE: 'Field Fluid Reservoir Rate',
  VRR: 'Voidage Replacement Ratio',
};

export const ARPS_DECLINE_CURVE_LIST_HEADER_NAMES = {
  OIL_RATE: [OIL_RATE] + ' ' + [RATE],
  CURVE_ANALYSIS: 'Curve Analysis - Oil',
  CURVE_ANALYSIS_FUTURE: 'Future Curve Analysis - Oil',
};

const CROSS_CORR_LIST_HEADER_NAMES = {
  CONFIGURATION: 'Configuration',
  FIELD_MAP: 'Field Map',
  PRODUCER: 'Producer',
  INJECTOR: 'Injector',
  CORRELATION_LAG: 'Correlation vs Lag',
  WELL_PAIR_ANALYSIS: 'Well Pair Feature Analysis',
  WELL_PAIR_CORRELATIONS: 'Well Pair Correlation Values',
};

const ML_CONN_LIST_HEADER_NAMES = {
  // CONFIGURATION: 'Configuration',
  FIELD_MAP: 'Field Map',
  INJECTOR: 'Injector',
  PRODUCER: 'Producer',
  // SCORE_VARIATION_WITH_LAG: 'Score Variation with Lag',
  WELL_PAIR_ANALYSIS: 'Well Pair Feature Analysis',
  PRODUCER_PERFORMANCE_EVALUATION: 'Producer Performance Evaluation',
  RELATION_HEATMAP: 'Relation Heatmap',
};

const TREND_ANALYSIS_LIST_HEADER_NAMES = {
  ProductionCut: 'Production Cut',
  ClusterView: 'Cluster View',
  Timeline: 'Timeline',
  OverallAnalysis: 'Overall Analysis',
};

const CROSS_COMPARISON_LIST_HEADER_NAMES = {
  MODEL_ANALYSES: 'Model Well Analyses',
  PRODUCER: 'Producer Wells Comparison',
  INJECTOR: 'Injector Wells Comparison',
};

const CRMP_LIST_HEADER_NAMES = {
  // CONFIGURATION: 'Configuration',
  STATIC_GAIN_VALUES: 'Static Gain Values',
  DYNAMIC_GAIN_VALUES: 'Dynamic Gain Values',
  INJECTOR: 'Injector',
  PRODUCER: 'Producer',
  GAIN_VALUES: 'Gain Values',
  // FIELD_ANALYSIS: 'Field Analysis',
  FLUID_PRODUCTION: 'Fluid Production',
  // OVERALL_ANALYSIS: 'Overall Analysis',
  OIL_MATCHING: 'Oil Matching',
  OPTIMIZATION: 'Optimization',
};

const MULTI_WORKFLOW_COMPARISON_LIST_HEADER_NAMES = {
  CONFIGURATIONS: 'Configurations',
  WELL_GAIN_VALUES: 'Well Gain Values',
  RESULT_THRESHOLD_ACCURACY: 'Result Files Threshold Accuracy',
};

const CRMT_LIST_HEADER_NAMES = {
  CONFIGURATION: 'Configuration',
  FIELD_FLUID_INJ_PROD: 'Field Fluid Injection & Production',
};

const ML_RATE_PREDICTION_LIST_HEADER_NAMES = {
  SUMMARY_TABLE: 'Summary Table',
  HISTORY_MATCHING: 'History Matching',
  PRODUCTION_RATE_TESTING: 'Production Rate Testing',
  FUTURE_PREDICTION: 'Future Prediction',
  OVERALL_ANALYSIS: 'Overall Analysis',
};

const DIAGNOSTIC_PLOT_LIST_HEADER_NAMES = {
  Plot: 'Plot',
  FieldLiquidInjectionRate: 'Field Liquid/Injection Rate',
  FieldRatio: 'Field Ratio',
  LiquidInjectionProductionRate: 'Liquid Injection/Production Rate',
  PlotComparisson: 'Plot Comparrison',
};

const CRMP_LAYER_LIST_HEADER_NAMES = {
  LayerBasedGainValues: 'Field Gain Values',
  ProductionFluidMatching: 'Total Fluid Matching - Production Well Analysis',
  InjectionFluidMatching: 'Total Fluid Matching - Injection Well Analysis',
  OilMatching: 'Oil Matching',
  FieldAnalysis: 'Field Analysis',
  FieldResultAnalysis: 'Field Result Analysis',
  InjEfficiencyAnalysis: 'Injector Efficiency Analysis',
  // Optimization: 'Optimization',
};

const POLY_INJECTION_LIST_HEADER_NAMES = {
  OIL_MATCHING: 'Oil Matching',
  WATER_MATCHING: 'Water Matching',
  R2_VALUES: 'R2 Values',
  INIT_OIL_SAT: 'Initial Oil Saturation',
  CURRENT_OIL_SAT: 'Current Oil Saturation',
  ADDITIONAL_RECOVERY_MAP: 'Additional Recovery Map',
  FIELD_SUMMARY: 'Field Summary',
  PRODUCER_SUMMARY_TABLE: 'Producer Summary',
  INJECTOR_SUMMARY_TABLE: 'Injector Summary',
};

const GREEN_FIELD_LIST_HEADER_NAMES = {
  SurfaceVisualization: '3D Surface Visual',
  HybridModel: 'Hybrid Model',
};

const BROWN_FIELD_LIST_HEADER_NAMES = {
  SurfaceVisualization: '3D Surface Visual',
  HybridModel: 'Hybrid Model',
};

const SOI_LIST_HEADER_NAMES = {
  LayerAnalysis: 'Layer Analysis',
  Optimization: 'Optimization',
  Simulation: 'Simulation',
};

const FMM_LIST_HEADER_NAMES = {
  LayerAnalysis: 'Layer Analysis',
  Optimization: 'Optimization',
  Simulation: 'Simulation',
};

const HYBRID_MODEL_LIST_HEADER_NAMES = {
  LayerAnalysis: 'Layer Analysis',
  Optimization: 'Optimization',
  Simulation: 'Simulation',
};

export const CROSS_CORR_INJECTOR_COLS = [
  FLUID_RATE,
  'Water',
  TUBING_HEAD_PRESSURE,
];

export const CROSS_CORR_PRODUCER_COLS = [
  FLUID_RATE,
  'Water',
  OIL_RATE,
  TUBING_HEAD_PRESSURE,
  'Water_Cut',
];

export const ML_CONN_INJECTOR_COLS = [FLUID_RATE, WATER];

export const ML_CONN_PRODUCER_COLS = [
  FLUID_RATE,
  WATER,
  OIL_RATE,
  WATER_CUT,
  OIL_CUT,
];

export const PLOT_TYPE_OBJECTS = [
  {
    title: 'X Plot',
    xAxisName: 'Np',
    yAxisName: 'X',
    xColName: 'No_f',
    yColName: 'X_f',
  },
  {
    title: 'Y Plot',
    xAxisName: 'Cumulative Liquid Production',
    yAxisName: 'Y-fofw',
    xColName: 'N_f',
    yColName: 'Y_f',
  },
  {
    title: 'WOR vs Np',
    xAxisName: 'Np',
    yAxisName: 'WOR',
    xColName: 'No_f',
    yColName: 'WOR_f',
  },
  {
    title: 'Oil Cut vs Np',
    xAxisName: 'Np',
    yAxisName: 'Oil Cut - f',
    xColName: 'No_f',
    yColName: 'fo_f',
  },
  {
    title: 'WOR vs Time',
    xAxisName: 'Date',
    yAxisName: 'WOR',
    xColName: 'Date',
    yColName: 'WOR_f',
  },
  {
    title: 'Movable Oil Plot',
    xAxisName: 'Np/qo',
    yAxisName: '1/qo',
    xColName: 'qo_f',
    yColName: 'No_f',
  },
];

export const CUM_PLOT_TITLES = [
  CUMULATIVE_OIL_PRODUCTION,
  CUMULATIVE_WATER_PRODUCTION,
  CUMULATIVE_GAS_PRODUCTION,
  CUMULATIVE_WATER_INJECTION,
  CUMULATIVE_GAS_INJECTION,
];

export const WORKFLOW_COMPONENTS = {
  // Surveillance Models -->
  [ARPS_DECLINE_CURVE]: <ArpsDeclineReport />,
  [CROSS_CORRELATION]: <CrossCorrelation />,
  [ML_RATE_PREDICTION]: <ML_Rate_Prediction />,
  [DIAGNOSTIC_PLOT]: <DiagnosticPlot />,
  [ML_CONNECTIVITY]: <MLConnectivity />,
  [TREND_ANALYSIS]: <TrendAnalysis />,
  // [CONFORMANCE]: <Conformance />,
  [CRMP_LAYER]: <CRMP_Layer />,
  [CRMP]: <CRMP_Report />,
  [CRMT]: <CRMT_Report />,
  [MULTI_WORKFLOW_COMPARISON]: <MultiWorkflowComparison />,
  [CROSS_WORKFLOW_COMPARISON]: <CrossComparisonReport />,
  [POLY_INJECTION]: <PolyInjection />,
  // <-- Surveillance Models
  // Integration Models -->
  // [GREEN_FIELD]: <GreenFieldReport headers={GREEN_FIELD_LIST_HEADER_NAMES} />,
  // [BROWN_FIELD]: <BrownFieldReport headers={BROWN_FIELD_LIST_HEADER_NAMES} />,
  // [SOI]: <BaseHessReport headers={SOI_LIST_HEADER_NAMES} />,
  // [FMM]: <BaseHessReport headers={FMM_LIST_HEADER_NAMES} />,
  // [HYBRID_MODEL]: <BaseHessReport headers={HYBRID_MODEL_LIST_HEADER_NAMES} />,
  // <-- Integration Models
};

export const LIST_HEADERS_BY_WORKFLOW_TYPE = {
  // Surveillance Models
  [ARPS_DECLINE_CURVE]: ARPS_DECLINE_CURVE_LIST_HEADER_NAMES,
  [CROSS_CORRELATION]: CROSS_CORR_LIST_HEADER_NAMES,
  [ML_RATE_PREDICTION]: ML_RATE_PREDICTION_LIST_HEADER_NAMES,
  [DIAGNOSTIC_PLOT]: DIAGNOSTIC_PLOT_LIST_HEADER_NAMES,
  [ML_CONNECTIVITY]: ML_CONN_LIST_HEADER_NAMES,
  [TREND_ANALYSIS]: TREND_ANALYSIS_LIST_HEADER_NAMES,
  // [CONFORMANCE]: CONFORMANCE_LIST_HEADER_NAMES,
  [CRMP_LAYER]: CRMP_LAYER_LIST_HEADER_NAMES,
  [CRMP]: CRMP_LIST_HEADER_NAMES,
  [CRMT]: CRMT_LIST_HEADER_NAMES,
  [CROSS_WORKFLOW_COMPARISON]: CROSS_COMPARISON_LIST_HEADER_NAMES,
  [MULTI_WORKFLOW_COMPARISON]: MULTI_WORKFLOW_COMPARISON_LIST_HEADER_NAMES,
  [POLY_INJECTION]: POLY_INJECTION_LIST_HEADER_NAMES,
  // Integration Models
  [GREEN_FIELD]: GREEN_FIELD_LIST_HEADER_NAMES,
  [BROWN_FIELD]: BROWN_FIELD_LIST_HEADER_NAMES,
  [SOI]: SOI_LIST_HEADER_NAMES,
  [FMM]: FMM_LIST_HEADER_NAMES,
  [HYBRID_MODEL]: HYBRID_MODEL_LIST_HEADER_NAMES,
};

export const HELP_PAGE_LIST_HEADER_NAMES = {
  CROSS_CORRELATION,
  ML_CONNECTIVITY,
  // ML_RATE_PREDICTION,
  CRMP,
  // CRMT,
  // CONFORMANCE
};
