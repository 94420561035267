import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import {
  createTraceWithText,
  wrapPlots,
} from '../../../../Utils/PlotlyUtils/Plots';

import { YEAR, XCOL, YCOL, WELL_ID } from '../../../../constants/WellConstants';

import { Button, Grid } from '@material-ui/core';
import LoadingPage from '../../../Components/LoadingPage';

// FIXME:
//  Current cluster implementation is just showing the trace names, in the dash application, k-means
//  algorithm was being used. But Node.js limitaitons doesnt't allow such data science algorithms. Postponed
//  when the issue was brought up.
const ClusterPlot = ({ reportType, selectedYear, workflowAnalysisContent }) => {
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const [clusterView, setclusterView] = useState(false);
  const [plot, setplot] = useState(null);

  // This effect builds the Cluster Plot. It gets invoked each time user updates selected year, report type,
  // or  the cluster info.
  useEffect(() => {
    // Filter workflow analysis production data by selected year.
    const prodWellInfos = workflowAnalysisContent.filter(
      row => row[YEAR] == selectedYear
    );
    const colorScale = prodWellInfos.map(well => Number(well[reportType]));

    // create a trace for each production well based on well location, well name, specified report type,
    // and cluster selection.
    const traces = prodWellInfos.map(well =>
      createTraceWithText(
        [well[XCOL]],
        [well[YCOL]],
        well[WELL_ID],
        [Number(well[reportType])],
        undefined,
        `${WELL_ID}: ${well[WELL_ID]}<br />Yearly ${reportType}: ${well[reportType]}`,
        undefined,
        undefined,
        well[WELL_ID],
        !clusterView,
        undefined,
        undefined,
        clusterView
      )
    );

    // add color scale to traces.
    traces.forEach(trace => {
      trace['marker']['colorscale'] = colorScale;
      trace['marker']['cmin'] = Math.min(...colorScale);
      trace['marker']['cmax'] = Math.max(...colorScale);
    });

    setplot(wrapPlots(traces, XCOL, YCOL, `${reportType} in ${selectedYear}`));
  }, [reportType, selectedYear, clusterView]);

  return (
    <React.Fragment>
      <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={() => setclusterView(false)}>
          Base View
        </Button>
        <Button variant="outlined" onClick={() => setclusterView(true)}>
          Clusters
        </Button>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage message="Cluster Plot is Loading..." goHome={false} />
      )}
    </React.Fragment>
  );
};

ClusterPlot.propTypes = {
  reportType: PropTypes.string,
  selectedYear: PropTypes.number,
  workflowAnalysisContent: PropTypes.array,
};

export default ClusterPlot;
