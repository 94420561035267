import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';

import LoadingPage from '../../../../Components/LoadingPage';

import { generateLayerBasedGainValuePlot } from '../../../../../Utils/WorkflowReportUtils/CRMPLayerUtils';

import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { SYSTEM_OF_UNITS } from '../../../../../constants/WellConstants';

const FieldGainAnalysisPlot = ({
  filteredDatasetContent,
  workflowParameter,
  gainValues,
  layers,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [networkPlot, setnetworkPlot] = useState(null);
  const [selectedLayer, setselectedLayer] = useState(null);

  // Callbacks -->
  // <-- Callbacks

  // Handlers -->
  // <-- Handlers

  const onLayerSelect = event => {
    setselectedLayer(event.target.value);
    setnetworkPlot(
      generateLayerBasedGainValuePlot(
        filteredDatasetContent,
        gainValues,
        event.target.value,
        workflowParameter[SYSTEM_OF_UNITS]
      )
    );
  };

  useEffect(() => {
    if (filteredDatasetContent != null && gainValues != null && layers) {
      setselectedLayer(layers[0]);
      setnetworkPlot(
        generateLayerBasedGainValuePlot(
          filteredDatasetContent,
          gainValues,
          layers[0],
          workflowParameter[SYSTEM_OF_UNITS]
        )
      );
    }
  }, [filteredDatasetContent, gainValues, layers]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent} id={header}>
        <Grid item className={styles.visualContentRow}>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item container className={styles.inputContentRow}>
          <FormControl
            variant="outlined"
            size="small"
            className="dropdownSelection"
          >
            <Select value={selectedLayer} onChange={onLayerSelect}>
              {layers &&
                layers.map(layer => (
                  <MenuItem key={layer} value={layer}>
                    {layer}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Layer</FormHelperText>
          </FormControl>
        </Grid>
        {networkPlot ? (
          <PlotlyComponent
            data={networkPlot.data}
            layout={networkPlot.layout}
            config={networkPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage message="Network plot is loading..." goHome={false} />
        )}
      </Grid>
    </React.Fragment>
  );
};

FieldGainAnalysisPlot.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowParameter: PropTypes.object,
  gainValues: PropTypes.array,
  layers: PropTypes.array,
  header: PropTypes.string,
};

export default FieldGainAnalysisPlot;
