import { reduxAction, getJWTToken } from '../base';
import { apiClient } from '../../../service/apiClient';
import * as workflowApi from '../../../API/Routes/workflows';
import {
  convertToCSVArray,
  unzipFiles,
} from '../../../Utils/DatasetUtils/DataProcessing';
import { updateSnackBar } from '../feedback';
import {
  SET_ALL_WORKFLOWS,
  ADD_WORKFLOW,
  REMOVE_WORKFLOW,
  UPDATE_WORKFLOW,
  SELECT_WORKFLOW,
} from './types';
import { SNACK_BAR_SEVERITY } from '../../../constants/ComponentConstants';

/**
 * Redux thunk function that retrieves all of the workflows of the logged user. Upon success, it dispatches
 * redux action that saves these workflows to redux state.
 *
 * @returns
 */
export const getAllWorkflows = () => async dispatch => {
  let response = await apiClient({
    method: 'GET',
    url: workflowApi.getAllWorkflowsRoute(),
    headers: getJWTToken(),
  });
  dispatch(reduxAction(SET_ALL_WORKFLOWS, response.data));
};

/**
 * Redux thunk function that retrieves all workflows, compares it with the redux state, and if there is an update
 * on workflow statuses, it updates the individual workflows in the redux state, rathen than replacing the whole.
 *
 * @param {Array} currentState - Current state to compare with the server.
 *
 * @returns
 *
 */
export const getWorkflowStatusUpdates = currentState => async dispatch => {
  let response = await apiClient({
    method: 'GET',
    url: workflowApi.getAllWorkflowsRoute(),
    headers: getJWTToken(),
  });

  let workflows = response.data;
  let workflowsToUpdate = [];

  // Compare workflows with the current state.
  workflows.forEach(workflow => {
    let currentWorkflow = currentState.find(
      w => w.workflow_id == workflow.workflow_id
    );
    if (currentWorkflow) {
      if (currentWorkflow.status != workflow.status) {
        workflowsToUpdate.push(workflow);
      }
    }
  });

  // Update workflows in redux state.
  if (workflowsToUpdate.length > 0) {
    workflowsToUpdate.forEach(workflow => {
      dispatch(reduxAction(UPDATE_WORKFLOW, workflow));
    });
  }
};

/**
 * Redux thunk function that adds user defined workflow to specified dataset in the server.
 * Upon success, it dispathces redux action that saves given workflow object, and makes a
 * start_workflow request to the server.
 *
 * @param {Number} dataset_id
 * @param {Object} workflow
 * @returns
 */
export const addWorkflow = (dataset_id, workflow) => async dispatch => {
  // Send workflow object to server to save it.
  try {
    let addWorkflowResponse = await apiClient({
      method: 'POST',
      url: workflowApi.createWorkflowRoute(dataset_id),
      headers: getJWTToken(),
      data: workflow,
    });
    dispatch(reduxAction(ADD_WORKFLOW, addWorkflowResponse.data));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while adding workflow',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw error;
  }
};

/**
 * Redux thunk function that removes specified workflow from server. Upon succes it dispathces redux action that
 * removes specifed workflow from redux state.
 *
 * @param {*} workflow_id
 * @returns
 */
export const removeWorkflow = workflow_id => async dispatch => {
  try {
    await apiClient({
      method: 'DELETE',
      url: workflowApi.deleteWorkflowRoute(workflow_id),
      headers: getJWTToken(),
    });
    dispatch(reduxAction(REMOVE_WORKFLOW, workflow_id));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while removing workflow',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
  }
};

/**
 * Redux thunk function that updates specified workflow in the server. Upon succes it dispathces redux action that
 * updates specifed workflow from redux state.
 *
 * @param {*} workflow_id
 * @returns
 */
export const updateWorkflow = (workflow_id, payload) => async dispatch => {
  try {
    const updatedWorkflow = await apiClient({
      method: 'PUT',
      url: workflowApi.updateWorkflowRoute(workflow_id),
      headers: getJWTToken(),
      data: payload,
    });
    dispatch(reduxAction(UPDATE_WORKFLOW, updatedWorkflow));
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while updating workflow',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw error;
  }
};

/**
 * Redux thunk function that updates specified workflow in the server. Upon succes it dispathces redux action that
 * updates specifed workflow from redux state.
 *
 * @param {*} workflow_id
 * @returns
 */
export const getProdAnalysisPrediction =
  (workflow_id, payload) => async dispatch => {
    try {
      let response = await apiClient({
        method: 'PUT',
        url: workflowApi.getProdAnalysisPredictionRoute(workflow_id),
        headers: getJWTToken(),
        data: payload,
      });
      dispatch(updateSnackBar('Success', SNACK_BAR_SEVERITY.success));
      return response;
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while retrieving Data.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
      throw error;
    }
  };

/**
 * Basic redux action creator that sets the selected workflow on redux state.
 * @param {Object} workflow
 * @returns
 */
export const setSelectedWorkflow = workflow => {
  return reduxAction(SELECT_WORKFLOW, workflow);
};

/**
 * This function will retrieve zipped file from the API, and unzip each file and return them in a object
 * structure.
 *
 * @param {Number} workflowId
 * @param {Object} fileNameCsvParams  - CSV Paramenters to convert retrieved file data properly. (More info. at documentation)
 * @returns
 */
export const retrieveZippedWorkflowContent =
  (workflowId, fileNameCsvParams = {}) =>
  async dispatch => {
    try {
      if (workflowId) {
        let response = await apiClient({
          method: 'GET',
          url: workflowApi.getWorkflowResult(workflowId),
          headers: getJWTToken(),
          responseType: 'arraybuffer',
        });
        let result = await unzipFiles(response.data, fileNameCsvParams);
        return result;
      }
      throw new Error(`Invalid workflow id: "${workflowId}"`);
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while retrieving workflow results.',
          SNACK_BAR_SEVERITY.error
        )
      );
      throw error;
    }
  };

/**
 * This function will retrieve workflow parameters from the API.
 *
 * @param {Number} workflowId
 * @returns {Object}
 */
export const retrieveWorkflowParameters = workflowId => async dispatch => {
  try {
    if (workflowId) {
      let response = await apiClient({
        method: 'GET',
        url: workflowApi.getWorkflowParameters(workflowId),
        headers: getJWTToken(),
      });
      return Object.entries(response.data).length > 0 ? response.data : {};
    }
    throw new Error(`Invalid workflow id: "${workflowId}"`);
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while retrieving workflow parameters',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw error;
  }
};

export const getWorkflowNPVScore = workflow_id => async dispatch => {
  try {
    let response = await apiClient({
      method: 'POST',
      url: workflowApi.retrieveWorkflowNPVScore(workflow_id),
      headers: getJWTToken(),
    });
    return response;
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while retrieving NPV Score.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw error;
  }
};

export const postOptimizationParameters =
  (workflow_id, parameters) => async dispatch => {
    try {
      let response = await apiClient({
        method: 'POST',
        url: workflowApi.postOptimizationParameters(workflow_id),
        headers: getJWTToken(),
        data: { parameters },
      });
      return response;
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while retrieving optimization results.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
      throw error;
    }
  };

export const postSimulationParameters =
  (workflow_id, parameters) => async dispatch => {
    try {
      let response = await apiClient({
        method: 'POST',
        url: workflowApi.postSimulationParameters(workflow_id),
        headers: getJWTToken(),
        data: { parameters },
      });
      return response;
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while retrieving simulation results.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
      throw error;
    }
  };

export const retrieveIncrementalFGPTValues = workflow_id => async dispatch => {
  try {
    let response = await apiClient({
      method: 'GET',
      url: workflowApi.getIncrementalFGPTUrl(workflow_id),
      headers: getJWTToken(),
    });
    let result = await convertToCSVArray(response.data, {}, false);
    return result;
  } catch (error) {
    try {
      dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
    } catch (error) {
      dispatch(
        updateSnackBar(
          'Unknown error occurred while retrieving simulation results.',
          SNACK_BAR_SEVERITY.error
        )
      );
    }
    throw error;
  }
};

// FIXME: Redundant implementation. Should be removed after implementing integration workflow results.
export const getSimulationNorneDataCSV =
  (propertyFile, csvParams = {}) =>
  async dispatch => {
    try {
      let response = await apiClient({
        method: 'GET',
        url: workflowApi.getSimulationNorneDataCSV(propertyFile),
        headers: getJWTToken(),
      });
      let result = await convertToCSVArray(response.data, csvParams, false);
      return result;
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while retrieving simulation results.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
      throw error;
    }
  };
// FIXME: Redundant implementation. Should be removed after implementing integration workflow results.
export const getSimulationResultCSV =
  (propertyFile, csvParams) => async dispatch => {
    try {
      let response = await apiClient({
        method: 'GET',
        url: workflowApi.getSimulationResultDataCSV(propertyFile),
        headers: getJWTToken(),
      });
      let result = convertToCSVArray(response.data, csvParams, false);
      return result;
    } catch (error) {
      try {
        dispatch(updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error));
      } catch (error) {
        dispatch(
          updateSnackBar(
            'Unknown error occurred while retrieving simulation results.',
            SNACK_BAR_SEVERITY.error
          )
        );
      }
      throw error;
    }
  };
// FIXME: Redundant implementation. Should be removed after implementing integration workflow results.
export const postSimulationSelectedWells = async (
  workflow_id,
  updatedWellsDf
) => {
  try {
    let response = await apiClient({
      method: 'POST',
      url: workflowApi.postSimulationSelectedWells(workflow_id),
      headers: getJWTToken(),
      data: updatedWellsDf,
    });
    return response;
  } catch (error) {
    return { error };
  }
};
