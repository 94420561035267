import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflow: 'auto',
  },
  logoGrid: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
  },
  controlPanelGrid: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& button': {
      borderRadius: '2em',
    },
  },
  logo: {
    cursor: 'pointer',
    marginLeft: '1em',
    width: '5em',
  },
  name: {
    marginLeft: '1em',
    fontSize: '1.75em',
  },
  controlButton: {
    marginRight: '2em',
    width: '20%',
    textTransform: 'capitalize',
    backgroundColor: '#003333',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#003333 !important',
      textDecoration: 'none',
    },
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      textDecoration: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default useStyles;
