import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../../Styles/ArgumentPageStyles';
import PropTypes from 'prop-types';

import AdvancedParametersModal from '../../../Components/NumericAdvancedParamsModal';
import FileUploadComponent from '../../../Components/FileUpload';

import { addWorkflow } from '../../../../redux/actions/workflows';

import { getUnitRate } from '../../../../Utils/ReservoirUtils';

import {
  SHUT_IN,
  SURVEILLANCE,
  CRMP as crmp,
  SYSTEM_OF_UNITS,
  ANGLE_THRESHOLD,
  TRAIN_TEST_SPLIT,
  WATER_CUT_THRESHOLD,
  WELL_RATE_THRESHOLD,
  BOTTOMHOLE_PRESSURE,
  TOLERANCE_FLUID_MATCHING,
  MAX_ITERATION_FLUID_MATCHING,
  ML_CONNECTIVITY,
  FMM,
  WAG,
} from '../../../../constants/WellConstants';
import * as workflowParameters from '../../../../constants/WorkflowsParameterConstants';

import {
  Grid,
  Typography,
  Button,
  Paper,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  IconButton,
  Collapse,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const CRMP = ({
  submitWorkflow,
  reduxSelectedDataset,
  reduxSelectedDatasetParams,
  reduxAllWorkflows,
  injWells,
  prodWells,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [name, setname] = useState('');
  const [description, setdescription] = useState('');
  const [modalState, setmodalState] = useState(false);
  const [parameters, setparameters] = useState({
    ...workflowParameters.CRMP_PARAMS,
  });
  const [advancedParameters, setadvancedParameters] = useState({
    [SHUT_IN]: true,
    [MAX_ITERATION_FLUID_MATCHING]: 1000,
    [TOLERANCE_FLUID_MATCHING]: 100,
    [TRAIN_TEST_SPLIT]: workflowParameters.DEFAULT_VALUE_TRAIN_TEST_SPLIT,
    [ANGLE_THRESHOLD]: 5,
    [WELL_RATE_THRESHOLD]: 0.9,
    [WATER_CUT_THRESHOLD]: 0.05,
  });
  const [selectedWells, setselectedWells] = useState({
    [workflowParameters.SELECTED_PRODUCERS]: [],
    [workflowParameters.SELECTED_INJECTORS]: [],
    [workflowParameters.EXCLUDED_PRODUCERS]: [],
    [workflowParameters.ZERO_WATER_DRIVE]: [],
  });
  const [mlcWorkflowOptions, setMlcWorkflowOptions] = useState([]);
  const [fmmWorkflowOptions, setFmmWorkflowOptions] = useState([]);

  // Handlers -->
  const onSubmitWorkflow = async () => {
    try {
      await dispatch(
        addWorkflow(reduxSelectedDataset.dataset_id, {
          module_type: SURVEILLANCE,
          workflow_type: crmp,
          name,
          description,
          parameters: {
            ...parameters,
            ...advancedParameters,
            ...reduxSelectedDatasetParams,
            [workflowParameters.EXCLUDED_PRODUCERS]:
              selectedWells[workflowParameters.EXCLUDED_PRODUCERS],
            [workflowParameters.ZERO_WATER_DRIVE]:
              selectedWells[workflowParameters.ZERO_WATER_DRIVE],
            stage: workflowParameters.CRMP_WORKFLOW_STAGES.FLUID_MATCHING,
          },
        })
      );
      submitWorkflow(true);
    } catch (error) {
      submitWorkflow(false);
    }
  };

  const onParameterChange = (target, value) => {
    setparameters(previous => {
      return {
        ...previous,
        [target]: value,
      };
    });
  };

  const onSelectWell = (wellListName, wellIndex) => {
    wellIndex = wellIndex.toString();
    let copyList = [...selectedWells[wellListName]];
    if (selectedWells[wellListName].includes(wellIndex)) {
      copyList.splice(selectedWells[wellListName].indexOf(wellIndex), 1);
    } else {
      copyList.push(wellIndex);
    }

    setselectedWells(previous => {
      return {
        ...previous,
        [wellListName]: copyList,
      };
    });
  };

  const onPairWells = () => {
    const wells = [...parameters[workflowParameters.WELL_PAIRS]];
    selectedWells[workflowParameters.SELECTED_PRODUCERS].forEach(
      prodWellIndex => {
        selectedWells[workflowParameters.SELECTED_INJECTORS].forEach(
          injWellIndex => {
            if (!wells.includes(`${prodWellIndex} ${injWellIndex}`)) {
              wells.push(`${prodWellIndex} ${injWellIndex}`);
            }
          }
        );
      }
    );

    onParameterChange(workflowParameters.WELL_PAIRS, wells);
  };

  const onRemoveWellPair = pair => {
    let tempWells = [...parameters[workflowParameters.WELL_PAIRS]];
    tempWells = tempWells.filter(
      wellPair => JSON.stringify(wellPair) != JSON.stringify(pair)
    );
    onParameterChange(workflowParameters.WELL_PAIRS, tempWells);
  };

  const toggle = () => {
    setmodalState(previous => !previous);
  };

  const saveUploadedDataset = jsonString => {
    onParameterChange(workflowParameters.WELL_PAIRS, []);
    onParameterChange(workflowParameters.UPLOADED_WELL_PAIRS, jsonString);
  };

  // <-- Handlers

  // Callbacks/Helpers -->
  const applyAdvancedParameters = newParamsPairs => {
    setadvancedParameters(newParamsPairs);
  };

  const filterWorkflowOptionsBy = modelType => {
    return reduxAllWorkflows.filter(
      workflow =>
        workflow['dataset_id'] == reduxSelectedDataset['dataset_id'] &&
        workflow['workflow_type'] == modelType &&
        workflow['status'] == 'Completed'
    );
  };
  // <-- Callbacks/Helpers

  useEffect(() => {
    setMlcWorkflowOptions(filterWorkflowOptionsBy(ML_CONNECTIVITY));
    setFmmWorkflowOptions(filterWorkflowOptionsBy(FMM));
  }, []);

  return (
    <Grid item className={styles.rootContainer}>
      <Grid item className={styles.argumentsContainer}>
        <Typography className={styles.header}>Workflow Details</Typography>
        <Grid item className={styles.argumentRow}>
          <Typography>Name</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '50',
            }}
            onChange={event => {
              setname(event.target.value);
            }}
          />
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Description</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '100',
            }}
            onChange={event => {
              setdescription(event.target.value);
            }}
          />
        </Grid>
        <br />
        <Typography className={styles.header}>Input Parameter</Typography>
        <Collapse
          in={parameters[workflowParameters.UPLOADED_WELL_PAIRS].length == 0}
          timeout={1500}
        >
          <Grid item className={styles.argumentContainer}>
            <Grid item className={styles.argumentRow}>
              <Typography>Choose the wells with zero connectivity</Typography>
              <Button
                variant="outlined"
                className={styles.button}
                onClick={onPairWells}
              >
                Pair Wells
              </Button>
            </Grid>
            <Grid item className={styles.argumentRow}>
              <Paper elevation={1} className={`${styles.paperContainer} card`}>
                <List dense={true} style={{ backgroundColor: 'white' }}>
                  <ListSubheader>Select Producer</ListSubheader>
                  {prodWells.map(well => (
                    <ListItem key={well + '-ListItem'}>
                      <ListItemText>{well}</ListItemText>
                      <Checkbox
                        onChange={() => {
                          onSelectWell(
                            workflowParameters.SELECTED_PRODUCERS,
                            prodWells.indexOf(well)
                          );
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
              <Paper elevation={1} className={`${styles.paperContainer} card`}>
                <List dense={true} style={{ backgroundColor: 'white' }}>
                  <ListSubheader>Select Injector</ListSubheader>
                  {injWells.map(well => (
                    <ListItem key={well + '-ListItem'}>
                      <ListItemText>{well}</ListItemText>
                      <Checkbox
                        onChange={() => {
                          onSelectWell(
                            workflowParameters.SELECTED_INJECTORS,
                            injWells.indexOf(well)
                          );
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
              <Paper elevation={1} className={`${styles.paperContainer} card`}>
                <List dense={true} style={{ backgroundColor: 'white' }}>
                  <ListSubheader>Exclude Producer</ListSubheader>
                  {prodWells.map(well => (
                    <ListItem key={well + '-ListItem'}>
                      <ListItemText>{well}</ListItemText>
                      <Checkbox
                        onChange={() => {
                          onSelectWell(
                            workflowParameters.EXCLUDED_PRODUCERS,
                            prodWells.indexOf(well)
                          );
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
          <Grid item className={styles.argumentContainer}>
            <Collapse
              in={parameters[workflowParameters.WELL_PAIRS].length > 0}
              timeout={1500}
            >
              <Grid item className={styles.argumentRow}>
                <Paper
                  elevation={1}
                  className={`${styles.paperContainer}  small`}
                >
                  <Grid container>
                    {parameters[workflowParameters.WELL_PAIRS].map(
                      wellIndexPair => (
                        <Paper
                          key={wellIndexPair}
                          style={{
                            margin: '0.35em',
                            width: '15%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                          }}
                          elevation={3}
                        >
                          {prodWells[wellIndexPair.split(' ')[0]] +
                            ' - ' +
                            injWells[wellIndexPair.split(' ')[1]]}
                          <IconButton
                            onClick={() => {
                              onRemoveWellPair(wellIndexPair);
                            }}
                          >
                            <CloseRoundedIcon fontSize="small" />
                          </IconButton>
                        </Paper>
                      )
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Collapse>
          </Grid>
        </Collapse>
        <Grid item className={styles.argumentContainer}>
          <Grid item className={styles.argumentRow}>
            <Typography>{workflowParameters.UPLOADED_WELL_PAIRS}</Typography>
            <FileUploadComponent saveUploadedDataset={saveUploadedDataset} />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>
              {`${workflowParameters.RADIUS_OF_INVESTIGATION} (${getUnitRate(
                reduxSelectedDatasetParams[SYSTEM_OF_UNITS],
                workflowParameters.RADIUS_OF_INVESTIGATION
              )})`}
            </Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[workflowParameters.RADIUS_OF_INVESTIGATION]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(
                  workflowParameters.RADIUS_OF_INVESTIGATION,
                  parseFloat(event.target.value)
                );
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>
              {`${workflowParameters.INJECTION_CALCULATION_TYPE} (${WAG})`}
            </Typography>
            <Checkbox
              onClick={event => {
                onParameterChange(
                  workflowParameters.INJECTION_CALCULATION_TYPE,
                  event.target.checked ? WAG : 'Both'
                );
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Include Pseudo Injectors well</Typography>
            <Checkbox
              onClick={event => {
                onParameterChange(
                  workflowParameters.PSEUDO,
                  event.target.checked
                );
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Base ML Connectivity Workflow</Typography>
            <FormControl size="small">
              <InputLabel>Workflow</InputLabel>
              <Select
                variant="outlined"
                value={parameters[workflowParameters.BASE_ML_MODEL_ID]}
                className={styles.selectionInput}
                onChange={event =>
                  onParameterChange(
                    workflowParameters.BASE_ML_MODEL_ID,
                    event.target.value
                  )
                }
              >
                {mlcWorkflowOptions.map(workflow => (
                  <MenuItem
                    key={'option-' + workflow['workflow_id']}
                    value={workflow['workflow_id']}
                  >
                    {`${workflow[`workflow_id`]} - ${
                      workflow['name'] || workflow['workflow_type']
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Base FMM Workflow</Typography>
            <FormControl size="small">
              <InputLabel>Workflow</InputLabel>
              <Select
                variant="outlined"
                value={parameters[workflowParameters.BASE_FMM_MODEL_ID]}
                className={styles.selectionInput}
                onChange={event =>
                  onParameterChange(
                    workflowParameters.BASE_FMM_MODEL_ID,
                    event.target.value
                  )
                }
              >
                {fmmWorkflowOptions.map(workflow => (
                  <MenuItem
                    key={'option-' + workflow['workflow_id']}
                    value={workflow['workflow_id']}
                  >
                    {`${workflow[`workflow_id`]} - ${
                      workflow['name'] || workflow['workflow_type']
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {false && ( // Temporarily disabled for pdo deployment
            <>
              <Grid item className={styles.argumentRow}>
                <Typography>Include bottomhole pressure</Typography>
                <Checkbox
                  onClick={event => {
                    onParameterChange(
                      BOTTOMHOLE_PRESSURE,
                      event.target.checked
                    );
                  }}
                />
              </Grid>
              <Grid item className={styles.argumentRow}>
                <Typography>Include water drive</Typography>
                <Checkbox
                  onClick={event => {
                    onParameterChange(
                      workflowParameters.WATER_DRIVE,
                      event.target.checked
                    );
                  }}
                />
              </Grid>
            </>
          )}
          <Collapse in={parameters[workflowParameters.WATER_DRIVE]}>
            <Grid item className={styles.argumentRow}>
              {parameters[workflowParameters.WATER_DRIVE] ? (
                <Paper
                  elevation={1}
                  className={`${styles.paperContainer} card`}
                >
                  <List dense={true} style={{ backgroundColor: 'white' }}>
                    <ListSubheader>Select Zero Water Drive Wells</ListSubheader>
                    {prodWells.map(well => (
                      <ListItem key={well + '-ListItem'}>
                        <ListItemText>{well}</ListItemText>
                        <Checkbox
                          onChange={() => {
                            onSelectWell(
                              workflowParameters.ZERO_WATER_DRIVE,
                              prodWells.indexOf(well)
                            );
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              ) : (
                ''
              )}
            </Grid>
          </Collapse>
          <Grid item className={styles.argumentRow} direction="row-reverse">
            <Tooltip title="Advanced Settings">
              <IconButton onClick={toggle}>
                <SettingsRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <AdvancedParametersModal
            modalState={modalState}
            toggle={toggle}
            applyAdvancedParameters={applyAdvancedParameters}
            advancedParameterPairs={advancedParameters}
          />
        </Grid>
      </Grid>
      <Grid item className={styles.runContainer}>
        <Grid item className={styles.buttonContainer}>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.runButton} ${styles.primaryButton}`}
            onClick={onSubmitWorkflow}
          >
            Run
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

CRMP.propTypes = {
  submitWorkflow: PropTypes.func,
  reduxSelectedDataset: PropTypes.object,
  reduxSelectedDatasetParams: PropTypes.object,
  reduxAllWorkflows: PropTypes.array,
  injWells: PropTypes.array,
  prodWells: PropTypes.array,
};

export default CRMP;
