import { reduxAction } from '../base';
import { USER_LOGIN } from './types';
import { apiClient } from '../../../service/apiClient';
import * as userApi from '../../../API/Routes/user';
/**
 * This is a redux-thunk function, where it returns a dispatch function. Returned function is not executed until it is
 * dispatched by the "store.dispatch()". It makes a post request to server and tries to log in the user. On success, it
 * dispaches USER_LOGIN action and saves user token.
 *
 * @param {Object} userInfo
 * @param {Function} onSuccess - Callback function that runs after successful login.
 * @param {Function} onFail - Callback function that runs after failed login. Expects raised error as argument.
 * @returns Thunk Function
 */
export const userLogin = (userInfo, onSuccess, onFail) => async dispatch => {
  try {
    let response = await apiClient({
      method: 'POST',
      url: userApi.loginUserRoute(),
      data: userInfo,
    });
    dispatch(reduxAction(USER_LOGIN, response.data.user));
    localStorage.setItem('x-access-token', response.data.token);
    onSuccess();
  } catch (error) {
    localStorage.removeItem('x-access-token');
    onFail(error);
  }
};

/**
 * Make password reset request to the backend.
 * @param {Object} payload
 * @param {Function} onSuccess - Callback function that runs after successful login.
 * @param {Function} onFail - Callback function that runs after failed login. Expects raised error as argument.
 */
export const resetPassword = async (payload, onSuccess, onFail) => {
  try {
    await apiClient({
      method: 'PUT',
      url: userApi.updateUserPassword(payload.userId),
      data: { password: payload.password },
    });
    onSuccess();
  } catch (error) {
    onFail(error);
  }
};
