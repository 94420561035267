import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';
import { BasicTable } from '../../../Components/TableView';

import { generateFutureArpsAnalysisResult } from '../../../../Utils/WorkflowReportUtils/ArpsDeclineCurve';

import { Grid, IconButton, Link, Paper, Typography } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import {
  DATE,
  METHOD,
  SYSTEM_OF_UNITS,
} from '../../../../constants/WellConstants';

const CurveAnalysisFuture = ({
  filteredDatasetContent,
  sdfFuture,
  futureDf,
  parameters,
  selectedWell,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);
  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks -->
  // <-- Callbacks

  // Helpers -->
  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Helpers

  useEffect(() => {
    if (
      sdfFuture != null &&
      futureDf != null &&
      parameters != null &&
      selectedWell != null &&
      header
    ) {
      setplot(
        generateFutureArpsAnalysisResult(
          sdfFuture,
          selectedWell,
          parameters[METHOD],
          parameters[SYSTEM_OF_UNITS],
          filteredDatasetContent.at(-1)[DATE]
        )
      );
    }
  }, [sdfFuture, futureDf, parameters, selectedWell, header]);

  return (
    <React.Fragment>
      <Grid id={header} container className={styles.visualContent}>
        {plot ? (
          <PlotlyComponent
            data={plot.data}
            layout={plot.layout}
            config={plot.config}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <LoadingPage
            message="Future Curve analysis plot is loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid id={header} container className={styles.visualContentFlex}>
        {futureDf && (
          <Paper elevation={5} className={styles.visualContentRow}>
            <BasicTable data={futureDf} />
          </Paper>
        )}
        <Grid container className={styles.visualContentRow}>
          <Grid
            item
            container
            xs={6}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="overline">Download Results</Typography>
            <Link
              download="future_curve_analysis_df.csv"
              href={encodedUri}
              onClick={() => {
                onDownload(futureDf);
              }}
            >
              <IconButton>
                <GetAppRoundedIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

CurveAnalysisFuture.propTypes = {
  filteredDatasetContent: PropTypes.array,
  sdfFuture: PropTypes.array,
  futureDf: PropTypes.array,
  parameters: PropTypes.object,
  selectedWell: PropTypes.string,
  header: PropTypes.string,
};

export default CurveAnalysisFuture;
