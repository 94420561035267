import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../../../Styles/WorkflowStyles';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveStaticGainValuesResult } from '../../../../../../API/Functions/CRMP';
import { retrieveWellSurfaceLocations } from '../../../../../../API/Functions/Visualization';

import GainValueAnalysisComponent from './GainValueAnalysisComponent';

import { Grid } from '@material-ui/core';

import { GAS, WAG, WATER } from '../../../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';
import { INJECTION_CALCULATION_TYPE } from '../../../../../../constants/WorkflowsParameterConstants';
import { filterInjectorsByWAG } from '../../../../../../Utils/WorkflowReportUtils/CRMPUtils';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const StaticGainValuesMap = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [params, setParams] = useState({
    minGain: 0,
    maxGain: 1,
  });

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const wellGainValues = await retrieveStaticGainValuesResult(
          reduxSelectedWorkflow.workflow_id,
          params
        );

        const wellDetails = await retrieveWellSurfaceLocations(
          reduxSelectedWorkflow.dataset_id,
          reduxSelectedWorkflow.parameters
        );

        if (mounted) {
          setapiRes({ wellGainValues, wellDetails });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();
    return () => (mounted = false);
  }, [params]);

  // // Callbacks/Helpers -->
  // // <-- Callbacks/Helpers

  // Handlers -->
  const onSliderChange = params => {
    setParams(params);
  };
  // <-- Handlers

  return (
    <Grid container className={styles.visualContent}>
      {reduxSelectedWorkflow['parameters'][INJECTION_CALCULATION_TYPE] ==
      WAG ? (
        <Grid container style={{ flex: 1 }}>
          <Grid item container direction="column" xs={6}>
            <GainValueAnalysisComponent
              header="Static Gain Values"
              wellsGainValues={filterInjectorsByWAG(
                apiRes?.wellGainValues || [],
                WATER
              )}
              wellsDetails={apiRes?.wellDetails}
              workflowParameters={reduxSelectedWorkflow.parameters}
              apiCallback={onSliderChange}
            />
          </Grid>
          <Grid item container direction="column" xs={6}>
            <GainValueAnalysisComponent
              header="Static Gain Values"
              wellsGainValues={filterInjectorsByWAG(
                apiRes?.wellGainValues || [],
                GAS
              )}
              wellsDetails={apiRes?.wellDetails}
              workflowParameters={reduxSelectedWorkflow.parameters}
              apiCallback={onSliderChange}
            />
          </Grid>
        </Grid>
      ) : (
        <GainValueAnalysisComponent
          header="Static Gain Values"
          wellsGainValues={apiRes?.wellGainValues}
          wellsDetails={apiRes?.wellDetails}
          workflowParameters={reduxSelectedWorkflow.parameters}
          apiCallback={onSliderChange}
        />
      )}
    </Grid>
  );
};

export default StaticGainValuesMap;
