import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useStyles from '../../../Styles/VisualizationPageStyle';

import Navbar from '../../Components/NavBar';
import SideBar from '../../Components/SideBar';
import FieldMap2D from './FieldMap2D';

import { retrieveIntegrationDatasetContent } from '../../../redux/actions/dataset';
import { updateSnackBar } from '../../../redux/actions/feedback';

import { Button, Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

import {
  INTEGRATION_FILES_HEADER_FORMATS,
  INTEGRATION_MODULE_FILE_NAMES,
} from '../../../constants/WorkflowsParameterConstants';
import { SNACK_BAR_SEVERITY } from '../../../constants/ComponentConstants';
import { INTEGRATION_DATASET_VISUALIZATION_HEADER_NAMES as PLOT_HEADERS } from '../../../constants/WorkflowComponentConstants';
import { INTEGRATION_MODULE_ATTRIBUTE_PAIRS as ATTRIBUTES } from '../../../constants/WorkflowsParameterConstants';
import {
  FORCAST_TIME_PERIOD_YEARS,
  MAX_WELL_GAS_RATE,
  TYPICAL_TUBING_SIZE,
  WELL_COST,
  WELL_FLOW_MIN_BHP,
  WELL_FLOW_MIN_THP,
} from '../../../constants/WellConstants';
import AdvancedParametersModal from '../../Components/NumericAdvancedParamsModal';
import LogoComponent from '../../Components/LogoComponent';

const IntegrationDatasetVisualization = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();

  const [uploadedCSVFiles, setuploadedCSVFiles] = useState(null);

  const [advacedModalState, setadvacedModalState] = useState(false);
  const [advancedParameters, setadvancedParameters] = useState({
    [WELL_FLOW_MIN_BHP]: 100,
    [WELL_FLOW_MIN_THP]: 170,
    [MAX_WELL_GAS_RATE]: 25000,
    [TYPICAL_TUBING_SIZE]: 5.5,
    [WELL_COST]: 1500,
    [FORCAST_TIME_PERIOD_YEARS]: 20,
  });

  // Callback/Helpers -->
  const applyAdvancedParameters = newParamsPairs => {
    setadvancedParameters(newParamsPairs);
  };
  // <-- Callback/Helpers

  // Handlers -->
  // const onHelpClick = () => {
  //   navigate('/help');
  // };

  const toggle = () => {
    setadvacedModalState(previous => !previous);
  };

  const onChooseWorkflow = () => {
    navigate(`/integrationWorkflowSelection/${urlDatasetId}`);
  };
  // <-- Handlers

  // Fetch Datasets
  useEffect(() => {
    let mounted = true;
    // Asynchronous function to properly fetch and set response to useState variable, if possible.
    const fetchWorkflowData = async () => {
      try {
        let response = await retrieveIntegrationDatasetContent(
          urlDatasetId,
          INTEGRATION_FILES_HEADER_FORMATS
        );

        if (mounted) {
          setuploadedCSVFiles({
            actnumFile: response[INTEGRATION_MODULE_FILE_NAMES.ACT_NUM_GRID],
            parameters: response[INTEGRATION_MODULE_FILE_NAMES.PARAMETERS][0],
            permx: response[INTEGRATION_MODULE_FILE_NAMES.PERMABILITY],
            poro: response[INTEGRATION_MODULE_FILE_NAMES.POROSITY],
            results: response[INTEGRATION_MODULE_FILE_NAMES.RESULTS],
            mgip_21: response[INTEGRATION_MODULE_FILE_NAMES.MGIP_21],
            mgip_40: response[INTEGRATION_MODULE_FILE_NAMES.MGIP_40],
            sg_21: response[INTEGRATION_MODULE_FILE_NAMES.SG_21],
            sg_40: response[INTEGRATION_MODULE_FILE_NAMES.SG_40],
            // Legacy versions -->
            activeMap: response[INTEGRATION_MODULE_FILE_NAMES.ACTIVE_MAP],
            swat: response[INTEGRATION_MODULE_FILE_NAMES.WATER_SAT],
            sgas: response[INTEGRATION_MODULE_FILE_NAMES.GAS_SAT],
            somax: response[INTEGRATION_MODULE_FILE_NAMES.OIL_SAT],
            oneWell1D: response[INTEGRATION_MODULE_FILE_NAMES.ONE_WELL],
            // <-- Legacy versions
          });
        }
      } catch (error) {
        try {
          dispatch(
            updateSnackBar(error.response.data, SNACK_BAR_SEVERITY.error)
          );
        } catch (error) {
          dispatch(
            updateSnackBar(
              'Unknown error occurred while retrieving datasets.',
              SNACK_BAR_SEVERITY.error
            )
          );
        }
      }
    };

    fetchWorkflowData();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sideBarGrid}>
          <SideBar
            mainHeader={'Dataset Visualization'}
            plotHeaderObject={PLOT_HEADERS}
          />
        </Grid>
        <Grid item className={styles.visualizationGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <FieldMap2D
              uploadedCSVFiles={uploadedCSVFiles}
              header={PLOT_HEADERS.ANALYSIS_MODEL}
              attributes={ATTRIBUTES}
            />
            <Grid item className={styles.flexVisualContainer}>
              <Grid
                item
                container
                direction="row-reverse"
                className={styles.inputContentRow}
              >
                <Tooltip title="Advanced Settings">
                  <IconButton onClick={toggle}>
                    <SettingsRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <AdvancedParametersModal
                modalState={advacedModalState}
                toggle={toggle}
                applyAdvancedParameters={applyAdvancedParameters}
                advancedParameterPairs={advancedParameters}
              />
              <Button
                id={PLOT_HEADERS.CHOOSE_WORKFLOW}
                className={styles.workflowButton}
                onClick={onChooseWorkflow}
              >
                {PLOT_HEADERS.CHOOSE_WORKFLOW}
              </Button>
              {/* <Grid item className={styles.footer}>
                <Button
                  variant="contained"
                  className={styles.footerButton}
                  onClick={onHelpClick}
                >
                  Help
                </Button>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationDatasetVisualization;
