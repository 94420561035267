import React from 'react';

import useStyles from '../../../Styles/WorkflowStyles';

import Navbar from '../NavBar';
import SideBar from '../SideBar';
import CRMP from './CRMP';
import CrossCorrelation from './CrossCorrelation';
import MLConnectivity from './MLConnectivity';

import { Grid, Paper } from '@material-ui/core';

import { HELP_PAGE_LIST_HEADER_NAMES } from '../../../constants/WorkflowComponentConstants';
import LogoComponent from '../../Components/LogoComponent';

const HelpPage = () => {
  const styles = useStyles();

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          <SideBar
            mainHeader={'Workflow Types'}
            plotHeaderObject={HELP_PAGE_LIST_HEADER_NAMES}
          />
        </Grid>
        <Grid item className={styles.contentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <CrossCorrelation />
            <MLConnectivity />
            <CRMP />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

HelpPage.propTypes = {};

export default HelpPage;
