import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      fontWeight: '600',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',

    '& .body': {
      flex: '5 1 auto',
    },
    '& .paper': {
      margin: '2.5vh',
      maxHeight: '45vh',
      flex: '1 1 auto',
      overflow: 'auto',
    },
  },
});

const VisualizationSynopsisModal = ({
  toggle,
  modalState,
  synopsisHeader,
  synopsisComponent,
}) => {
  const styles = useStyles();

  // callback/helpers -->
  const onClose = () => {
    toggle();
  };
  // <-- callback/helpers
  return (
    <Dialog fullWidth={true} maxWidth="md" open={modalState} onClose={onClose}>
      <DialogTitle disableTypography className={styles.dialogTitle}>
        <Typography className="title" variant="h6">
          {synopsisHeader}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Grid container className="body">
          <Grid container className="paper">
            {synopsisComponent}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

VisualizationSynopsisModal.propTypes = {
  toggle: PropTypes.func,
  modalState: PropTypes.bool,
  synopsisHeader: PropTypes.string,
  synopsisComponent: PropTypes.element,
};

export default VisualizationSynopsisModal;
