import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';

import { Grid } from '@material-ui/core';
import {
  CORRELATION,
  INJECTOR,
  LAG,
  PRODUCER,
} from '../../../../constants/WellConstants';
import {
  createLinePlotTrace,
  LINES_WITH_MARKERS,
  wrapPlots,
} from '../../../../Utils/PlotlyUtils/Plots';

const CumWorkflowAnalysisPlot = ({
  header,
  allWorkflowAnalysisContents,
  selectedInjector,
  selectedProducer,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);

  // Callbacks -->
  const generateWellPairCumCorrelationPlotWithLag = () => {
    const traces = [];
    const corrPairs = [
      ...new Set(allWorkflowAnalysisContents.map(row => row['CORR_SERIES'])),
    ];

    const wellFilteredDataset = allWorkflowAnalysisContents.filter(
      row =>
        row[INJECTOR] == selectedInjector && row[PRODUCER] == selectedProducer
    );

    corrPairs.forEach(corrPair => {
      const wellCorrValuesBySignal = wellFilteredDataset.filter(
        row => row['CORR_SERIES'] == corrPair
      );

      wellCorrValuesBySignal.length > 0 &&
        traces.push(
          createLinePlotTrace(
            wellCorrValuesBySignal.map(row => row[LAG]),
            wellCorrValuesBySignal.map(row => row[CORRELATION]),
            corrPair,
            undefined,
            undefined,
            undefined,
            LINES_WITH_MARKERS
          )
        );
    });

    setplot(
      wrapPlots(
        traces,
        LAG,
        CORRELATION,
        `${selectedInjector} - ${selectedProducer} Correlations`
      )
    );
  };
  // <-- Callbacks

  useEffect(() => {
    if (allWorkflowAnalysisContents && selectedInjector && selectedProducer) {
      generateWellPairCumCorrelationPlotWithLag();
    }
  }, [allWorkflowAnalysisContents, selectedInjector, selectedProducer]);
  return (
    <Grid id={header} container className={styles.visualContent}>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Cumulative Correlation plot is loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

CumWorkflowAnalysisPlot.propTypes = {
  header: PropTypes.string,
  allWorkflowAnalysisContents: PropTypes.array,
  selectedInjector: PropTypes.string,
  selectedProducer: PropTypes.string,
};

export default CumWorkflowAnalysisPlot;
