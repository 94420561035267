import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  dropzone: {
    border: '0.25em dotted #2d6a4f',
    borderRadius: '1em',
    height: '50%',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& .icon': { fontSize: '4em', color: '#40916c' },
    '& .descriptionText': { fontSize: '1em' },
    '& .footerText': { fontSize: '0.75em' },
  },

  fileArguments: {
    border: '1px solid #2d6a4f',
    borderRadius: '1em',
    margin: '2em 0em',

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& .textInput': {
      padding: '0% 2.5%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.75em 0em 0em 0em',
      '& .description': { fontSize: '0.875em' },
      '& .field': {
        width: '60%',
        marginRight: '15%',
      },
    },
    '& .buttonField': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'baseline',
      margin: '0.5em',
      '& .button': {
        fontSize: '0.75em',
        padding: '0.5em 2em',
        borderRadius: '2em',
        margin: '0 2.5%',
        '&:enabled': {
          backgroundColor: '#000B46',
          color: 'white',
          '&:hover': {
            backgroundColor: '#000B46',
          },
        },
      },
    },
  },

  loadingPage: {
    border: '1px solid #2d6a4f',
    borderRadius: '1em',
    margin: '2em 0em',

    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& .text': {
      flex: 1,
      padding: '0% 2.5%',
    },
    '& .bar': {
      flex: 5,
      padding: '0% 2.5%',
      '& .progress-bar': {
        backgroundColor: 'red',
        '& .MuiLinearProgress-root': {
          height: 10,
          borderRadius: 5,
        },
      },
    },
  },
});

export default useStyles;
