import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
  },
  navbarGrid: {
    width: '100%',
    height: '10%',
    display: 'flex',
  },
  bodyGrid: {
    height: '85%',
    width: '100%',
  },
  sidebarGrid: {
    width: '20%',
    height: '100%',
    padding: '0em',
    '& hr': {
      border: '1px solid grey',
      marginBottom: '1em',
    },
    '& a': {
      color: 'black',
      '&:hover': {
        color: 'black',
        textDecoration: 'none',
      },
    },
  },
  sidebarListItem: {
    color: '#c1c5d1',
    '&:hover': {
      color: '#ffffff', // Change this to the desired hover color
    },
  },
  sidebarList: {
    paddingTop: '25px',
  },
  contentGrid: {
    width: '80%',
    height: '100%',
    padding: '1em',
  },
  paperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  sidebarPaperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: '#003333',
    color: '#c1c5d1',
  },
  visualContent: {
    width: '100%',
    height: '100%',
    padding: '1%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  visualContentFlex: {
    width: '100%',
    display: 'flex',
    padding: '1%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  visualContentRow: {
    width: '90%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    margin: '2% 5%',
    '& .dropdownSelection': {
      width: '35%',
      margin: '1% 0%',
    },
  },
  inputContentRow: {
    width: '90%',
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '0.5% 5%',
    alignItems: 'center',
    '&.multipleContent': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .expandInputElement': {
      flex: '1 1 auto',
      margin: '1%',
    },
    '& .dropdownSelection': {
      width: '35%',
      margin: '1% 0%',
    },
    '& .numericInput': {
      borderRadius: '2em',
      width: '5em',
    },
  },
  button: {
    borderRadius: '2em',
    textTransform: 'capitalize',
    '&.largeButton': {
      width: '20%',
    },
    '&.xlargeButton': {
      width: '25%',
    },
    '&.selectedButton': {
      backgroundColor: '#000B46',
      color: 'white',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  primaryButton: {
    backgroundColor: '#000B46',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000B46',
    },
  },
  secondaryButton: {},
  runButton: {
    paddingLeft: '7em',
    paddingRight: '7em',
  },
});

export default useStyles;
