import { getJWTToken } from '../../../redux/actions/base';
import * as mlConnApiRoutes from '../../Routes/workflowResults';
import { fieldWellNamesRoute } from '../../Routes/visualization';
import { apiClient } from '../../../service/apiClient';

export async function retrieveMLConnFilteredDataset(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: mlConnApiRoutes.mlConnFilteredDatasetRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving filtered dataset.');
  }
}

export async function retrieveAdvancedAnalysisResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: mlConnApiRoutes.advancedAnalysisResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving advanced analysis result.');
  }
}

export async function retrieveCalculationScoresResult(
  workflowId,
  selectedCorrPair
) {
  try {
    const response = await apiClient({
      method: 'POST',
      url: mlConnApiRoutes.calculationScoresResultRoute(workflowId),
      headers: getJWTToken(),
      data: { ['selectedCorrPair']: selectedCorrPair },
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving calculation scores result.');
  }
}

export async function retrieveMLConnWorkflowResult(
  workflowId,
  selectedCorrPair
) {
  try {
    const response = await apiClient({
      method: 'POST',
      url: mlConnApiRoutes.mlConnWorkflowResultRoute(workflowId),
      headers: getJWTToken(),
      data: { ['selectedCorrPair']: selectedCorrPair },
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving workflow result.');
  }
}

export async function retrieveMLConnDiagnosticResults(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: mlConnApiRoutes.mlConnDiagnosticResultsRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving diagnostic results.');
  }
}

export async function retrieveFieldWellNames(datasetId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: fieldWellNamesRoute(datasetId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}
