import {
  SET_SELECTED_HEADER,
  RESET_SELECTED_HEADER,
} from '../actions/sidebar/types';
const DEFAULT_STATE = {
  selectedPlotHeader: null,
};
export const sideBarReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_HEADER:
      return {
        ...state,
        selectedPlotHeader: action.payload.selectedHeader,
      };
    case RESET_SELECTED_HEADER:
      return {
        ...state,
        selectedPlotHeader: null,
      };
    default: {
      return state;
    }
  }
};
