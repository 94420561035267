import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../LoadingPage';

import {
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
} from '@material-ui/core';

import {
  filterAvailaleColNamesFromDataset,
  filterWellNamesFromDataset,
} from '../../../Utils/DatasetUtils/DataProcessing';

import {
  INJECTOR,
  PER_DAY,
  PRODUCER,
  SYSTEM_OF_UNITS,
} from '../../../constants/WellConstants';
import { generateWellPairAnalysisGraph } from '../../../Utils/CommonReportUtil';

const WellPairAnalysisPlot = ({
  header,
  filteredDatasetContent,
  selectedInjector,
  selectedProducer,
  workflowParameters,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);
  const [injFeature, setinjFeature] = useState('');
  const [availableInjFeatures, setavailableInjFeatures] = useState([]);
  const [prodFeature, setprodFeature] = useState('');
  const [availableProdFeatures, setavailableProdFeatures] = useState([]);

  // Callbacks -->
  // <-- Callbacks

  // Handlers -->
  const onInjFeatureSelect = event => {
    setinjFeature(event.target.value);
  };

  const onProdFeatureSelect = event => {
    setprodFeature(event.target.value);
  };
  // <-- Handlers

  useEffect(() => {
    const { [PRODUCER]: prodColNames, [INJECTOR]: injColNames } =
      filterAvailaleColNamesFromDataset(Object.keys(filteredDatasetContent[0]));
    setavailableProdFeatures(prodColNames);
    setavailableInjFeatures(injColNames);
    setprodFeature(prodColNames[0]);
    setinjFeature(injColNames[0]);
  }, []);

  useEffect(() => {
    if (filteredDatasetContent && injFeature && prodFeature) {
      // if props are not available, retrieve and set default values
      const { injectors, producers } =
        !selectedInjector &&
        !selectedProducer &&
        filterWellNamesFromDataset(filteredDatasetContent);

      setplot(
        generateWellPairAnalysisGraph(
          injFeature,
          prodFeature,
          selectedInjector || injectors?.[0],
          selectedProducer || producers?.[0],
          filteredDatasetContent,
          workflowParameters[SYSTEM_OF_UNITS],
          PER_DAY
        )
      );
    }
  }, [
    filteredDatasetContent,
    selectedInjector,
    selectedProducer,
    injFeature,
    prodFeature,
  ]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{header}</Typography>
      </Grid>
      <Grid item className={styles.inputContentRow + ' multipleContent'}>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <Select
            value={injFeature}
            onChange={onInjFeatureSelect}
            label="Injector Feature"
          >
            {availableInjFeatures.map(feature => (
              <MenuItem key={'menu-item-' + feature} value={feature}>
                {feature}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <Select
            value={prodFeature}
            onChange={onProdFeatureSelect}
            label="Producer Feature"
          >
            {availableProdFeatures.map(feature => (
              <MenuItem key={'menu-item-' + feature} value={feature}>
                {feature}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Well pair analysis plot is loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

WellPairAnalysisPlot.propTypes = {
  header: PropTypes.string,
  filteredDatasetContent: PropTypes.array,
  selectedInjector: PropTypes.string,
  selectedProducer: PropTypes.string,
  workflowParameters: PropTypes.object,
};

export default WellPairAnalysisPlot;
