import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';

import { Typography } from '@material-ui/core';

// TODO: Need to be able to perform current status of loading process, especially for loading pages.
const LoadingPage = ({ message, goHome = true }) => {
  const navigate = useNavigate();
  const [timer, settimer] = useState(false);

  /**
   * Mount loading screen, and set the timer true after 5 seconds. Async useEffect methods needs mounting
   * logic so that they don't cause memory leak. (https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect)
   */
  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        settimer(true);
      }
    }, 5000);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ margin: '1%' }} />
      <Typography variant="h5">
        {message ? message : 'Please wait...'}
      </Typography>
      {timer && goHome ? (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '1em 0em' }}
          onClick={() => navigate('/homepage')}
        >
          Go Home
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

LoadingPage.propTypes = {
  message: PropTypes.string,
  goHome: PropTypes.bool,
};

export default LoadingPage;
