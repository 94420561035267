import React, {
  useState,
  // useEffect,
} from 'react';
import {
  useNavigate,
  // useParams
} from 'react-router-dom';
// import { useDispatch } from 'react-redux';

import useStyles from '../../../Styles/WorkflowSelectionStyle';

import Navbar from '../../Components/NavBar';
import FMM from './WorkflowArgumentViews/FMM';
import SOI from './WorkflowArgumentViews/SOI';
import HybridModel from './WorkflowArgumentViews/HybridModel';
// import GreenFieldParameters from './WorkflowArgumentViews/GreenField';
// import BrownFieldParameters from './WorkflowArgumentViews/BrownField';

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const IntegrationWorkflowSelection = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { datasetId: urlDatasetId } = useParams();

  const [workflowDetails, setworkflowDetails] = useState({
    selectedWorkflow: null,
    isSubmitted: false,
    status: false,
  });

  // Callback/Helpers -->
  const submitWorkflow = status => {
    setworkflowDetails(previous => {
      return { ...previous, isSubmitted: true, status };
    });
  };
  // <-- Callback/Helpers
  // Handlers -->
  const onHome = () => {
    navigate('/homepage');
  };

  const onSelectWorkflow = workflow => {
    setworkflowDetails({
      selectedWorkflow: workflow,
      isSubmitted: false,
      status: false,
    });
  };
  // <-- Handlers

  const WORKFLOW_NAMES = {
    // 'Green Field': <GreenFieldParameters submitWorkflow={submitWorkflow} />,
    // 'Brown Field': <BrownFieldParameters submitWorkflow={submitWorkflow} />,
    SOI: <SOI submitWorkflow={submitWorkflow} />,
    FMM: <FMM submitWorkflow={submitWorkflow} />,
    'Hybrid Model': <HybridModel submitWorkflow={submitWorkflow} />,
  };

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sideBarGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <List>
              {Object.keys(WORKFLOW_NAMES).map(workflow => (
                <React.Fragment key={'header-' + workflow}>
                  <ListItem
                    button
                    selected={workflow == workflowDetails.selectedWorkflow}
                    key={'listItem-' + workflow}
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                      onSelectWorkflow(workflow);
                    }}
                  >
                    <ListItemText>{workflow}</ListItemText>
                  </ListItem>
                  <Divider variant="middle" />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.argumentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <Grid item className={styles.argumentConainer}>
              {workflowDetails.selectedWorkflow ? (
                <React.Fragment>
                  {workflowDetails.isSubmitted ? (
                    <Grid item className={styles.flexCenter}>
                      {workflowDetails.status ? (
                        <React.Fragment>
                          <CheckCircleOutlineRoundedIcon
                            style={{ fontSize: '5em', color: 'green' }}
                          />
                          <Typography variant="h4" style={{ color: 'green' }}>
                            Operation Successful
                          </Typography>
                          <Button
                            variant="contained"
                            className={`${styles.button} xlargeButton`}
                            onClick={onHome}
                          >
                            Back to Home
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <HighlightOffRoundedIcon
                            style={{ fontSize: '5em', color: 'red' }}
                          />
                          <Typography variant="h4" style={{ color: 'red' }}>
                            Operation Unsuccessful
                          </Typography>
                          <Button
                            variant="outlined"
                            className={`${styles.button} xlargeButton`}
                            onClick={onHome}
                          >
                            Back to Home
                          </Button>
                        </React.Fragment>
                      )}
                    </Grid>
                  ) : (
                    WORKFLOW_NAMES[workflowDetails.selectedWorkflow]
                  )}
                </React.Fragment>
              ) : (
                <Grid item className={styles.flexCenter}>
                  <Typography variant="h4" style={{ opacity: '0.5' }}>
                    Choose a Workflow
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item className={styles.footer}>
              <Button variant="contained" id="Help">
                Help
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationWorkflowSelection;
