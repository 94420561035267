import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import OilMatching from './OilMatching';
import Optimization from './Optimization';
import FluidMatching from './FluidMatching';
import ShutOffAnalysis from './ShutOffAnalysis';
import FieldResultAnalysis from './FieldResultAnalysis';
import LoadingPage from '../../../Components/LoadingPage';
import FieldAnalysis from './FieldAnalysisPlot/FieldAnalysis';
import FieldGainAnalysisPlot from './FluidMatching/FieldGainAnalysisPlot';

import {
  filterWellNamesFromDataset,
  saveDatasetLayers,
} from '../../../../Utils/DatasetUtils/DataProcessing';

import {
  ALL_LAYERS,
  FIELD,
  INJECTOR,
  PRODUCER,
} from '../../../../constants/WellConstants';
import { CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES } from '../../../../constants/WorkflowsParameterConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const CRMP_Layer = ({ headers, workflowResultFiles, workflowParameters }) => {
  const isOptimizationDisabled = true;
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [gainValues, setgainValues] = useState(null);
  const [r2Oil, setr2Oil] = useState(null);
  const [r2History, setr2History] = useState(null);
  const [liquidRateAll, setliquidRateAll] = useState(null);
  const [liquidRateHistory, setliquidRateHistory] = useState(null);
  const [fieldData, setfieldData] = useState(null);
  const [fieldLayerData, setfieldLayerData] = useState(null);
  const [oilRateAll, setoilRateAll] = useState(null);
  const [oilRateHistory, setoilRateHistory] = useState(null);
  const [injEfficiencyData, setinjEfficiencyData] = useState(null);
  const [injEfficiencyLayerData, setinjEfficiencyLayerData] = useState(null);
  const [oilMaxWellData, setoilMaxWellData] = useState(null);
  const [IFactorMaxWellData, setIFactorMaxWellData] = useState(null);

  const [layers, setlayers] = useState(null);
  const [injWellNames, setinjWellNames] = useState(null);
  const [prodWellNames, setprodWellNames] = useState(null);
  const [workflowReady, setworkflowReady] = useState(false);

  // Callback/Helpers -->
  // <-- Callback/Helpers

  // Retrieve the required data to generate workflow model from the servers.
  useEffect(() => {
    if (workflowResultFiles) {
      setfilteredDatasetContent(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET
        ]
      );
      setgainValues(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.WORKFLOW_RESULT
        ]
      );
      setr2Oil(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.OIL_MATCHING_R2
        ]
      );
      setr2History(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.FLUID_MATCHING_R2
        ]
      );
      setfieldData(
        workflowResultFiles[CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.FIELD_DATA]
      );
      setfieldLayerData(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.FIELD_LAYERS_DATA
        ]
      );
      setliquidRateAll(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.LIQUID_RATE_ALL
        ]
      );
      setliquidRateHistory(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.LIQUID_RATE_HISTORY
        ]
      );
      setoilRateAll(
        workflowResultFiles[CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.OIL_RATE_ALL]
      );
      setoilRateHistory(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.OIL_RATE_HISTORY
        ]
      );
      setinjEfficiencyData(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.INJ_EFFICIENCY
        ]
      );
      setinjEfficiencyLayerData(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.INJ_EFFICIENCY_LAYER
        ]
      );
      setoilMaxWellData(
        workflowResultFiles[CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.OIL_OPT_WELL]
      );
      setIFactorMaxWellData(
        workflowResultFiles[
          CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES.I_FACTOR_MAX_WELL
        ]
      );
    }
  }, [workflowResultFiles]);

  useEffect(() => {
    if (filteredDatasetContent && workflowParameters) {
      const datasetLayers = saveDatasetLayers(filteredDatasetContent);
      const gainValueLayers = saveDatasetLayers(gainValues);
      const { injectors, producers } = filterWellNamesFromDataset(
        filteredDatasetContent
      );

      setinjWellNames(injectors);
      setprodWellNames(producers);
      setlayers(
        datasetLayers.filter(layerName => gainValueLayers.includes(layerName))
      );
      setworkflowReady(true);
    }
  }, [filteredDatasetContent]);

  if (workflowReady)
    return (
      <React.Fragment>
        <FieldGainAnalysisPlot
          filteredDatasetContent={filteredDatasetContent}
          workflowParameter={workflowParameters}
          gainValues={gainValues}
          layers={layers && layers}
          header={headers.LayerBasedGainValues}
        />
        <FluidMatching
          filteredDatasetContent={filteredDatasetContent}
          workflowParameter={workflowParameters}
          gainValues={gainValues}
          r2History={r2History}
          liquidRateHistory={liquidRateHistory}
          liquidRateAll={liquidRateAll}
          analyzedWellNames={prodWellNames}
          analyzedWellType={PRODUCER}
          layers={layers && [ALL_LAYERS, ...layers]}
          header={headers.ProductionFluidMatching}
          fieldLayerData={fieldLayerData}
          fieldData={fieldData}
        />
        <FluidMatching
          filteredDatasetContent={filteredDatasetContent}
          workflowParameter={workflowParameters}
          gainValues={gainValues}
          r2History={r2History}
          analyzedWellNames={injWellNames}
          analyzedWellType={INJECTOR}
          layers={layers && [ALL_LAYERS, ...layers]}
          header={headers.InjectionFluidMatching}
        />
        <OilMatching
          reduxSelectedWorkflow={reduxSelectedWorkflow}
          workflowParameters={workflowParameters}
          filteredDatasetContent={filteredDatasetContent}
          gainValues={gainValues}
          r2Oil={r2Oil}
          oilRateHistory={oilRateHistory}
          oilRateAll={oilRateAll}
          prodWellNames={prodWellNames}
          layers={layers}
          header={headers.OilMatching}
          fieldLayerData={fieldLayerData}
          fieldData={fieldData}
        />
        <FieldAnalysis
          header={headers.FieldAnalysis}
          filteredDatasetContent={filteredDatasetContent}
          workflowParameters={workflowParameters}
        />
        <FieldResultAnalysis
          reduxSelectedWorkflow={reduxSelectedWorkflow}
          workflowParameters={workflowParameters}
          filteredDatasetContent={filteredDatasetContent}
          oilRateHistory={oilRateHistory}
          liquidRateHistory={liquidRateHistory}
          header={headers.FieldResultAnalysis}
        />
        <ShutOffAnalysis
          reduxSelectedWorkflow={reduxSelectedWorkflow}
          workflowParameters={workflowParameters}
          filteredDatasetContent={filteredDatasetContent}
          injEfficiencyData={injEfficiencyData}
          injEfficiencyLayerData={injEfficiencyLayerData}
          header={headers.InjEfficiencyAnalysis}
        />
        {!isOptimizationDisabled && (
          <Optimization
            reduxSelectedWorkflow={reduxSelectedWorkflow}
            workflowParameters={workflowParameters}
            filteredDatasetContent={filteredDatasetContent}
            oilRateHistory={oilRateHistory}
            oilMaxWellData={oilMaxWellData}
            IFactorMaxWellData={IFactorMaxWellData}
            injWellNames={injWellNames}
            prodWellNames={prodWellNames && [FIELD, ...prodWellNames]}
            header={headers.Optimization}
          />
        )}
      </React.Fragment>
    );
  else return <LoadingPage />;
};

CRMP_Layer.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default CRMP_Layer;
