import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../../../Styles/WorkflowStyles';

import Parameters from './Parameters';
import Results from './Results';

import { updateWorkflow } from '../../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../../redux/actions/feedback';

import {
  retrieveFieldInjectionData,
  retrieveWellSurfaceLocations,
} from '../../../../../../API/Functions/Visualization';
import {
  // retrieveFutureInjectionSensitivityResult,
  retrieveFutureProductionDataResult,
  retrieveInjFactorsResult,
  retrieveInjShutoffFluidResult,
  retrieveInjShutoffOilResult,
  // retrieveInjectionRatesResult,
  retrieveOilContributionResult,
  retrieveOilContributionSummaryResult,
  retrieveProductionDataResult,
  retrieveStaticGainValuesResult,
} from '../../../../../../API/Functions/CRMP';

import { Grid, Typography } from '@material-ui/core';

import { CRMP_WORKFLOW_STAGES } from '../../../../../../constants/WorkflowsParameterConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';
import { STAGE } from '../../../../../../constants/WorkflowResultConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const Optimization = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // Callbacks -->
  // <-- Callbacks

  // Handlers -->
  const runOptimization = async (params, advancedParameters) => {
    const updatedWorkflowPayload = {
      parameters: {
        ...reduxSelectedWorkflow['parameters'],
        ...params,
        ...advancedParameters,
        stage: CRMP_WORKFLOW_STAGES.OPTIMIZATION,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Optimization request sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Optimization didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchFieldData() {
      try {
        const fieldWellLocations = await retrieveWellSurfaceLocations(
          reduxSelectedWorkflow['dataset_id'],
          reduxSelectedWorkflow['parameters']
        );
        const fieldInjData = await retrieveFieldInjectionData(
          reduxSelectedWorkflow['dataset_id'],
          reduxSelectedWorkflow['parameters']
        );
        if (mounted) {
          setapiRes(prev => ({
            ...prev,
            fieldWellLocations,
            fieldInjData,
          }));
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    async function fetchOptimizationData() {
      try {
        const staticGainValues = await retrieveStaticGainValuesResult(
          reduxSelectedWorkflow['workflow_id'],
          reduxSelectedWorkflow['parameters']
        );
        const injShutoffFluidValues = await retrieveInjShutoffFluidResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const injShutoffOilValues = await retrieveInjShutoffOilResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const productionDataValues = await retrieveProductionDataResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const futureProdDataValues = await retrieveFutureProductionDataResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const injectorFactors = await retrieveInjFactorsResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const oilContrValues = await retrieveOilContributionResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const oilContrSumValues = await retrieveOilContributionSummaryResult(
          reduxSelectedWorkflow['workflow_id']
        );
        // const futureInjSensValues =
        //   await retrieveFutureInjectionSensitivityResult(
        //     reduxSelectedWorkflow['workflow_id']
        //   );
        // const injectionRateValues = await retrieveInjectionRatesResult(
        //   reduxSelectedWorkflow['workflow_id']
        // );

        if (mounted) {
          setapiRes(prev => ({
            ...prev,
            staticGainValues,
            injShutoffFluidValues,
            injShutoffOilValues,
            productionDataValues,
            futureProdDataValues,
            injectorFactors,
            oilContrValues,
            oilContrSumValues,
            // futureInjSensValues,
            // injectionRateValues,
          }));
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (
      reduxSelectedWorkflow?.parameters?.[STAGE] !==
      CRMP_WORKFLOW_STAGES.FLUID_MATCHING
    )
      fetchFieldData();
    if (
      reduxSelectedWorkflow?.parameters?.[STAGE] ===
      CRMP_WORKFLOW_STAGES.OPTIMIZATION
    )
      fetchOptimizationData();

    return () => (mounted = false);
  }, []);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentRow}>
          <Typography variant="h5">Optimization</Typography>
        </Grid>
      </Grid>
      <Parameters
        onRunOptimization={runOptimization}
        fieldInjData={apiRes?.fieldInjData || []}
        workflowParameters={reduxSelectedWorkflow.parameters}
      />
      {reduxSelectedWorkflow?.parameters?.[STAGE] ===
        CRMP_WORKFLOW_STAGES.OPTIMIZATION && (
        <Results
          workflowParameters={reduxSelectedWorkflow.parameters}
          fieldWellLocations={apiRes?.fieldWellLocations || []}
          staticGainValues={apiRes?.staticGainValues || []}
          injShutOffFluidData={apiRes?.injShutoffFluidValues || []}
          injShutOffOilData={apiRes?.injShutoffOilValues || []}
          productionData={apiRes?.productionDataValues || []}
          futureProductionData={apiRes?.futureProdDataValues || []}
          factors={apiRes?.injectorFactors || []}
          oilContribution={apiRes?.oilContrValues || []}
          oilContrSum={apiRes?.oilContrSumValues || []}
          futureInjSens={apiRes?.futureInjSensValues || []}
          injectionRates={apiRes?.injectionRateValues || []}
        />
      )}
    </React.Fragment>
  );
};

export default Optimization;
