import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import AdvancedParametersModal from './AdvancedParametersModal';
import { AveInjTable } from '../../../../../Components/ArgumentsPage/InjAverageTable';

import { Grid, Typography, TextField, Button } from '@material-ui/core';

import {
  MAX_INJ_MULT,
  OPT_R2_THRESHOLD,
  TOLERANCE_OPTIMISATION,
  MAX_ITERATION_OPTIMISATION,
  GWR_OPT,
  GWR_MIN,
  GWR_MAX,
} from '../../../../../../constants/WellConstants';
import {
  AVE_MON_INJ_LENGTH,
  CRMP_WORKFLOW_STAGES,
  FUTURE_YEARS,
  WELL_AVE_MON_INJ_VALS,
} from '../../../../../../constants/WorkflowsParameterConstants';
import { STAGE } from '../../../../../../constants/WorkflowResultConstants';

const Parameters = ({
  onRunOptimization,
  fieldInjData,
  workflowParameters,
}) => {
  const styles = useStyles();

  const [modalState, setmodalState] = useState(false);
  const [params, setparams] = useState({
    [FUTURE_YEARS]: 3,
    [AVE_MON_INJ_LENGTH]: 6,
    [WELL_AVE_MON_INJ_VALS]: [],
  });

  const [advancedParameters, setadvancedParameters] = useState({
    [MAX_ITERATION_OPTIMISATION]: 1000,
    [TOLERANCE_OPTIMISATION]: 100,
    [MAX_INJ_MULT]: 2,
    [OPT_R2_THRESHOLD]: -1,
    [GWR_OPT]: false,
    [GWR_MIN]: 0.1,
    [GWR_MAX]: 1.0,
  });

  // Callbacks -->
  const saveParams = (name, value) => {
    setparams(prev => ({ ...prev, [name]: value }));
  };

  const applyAdvancedParameters = advancedParams => {
    setadvancedParameters(advancedParams);
  };
  // Callbacks <--

  // Handlers -->
  const toggle = () => {
    setmodalState(previous => !previous);
  };
  // Handlers <--

  return (
    <Grid container className={styles.visualContentFlex}>
      <Grid container className={styles.inputContentRow + ' multipleContent'}>
        <Typography>Future Years</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          className="numericInput"
          value={params[FUTURE_YEARS]}
          onChange={event =>
            saveParams(FUTURE_YEARS, parseFloat(event.target.value))
          }
          inputProps={{
            min: 0,
            // max: 20
          }}
        />
      </Grid>
      <Grid container className={styles.inputContentRow + ' multipleContent'}>
        <Typography>{AVE_MON_INJ_LENGTH}</Typography>
        <TextField
          type="Number"
          variant="outlined"
          size="small"
          value={params[AVE_MON_INJ_LENGTH]}
          className={`${styles.numericInput} smallInput`}
          onChange={event => {
            saveParams(AVE_MON_INJ_LENGTH, parseInt(event.target.value));
          }}
          InputProps={{
            inputProps: {
              min: 1,
              max: 1000,
            },
          }}
        />
      </Grid>
      <Grid container className={styles.inputContentRow + ' multipleContent'}>
        <AveInjTable
          datasetContent={fieldInjData}
          aveInjMonth={params[AVE_MON_INJ_LENGTH]}
          wellAveInjCallback={rows => saveParams(WELL_AVE_MON_INJ_VALS, rows)}
        />
      </Grid>
      <Grid
        container
        className={styles.inputContentRow}
        justifyContent="space-between"
        style={{ width: '30%', marginLeft: 'auto' }}
      >
        <Button
          variant="contained"
          className={styles.button}
          onClick={toggle}
          style={{ textTransform: 'uppercase' }}
        >
          Advanced
        </Button>
        <Button
          variant="contained"
          className={styles.button + ' selectedButton'}
          onClick={() =>
            onRunOptimization({ ...params }, { ...advancedParameters })
          }
          style={{ textTransform: 'uppercase' }}
          disabled={
            workflowParameters[STAGE] !== CRMP_WORKFLOW_STAGES.OIL_MATCHING
          }
        >
          Optimize Injectors
        </Button>
        <AdvancedParametersModal
          modalState={modalState}
          toggle={toggle}
          applyAdvancedParameters={applyAdvancedParameters}
          advancedParameters={advancedParameters}
          workflowParameters={workflowParameters}
        />
      </Grid>
    </Grid>
  );
};

Parameters.propTypes = {
  onRunOptimization: PropTypes.func,
  fieldInjData: PropTypes.array,
  workflowParameters: PropTypes.object,
};

export default Parameters;
