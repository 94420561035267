import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../Styles/WorkflowStyles';
import { Grid, Typography } from '@material-ui/core';

const WorkflowHelpBase = ({ workflowName, workflowExplanations }) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.visualContentFlex}>
      {Object.entries(workflowExplanations).map(([title, explanation]) => (
        <Grid
          item
          key={title}
          id={workflowName}
          className={styles.visualContentRow}
        >
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{explanation}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

WorkflowHelpBase.propTypes = {
  workflowName: PropTypes.string,
  workflowExplanations: PropTypes.object,
};

export default WorkflowHelpBase;
