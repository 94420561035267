import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { UI_DATE_FORMAT } from '../../constants/WellConstants';

const MaterialUIPickers = ({ fieldTimeline, selectedDate, callback }) => {
  const [date, setdate] = useState(
    moment(selectedDate, UI_DATE_FORMAT).toDate()
  );

  const handleDateChange = date => {
    callback(moment(date).format(UI_DATE_FORMAT));
    setdate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        minDate={moment(fieldTimeline?.at(0), UI_DATE_FORMAT).toDate()}
        maxDate={moment(fieldTimeline?.at(-1), UI_DATE_FORMAT).toDate()}
        views={['year', 'month']}
        disableToolbar
        variant="inline"
        margin="normal"
        label="Date Picker"
        value={date}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

MaterialUIPickers.propTypes = {
  fieldTimeline: PropTypes.array,
  selectedDate: PropTypes.string,
  callback: PropTypes.func,
};

export default MaterialUIPickers;
