import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import AdvancedParametersModal from './AdvancedParametersModal';

import {
  Grid,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import {
  OIL,
  RATE,
  ORIGINAL,
  POWER_LAW,
  OIL_FIT_MODEL,
  ADVANCED_POWER_LAW,
  OIL_FITTING_OBJECTIVE,
  TOLERANCE_OIL_MATCHING,
  OIL_FITTING_LIQUID_RATE,
  MAX_ITERATION_OIL_MATCHING,
  OIL_CUT,
  CUMULATIVE,
  CALCULATED,
} from '../../../../../../constants/WellConstants';

const Parameters = ({ onRunOilMatching }) => {
  const styles = useStyles();

  const [modalState, setmodalState] = useState(false);
  const [params, setparams] = useState({
    [OIL_FIT_MODEL]: 'gentil',
    [OIL_FITTING_OBJECTIVE]: 'oil_rate',
    [OIL_FITTING_LIQUID_RATE]: 'original',
  });
  const [advancedParameters, setadvancedParameters] = useState({
    [MAX_ITERATION_OIL_MATCHING]: 1000,
    [TOLERANCE_OIL_MATCHING]: 10,
  });

  // Callbacks -->
  const saveParams = (name, value) => {
    setparams(prev => ({ ...prev, [name]: value }));
  };

  const applyAdvancedParameters = advancedParams => {
    setadvancedParameters(advancedParams);
  };
  // Callbacks <--

  // Handlers -->
  const toggle = () => {
    setmodalState(previous => !previous);
  };
  // Handlers <--

  return (
    <Grid container className={styles.visualContentFlex}>
      <Grid item className={styles.inputContentRow + ' multipleContent'}>
        <Typography>Oil Fitting Liquid Rate</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={params[OIL_FITTING_LIQUID_RATE]}
            onChange={e => saveParams(OIL_FITTING_LIQUID_RATE, e.target.value)}
          >
            <FormControlLabel
              value="original"
              control={<Radio />}
              label={ORIGINAL}
            />
            <FormControlLabel
              value="calculated"
              control={<Radio />}
              label={CALCULATED}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={styles.inputContentRow + ' multipleContent'}>
        <Typography>Oil Matching Model</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={params[OIL_FIT_MODEL]}
            onChange={e => saveParams(OIL_FIT_MODEL, e.target.value)}
          >
            <FormControlLabel
              value="gentil"
              control={<Radio />}
              label={POWER_LAW}
            />
            <FormControlLabel
              value="gentil_new"
              control={<Radio />}
              label={ADVANCED_POWER_LAW}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={styles.inputContentRow + ' multipleContent'}>
        <Typography>Oil Fitting Objective</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            row
            value={params[OIL_FITTING_OBJECTIVE]}
            onChange={e => saveParams(OIL_FITTING_OBJECTIVE, e.target.value)}
          >
            <FormControlLabel
              value="oil_rate"
              control={<Radio />}
              label={`${OIL} ${RATE}`}
            />
            <FormControlLabel
              value="oil_cut"
              control={<Radio />}
              label={OIL_CUT}
            />
            <FormControlLabel
              value="oil_cumulative"
              control={<Radio />}
              label={`${OIL} ${CUMULATIVE}`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        container
        className={styles.inputContentRow}
        direction="row-reverse"
      >
        <Button
          variant="contained"
          className={styles.button + ' selectedButton'}
          style={{ textTransform: 'uppercase' }}
          onClick={() =>
            onRunOilMatching({ ...params }, { ...advancedParameters })
          }
        >
          Run Oil Matching
        </Button>
        <Button
          variant="contained"
          className={styles.button}
          onClick={toggle}
          style={{ textTransform: 'uppercase' }}
        >
          Advanced
        </Button>
        <AdvancedParametersModal
          modalState={modalState}
          toggle={toggle}
          applyAdvancedParameters={applyAdvancedParameters}
          advancedParameters={advancedParameters}
        />
      </Grid>
    </Grid>
  );
};

Parameters.propTypes = {
  onRunOilMatching: PropTypes.func,
};

export default Parameters;
