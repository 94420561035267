import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import { BasicTable } from '../../../../../Components/TableView';
import LoadingPage from '../../../../../Components/LoadingPage';

import { Paper } from '@material-ui/core';

export const ResultConfigurationTable = ({
  injectors,
  producers,
  minFeatureValue,
  maxFeatureValue,
}) => {
  const styles = useStyles();
  const [tableData, settableData] = useState(null);

  useEffect(() => {
    settableData([
      {
        'Num. of injectors': injectors.length,
        'Num. of producers': producers.length,
        'Min feature value': minFeatureValue,
        'Max feature value': maxFeatureValue,
      },
    ]);
  }, [injectors, producers, minFeatureValue, maxFeatureValue]);

  return (
    <Paper elevation={5} className={styles.visualContentRow}>
      {tableData ? (
        <BasicTable data={tableData} />
      ) : (
        <LoadingPage
          message="Configuration table is loading..."
          goHome={false}
        />
      )}
    </Paper>
  );
};

ResultConfigurationTable.propTypes = {
  injectors: propTypes.array,
  producers: propTypes.array,
  minFeatureValue: propTypes.array,
  maxFeatureValue: propTypes.array,
};

export default ResultConfigurationTable;
