import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../Styles/WorkflowStyles';

import {
  Grid,
  Button,
  Checkbox,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

const Parameters = ({ applyOptimization }) => {
  const styles = useStyles();
  const [resetOptimization, setresetOptimization] = useState(false);

  // TODO: total injected fluid constant, injectio fluid rate and total producer constant
  // options doenst have any implementation in Dash code base, hence left disabled. Also
  // layer specific control option requires oil Max well data for layer, which is not available
  // for layer_data4.csv file yet.
  const [parameters, setparameters] = useState({
    constraints: [true, true, false],
    optimizationControl: 'Well Specific',
  });
  // <-- Callbacks/Handlers

  // Handlers -->
  const onConstraintChange = (index, value) => {
    setresetOptimization(false);
    let constraints = [...parameters.constraints];
    constraints[index] = value;
    setparameters(previous => {
      return {
        ...previous,
        constraints,
      };
    });
  };
  const onControlChange = optimizationControl => {
    setresetOptimization(false);
    setparameters(previous => {
      return {
        ...previous,
        optimizationControl,
      };
    });
  };
  // <-- Handlers

  return (
    <React.Fragment>
      <Grid item className={styles.inputContentRow}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Optimization Constraints</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={parameters.constraints[0]}
                  onChange={event =>
                    onConstraintChange(0, event.target.checked)
                  }
                />
              }
              label="Total injected fluid constant"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={parameters.constraints[1]}
                  onChange={event =>
                    onConstraintChange(1, event.target.checked)
                  }
                />
              }
              label="Injection fluid rate does not exceed maximum"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={parameters.constraints[2]}
                  onChange={event =>
                    onConstraintChange(2, event.target.checked)
                  }
                />
              }
              label="Total produced fluid constant"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item className={styles.inputContentRow}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Optimization Control</FormLabel>
          <RadioGroup
            row
            aria-label="Optimization Control"
            value={parameters.optimizationControl}
            onChange={event => onControlChange(event.target.value)}
          >
            <FormControlLabel
              value="Well Specific"
              control={<Radio />}
              label="Well Specific"
            />
            <FormControlLabel
              disabled
              value="Layer Specific"
              control={<Radio />}
              label="Layer Specific"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item alignItems="flex-end" className={styles.visualContentRow}>
        <Grid item>
          <Button
            disabled={resetOptimization}
            variant="outlined"
            onClick={() => applyOptimization(parameters)}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Parameters.propTypes = {
  applyOptimization: PropTypes.func,
};

export default Parameters;
