import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import {
  filterWorkflowAnalysisByWell,
  generateSummaryTable,
  generateHistoryMatchingPlot,
  generateProductionRateTestingPlot,
  generateFuturePredictionPlot,
} from '../../../../Utils/WorkflowReportUtils/ML_Rate_Prediction';

import LoadingPage from '../../../Components/LoadingPage';
import { BasicTable } from '../../../Components/TableView';

import {
  Grid,
  Paper,
  Select,
  MenuItem,
  Typography,
  FormControl,
} from '@material-ui/core';

import {
  FUTURE_YEARS,
  ML_RATE_PREDICTION_RESULT_FILE_NAMES,
} from '../../../../constants/WorkflowsParameterConstants';

import { PRODUCER, WELL_ID } from '../../../../constants/WellConstants';

const ML_Rate_Prediction = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [wellFilteredDataset, setwellFilteredDataset] = useState(null);
  const [wellFilteredAnalysisData, setwellFilteredAnalysisData] =
    useState(null);
  const [wellFilteredDataHistory, setwellFilteredDataHistory] = useState(null);
  const [wellFilteredDataPrediction, setwellFilteredDataPrediction] =
    useState(null);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [outputDataHistory, setoutputDataHistory] = useState(null);
  const [outputDataPrediction, setoutputDataPrediction] = useState(null);

  const [selectedProducer, setselectedProducer] = useState('');
  const [prodWellNames, setprodWellNames] = useState([]);

  const [summaryTableData, setsummaryTableData] = useState([]);
  const [historyMatchingPlot, sethistoryMatchingPlot] = useState(null);
  const [prodRateTestingPlot, setprodRateTestingPlot] = useState(null);
  const [futurePredictionPlot, setfuturePredictionPlot] = useState(null);
  const [analysisTableData, setanalysisTableData] = useState([]);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onWellSelect = wellName => {
    setwellFilteredAnalysisData(
      workflowAnalysisContent.filter(row => row[PRODUCER] == wellName)[0]
    );
    setanalysisTableData(
      filterWorkflowAnalysisByWell(workflowAnalysisContent, wellName)
    );
    setwellFilteredDataset(
      filteredDatasetContent.filter(row => row[WELL_ID] == wellName)
    );
    setwellFilteredDataHistory(outputDataHistory.map(row => row[wellName]));
    setwellFilteredDataPrediction(
      outputDataPrediction.map(row => row[wellName])
    );
    setselectedProducer(wellName);
  };
  // <-- Handlers

  // Retrieve required workflow analysis data from props to generate workflow report.
  useEffect(() => {
    try {
      if (workflowResultFiles) {
        setfilteredDatasetContent(
          workflowResultFiles[
            ML_RATE_PREDICTION_RESULT_FILE_NAMES.FILTERED_DATASET
          ]
        );
        setworkflowAnalysisContent(
          workflowResultFiles[
            ML_RATE_PREDICTION_RESULT_FILE_NAMES.WORKFLOW_RESULT
          ]
        );
        setoutputDataHistory(
          workflowResultFiles[
            ML_RATE_PREDICTION_RESULT_FILE_NAMES.OUTPUT_DATA_HISTORY
          ]
        );
        setoutputDataPrediction(
          workflowResultFiles[
            ML_RATE_PREDICTION_RESULT_FILE_NAMES.OUTPUT_DATA_PREDICTION
          ]
        );
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setworkflowAnalysisContent(null);
      setoutputDataHistory(null);
      setoutputDataPrediction(null);
    }
  }, [workflowResultFiles]);

  useEffect(() => {
    if (
      filteredDatasetContent &&
      workflowAnalysisContent &&
      outputDataHistory &&
      outputDataPrediction
    ) {
      let producers = workflowAnalysisContent.map(row => row[PRODUCER]);
      setprodWellNames(producers);
      onWellSelect(producers[0]);
    }
  }, [
    workflowAnalysisContent,
    workflowAnalysisContent,
    outputDataHistory,
    outputDataPrediction,
  ]);

  useEffect(() => {
    let mounted = true;
    if (
      wellFilteredAnalysisData &&
      wellFilteredDataHistory &&
      wellFilteredDataPrediction
    ) {
      const plotGenerator = async () => {
        let historyPlot = await generateHistoryMatchingPlot(
          wellFilteredAnalysisData,
          wellFilteredDataHistory,
          selectedProducer,
          wellFilteredDataset.length
        );
        let testingPlot = await generateProductionRateTestingPlot(
          wellFilteredAnalysisData,
          wellFilteredDataPrediction,
          selectedProducer,
          wellFilteredDataset.length
        );
        let predictionPlot = generateFuturePredictionPlot(
          wellFilteredAnalysisData,
          wellFilteredDataset.length,
          workflowParameters[FUTURE_YEARS]
        );

        if (mounted) {
          setsummaryTableData(generateSummaryTable(workflowAnalysisContent));
          sethistoryMatchingPlot(historyPlot);
          setprodRateTestingPlot(testingPlot);
          setfuturePredictionPlot(predictionPlot);
          setanalysisTableData(analysisTableData);
        }
      };

      plotGenerator();
    }
    return () => {
      mounted = false;
    };
  }, [
    wellFilteredAnalysisData,
    wellFilteredDataHistory,
    wellFilteredDataPrediction,
  ]);

  return (
    <React.Fragment>
      <Grid
        id={headers.SUMMARY_TABLE}
        container
        className={styles.visualContentFlex}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.SUMMARY_TABLE}</Typography>
        </Grid>
        <Paper elevation={5} className={styles.visualContentRow}>
          <BasicTable data={summaryTableData} />
        </Paper>
      </Grid>
      <Grid
        id={headers.HISTORY_MATCHING}
        container
        className={styles.visualContent}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.HISTORY_MATCHING}</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedProducer}
              onChange={event => onWellSelect(event.target.value)}
              className="dropdownSelection"
            >
              {prodWellNames.map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {historyMatchingPlot ? (
          <PlotlyComponent
            data={historyMatchingPlot.data}
            layout={historyMatchingPlot.layout}
            config={historyMatchingPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="History Matching Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid
        id={headers.PRODUCTION_RATE_TESTING}
        container
        className={styles.visualContent}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.PRODUCTION_RATE_TESTING}</Typography>
        </Grid>
        {prodRateTestingPlot ? (
          <PlotlyComponent
            data={prodRateTestingPlot.data}
            layout={prodRateTestingPlot.layout}
            config={prodRateTestingPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Production Rate Testing Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid
        id={headers.FUTURE_PREDICTION}
        container
        className={styles.visualContent}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.FUTURE_PREDICTION}</Typography>
        </Grid>
        {futurePredictionPlot ? (
          <PlotlyComponent
            data={futurePredictionPlot.data}
            layout={futurePredictionPlot.layout}
            config={futurePredictionPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Future Prediction Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid
        id={headers.OVERALL_ANALYSIS}
        container
        className={styles.visualContentFlex}
      >
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.OVERALL_ANALYSIS}</Typography>
        </Grid>
        <Paper elevation={5} className={styles.visualContentRow}>
          <BasicTable data={analysisTableData} />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

ML_Rate_Prediction.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default ML_Rate_Prediction;
