import { reduxAction } from '../base';
import { UPDATE_SNACKBAR, SET_SNACKBAR_OPEN } from './types';

/**
 * Basic redux action creator that updates the error message on snackbar redux state.
 * @param {String} message
 * @param {String} severity
 * @returns {Object} - reduxAction
 */
export const updateSnackBar = (message, severity) => {
  return reduxAction(UPDATE_SNACKBAR, {
    snackBarOpen: true,
    snackBarText: message,
    snackBarSeverity: severity,
  });
};

/**
 * Basic redux action creator that changes snackbar visibility on redux state.
 * @param {Boolean} value - snackbar visibility
 * @returns {Object} reduxAction
 */
export const setSnackBarVisibility = value => {
  return reduxAction(SET_SNACKBAR_OPEN, value);
};
