import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from '../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { BasicTable } from '../../../Components/TableView';
import LoadingPage from '../../../Components/LoadingPage';

import { createBarGraph } from '../../../../Utils/PlotlyUtils/Plots';
import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import { findImportanceLagValues, findInjectorImpData } from './Utils';

import {
  INJECTOR,
  PRODUCER,
  FEATURE_IMPORTANCE,
} from '../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

import {
  retrieveMLConnFilteredDataset,
  retrieveMLConnWorkflowResult,
} from '../../../../API/Functions/MLConnectivity';

import { updateSnackBar } from '../../../../redux/actions/feedback';

import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

const reduxSelectedWorkflowSubscriber = state =>
  state.workflow.selectedWorkflow;

const ProducerImportanceAnalysis = ({
  headers,
  selectedProducer,
  onProducerSelect,
  selectedCorrPair,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const reduxSelectedWorkflow = useSelector(reduxSelectedWorkflowSubscriber);

  const [minImp, setminImp] = useState(null);
  const [maxImp, setmaxImp] = useState(null);
  const [injWellNames, setInjWellNames] = useState(null);
  const [prodWellNames, setProdWellNames] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [injectorsImportanceData, setinjectorsImportanceData] = useState(null);

  const [apiRes, setApiRes] = useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const [datasetContent, workflowResultFile] = await Promise.all([
          retrieveMLConnFilteredDataset(reduxSelectedWorkflow['workflow_id']),
          retrieveMLConnWorkflowResult(
            reduxSelectedWorkflow['workflow_id'],
            selectedCorrPair
          ),
        ]);

        if (mounted) {
          setApiRes({
            datasetContent,
            workflowResultFile,
          });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (selectedCorrPair) {
      fetchPlotData();
    }

    return () => (mounted = false);
  }, []);

  // This useEffect acts as a change handler, since selectedProducer is a prop.
  useEffect(() => {
    if (selectedProducer && apiRes?.workflowResultFile) {
      const filteredData = apiRes.workflowResultFile.filter(
        row => row[PRODUCER] == selectedProducer
      );
      const { minImp, maxImp } = findImportanceLagValues(filteredData);
      const injImpData = findInjectorImpData(filteredData);
      setInjWellNames([
        ...new Set(apiRes.workflowResultFile.map(row => row[INJECTOR])),
      ]);
      setProdWellNames([
        ...new Set(apiRes.workflowResultFile.map(row => row[PRODUCER])),
      ]);
      setFilteredData(filteredData);
      setinjectorsImportanceData(injImpData);
      setminImp(minImp);
      setmaxImp(maxImp);
    }
  }, [selectedProducer, apiRes]);

  const cachedProducerNetworkGraph = useMemo(() => {
    if (apiRes?.datasetContent && filteredData) {
      return generateNetworkGraphWithLags(
        apiRes.datasetContent,
        filteredData,
        FEATURE_IMPORTANCE,
        minImp,
        maxImp,
        undefined,
        undefined,
        selectedProducer + ' Feature Importance Distribution Map'
      );
    }
  }, [apiRes?.datasetContent, filteredData]);

  const cachedBarPlot = useMemo(() => {
    if (injectorsImportanceData && injWellNames) {
      return createBarGraph(
        injectorsImportanceData,
        injWellNames,
        `${selectedProducer} ${FEATURE_IMPORTANCE} distribution`,
        FEATURE_IMPORTANCE
      );
    }
  }, [injectorsImportanceData, injWellNames, selectedProducer]);

  return (
    <React.Fragment>
      <Grid id={headers.PRODUCER} container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{headers.PRODUCER}</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedProducer ? selectedProducer : ''}
              onChange={event => onProducerSelect(event.target.value)}
              className="dropdownSelection"
            >
              {prodWellNames?.map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {cachedProducerNetworkGraph ? (
          <PlotlyComponent
            data={cachedProducerNetworkGraph.data}
            layout={cachedProducerNetworkGraph.layout}
            config={cachedProducerNetworkGraph.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message={selectedProducer + ' Network Plot is Loading...'}
            goHome={false}
          />
        )}
      </Grid>
      <Grid item className={styles.visualContentFlex}>
        <Grid
          id={headers.CONFIGURATION}
          container
          className={styles.visualContentFlex}
        >
          <Paper elevation={5} className={styles.visualContentRow}>
            <BasicTable data={[injectorsImportanceData]} />
          </Paper>
        </Grid>
      </Grid>

      <Grid
        id={headers.SCORE_VARIATION_WITH_LAG}
        container
        className={styles.visualContent}
      >
        {cachedBarPlot ? (
          <PlotlyComponent
            data={cachedBarPlot.data}
            layout={cachedBarPlot.layout}
            config={cachedBarPlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage message="Bar Plot is Loading..." goHome={false} />
        )}
      </Grid>
    </React.Fragment>
  );
};

ProducerImportanceAnalysis.propTypes = {
  headers: PropTypes.object,
  selectedProducer: PropTypes.string,
  onProducerSelect: PropTypes.func,
  selectedCorrPair: PropTypes.array,
  wellNames: PropTypes.object,
};
export default ProducerImportanceAnalysis;
