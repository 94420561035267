import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useStyles from '../../Styles/WorkflowStyles';
import {
  resetSelectedHeader,
  setSelectedHeader,
} from '../../redux/actions/sidebar';

import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Divider,
} from '@material-ui/core';

const reduxSelectedHeaderSubscriber = state => state.sidebar.selectedHeader;

const SideBar = ({ mainHeader, plotHeaderObject, headerCallbacks = {} }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const selectedHeader = useSelector(reduxSelectedHeaderSubscriber);

  const onClick = headerName => {
    dispatch(setSelectedHeader(headerName));

    if (Object.keys(headerCallbacks).includes(headerName)) {
      headerCallbacks[headerName]?.(headerName);
    }
  };

  // Reset selected header on unmount to prevent bugs.
  useEffect(() => {
    return () => {
      dispatch(resetSelectedHeader());
    };
  }, []);

  return (
    <Paper elevation={20} className={styles.sidebarPaperContainer}>
      <List className={styles.sidebarList}>
        <ListItem>
          <ListItemText disableTypography={true}>
            <Typography>{mainHeader}</Typography>
          </ListItemText>
        </ListItem>
        <Divider variant="middle" />
        {Object.entries(plotHeaderObject).map(headerPair => (
          <React.Fragment key={'header-' + headerPair[0]}>
            <Link>
              <ListItem
                button
                selected={selectedHeader == headerPair[1]}
                className={styles.sidebarListItem}
                onClick={() => onClick(headerPair[1])}
              >
                <ListItemText>{headerPair[1]}</ListItemText>
              </ListItem>
            </Link>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

SideBar.propTypes = {
  mainHeader: PropTypes.string,
  plotHeaderObject: PropTypes.object,
  headerCallbacks: PropTypes.object,
};

export default SideBar;
