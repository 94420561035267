import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../Components/LoadingPage';

import { generateConnectivityGraph } from '../../../../../Utils/CommonReportUtil';

import { Grid, Slider, Typography } from '@material-ui/core';
import {
  CRMP,
  GAIN,
  ML_CONNECTIVITY,
} from '../../../../../constants/WellConstants';

const WellAnalysisConnectivityPlot = ({
  filteredDatasetContent,
  wellFeatureValues,
  minValue,
  maxValue,
  resultProperty = GAIN,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [networkPlot, setnetworkPlot] = useState(null);

  const [sliderValue, setsliderValue] = useState([0, 1]);

  // Callbacks/Helpers -->
  const generateNetworkPlotDataset = sliderValue => {
    setnetworkPlot(
      generateConnectivityGraph(
        filteredDatasetContent,
        wellFeatureValues,
        resultProperty,
        sliderValue[0],
        sliderValue[1],
        resultProperty + ' Well Analysis',
        undefined
      )
    );
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * This handler updated Correlation slider min and max value, then it invokes an update
   * to the network graph.
   * @param {Event} event
   * @param {Array} newValue
   */
  const gainSliderHandler = (event, newValue) => {
    setsliderValue(newValue);
    generateNetworkPlotDataset(newValue);
  };
  // <-- Handlers

  useEffect(() => {
    if (filteredDatasetContent && wellFeatureValues) {
      generateNetworkPlotDataset([minValue, maxValue]);
    }
  }, [filteredDatasetContent, wellFeatureValues]);

  return (
    <React.Fragment>
      <Grid item className={styles.visualContentRow}>
        <Typography variant="h6">
          {resultProperty == GAIN ? CRMP : ML_CONNECTIVITY} Well Analysis
        </Typography>
      </Grid>
      <Grid item className={styles.visualContentRow}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>{resultProperty} Value Range</Typography>
          <Slider
            min={minValue}
            step={0.01}
            max={maxValue}
            value={sliderValue}
            onChange={gainSliderHandler}
            valueLabelDisplay="auto"
            style={{ width: '80%' }}
          />
        </Grid>
      </Grid>
      {networkPlot ? (
        <PlotlyComponent
          data={networkPlot.data}
          layout={networkPlot.layout}
          config={networkPlot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Injector Network Plot is Loading..."
          goHome={false}
        />
      )}
    </React.Fragment>
  );
};

WellAnalysisConnectivityPlot.propTypes = {
  filteredDatasetContent: PropTypes.array,
  wellFeatureValues: PropTypes.array,
  fieldInjNames: PropTypes.array,
  fieldProdNames: PropTypes.array,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  resultProperty: PropTypes.string,
};

export default WellAnalysisConnectivityPlot;
