import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useStyles from '../../../../../../Styles/WorkflowStyles';

import { PropTypes } from 'prop-types';

import Parameters from './Parameters';
import Results from './Results';

import {
  retrieveOilProdRateResult,
  retrieveR2RMSEOilMatchingResult,
} from '../../../../../../API/Functions/CRMP';
import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveFieldWellNames } from '../../../../../../API/Functions/Visualization';

import { Grid, Typography } from '@material-ui/core';
import { updateWorkflow } from '../../../../../../redux/actions/workflows';

import { STAGE } from '../../../../../../constants/WorkflowResultConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';
import { CRMP_WORKFLOW_STAGES } from '../../../../../../constants/WorkflowsParameterConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const OilMatching = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // Callbacks -->
  // <-- Callbacks

  // Handlers -->
  const runOilMatching = async (params, advancedParameters) => {
    const updatedWorkflowPayload = {
      parameters: {
        ...reduxSelectedWorkflow['parameters'],
        ...params,
        ...advancedParameters,
        stage: CRMP_WORKFLOW_STAGES.OIL_MATCHING,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Oil Matching request sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Oil Matching didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchFieldData() {
      try {
        const fieldWellNames = await retrieveFieldWellNames(
          reduxSelectedWorkflow['dataset_id']
        );
        const oilMatchingResults = await retrieveOilProdRateResult(
          reduxSelectedWorkflow['workflow_id']
        );
        const r2OilMatchingResults = await retrieveR2RMSEOilMatchingResult(
          reduxSelectedWorkflow['workflow_id']
        );

        if (mounted) {
          setapiRes({
            fieldWellNames,
            oilMatchingResults,
            r2OilMatchingResults,
          });
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (
      reduxSelectedWorkflow?.parameters?.[STAGE] !==
      CRMP_WORKFLOW_STAGES.FLUID_MATCHING
    )
      fetchFieldData();
    return () => (mounted = false);
  }, []);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentRow}>
          <Typography variant="h5">Oil Matching</Typography>
        </Grid>
      </Grid>
      <Parameters onRunOilMatching={runOilMatching} />
      {apiRes ? (
        <Results
          workflowParameters={reduxSelectedWorkflow.parameters}
          oilMatchingData={apiRes.oilMatchingResults}
          r2RMSEData={apiRes.r2OilMatchingResults}
          wellNames={apiRes.fieldWellNames}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

OilMatching.propTypes = {
  header: PropTypes.string,
  workflowParameters: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  reduxSelectedWorkflow: PropTypes.object,
  oilMatchingData: PropTypes.array,
  r2RMSEData: PropTypes.array,
};

export default OilMatching;
