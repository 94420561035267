import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import LoadingPage from '../../../Components/LoadingPage';
import ResultAccuracyPlot from './ResultAccuracy';
import ResultOrientedAnalysis from './ResultOrientedAnalysis';

import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';

import {
  MULTI_WORKFLOW_COMPARISON_FILE_NAMES,
  SELECTED_WORKFLOW_TYPE,
} from '../../../../constants/WorkflowsParameterConstants';

import { Grid } from '@material-ui/core';

import {
  CRMP,
  FEATURE_IMPORTANCE,
  GAIN,
  WELL_ID,
} from '../../../../constants/WellConstants';

const MultiWorkflowComparison = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const styles = useStyles();

  const [workflowReady, setworkflowReady] = useState(false);

  const [resultFileNames, setresultFileNames] = useState(null);

  const [fieldInjNames, setfieldInjNames] = useState(null);
  const [fieldProdNames, setfieldProdNames] = useState(null);
  const [focusedFeature, setfocusedFeature] = useState(null);

  const [allWorkflowResultDetails, setallWorkflowResultDetails] =
    useState(null);
  const [superSetFilteredDatasetContent, setsuperSetFilteredDatasetContent] =
    useState(null);

  // Callbacks -->
  const findWellFieldDataSuperSet = () => {
    const superSet = {};

    Object.values(workflowResultFiles)
      .map(
        resultFiles =>
          resultFiles[MULTI_WORKFLOW_COMPARISON_FILE_NAMES.FILTERED_DATASET]
      )
      .forEach(dataset => {
        dataset.forEach(row => {
          if (!(row[WELL_ID] in superSet)) {
            superSet[row[WELL_ID]] = row;
          }
        });
      });

    return Object.values(superSet);
  };

  const findWorkflowAnalysisFiles = () => {
    const resultFiles = {};
    Object.entries(workflowResultFiles).forEach(([key, results]) => {
      resultFiles[key] =
        results[
          MULTI_WORKFLOW_COMPARISON_FILE_NAMES[
            workflowParameters[SELECTED_WORKFLOW_TYPE]
          ]
        ];
    });
    return resultFiles;
  };
  // <-- Callbacks

  // Handlers -->
  // <-- Handlers

  useEffect(() => {
    if (workflowResultFiles && workflowParameters) {
      const wellDataSuperSet = findWellFieldDataSuperSet();
      const workflowAnalysisFiles = findWorkflowAnalysisFiles();
      const { injectors, producers } =
        filterWellNamesFromDataset(wellDataSuperSet);

      setallWorkflowResultDetails(workflowAnalysisFiles);
      setsuperSetFilteredDatasetContent(wellDataSuperSet);
      setfieldInjNames(injectors);
      setfieldProdNames(producers);
      setresultFileNames(Object.keys(workflowResultFiles));
      setfocusedFeature(
        workflowParameters[SELECTED_WORKFLOW_TYPE] == CRMP
          ? GAIN
          : FEATURE_IMPORTANCE
      );
      setworkflowReady(true);
    }

    return () => {
      setworkflowReady(false);
    };
    // const extractedWorkflowDetails = Object.entries(workflowResultFiles).map(
    //   ([key, workflowContent]) => {
    //     const processedWorkflowDetails = extractResultModelDetails(
    //       key,
    //       workflowContent
    //     );
    //     return {
    //       [key]: processedWorkflowDetails,
    //     };
    //   }
    // );
    // console.log(extractResultModelDetails);
  }, [workflowResultFiles, workflowParameters]);

  // useEffect(() => {
  //   if (workflowResultFiles && workflowParameters) {
  //     const filteredDataset =
  //       workflowResultFiles[CRMP_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET];
  //     const gainValueFileNames = Object.keys(workflowResultFiles).filter(
  //       fileName =>
  //         !fileName.includes(CRMP_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET)
  //     );
  //     const { injectors: fieldInjs, producers: fieldProds } =
  //       filterWellNamesFromDataset(filteredDataset);

  //     setfieldInjNames(fieldInjs);
  //     setfieldProdNames(fieldProds);
  //     setresultFileNames(gainValueFileNames);

  //     setfilteredDatasetContent(filteredDataset);
  //     setworkflowReady(true);
  //   }

  //   return () => {
  //     setworkflowReady(false);
  //   };
  // }, [workflowResultFiles, workflowParameters]);

  if (workflowReady) {
    return (
      <React.Fragment>
        <Grid
          container
          id={headers.CONFIGURATIONS}
          style={{
            width: '100%',
            padding: '1%',
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
        >
          <Grid container xs={6} className={styles.visualContentFlex}>
            <ResultOrientedAnalysis
              headers={headers}
              injNames={fieldInjNames}
              prodNames={fieldProdNames}
              workflowResultFiles={allWorkflowResultDetails}
              datasetContent={superSetFilteredDatasetContent}
              focusedFeature={focusedFeature}
              resultFileNames={resultFileNames}
            />
          </Grid>
          <Grid container xs={6} className={styles.visualContentFlex}>
            <ResultOrientedAnalysis
              headers={headers}
              injNames={fieldInjNames}
              prodNames={fieldProdNames}
              workflowResultFiles={allWorkflowResultDetails}
              datasetContent={superSetFilteredDatasetContent}
              focusedFeature={focusedFeature}
              resultFileNames={resultFileNames}
            />
          </Grid>
        </Grid>
        <ResultAccuracyPlot
          fieldInjNames={fieldInjNames}
          fieldProdNames={fieldProdNames}
          resultFileNames={resultFileNames}
          workflowResultFiles={allWorkflowResultDetails}
          filteredDatasetContent={superSetFilteredDatasetContent}
          header={headers.RESULT_THRESHOLD_ACCURACY}
        />
      </React.Fragment>
    );
  } else {
    return <LoadingPage />;
  }
};

MultiWorkflowComparison.propTypes = {
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
  headers: PropTypes.object,
};

export default MultiWorkflowComparison;
