import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../Styles/VisualizationPageStyle';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';

import { retrieveWellActivitySchedule } from '../../../../API/Functions/Visualization';

import { generateSchedulePlot } from '../../../../Utils/VisualizationUtils';
import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';

import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSnackBar } from '../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

const selectedDatasetParameters = state => state.dataset.parameters;

const ActiveWellScheduleComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedDatasetParameters = useSelector(selectedDatasetParameters);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveWellActivitySchedule(
          urlDatasetId,
          reduxSelectedDatasetParameters
        );
        if (mounted) {
          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, [reduxSelectedDatasetParameters]);

  const cachedPlotResult = useMemo(() => {
    if (apiRes) {
      const { injectors, producers } = filterWellNamesFromDataset(apiRes);
      return generateSchedulePlot(apiRes, injectors, producers);
    }
  }, [apiRes]);

  return (
    <Grid item className={styles.plotContainer}>
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

ActiveWellScheduleComponent.propTypes = {
  header: PropTypes.string,
};

export default ActiveWellScheduleComponent;
