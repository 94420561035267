import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../Styles/VisualizationPageStyle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';
import CustomizeModal from '../../../Components/CustomizablePlotModal';

import {
  retrieveCustomWellAnalysisData,
  retrieveFieldWellNames,
  retrieveActiveFieldAttrs,
} from '../../../../API/Functions/Visualization';

import { generateCustomizablePlot } from '../../../../Utils/VisualizationUtils';

import { Button, Grid } from '@material-ui/core';

import {
  INJECTOR,
  OIL_PRODUCTION_RATE,
  PER_DAY,
  PRODUCER,
  SYSTEM_OF_UNITS,
  WATER_INJECTION_RATE,
} from '../../../../constants/WellConstants';
import { updateSnackBar } from '../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

const selectedDatasetParameters = state => state.dataset.parameters;

const CustomWellRateAnalysisComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedDatasetParameters = useSelector(selectedDatasetParameters);
  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [allAttr, setallAttr] = useState(undefined);
  const [allWellNames, setallWellNames] = useState(undefined);
  const [configurations, setconfigurations] = useState(undefined);
  const [configModalState, setconfigModalState] = useState(false);

  // Callbacks/Helpers -->
  const applyConfigurations = newConfiguration => {
    setconfigurations(newConfiguration);
  };

  const toggleConfigModal = () => {
    setconfigModalState(previous => !previous);
  };

  const saveWellNameConfigs = (wellNames, wellType, initConfig) => {
    wellNames.reduce((acc, curr) => {
      acc[wellType].wellNamePairs[curr] = false;
      return acc;
    }, initConfig);
  };

  const saveWellAttrConfigs = (wellAttrs, wellType, initConfig) => {
    wellAttrs.reduce((acc, curr) => {
      acc[wellType].attrPairs[curr] = false;
      return acc;
    }, initConfig);
  };

  const activateRandomWells = (allWellNamesObj, initConfig) => {
    for (let i = 0; i < 5; i++) {
      if (Object.keys(initConfig[INJECTOR].wellNamePairs).length > i)
        initConfig[INJECTOR].wellNamePairs[allWellNamesObj[INJECTOR][i]] = true;
      if (Object.keys(initConfig[PRODUCER].wellNamePairs).length > i)
        initConfig[PRODUCER].wellNamePairs[allWellNamesObj[PRODUCER][i]] = true;
    }
    initConfig[INJECTOR].attrPairs[WATER_INJECTION_RATE] = true;
    initConfig[PRODUCER].attrPairs[OIL_PRODUCTION_RATE] = true;
  };

  const initConfigurations = (allWellNames, allWellAttrs) => {
    const initConfig = {
      [INJECTOR]: {
        wellNamePairs: {},
        attrPairs: {},
      },
      [PRODUCER]: {
        wellNamePairs: {},
        attrPairs: {},
      },
    };

    saveWellNameConfigs(allWellNames[INJECTOR], INJECTOR, initConfig);
    saveWellNameConfigs(allWellNames[PRODUCER], PRODUCER, initConfig);
    saveWellAttrConfigs(allWellAttrs[INJECTOR], INJECTOR, initConfig);
    saveWellAttrConfigs(allWellAttrs[PRODUCER], PRODUCER, initConfig);
    activateRandomWells(allWellNames, initConfig);
    return initConfig;
  };

  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  // Will only run once to fetch all well names and attributes.
  useEffect(() => {
    let mounted = true;
    async function fetchWellNames() {
      try {
        const res = await retrieveFieldWellNames(urlDatasetId);
        if (mounted) {
          setallWellNames(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }
    async function fetchActiveFieldAttr() {
      try {
        const res = await retrieveActiveFieldAttrs(urlDatasetId);
        if (mounted) {
          setallAttr(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchWellNames();
    fetchActiveFieldAttr();

    return () => (mounted = false);
  }, []);

  // Will only run once to initialize configurations.
  useEffect(() => {
    if (allAttr && allWellNames) {
      setconfigurations(initConfigurations(allWellNames, allAttr));
    }
  }, [allAttr, allWellNames]);

  // Will run every time configurations are updated to fetch plot data.
  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveCustomWellAnalysisData(urlDatasetId, {
          ...reduxSelectedDatasetParameters,
          configurations: configurations,
        });
        if (mounted) {
          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    if (configurations) fetchPlotData();

    return () => (mounted = false);
  }, [reduxSelectedDatasetParameters, configurations]);

  // Will run every time configurations are updated to generate plot.
  const cachedPlotResult = useMemo(() => {
    if (apiRes && configurations) {
      return generateCustomizablePlot(
        configurations,
        apiRes,
        reduxSelectedDatasetParameters[SYSTEM_OF_UNITS],
        PER_DAY
      );
    }
  }, [apiRes, configurations]);

  return (
    <Grid className={styles.plotContainer}>
      <Grid item container className={styles.buttonContainer}>
        <Button onClick={toggleConfigModal}>Customize</Button>
      </Grid>
      {configurations && (
        <CustomizeModal
          allAttr={allAttr}
          allWellNames={allWellNames}
          toggle={toggleConfigModal}
          modalState={configModalState}
          configObject={configurations}
          applyConfigurations={applyConfigurations}
        />
      )}
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

CustomWellRateAnalysisComponent.propTypes = {
  header: PropTypes.string,
};

export default CustomWellRateAnalysisComponent;
