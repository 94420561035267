import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import moment from 'moment';

import LoadingPage from '../../../Components/LoadingPage';
import ProductionCut from './ProductionCut';
import ClusterView from './ClusterView';
import { BasicTable } from '../../../Components/TableView';

import {
  filterTimelineFromDataset,
  filterWellNamesFromDataset,
} from '../../../../Utils/DatasetUtils/DataProcessing';

import { Grid, Paper, Slider } from '@material-ui/core';

import {
  OIL_PRODUCTION_RATE,
  YEARLY_WATER_CUT,
  YEARLY_OIL_CUT,
  YEARLY_WOR,
  YEARLY_GOR,
  YEAR,
  WELL_ID,
} from '../../../../constants/WellConstants';

import { TREND_ANALYSIS_WORKFLOW_RESULT_FILE_NAMES } from '../../../../constants/WorkflowsParameterConstants';

const TrendAnalysis = ({ headers, workflowResultFiles }) => {
  const styles = useStyles();
  const [workflowReady, setworkflowReady] = useState(false);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [selectedYearData, setselectedYearData] = useState(null);

  const [yearMarks, setyearMarks] = useState(null);
  const [selectedYear, setselectedYear] = useState(null);
  const [prodWellNames, setprodWellNames] = useState(null);

  // Callbacks/Helpers -->
  const updateSelectedYearData = year => {
    setselectedYearData(
      workflowAnalysisContent
        .filter(row => row[YEAR] == year)
        .map(well => {
          return {
            [WELL_ID]: well[WELL_ID],
            [YEAR]: well[YEAR],
            [OIL_PRODUCTION_RATE]: well[OIL_PRODUCTION_RATE],
            [YEARLY_WATER_CUT]: well[YEARLY_WATER_CUT],
            [YEARLY_OIL_CUT]: well[YEARLY_OIL_CUT],
            [YEARLY_WOR]: well[YEARLY_WOR],
            [YEARLY_GOR]: well[YEARLY_GOR],
          };
        })
    );
  };

  const exctractYearDataFromDataset = () => {
    const years = [
      ...new Set(
        filterTimelineFromDataset(filteredDatasetContent).map(date =>
          Number(moment(date).format('YYYY'))
        )
      ),
    ];
    setyearMarks(
      years.map(year => {
        return { value: year, label: year };
      })
    );
    setselectedYear(years[0]);
    updateSelectedYearData(years[0]);
  };

  const extractWellNamesFromDataset = () => {
    const { producers } = filterWellNamesFromDataset(filteredDatasetContent);
    setprodWellNames(producers);
  };

  /**
   * This function enhances the uploaded dataset. It generates FLUID_PRODUCTION_RATE, OIL_CUT, and
   * WATER_CUT datafields. Then it saves neccessary fields to useState variables.
   */
  const processDataContent = () => {
    exctractYearDataFromDataset();
    extractWellNamesFromDataset();
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  const yearSliderHandler = (event, newValue) => {
    setselectedYear(newValue);
    updateSelectedYearData(newValue);
  };
  // <-- Handlers

  // Retrieve required workflow analysis data from props to generate workflow report.
  useEffect(() => {
    try {
      if (workflowResultFiles) {
        setfilteredDatasetContent(
          workflowResultFiles[
            TREND_ANALYSIS_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET
          ]
        );
        setworkflowAnalysisContent(
          workflowResultFiles[
            TREND_ANALYSIS_WORKFLOW_RESULT_FILE_NAMES.WORKFLOW_RESULT
          ]
        );
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setworkflowAnalysisContent(null);
    }
  }, [workflowResultFiles]);

  // Process the recieved data accordingly.
  useEffect(() => {
    if (filteredDatasetContent && workflowAnalysisContent) {
      processDataContent();
      setworkflowReady(true);
    }
    return () => {
      setworkflowReady(false);
    };
  }, [filteredDatasetContent, workflowAnalysisContent]);

  if (workflowReady) {
    return (
      <React.Fragment>
        <ProductionCut
          workflowAnalysisContent={workflowAnalysisContent}
          prodWellNames={prodWellNames}
          headerName={headers.ProductionCut}
        />

        <ClusterView
          workflowAnalysisContent={workflowAnalysisContent}
          selectedYear={selectedYear}
          headerName={headers.ClusterView}
        />

        <Grid container className={styles.visualContentFlex}>
          {yearMarks ? (
            <Slider
              id={headers.Timeline}
              min={yearMarks[0].label}
              max={yearMarks[yearMarks.length - 1].label}
              marks={yearMarks}
              value={selectedYear}
              onChange={yearSliderHandler}
              style={{
                margin: '0% 10% 5% 10%',
                width: '80%',
              }}
            />
          ) : (
            ''
          )}
          <Grid
            item
            id={headers.OverallAnalysis}
            style={{
              margin: '0% 5% 5% 5%',
              width: '90%',
            }}
          >
            <Paper elevation={5} className={styles.visualContentRow}>
              <BasicTable data={selectedYearData} />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return <LoadingPage />;
  }
};

TrendAnalysis.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
};

export default TrendAnalysis;
