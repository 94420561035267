import {
  GAS,
  CRMP,
  LAYER,
  METRIC,
  FORMATION_VFG,
  FORMATION_VFO,
  FORMATION_VFW,
  DATA_SMOOTHING,
  DIAGNOSTIC_PLOT,
  ML_CONNECTIVITY,
  SYSTEM_OF_UNITS,
  CROSS_CORRELATION,
  BOTTOMHOLE_PRESSURE,
  NORMALIZE_ACTIVE_DAYS,
  DATA_SMOOTHING_FACTOR,
  TOLERANCE_FLUID_MATCHING,
  MAX_ITERATION_FLUID_MATCHING,
} from './WellConstants';

// Arps Decline Curve Constants
export const SELECTED_WELL = 'Selected Well';
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';
export const INITIAL_VALUE = 'Initial Value';
export const FINAL_VALUE = 'Final Value';
export const METHOD = 'Method';
export const METHODS = 'Methods';
export const FUTURE_YEARS = 'Future Years';
export const ECONOMIC_LIMIT = 'Economic Limit';
export const CUSTOM = 'Custom';
export const FIRST_VALUE = 'First Value';
export const LAST_VALUE = 'Last Value';
export const NONE = 'None';
export const EXPONENTIAL = 'Exponential';
export const HARMONIC = 'Harmonic';
export const HYPERBOLIC = 'Hyperbolic';
export const ALL = 'All';

export const TIME_SPAN_1 = 'Time Span 1';
export const TIME_SPAN_2 = 'Time Span 2';
export const SELECTED_PRODUCERS = 'Selected Producers';
export const SELECTED_INJECTORS = 'Selected Injectors';
export const EXCLUDED_PRODUCERS = 'Excluded Producers';
export const ZERO_WATER_DRIVE = 'Zero Water Drive';
export const WELL_PAIRS = 'Well Pairs';
export const RADIUS_OF_INVESTIGATION = 'Radius of investigation';
export const PSEUDO = 'Include Pseudo Injectors';
export const WATER_DRIVE = 'Water Drive';
export const OPERATION = 'Operation';
export const BASE_MODEL_ID = 'Base Model Id';
export const BASE_MODEL_RESULT_PATH = 'Base Model Result Path';
export const RESULT_MODEL_1 = 'Result Model 1';
export const RESULT_MODEL_2 = 'Result Model 2';
export const SELECTED_WORKFLOW_TYPE = 'Selected Workflow Type';
export const SELECTED_WORKFLOWS = 'Selected Workflows';
export const INJECTION_CALCULATION_TYPE = 'Injection Calculation Type';
export const AVE_MON_INJ_LENGTH = 'Average Monthly Injection Length';
export const WELL_AVE_MON_INJ_VALS = 'Average Monthly Injection Values';
export const BASE_ML_MODEL_ID = 'Base ML Model Id';
export const BASE_FMM_MODEL_ID = 'Base FMM Model Id';
export const UPLOADED_WELL_PAIRS = 'Uploaded Well Pairs';

export const TIME_PERIODS = 'Time Periods';
export const TIME_SHIFT = 'Time Shift';

export const CORRELATION_TYPE = 'Correlation Type';
export const SPEARMAN = 'Spearman';
export const PEARSON = 'Pearson';
export const INJECTOR_SIGNALS = 'Injector Signals';
export const PRODUCER_SIGNALS = 'Producer Signals';

export const MODEL = 'Model';
export const MODEL_TYPE = 'Model Type';
export const RANDOM_FOREST = 'Random Forest';
export const LINEAR_REGRESSION = 'Linear Regression';

export const ELASTIC_NET = 'ElasticNet';
export const RIDGE_REGRESSION = 'Ridge Regression';
export const GRADIENT_BOOST = 'Gradient Boosted DT';
export const MLPR = 'MLPR';
export const FIND_BEST = 'Find Best';

// Polymer Injection Workflow Constants -->
export const OIL_VISCOSITY_MIN = 'Oil Viscosity Minimum';
export const OIL_VISCOSITY_MAX = 'Oil Viscosity Maximum';
export const WATER_VISCOSITY_MIN = 'Water Viscosity Minimum';
export const WATER_VISCOSITY_MAX = 'Water Viscosity Maximum';
export const OIL_RELATIVE_PERMEABILITY_ENDPOINT_MIN =
  'Oil Relative Permeability Endpoint Minimum';
export const OIL_RELATIVE_PERMEABILITY_ENDPOINT_MAX =
  'Oil Relative Permeability Endpoint Maximum';
export const WATER_RELATIVE_PERMEABILITY_ENDPOINT_MIN =
  'Water Relative Permeability Endpoint Minimum';
export const WATER_RELATIVE_PERMEABILITY_ENDPOINT_MAX =
  'Water Relative Permeability Endpoint Maximum';
export const COREY_OIL_EXPONENT_MIN = 'Corey Oil Exponent Minimum';
export const COREY_OIL_EXPONENT_MAX = 'Corey Oil Exponent Maximum';
export const COREY_WATER_EXPONENT_MIN = 'Corey Water Exponent Minimum';
export const COREY_WATER_EXPONENT_MAX = 'Corey Water Exponent Maximum';
export const CONNATE_WATER_SATURATION_MIN = 'Connate Water Saturation Minimum';
export const CONNATE_WATER_SATURATION_MAX = 'Connate Water Saturation Maximum';
export const IRREDUCIBLE_OIL_SATURATION_MIN =
  'Irreducible Oil Saturation Minimum';
export const IRREDUCIBLE_OIL_SATURATION_MAX =
  'Irreducible Oil Saturation Maximum';
export const INITIAL_WATER_SATURATION_MIN = 'Initial Water Saturation Minimum';
export const INITIAL_WATER_SATURATION_MAX = 'Initial Water Saturation Maximum';
export const PORE_VOLUME_DIMENSIONLESS_MIN =
  'Pore Volume Dimensionless Minimum';
export const PORE_VOLUME_DIMENSIONLESS_MAX =
  'Pore Volume Dimensionless Maximum';
export const TRAIN_TEST_SPLIT = 'Train Test Split';
export const VISCOSITY_RATIO = 'Viscosity Ratio';
export const N_YEARS = 'N Years';
export const POLYMER_ITERATION = 'Polymer Iteration';
// <-- Polymer Injection Workflow Constants

export const ACTIVE_WELL = 'Active Well';
export const SELECTED_WELLS = 'Selected Wells';
export const PRODUCER_COUNT = 'Producer Count';
export const INJECTOR_COUNT = 'Injector Count';
export const ACTIVE_PRODUCER_COUNT = 'Active Producer Count';
export const ACTIVE_INJECTOR_COUNT = 'Active Injector Count';

export const DEFAULT_VALUE_TRAIN_TEST_SPLIT = 0.9;

export const SURVEILLANCE_DATASET_VISUALIZATION_PARAMS = {
  [SYSTEM_OF_UNITS]: METRIC,
  [FORMATION_VFO]: 1.1,
  [FORMATION_VFW]: 1.0,
  [FORMATION_VFG]: 0.01,
  [DATA_SMOOTHING_FACTOR]: 4,
  [START_DATE]: '',
  [END_DATE]: '',
  [NORMALIZE_ACTIVE_DAYS]: false,
  [DATA_SMOOTHING]: false,
  [LAYER]: null,
  [SELECTED_WELLS]: [],
};
export const ARPS_DECLINE_CURVE_PARAMS = {
  [SELECTED_WELL]: '',
  [INITIAL_VALUE]: FIRST_VALUE,
  [FINAL_VALUE]: LAST_VALUE,
  [METHOD]: ALL,
  [FUTURE_YEARS]: 3,
  [ECONOMIC_LIMIT]: 0,
};

export const CONFORMANCE_PARAMS = {
  [TIME_SPAN_1]: {
    [WELL_PAIRS]: [],
    [EXCLUDED_PRODUCERS]: [],
    [RADIUS_OF_INVESTIGATION]: 0,
    [PSEUDO]: false,
    [WATER_DRIVE]: false,
    [BOTTOMHOLE_PRESSURE]: false,
  },
  [TIME_SPAN_2]: {
    [WELL_PAIRS]: [],
    [EXCLUDED_PRODUCERS]: [],
    [RADIUS_OF_INVESTIGATION]: 0,
    [PSEUDO]: false,
    [WATER_DRIVE]: false,
    [BOTTOMHOLE_PRESSURE]: false,
  },
  [OPERATION]: CRMP,
};

export const CRMP_PARAMS = {
  [WELL_PAIRS]: [],
  [EXCLUDED_PRODUCERS]: [],
  [RADIUS_OF_INVESTIGATION]: 1000,
  [MAX_ITERATION_FLUID_MATCHING]: 1000,
  [TOLERANCE_FLUID_MATCHING]: 100,
  [PSEUDO]: false,
  [WATER_DRIVE]: false,
  [BOTTOMHOLE_PRESSURE]: false,
  [INJECTION_CALCULATION_TYPE]: 'Both',
  [AVE_MON_INJ_LENGTH]: 6,
  [BASE_FMM_MODEL_ID]: '',
  [BASE_ML_MODEL_ID]: '',
  [UPLOADED_WELL_PAIRS]: '',
};

export const CRMP_LAYER_PARAMS = {
  [WELL_PAIRS]: [],
  [EXCLUDED_PRODUCERS]: [],
  [RADIUS_OF_INVESTIGATION]: 1000,
  [MAX_ITERATION_FLUID_MATCHING]: 1000,
  [TOLERANCE_FLUID_MATCHING]: 100,
  [PSEUDO]: false,
  [WATER_DRIVE]: false,
  [BOTTOMHOLE_PRESSURE]: false,
};

export const CRMT_PARAMS = {
  [TIME_PERIODS]: [],
};

export const CROSS_CORRELATION_PARAMS = {
  [RADIUS_OF_INVESTIGATION]: 1000,
  [CORRELATION_TYPE]: SPEARMAN,
};

export const DIAGNOSTIC_PLOT_PARAMS = {};

export const ML_CONNECTIVITY_PARAMS = {
  [MODEL_TYPE]: LINEAR_REGRESSION,
  [RADIUS_OF_INVESTIGATION]: 1000,
  [TIME_SHIFT]: 10,
  [INJECTION_CALCULATION_TYPE]: GAS,
  [INJECTOR_SIGNALS]: [],
  [PRODUCER_SIGNALS]: [],
};

export const ML_RATE_PREDICTION_PARAMS = {
  [FUTURE_YEARS]: 3,
  [METHODS]: [FIND_BEST],
};

export const TREND_ANALYSIS_PARAMS = {};

export const CROSS_COMPARISON_PARAMS = {
  [RESULT_MODEL_1]: undefined,
  [RESULT_MODEL_2]: undefined,
};

export const COMPARE_RESULT_WORKFLOWS = [
  CROSS_CORRELATION,
  ML_CONNECTIVITY,
  CRMP,
];

export const POLY_INJECTION_PARAMS = {
  [BASE_MODEL_ID]: '',
  [POLYMER_ITERATION]: 0,
  [OIL_VISCOSITY_MIN]: 1,
  [OIL_VISCOSITY_MAX]: 100,
  [WATER_VISCOSITY_MIN]: 0.5,
  [WATER_VISCOSITY_MAX]: 1,
  [OIL_RELATIVE_PERMEABILITY_ENDPOINT_MIN]: 0.01,
  [OIL_RELATIVE_PERMEABILITY_ENDPOINT_MAX]: 1,
  [WATER_RELATIVE_PERMEABILITY_ENDPOINT_MIN]: 0.01,
  [WATER_RELATIVE_PERMEABILITY_ENDPOINT_MAX]: 1,
  [CONNATE_WATER_SATURATION_MIN]: 0.2,
  [CONNATE_WATER_SATURATION_MAX]: 0.5,
  [IRREDUCIBLE_OIL_SATURATION_MIN]: 0.2,
  [IRREDUCIBLE_OIL_SATURATION_MAX]: 1,
  [INITIAL_WATER_SATURATION_MIN]: 0.21,
  [INITIAL_WATER_SATURATION_MAX]: 1,
  [COREY_WATER_EXPONENT_MIN]: 1,
  [COREY_WATER_EXPONENT_MAX]: 4,
  [COREY_OIL_EXPONENT_MIN]: 1.5,
  [COREY_OIL_EXPONENT_MAX]: 5,
  [PORE_VOLUME_DIMENSIONLESS_MIN]: 0.01,
  [PORE_VOLUME_DIMENSIONLESS_MAX]: 1,
  [TRAIN_TEST_SPLIT]: 0.9,
  [VISCOSITY_RATIO]: 2,
  [N_YEARS]: 5,
};

export const POLY_INJECTION_WORKFLOWS = [CRMP];

export const MULTI_WORKFLOW_COMPARISON_MODEL_OPTIONS = [CRMP, ML_CONNECTIVITY];

export const MULTI_WORKFLOW_COMPARISON_PARAMS = {
  [SELECTED_WORKFLOW_TYPE]: MULTI_WORKFLOW_COMPARISON_MODEL_OPTIONS[0],
  [SELECTED_WORKFLOWS]: [],
};

export const ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  SDF: 'sdf.csv',
  SUMMARY_DF: 'summary_df.csv',
  SDF_FUTURE: 'future_analysis_sdf.csv',
  FUTURE_DF: 'future_analysis_df.csv',
};

export const TREND_ANALYSIS_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  WORKFLOW_RESULT: 'trend_analysis_results.csv',
};

export const DIAGNOSTIC_PLOTS_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  WORKFLOW_RESULT: 'diagnostic_plots_results.csv',
  MONTHLY_RATIO_VALUES: 'monthly_ratio_values_df.csv',
  MONTHLY_INJ_PROD_LIQUD_RATE: 'monthly_liquid_inj_prod_df.csv',
};

export const ML_CONNECTIVITY_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  ADVANCE_ANALYSIS: 'advance_analysis_df.csv',
  CALCULATION_SCORE: 'calculation_scores_',
  WORKFLOW_RESULT: 'ml_connectivity_result_',
  DIAGNOSTIC_RESULTS: 'diagnostic_results.json',
};

export const CROSS_CORR_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  WORKFLOW_RESULT: 'cross_correlation_results.csv',
};

export const CRMT_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  WORKFLOW_RESULT: 'crmt_field_prod_inj.csv',
  SOLUTION_DATASET: 'crmt_solution_df.csv',
};

export const ML_RATE_PREDICTION_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  WORKFLOW_RESULT: 'ml_prediction_result.csv',
  OUTPUT_DATA_HISTORY: 'Output_data_hst.csv',
  OUTPUT_DATA_PREDICTION: 'Output_data_prd.csv',
};

export const CRMP_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  GAIN_VALUES: 'crmp_gain_values.csv',
  DYNAMIC_GAIN_VALUES: 'dynamic_gain_values.csv',
  // FLUID_MATCHING_SOLUTIONS: 'fluid_matching_solutions.csv',
  FLUID_PRODUCTION: 'fluid_production.csv',
  R2_RMSE_TOTAL_FLUID: 'r2_rmse_total_fluid.csv',
  // <-- Fluid Matching File Names
  OIL_MATCHING: 'oil_matching.csv',
  R2_RMSE_OIL_MATCHING: 'r2_rmse_oil_matching.csv',
  // <-- Oil Matching File Names
  I_SHUT_OFF_FLUID: 'I_shut_off_fluid.csv',
  I_SHUT_OFF_OIL: 'I_shut_off_oil.csv',
  PRODUCTION_DATA: 'production_data.csv',
  FUTURE_PRODUCTION_DATA: 'future_production_data.csv',
  FACTORS: 'factors.csv',
  OIL_CONTRIBUTION: 'oil_contribution.csv',
  OIL_CONTRIBUTION_SUMMARY: 'oil_contribution_summary.csv',
  // FUTURE_INJ_SENSITIVITY: 'future_injection_sensitivity.csv',
  // INJ_RATES: 'injection_rates.csv',
  SUMMARY_RESULTS: 'summary_results.csv',
  // <-- Optimization File Names
};

export const CRMP_LAYER_WORKFLOW_RESULT_FILE_NAMES = {
  FILTERED_DATASET: 'data_multilayer.csv',
  WORKFLOW_RESULT: 'results.csv',
  FLUID_MATCHING_R2: 'r2_history.csv',
  OIL_MATCHING_R2: 'r2_oil.csv',
  FIELD_DATA: 'field_data.csv',
  FIELD_LAYERS_DATA: 'field_layers_data.csv',
  LIQUID_RATE_ALL: 'liquid_rate_all.csv',
  LIQUID_RATE_HISTORY: 'liquid_rate_history.csv',
  OIL_RATE_ALL: 'oil_rate_all.csv',
  OIL_RATE_HISTORY: 'oil_rate_history.csv',
  INJ_EFFICIENCY: 'inj_efficiency.csv',
  INJ_EFFICIENCY_LAYER: 'inj_efficiency_layer.csv',
  OIL_OPT_WELL: 'oil_opt_well.csv',
  LIQUID_OPT_WELL: 'liquid_opt_well.csv',
  I_FACTOR_MAX_WELL: 'I_factor_max_well.csv',
};

export const CROSS_COMPARISON_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  [CRMP]: 'crmp_gain_values.csv',
  [ML_CONNECTIVITY]: 'ml_connectivity_result_Fluid_VS_Fluid.csv',
  // Old Comparison report file names -->
  CRMP_DATA: 'crmp_data.csv',
  CRMP_SCORES: 'crmp_scores.csv',
  CRMP_RESULTS: 'crmp_gain_values.csv',
  ML_CONN_DATA: 'ml_conn_data.csv',
  ML_CONN_SCORES: 'ml_conn_scores.csv',
  ML_CONN_RESULT: 'ml_conn_results.csv',
  CC_DATA: 'cc_data.csv',
  CC_RESULT: 'cc_results.csv',
};

export const MULTI_WORKFLOW_COMPARISON_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  [CRMP]: 'crmp_gain_values.csv',
  [ML_CONNECTIVITY]: 'ml_connectivity_result_Fluid_VS_Fluid.csv',
};

export const INTEGRATION_MODULE_FILE_NAMES = {
  ACT_NUM_GRID: 'active_map.csv',
  PARAMETERS: 'parameters.csv',
  PERMABILITY: 'permx.csv',
  POROSITY: 'poro.csv',
  // Legacy version -->
  ACTIVE_MAP: 'active_map.csv',
  WATER_SAT: 'SWAT.csv',
  GAS_SAT: 'SGAS.csv',
  OIL_SAT: 'SOMAX.csv',
  ONE_WELL: 'one_well.csv',
  OPTIMIZATION_RESULTS: 'optimization_results.csv',
  SIMULATION_RESULTS: 'simulation_results.csv',
  // <-- Legacy version
  LAYERS: 'layers.csv',
  MGIP_21: 'mgip_21.csv',
  MGIP_40: 'mgip_40.csv',
  SG_21: 'sg_21.csv',
  SG_40: 'sg_40.csv',
  RESULTS: 'results.csv',
};

export const POLY_INJECTION_FILE_NAMES = {
  FILTERED_DATASET: 'filtered_dataset.csv',
  PRODS_SAT_DF: 'prods_sat_df.csv',
  RATES_DF: 'rates_df.csv',
  PARAM_TOTALS: 'param_totals_df.csv',
  CONNECTIVITY_DF: 'connectivity_df.csv',
  // Will append iteration and extension to the end.
  PRODS_RECOVERY_DF: 'prods_recovery_df_',
  INJ_POTENT: 'inj_potent_',
  POLY_RECOVERY: 'polymer_recovery_',
  FIELD_SUM: 'field_summary_',
};

export const POLY_INJECTION_WORKFLOW_STAGES = {
  OIL_MATCHING: 'Oil Matching',
  POLY_INJECTION: 'Polymer Injection',
};

export const CRMP_WORKFLOW_STAGES = {
  FLUID_MATCHING: 'Fluid Matching',
  OIL_MATCHING: 'Oil Matching',
  OPTIMIZATION: 'Optimization',
};

export const CRMP_LAYER_WORKFLOW_STAGES = {
  FLUID_MATCHING: 'Fluid Matching',
  OIL_MATCHING: 'Oil Matching',
  FIELD_ANALYSIS: 'Field Analysis',
  SHUTOFF_ANALYSIS: 'Shutoff Analysis',
  OPTIMIZATION: 'Optimization',
};

export const ML_CONN_WORKFLOW_STAGES = {
  CONNECTIVITY: ML_CONNECTIVITY,
  DIAGNOSTIC_PLOT: DIAGNOSTIC_PLOT,
};

export const INTEGRATION_MODULE_WORKFLOW_STAGES = {
  LAYER_ANALYSIS: 'Layer Analysis',
  OPTIMIZATION: 'Optimization',
  SIMULATION: 'Simulation',
};

export const INTEGRATION_FILES_HEADER_FORMATS = {
  'active_map.csv': {},
  'parameters.csv': {},
  'PERMX.csv': { noheader: true },
  'PORO.csv': { noheader: true },
  'SWAT.csv': {},
  'SGAS.csv': {},
  'SOMAX.csv': {},
  'ACTNUM_FINAL_ENTIRE_GRID.csv': { noheader: true },
  'one_well.csv': { noheader: true },
};

export const INTEGRATION_MODULE_ATTRIBUTE_PAIRS = {
  // 'Quality Index': 'prodInd',
  // 'Oil Saturation': 'so',
  ROR: 'results',
  Porosity: 'poro',
  Permeability: 'perm',
  'MGIP (2021)': 'mgip_21',
  'MGIP (2040)': 'mgip_40',
  'Sg (2021)': 'sg_21',
  'Sg (2040)': 'sg_40',
};

/**
 * When retrieving the workflow file results, files might have specific csv file
 * formats. This object helps access those formats. Follow example below to add
 * csv formats.
 *
 * CSV formats can be found at, https://www.npmjs.com/package/csvtojson#parameters
 *
 * [CRMP]: {
 *    [CRMP_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET]: {noheader: true},
 *    ......
 * }
 */
export const WORKFLOW_RESULTS_FILE_HEADER_FORMATS = {};
