import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveFieldGainAnalysisResult } from '../../../../../../API/Functions/CRMP';

import LoadingPlot from '../../../../../Components/LoadingPlot';
import { BasicTable } from '../../../../../Components/TableView';

import { Grid, Paper, Typography } from '@material-ui/core';

import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const FieldGainAnalysisSumTable = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        // API call to retrieve data.
        const res = await retrieveFieldGainAnalysisResult(
          reduxSelectedWorkflow.workflow_id
        );
        if (mounted) {
          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, []);

  const cachedResult = useMemo(() => {
    if (apiRes) {
      // All the data processing and transformation should be done here.
      return apiRes;
    }
  }, [apiRes]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentRow}>
          <Typography variant="h6">Total Fluid Matching</Typography>
        </Grid>
        <Grid container className={styles.visualContentRow}>
          {cachedResult ? (
            <Paper elevation={5}>
              <BasicTable data={[cachedResult]} />
            </Paper>
          ) : (
            <LoadingPlot />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FieldGainAnalysisSumTable;
