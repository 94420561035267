import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import useStyles from '../../../../../../../Styles/WorkflowStyles';

import LoadingPage from '../../../../../../Components/LoadingPage';

import { generateOilContrGraph } from '../../../../../../../Utils/CommonReportUtil';

import { Grid } from '@material-ui/core';

import {
  GAS,
  GAIN,
  WATER,
  INJECTOR,
  SYSTEM_OF_UNITS,
} from '../../../../../../../constants/WellConstants';

const OilContributionPlot = ({
  fieldWellLocations,
  workflowParameters,
  staticGainValues,
  oilContribution,
  WAG_TYPE,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  // Callbacks -->
  const generateOilContrRatePlot = () => {
    let wellGainValues = staticGainValues;

    if (WAG_TYPE == WATER) {
      wellGainValues = wellGainValues.filter(row =>
        row[INJECTOR].endsWith('_w')
      );
    } else if (WAG_TYPE == GAS) {
      wellGainValues = wellGainValues.filter(row =>
        row[INJECTOR].endsWith('_g')
      );
    }

    return generateOilContrGraph(
      fieldWellLocations,
      wellGainValues,
      oilContribution,
      GAIN,
      `Oil Contribution Plot ${WAG_TYPE ? `(${WAG_TYPE})` : ''}`,
      undefined,
      workflowParameters[SYSTEM_OF_UNITS]
    );
  };
  // <-- Callbacks

  // Handlers -->
  // <-- Handlers

  const cachedPlotResult = useMemo(() => {
    return generateOilContrRatePlot();
  }, [fieldWellLocations, staticGainValues, oilContribution]);

  return (
    <Grid container className={styles.visualContent}>
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ flex: '1' }}
        />
      ) : (
        <LoadingPage
          message="Oil Contribution Plot is Loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

OilContributionPlot.propTypes = {
  fieldWellNames: PropTypes.object,
  fieldWellLocations: PropTypes.array,
  workflowParameters: PropTypes.object,
  staticGainValues: PropTypes.array,
  oilContribution: PropTypes.array,
  WAG_TYPE: PropTypes.string,
};

export default OilContributionPlot;
