import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../Components/LoadingPage';

import {
  GAIN,
  INJECTOR,
  PRODUCER,
} from '../../../../../constants/WellConstants';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { generateNetworkGraphWithLags } from '../../../../../Utils/CommonReportUtil';

const WellOrientedFeatureAnalysisPlot = ({
  focusedWellNames,
  datasetContent,
  wellFeatureValues,
  minFeatureValue,
  maxFeatureValue,
  focusedFeature = GAIN,
  wellType = INJECTOR,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);
  const [selectedWell, setselectedWell] = useState('');
  const [filteredwellFeatureValues, setfilteredwellFeatureValues] =
    useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onWellSelect = event => {
    setselectedWell(event.target.value);
    setfilteredwellFeatureValues(
      wellFeatureValues.filter(row => row[wellType] == event.target.value)
    );
  };
  // <-- Handlers

  // Preselect first well.
  useEffect(() => {
    setselectedWell(focusedWellNames[0]);
    setfilteredwellFeatureValues(
      wellFeatureValues.filter(row => row[wellType] == focusedWellNames[0])
    );
  }, [wellFeatureValues]);

  // Generate Plots
  useEffect(() => {
    if (selectedWell != '' && filteredwellFeatureValues) {
      const title =
        wellType == INJECTOR
          ? `${selectedWell} - ${PRODUCER}s ${focusedFeature} Values`
          : `${selectedWell} - ${INJECTOR}s ${focusedFeature} Values`;

      setplot(
        generateNetworkGraphWithLags(
          datasetContent,
          filteredwellFeatureValues,
          focusedFeature,
          minFeatureValue,
          maxFeatureValue,
          undefined,
          undefined,
          title
        )
      );
    }
  }, [selectedWell, filteredwellFeatureValues]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{wellType}</Typography>
          <FormControl variant="outlined" size="small">
            <Select
              value={selectedWell}
              onChange={onWellSelect}
              className="dropdownSelection"
            >
              {focusedWellNames &&
                focusedWellNames.map(wellName => (
                  <MenuItem key={'menu-item-' + wellName} value={wellName}>
                    {wellName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {plot ? (
          <PlotlyComponent
            data={plot.data}
            layout={plot.layout}
            config={plot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message={`${wellType} Network Plot is Loading...`}
            goHome={false}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

WellOrientedFeatureAnalysisPlot.propTypes = {
  focusedWellNames: PropTypes.array,
  otherWellNames: PropTypes.array,
  datasetContent: PropTypes.array,
  wellFeatureValues: PropTypes.array,
  minFeatureValue: PropTypes.number,
  maxFeatureValue: PropTypes.number,
  focusedFeature: PropTypes.string,
  wellType: PropTypes.string,
};

export default WellOrientedFeatureAnalysisPlot;
