import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import WellOilRatePlot from './WellOilRate';
import CurveAnalysis from './CurveAnalysis';
import CurveAnalysisFuture from './CurveAnalysisFuture';
import LoadingPage from '../../../Components/LoadingPage';

import {
  ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES,
  SELECTED_WELL,
} from '../../../../constants/WorkflowsParameterConstants';
import { DATE, UI_DATE_FORMAT } from '../../../../constants/WellConstants';

const ArpsDeclineReport = ({
  workflowResultFiles,
  workflowParameters,
  headers,
}) => {
  const [workflowReady, setworkflowReady] = useState(false);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [parameters, setparameters] = useState(null);

  const [sdf, setsdf] = useState(null);
  const [summaryDf, setsummaryDf] = useState(null);
  const [sdfFuture, setsdfFuture] = useState(null);
  const [futureDf, setfutureDf] = useState(null);

  const [selectedWell, setselectedWell] = useState(null);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  // <-- Handlers

  // Extract workflow result files and well names,
  useEffect(() => {
    if (workflowResultFiles != null && workflowParameters != null) {
      setfilteredDatasetContent(
        workflowResultFiles[
          ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET
        ].map(row => {
          return { ...row, [DATE]: moment(row[DATE]).format(UI_DATE_FORMAT) };
        })
      );
      setsdf(
        workflowResultFiles[ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES.SDF]
      );
      setsummaryDf(
        workflowResultFiles[
          ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES.SUMMARY_DF
        ]
      );
      setsdfFuture(
        workflowResultFiles[
          ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES.SDF_FUTURE
        ]
      );
      setfutureDf(
        workflowResultFiles[
          ARPS_DECLINE_CURVE_WORKFLOW_RESULT_FILE_NAMES.FUTURE_DF
        ]
      );

      setparameters({ ...workflowParameters });
      setselectedWell(workflowParameters[SELECTED_WELL]);

      setworkflowReady(true);
    }
  }, [workflowResultFiles, workflowParameters]);

  return workflowReady ? (
    <React.Fragment>
      <WellOilRatePlot
        filteredDatasetContent={filteredDatasetContent}
        parameters={parameters}
        selectedWell={selectedWell}
        header={headers.OIL_RATE}
      />
      <CurveAnalysis
        sdf={sdf}
        summaryDf={summaryDf}
        parameters={parameters}
        selectedWell={selectedWell}
        header={headers.CURVE_ANALYSIS}
      />
      <CurveAnalysisFuture
        filteredDatasetContent={filteredDatasetContent}
        sdfFuture={sdfFuture}
        futureDf={futureDf}
        parameters={parameters}
        selectedWell={selectedWell}
        header={headers.CURVE_ANALYSIS_FUTURE}
      />
    </React.Fragment>
  ) : (
    <LoadingPage />
  );
};

ArpsDeclineReport.propTypes = {
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
  headers: PropTypes.array,
};

export default ArpsDeclineReport;
