import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../Styles/VisualizationParamModalStyles';

import PropTypes from 'prop-types';

import {
  retrieveFieldLayers,
  retrieveFieldTimeline,
  retrieveFieldWellNames,
} from '../../API/Functions/Visualization';
import { fixDateFormat } from '../../Utils/DatasetUtils/DataProcessing';
import { getUnitRate } from '../../Utils/ReservoirUtils';

import {
  setReduxInjWellNames,
  setReduxLayers,
  setReduxParameters,
  setReduxProdWellNames,
  setReduxWellTimeLine,
} from '../../redux/actions/dataset';

import {
  Grid,
  Radio,
  Button,
  Select,
  Dialog,
  MenuItem,
  Checkbox,
  TextField,
  RadioGroup,
  IconButton,
  Typography,
  DialogTitle,
  FormControl,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
  DATE,
  FIELD,
  LAYER,
  METRIC,
  END_DATE,
  START_DATE,
  FORMATION_VFO,
  FORMATION_VFW,
  FORMATION_VFG,
  DATA_SMOOTHING,
  SYSTEM_OF_UNITS,
  NORMALIZE_ACTIVE_DAYS,
  DATA_SMOOTHING_FACTOR,
  ALL_LAYERS,
  PRODUCER,
  INJECTOR,
  UI_DATE_FORMAT,
} from '../../constants/WellConstants';

import { SURVEILLANCE_DATASET_VISUALIZATION_PARAMS as defaultParams } from '../../constants/WorkflowsParameterConstants';

const CheckboxInput = ({ label, checked, onChange }) => {
  return (
    <Grid container className="inputRow">
      <Typography>{label}</Typography>
      <Checkbox
        checked={checked}
        onChange={event => {
          onChange(event.target.checked);
        }}
      />
    </Grid>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TextFieldInput = ({ label, value, onChange, type, min, max }) => {
  return (
    <Grid container className="inputRow">
      <Typography>{label}</Typography>
      <TextField
        className="boxInput"
        size="small"
        variant="outlined"
        type={type || 'number'}
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        InputProps={{
          inputProps: {
            min,
            max,
          },
        }}
      />
    </Grid>
  );
};

TextFieldInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const RadioGroupInput = ({ label, value, onChange, options }) => {
  return (
    <Grid container className="inputRow">
      <Typography>{label}</Typography>
      <RadioGroup
        value={value}
        onChange={event => {
          onChange(event.target.value);
        }}
        style={{ flexDirection: 'row' }}
      >
        {options.map(option => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};

RadioGroupInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const DropDownInput = ({ label, value, onChange, options }) => {
  return (
    <Grid container className="inputRow">
      <Typography>{label}</Typography>
      <FormControl variant="outlined" size="small" className="boxInput">
        <Select
          value={value}
          onChange={event => {
            onChange(event.target.value);
          }}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

DropDownInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const VisualizationParamsModal = ({ toggle, modalState }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();

  const [isParamsModified, setIsParamsModified] = useState(false);
  const [fieldTimeline, setFieldTimeline] = useState({
    [START_DATE]: undefined,
    [END_DATE]: undefined,
  });
  const [layerOptions, setLayerOptions] = useState(undefined);
  const [params, setParams] = useState({
    ...defaultParams,
  });

  // Handlers -->
  const onClose = () => {
    toggle();
  };

  const onParamChange = (paramName, value) => {
    setParams(prev => ({
      ...prev,
      [paramName]: value,
    }));
    setIsParamsModified(true);
  };

  const onApplyParams = () => {
    dispatch(setReduxParameters(params));
    setIsParamsModified(false);
    toggle();
  };

  const onResetParams = () => {
    const resetParams = {
      ...defaultParams,
      [START_DATE]: fieldTimeline[START_DATE],
      [END_DATE]: fieldTimeline[END_DATE],
    };

    setParams(resetParams);
    setIsParamsModified(false);
    dispatch(setReduxParameters(resetParams));
    toggle();
  };
  // <-- Handlers

  // callback/helpers -->
  // <-- callback/helpers

  useEffect(() => {
    // Fetch field timeline and layers, only runs once.
    let mounted = true;
    async function fetchFieldTimeline() {
      const results = await retrieveFieldTimeline(urlDatasetId);
      if (mounted) {
        setFieldTimeline({
          [START_DATE]: fixDateFormat(results.at(0), UI_DATE_FORMAT),
          [END_DATE]: fixDateFormat(results.at(-1), UI_DATE_FORMAT),
        });
        dispatch(setReduxWellTimeLine(results));
      }
    }
    async function fetchFieldLayers() {
      const results = await retrieveFieldLayers(urlDatasetId);
      if (mounted) {
        setLayerOptions(results.length > 0 ? [ALL_LAYERS, ...results] : []);
        dispatch(setReduxLayers(results));
      }
    }
    // FIXME: This is a temporary solution to redux state management.
    async function fetchFieldWellNames() {
      const results = await retrieveFieldWellNames(urlDatasetId);
      if (mounted) {
        dispatch(setReduxInjWellNames(results[INJECTOR]));
        dispatch(setReduxProdWellNames(results[PRODUCER]));
      }
    }

    fetchFieldTimeline();
    fetchFieldLayers();
    fetchFieldWellNames();
  }, []);

  useEffect(() => {
    // Set initial parameters and dispatch to redux store. SHOULD ONLY RUN ONCE.
    if (fieldTimeline[START_DATE] && fieldTimeline[END_DATE] && layerOptions) {
      const initFilters = {
        ...params,
        [START_DATE]: fieldTimeline[START_DATE],
        [END_DATE]: fieldTimeline[END_DATE],
        [LAYER]: layerOptions.length > 0 ? ALL_LAYERS : undefined,
      };

      setParams(initFilters);
      dispatch(setReduxParameters(initFilters));
    }
  }, [fieldTimeline, layerOptions]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={modalState} onClose={onClose}>
      <DialogTitle disableTypography className={styles.dialogTitle}>
        <Typography className="title">Parameters</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Grid container className="body">
          <Grid container className="paper">
            <RadioGroupInput
              label={SYSTEM_OF_UNITS}
              value={params[SYSTEM_OF_UNITS]}
              options={[FIELD, METRIC]}
              onChange={value => onParamChange(SYSTEM_OF_UNITS, value)}
            />
            <CheckboxInput
              label={NORMALIZE_ACTIVE_DAYS}
              checked={params[NORMALIZE_ACTIVE_DAYS]}
              onChange={value => onParamChange(NORMALIZE_ACTIVE_DAYS, value)}
            />
            <CheckboxInput
              label={DATA_SMOOTHING}
              checked={params[DATA_SMOOTHING]}
              onChange={value => onParamChange(DATA_SMOOTHING, value)}
            />
            {params[DATA_SMOOTHING] && (
              <TextFieldInput
                label={DATA_SMOOTHING_FACTOR}
                value={params[DATA_SMOOTHING_FACTOR]}
                onChange={value => onParamChange(DATA_SMOOTHING_FACTOR, value)}
              />
            )}
            <TextFieldInput
              label={START_DATE}
              value={params[START_DATE]}
              type={DATE}
              min={fieldTimeline[START_DATE]}
              max={fieldTimeline[END_DATE]}
              onChange={value => onParamChange(START_DATE, value)}
            />
            <TextFieldInput
              label={END_DATE}
              value={params[END_DATE]}
              type={DATE}
              min={fieldTimeline[START_DATE]}
              max={fieldTimeline[END_DATE]}
              onChange={value => onParamChange(END_DATE, value)}
            />
            <TextFieldInput
              label={`${FORMATION_VFO} 
                (${getUnitRate(params[SYSTEM_OF_UNITS], FORMATION_VFO)})
                `}
              value={params[FORMATION_VFO]}
              onChange={value => onParamChange(FORMATION_VFO, value)}
            />
            <TextFieldInput
              label={`${FORMATION_VFW} 
                (${getUnitRate(params[SYSTEM_OF_UNITS], FORMATION_VFW)})
                `}
              value={params[FORMATION_VFW]}
              onChange={value => onParamChange(FORMATION_VFW, value)}
            />
            <TextFieldInput
              label={`${FORMATION_VFG} 
                (${getUnitRate(params[SYSTEM_OF_UNITS], FORMATION_VFG)})
                `}
              value={params[FORMATION_VFG]}
              onChange={value => onParamChange(FORMATION_VFG, value)}
            />
            {layerOptions?.length > 0 && (
              <DropDownInput
                label="Select layer"
                value={params[LAYER]}
                options={layerOptions}
                onChange={value => onParamChange(LAYER, value)}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className="paper">
          <Grid container className="action">
            <Button
              className="button"
              variant="outlined"
              color="primary"
              disabled={!isParamsModified}
              onClick={onApplyParams}
            >
              Apply
            </Button>
            <Button
              className="button"
              variant="outlined"
              color="default"
              onClick={onResetParams}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

VisualizationParamsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
};

export default VisualizationParamsModal;
