import {
  XCOL,
  YCOL,
  FIELD,
  WELL_ID,
  INJECTOR,
  COST_SAVINGS,
} from '../../constants/WellConstants';
import { findNormalizedUnitValue } from '../DatasetUtils/DataProcessing';
import { getUnitRate } from '../ReservoirUtils';

export const COLORS = [
  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];
export const LINES = 'lines';
export const MARKERS = 'markers';
export const MARKERS_WITH_TEXT = 'markers+text';
export const LINES_WITH_MARKERS = 'lines+markers';
export const DEFAULT_MARKER_SIZE = 20;

const NO_MATCHING_DATA_LAYOUT = {
  xaxis: {
    visible: false,
  },
  yaxis: {
    visible: false,
  },
  annotations: [
    {
      text: 'No matching data found',
      xref: 'paper',
      yref: 'paper',
      showarrow: false,
    },
  ],
};

export function wrapPlots(
  traces,
  x_title = undefined,
  y_title = undefined,
  title = undefined,
  x_dtick = undefined,
  y_dtick = undefined,
  x_range = undefined,
  y_range = undefined,
  showlegend = true,
  y_showline = true,
  x_showline = true,
  y_showticklabels = true,
  x_showticklabels = true,
  y_zeroline = false,
  x_zeroline = false,
  updatemenus = undefined,
  x_type = undefined,
  y_type = undefined,
  shapes = undefined,
  x_domain = [0, 1],
  y_domain = [0, 1],
  layout_extra = {},
  x_rangemode = undefined,
  y_rangemode = undefined
) {
  if (!updatemenus) {
    updatemenus = [];
  }
  const layout =
    traces.length > 0
      ? {
          title: title,
          xaxis: {
            title: x_title,
            domain: x_domain,
            dtick: x_dtick,
            range: x_range,
            showline: x_showline,
            showticklabels: x_showticklabels,
            zeroline: x_zeroline,
            type: x_type,
            rangemode: x_rangemode,
          },
          yaxis: {
            title: y_title,
            domain: y_domain,
            dtick: y_dtick,
            range: y_range,
            showline: y_showline,
            showticklabels: y_showticklabels,
            zeroline: y_zeroline,
            type: y_type,
            rangemode: y_rangemode,
          },
          hovermode: 'closest',
          showlegend: showlegend,
          updatemenus: updatemenus,
          shapes: shapes,
          autosize: true,
          ...layout_extra,
        }
      : {
          xaxis: {
            visible: false,
          },
          yaxis: {
            visible: false,
          },
          annotations: [
            {
              text: 'No matching data found',
              xref: 'paper',
              yref: 'paper',
              showarrow: false,
              font: {
                size: 28,
              },
            },
          ],
        };
  const config = { responsive: true };
  return { data: traces, layout, config };
}

export function createTrace(
  x,
  y,
  name = undefined,
  color = undefined,
  symbol = undefined,
  hovertext = undefined,
  mode = MARKERS,
  marker_size = DEFAULT_MARKER_SIZE,
  line_width = undefined,
  text = false,
  showscale = false,
  marker_line_color = 'black',
  visible = true,
  showlegend = true
) {
  if (text) {
    mode += '+text';
  }

  const trace = {
    name: name,
    x: x,
    y: y,
    text: hovertext,
    hoverinfo: 'text',
    mode: mode,
    visible: visible,
    showlegend: showlegend,
    marker: {
      color: color,
      symbol: symbol,
      showscale: showscale,
      line: { color: marker_line_color, width: 1 },
      size: marker_size,
    },
    line: { width: line_width },
  };

  return trace;
}

export function createTraceWithText(
  x,
  y,
  name = undefined,
  color = undefined,
  symbol = undefined,
  hovertext = undefined,
  line = false,
  line_width = undefined,
  text = undefined,
  showscale = false,
  marker_line_color = 'black',
  visible = true,
  showlegend = true,
  legendgroup = undefined,
  legendgrouptitle = undefined,
  markeSize = 20
) {
  let mode = line ? LINES : MARKERS;
  if (text) mode += '+text';

  const trace = {
    type: 'scatter',
    name: name,
    x: x,
    y: y,
    text: text,
    hovertext: hovertext,
    hoverinfo: 'text',
    mode: mode,
    visible: visible,
    showlegend: showlegend,
    textposition: 'bottom center',
    marker: {
      color: color,
      symbol: symbol,
      showscale: showscale,
      line: { color: marker_line_color, width: 2 },
      size: markeSize,
    },
    line: { width: line_width },
    legendgroup: legendgroup,
    legendgrouptitle: { text: legendgrouptitle },
  };
  return trace;
}

export function createLinePlotTrace(
  x,
  y,
  name = undefined,
  line_width = undefined,
  color = undefined,
  dash = undefined,
  mode = undefined,
  marker_line_color = undefined,
  marker_size = 5,
  showlegend = true,
  yaxis = 'y1'
) {
  if (mode == undefined) {
    mode = LINES;
  }

  const trace = {
    name: name,
    x: x,
    y: y,
    showlegend: showlegend,
    mode: mode,
    yaxis: yaxis,
    marker: {
      color: color,
      line: { color: marker_line_color, width: 1 },
      size: marker_size,
    },
    line: {
      width: line_width,
      color: color,
      dash: dash,
    },
    hovertemplate: '%{y:.2f}<br />%{x}',
  };
  return trace;
}

export function createLinePlotTraces(
  df,
  x_col,
  y_cols,
  traces,
  name = undefined,
  marker_cols = undefined,
  colors = undefined,
  mode = undefined,
  marker_size = undefined
) {
  y_cols.forEach((colName, index) => {
    let projectedData;
    let x;
    if (Array.isArray(df)) {
      projectedData = df.map(row => row[colName]);
      x = df.map(row => row[x_col]);
    } else {
      projectedData = df[colName];
      x = df[x_col];
    }
    projectedData = projectedData.filter(
      value => value !== undefined || value !== ''
    );
    let mode_i = mode ? mode : LINES;
    if (marker_cols) {
      if (marker_cols.includes(colName)) {
        mode_i = MARKERS;
      }
    }

    const leg = name ? name[index] : colName;
    const color = colors ? colors[index] : undefined;
    marker_size = marker_size ? marker_size : 5;
    const trace = createLinePlotTrace(
      x,
      projectedData,
      leg,
      undefined,
      color,
      undefined,
      mode_i,
      undefined,
      marker_size
    );
    traces.push(trace);
  });
  return traces;
}

export function createLinePlot(
  df,
  x_col,
  y_cols,
  name,
  marker_cols = undefined,
  colors = undefined,
  x_title = undefined,
  y_title = undefined,
  title = undefined,
  x_dtick = undefined,
  y_dtick = undefined,
  x_range = undefined,
  y_range = undefined,
  mode = undefined
) {
  let traces = createLinePlotTraces(
    df,
    x_col,
    y_cols,
    [],
    name,
    marker_cols,
    colors,
    mode
  );

  return wrapPlots(
    traces,
    x_title,
    y_title,
    title,
    x_dtick,
    y_dtick,
    x_range,
    y_range
  );
}

export function createNetworkGraph(
  nodes_df,
  edges_df,
  title,
  color = '#1f77b4',
  unitSystem = FIELD
) {
  const traces = [];

  edges_df.forEach(edge => {
    traces.push(
      createLinePlotTrace(
        [edge['Source Node X'], edge['Dest Node X']],
        [edge['Source Node Y'], edge['Dest Node Y']],
        undefined,
        edge['Edge Width'],
        edge['Color'] ? edge['Color'] : color,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
    );
    traces.push(
      createTrace(
        [(parseInt(edge['Source Node X']) + parseInt(edge['Dest Node X'])) / 2],
        [(parseInt(edge['Source Node Y']) + parseInt(edge['Dest Node Y'])) / 2],
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        edge['Edge HoverText'],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        undefined
      )
    );
  });

  nodes_df.forEach(node => {
    traces.push(
      createTraceWithText(
        [node['Node X']],
        [node['Node Y']],
        undefined,
        node['Node Color'],
        node['Node Symbol'],
        node['Node Id'],
        undefined,
        undefined,
        node['Node Text'] ? node['Node Text'] : node['Node Id'],
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        undefined,
        undefined,
        undefined,
        node['Node Size']
      )
    );
  });

  return wrapPlots(
    traces,
    `${XCOL} (${getUnitRate(unitSystem, XCOL)})`,
    `${YCOL} (${getUnitRate(unitSystem, YCOL)})`,
    title,
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );
}

export function createHeatmap(
  x,
  y,
  z,
  title,
  x_title,
  y_title,
  colorbar_title,
  colorscale = undefined,
  y_type = undefined
) {
  const trace = {
    x: x,
    y: y,
    z: z,
    colorscale: colorscale,
    colorbar: { title: colorbar_title },
    showscale: true,
    type: 'heatmap',
  };
  return wrapPlots(
    [trace],
    x_title,
    y_title,
    title,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    y_type
  );
}

export function createPieChart(labels, values, title) {
  const data = [
    {
      labels,
      values,
      type: 'pie',
      textinfo: 'label+percent',
      rotation: '180',
    },
  ];
  let layout = { title, autosize: true };
  if (values.filter(value => value != 0).length == 0)
    layout = { ...layout, ...NO_MATCHING_DATA_LAYOUT };

  const config = { responsive: true };

  return { data, layout, config };
}

/**
 * This function generates bar plot for each wellName from wellNames by retrieving values from
 * datasetContent object, that has well name value pairs.
 *
 * @param {Object} datasetContent
 * @param {Array} wellNames
 * @param {String} title
 * @param {String} traceName
 * @returns {Plotly} plotlyObject
 */
export function createBarGraph(datasetContent, wellNames, title, traceName) {
  // FIXME: Not universal function yet.
  const data = [];
  const layout = { title: title, autosize: true };
  const config = { responsive: true };

  data.push({
    x: wellNames,
    y: wellNames.map(wellName => datasetContent[wellName]),
    type: 'bar',
    name: traceName,
  });

  return { data, layout, config };
}

/**
 * This function generates injector efficiency plot based on cost specified feature of the injector.
 * By default, it looks for cost savings feature.
 *
 * cost saving values are normalized to fit in between [0, 1].
 * @param {Array} datasetContent
 * @param {Array} injEffDataset
 * @param {String} injFeature
 * @returns
 */
export function generateEfficiencyPlot(
  datasetContent,
  injEffDataset,
  injFeature = COST_SAVINGS,
  xTitle,
  yTitle,
  title
) {
  try {
    const nodeData = [];
    const injectors = [...new Set(injEffDataset.map(row => row[INJECTOR]))];
    const efficiencyValues = injEffDataset.map(row => row[injFeature]);

    injectors.forEach(wellName => {
      const wellEfficiency = injEffDataset.find(
        row => row[INJECTOR] == wellName
      );
      const wellContent = datasetContent.find(row => row[WELL_ID] == wellName);

      nodeData.push({
        'Node Id': `${wellName}<br />${injFeature}: `,
        'Node X': wellContent[XCOL],
        'Node Y': wellContent[YCOL],
        'Node Value': findNormalizedUnitValue(
          efficiencyValues,
          wellEfficiency[injFeature]
        ),
      });
    });

    return wrapPlots(
      [
        {
          x: nodeData.map(row => row['Node X']),
          y: nodeData.map(row => row['Node Y']),
          mode: MARKERS,
          marker: {
            color: nodeData.map(row => row['Node Value']),
            colorscale: [
              [0.0, 'rgb(255, 0, 0)'],
              [1.0, 'rgb(0, 255, 0)'],
            ],
            showscale: true,
            size: DEFAULT_MARKER_SIZE,
          },
          text: nodeData.map(
            row => row['Node Id'] + row['Node Value'].toFixed(3)
          ),
        },
      ],
      xTitle,
      yTitle,
      title,
      undefined,
      undefined,
      undefined,
      undefined,
      false
    );
  } catch (error) {
    return wrapPlots([], xTitle, yTitle, title);
  }
}

export const addTrainingLine = (plotData, timePeriods) => {
  plotData.layout['shapes'] = timePeriods.map(timePeriod => {
    return {
      type: 'line',
      x0: timePeriod,
      y0: 0,
      x1: timePeriod,
      xref: 'x',
      yref: 'paper',
      y1: 1,
      opacity: 0.25,
      line: {
        color: 'black',
        width: 2.5,
        dash: 'dash',
      },
    };
  });

  return plotData;
};

export const plotWithVerticalLine = (
  df,
  xCol,
  yCols,
  name = undefined,
  markerCols = undefined,
  colors = undefined,
  mode = undefined,
  xTitle = undefined,
  yTitle = undefined,
  title = undefined,
  xDtick = undefined,
  yDtick = undefined,
  xRange = undefined,
  yRange = undefined,
  date = undefined
) => {
  const traces = createLinePlotTraces(
    df,
    xCol,
    yCols,
    [],
    name,
    markerCols,
    colors,
    mode
  );
  let maxYValue = Number.MIN_SAFE_INTEGER;
  let minYValue = Number.MAX_SAFE_INTEGER;

  yCols.forEach(col => {
    minYValue = Math.min(...df.map(row => Number(row[col])), minYValue);
    maxYValue = Math.max(...df.map(row => Number(row[col])), maxYValue);
  });

  const shapes = [
    {
      type: 'line',
      x0: date,
      y0: 0,
      x1: date,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      opacity: 0.25,
      line: {
        color: 'black',
        width: 2.5,
        dash: 'dash',
      },
    },
  ];
  return wrapPlots(
    traces,
    xTitle,
    yTitle,
    title,
    xDtick,
    yDtick,
    xRange,
    yRange,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    shapes
  );
};

export const generateCombinedLineTracePlot = (
  df1,
  df2,
  xCol,
  yCols1,
  yCols2,
  name1 = undefined,
  name2 = undefined,
  xTitle = undefined,
  yTitle = undefined,
  title = undefined,
  seperationDates = [],
  extraLayout = {}
) => {
  const traces = [];

  // Create Production Data Traces
  createLinePlotTraces(df1, xCol, yCols1, traces, name1);

  // Create Future Production Data Traces
  createLinePlotTraces(df2, xCol, yCols2, traces, name2);

  const plot = wrapPlots(
    traces,
    xTitle,
    yTitle,
    title,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    extraLayout
  );

  return addTrainingLine(plot, seperationDates);
};
