import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../Components/LoadingPage';
import { BasicTable } from '../../../Components/TableView';

import { Grid, Paper, Typography, Link, IconButton } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  createLinePlotTrace,
  createTrace,
  MARKERS,
  wrapPlots,
  addTrainingLine,
} from '../../../../Utils/PlotlyUtils/Plots';

import {
  DATE,
  END_DATE,
  ERROR,
  GAIN,
  START_DATE,
  TOTAL_INJECTION_RATE,
  TOTAL_PRODUCTION_RATE,
  CRMT,
} from '../../../../constants/WellConstants';

import {
  TIME_PERIODS,
  CRMT_WORKFLOW_RESULT_FILE_NAMES,
} from '../../../../constants/WorkflowsParameterConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const CRMT_Report = ({ headers, workflowResultFiles }) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const [filteredDatasetContent, setfilteredDatasetContent] = useState(null);
  const [workflowAnalysisContent, setworkflowAnalysisContent] = useState(null);
  const [solutionDatasetContent, setsolutionDatasetContent] = useState(null);

  const [tableData, settableData] = useState(null);
  const [plotData, setplotData] = useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  /**
   *
   */
  const generateTableData = () => {
    settableData(
      solutionDatasetContent.map(row => {
        return {
          [START_DATE]: row['Start'],
          [END_DATE]: row['End'],
          'Source / Loss': row['water drive'],
          [GAIN]: row['gain value'],
          [ERROR]: row['error'],
        };
      })
    );
  };
  /**
   *
   */
  const generatePlot = () => {
    const traces = [];

    traces.push(
      createTrace(
        workflowAnalysisContent.map(dataRow => dataRow[DATE]),
        workflowAnalysisContent.map(dataRow => dataRow[TOTAL_INJECTION_RATE]),
        TOTAL_INJECTION_RATE,
        undefined,
        undefined,
        workflowAnalysisContent.map(dataRow => dataRow[TOTAL_INJECTION_RATE]),
        MARKERS,
        5
      )
    );
    traces.push(
      createTrace(
        workflowAnalysisContent.map(dataRow => dataRow[DATE]),
        workflowAnalysisContent.map(dataRow => dataRow[TOTAL_PRODUCTION_RATE]),
        TOTAL_PRODUCTION_RATE,
        undefined,
        undefined,
        workflowAnalysisContent.map(dataRow => dataRow[TOTAL_PRODUCTION_RATE]),
        MARKERS,
        5
      )
    );
    traces.push(
      createLinePlotTrace(
        workflowAnalysisContent.map(dataRow => dataRow[DATE]),
        workflowAnalysisContent.map(dataRow => dataRow[CRMT]),
        'Production: ' + CRMT
      )
    );

    setplotData(
      addTrainingLine(
        wrapPlots(traces, DATE, 'rb/month', headers.FIELD_FLUID_INJ_PROD),
        reduxSelectedWorkflow.parameters[TIME_PERIODS]
      )
    );
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  const onDownload = () => {
    const content = [];

    workflowAnalysisContent.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // Retrieve required workflow analysis data from the servers to generate workflow report.
  useEffect(() => {
    try {
      if (workflowResultFiles) {
        setfilteredDatasetContent(
          workflowResultFiles[CRMT_WORKFLOW_RESULT_FILE_NAMES.FILTERED_DATASET]
        );
        setworkflowAnalysisContent(
          workflowResultFiles[CRMT_WORKFLOW_RESULT_FILE_NAMES.WORKFLOW_RESULT]
        );
        setsolutionDatasetContent(
          workflowResultFiles[CRMT_WORKFLOW_RESULT_FILE_NAMES.SOLUTION_DATASET]
        );
      }
    } catch (error) {
      setfilteredDatasetContent(null);
      setworkflowAnalysisContent(null);
      setsolutionDatasetContent(null);
    }
  }, [workflowResultFiles]);

  useEffect(() => {
    if (
      filteredDatasetContent &&
      workflowAnalysisContent &&
      solutionDatasetContent
    ) {
      generateTableData();
      generatePlot();
    }
  }, [filteredDatasetContent, workflowAnalysisContent, solutionDatasetContent]);

  return (
    <React.Fragment>
      <Grid
        id={headers.CONFIGURATION}
        container
        className={styles.visualContentFlex}
      >
        <Paper elevation={5} className={styles.visualContentRow}>
          <BasicTable data={tableData} />
        </Paper>
      </Grid>
      <Grid
        id={headers.FIELD_FLUID_INJ_PROD}
        container
        className={styles.visualContent}
      >
        {plotData ? (
          <PlotlyComponent
            data={plotData.data}
            layout={plotData.layout}
            config={plotData.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Field Fluid Injection & Production Plot is loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download Results</Typography>
          <Link
            download="CRMT_Results.csv"
            href={encodedUri}
            onClick={onDownload}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

CRMT_Report.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
};

export default CRMT_Report;
