import { wrapPlots } from '../../../Utils/PlotlyUtils/Plots';
import {
  arrayZipper,
  create3DArray,
  extractLayerFrom3D,
  get3DShapeValues,
  reshapeArray,
  subtract3D,
} from './ArrayManipulationUtils';

export function mergeXY(X, Y) {
  let array = new Array(X.length + Y.length);
  for (let i = 0; i < X.length; i++) {
    array[i * 2] = X[i];
  }
  for (let i = 0; i < Y.length; i++) {
    array[1 + i * 2] = Y[i];
  }
  return array;
}

export function createTraceWithMask(xCoord, yCoord, property, mask) {
  let maskedValues = [];

  mask.forEach((value, index) => {
    if (value) {
      maskedValues.push({
        maskedX: Number(xCoord[index]),
        maskedY: Number(yCoord[index]),
        maskedProperty: Number(property[index]),
      });
    }
  });

  return {
    x: maskedValues.map(obj => obj['maskedX']),
    y: maskedValues.map(obj => obj['maskedY']),
    mode: 'markers',
    name: 'grids',
    showlegend: false,
    hovertext: maskedValues.map(
      obj => Math.round(obj['maskedProperty'] * 1000) / 1000
    ),
    marker: {
      size: 10,
      cmax: Math.max(...maskedValues.map(obj => obj['maskedProperty'])),
      cmin: Math.min(...maskedValues.map(obj => obj['maskedProperty'])),
      color: maskedValues.map(obj => obj['maskedProperty']),
      showscale: true,
      colorscale: 'Jet',
    },
  };
}

export function Convert3D(rawDataset, dataset3D) {
  for (let i = 0; i < rawDataset.length; i++) {
    let [x, y, z] = [
      Number(rawDataset[i]['x']),
      Number(rawDataset[i]['y']),
      Number(rawDataset[i]['layer']),
    ];
    dataset3D[x - 1][y - 1][z - 1] = Number(rawDataset[i]['value']);
  }
  return dataset3D;
}

export const processIntegrationDatasetsV2 = (
  activeMapEntireGrid,
  parameters,
  permx,
  poros,
  results,
  mgip_21,
  mgip_40,
  sg_21,
  sg_40
) => {
  const { Nx: sizeX, Ny: sizeY, Nz: sizeZ } = parameters;
  const perm3D = Convert3D(permx, create3DArray(sizeX, sizeY, sizeZ));
  const poro3D = Convert3D(poros, create3DArray(sizeX, sizeY, sizeZ));
  const results3D = Convert3D(results, create3DArray(sizeX, sizeY, sizeZ));
  const mgip_21_3D = Convert3D(mgip_21, create3DArray(sizeX, sizeY, sizeZ));
  const mgip_40_3D = Convert3D(mgip_40, create3DArray(sizeX, sizeY, sizeZ));
  const sg_21_3D = Convert3D(sg_21, create3DArray(sizeX, sizeY, sizeZ));
  const sg_40_3D = Convert3D(sg_40, create3DArray(sizeX, sizeY, sizeZ));
  const activeMap3D = Convert3D(
    activeMapEntireGrid,
    create3DArray(sizeX, sizeY, sizeZ)
  );

  // Save all the coordinates in a 1D format. So rather than saving the domain of the coordinates,
  // save selected axis points for each opposite axis.
  const xCoords = new Array(sizeX * sizeY).fill(0);
  const yCoords = new Array(sizeX * sizeY).fill(0);
  for (let i = 0; i < sizeX; i++) {
    for (let j = 0; j < sizeY; j++) {
      yCoords[i * sizeY + j] = j + 1;
    }
    for (
      let lowerRange = i * sizeY;
      lowerRange < (i + 1) * sizeY;
      lowerRange++
    ) {
      xCoords[lowerRange] = i + 1;
    }
  }

  return {
    activeMap: activeMap3D,
    poro: poro3D,
    perm: perm3D,
    xCoords,
    yCoords,
    results: results3D,
    mgip_21: mgip_21_3D,
    mgip_40: mgip_40_3D,
    sg_21: sg_21_3D,
    sg_40: sg_40_3D,
  };
};

/**
 * This function is the major tool that is used to extract and process uploaded integration file and generate
 * meta files that are going to be used to build layer oriented 2D field map of the dataset.
 *
 * Property files, (permx, poros, swat, sgas, somax_) are already configured to be a proper fit to the field map with respect
 * to active map grid. Thus, In order to build the proper 3D map for each properties, we iterate through the 3D active map file,
 * and extract each value from the property array where active map coordinate is active. In other words, length of property files
 * are equal to number of active points in the activeMapEntireGrid.
 *
 * to axtract proper coordinates propery and save it into 3D array, we take adva
 * @param {Object} parameters - Dimensions of the uploaded 3D dataset
 * @param {Array} permx - 1D array, (Z x Y x X), that contains permability property of the 3D dataset, (Active Point Sized).
 * @param {Array} poros - 1D array, (Z x Y x X), that contains porosity property of the dataset, (Active Point Sized).
 * @param {Array} swat - 1D array, (Z x Y x X), that contains water saturation property of the dataset, (Active Point Sized).
 * @param {Array} sgas - 1D array, (Z x Y x X), that contains gas saturation property of the dataset, (Active Point Sized).
 * @param {Array} somax_ - 1D array, (Z x Y x X), that contains oil saturation property of the dataset, (Active Point Sized).
 * @param {Array} activeMapEntireGrid - 1D array, (Z x Y x X), that contains active well coordinates of the dataset, (Entire Grid Sized).
 * @param {Array} oneWell1d - 1D array, (X x Y x Z) that is used to generate quality index score for the dataset, (Entire Grid Sized).
 * @returns {Object} - {activeMap, prodInd, poro, perm, so, xCoords, yCoords }
 */
export const processIntegrationDatasets = (
  parameters,
  permx,
  poros,
  swat,
  sgas,
  somax_,
  activeMapEntireGrid,
  oneWell1d
) => {
  const { Nx: sizeX, Ny: sizeY, Nz: sizeZ } = parameters;
  // Convert 1D files into 3D format for easier processing.
  const activeMap = reshapeArray(activeMapEntireGrid, [sizeZ, sizeY, sizeX]);
  const perm = create3DArray(sizeX, sizeY, sizeZ, 1e-8);
  const poro = create3DArray(sizeX, sizeY, sizeZ, 0.01);
  const sw = create3DArray(sizeX, sizeY, sizeZ);
  const sg = create3DArray(sizeX, sizeY, sizeZ);
  const somax = create3DArray(sizeX, sizeY, sizeZ);
  const prodInd = create3DArray(sizeX, sizeY, sizeZ);
  const onesLike = create3DArray(sizeX, sizeY, sizeZ, 1);

  let count = 0;
  let count2 = 0;

  for (let k = 0; k < sizeZ; k++) {
    for (let j = 0; j < sizeY; j++) {
      for (let i = 0; i < sizeX; i++) {
        // Build production index 3D map
        if (oneWell1d)
          prodInd[i][j][k] = Number(Object.values(oneWell1d[count2])[0]);
        count2 += 1;
        if (Number(Object.values(activeMap[k][j][i])[0]) == 1) {
          // If current coordinate point is active on the Grid, save the property value into 3D array format.
          perm[i][j][k] = Number(Object.values(permx[count])[0]);
          poro[i][j][k] = Number(Object.values(poros[count])[0]);
          if (swat) sw[i][j][k] = Number(Object.values(swat[count])[1]);
          if (sgas) sg[i][j][k] = Number(Object.values(sgas[count])[1]);
          if (somax_) somax[i][j][k] = Number(Object.values(somax_[count])[1]);
          count += 1;
        }
      }
    }
  }
  // Oil saturation would be equal to 1 - (water and gas saturation)
  let so = subtract3D(subtract3D(onesLike, sw), sg);

  // If calculated oil saturation value is greater than the max possible oil saturation value, prefer max value.
  if (swat && sgas) {
    for (let k = 0; k < sizeZ; k++) {
      for (let j = 0; j < sizeY; j++) {
        for (let i = 0; i < sizeX; i++) {
          if (so[i][j][k] > somax[i][j][k]) {
            so[i][j][k] = somax[i][j][k];
          }
        }
      }
    }
  }

  const xCoords = new Array(sizeX * sizeY).fill(0);
  const yCoords = new Array(sizeX * sizeY).fill(0);
  // Save all the coordinates in a 1D format. So rather than saving the domain of the coordinates,
  // save selected axis points for each opposite axis.
  for (let i = 0; i < sizeX; i++) {
    for (let j = 0; j < sizeY; j++) {
      yCoords[i * sizeY + j] = j + 1;
    }
    for (
      let lowerRange = i * sizeY;
      lowerRange < (i + 1) * sizeY;
      lowerRange++
    ) {
      xCoords[lowerRange] = i + 1;
    }
  }

  return { activeMap, prodInd, poro, perm, so, xCoords, yCoords };
};

export function reservoirLevelFigNew(
  xCoord,
  yCoord,
  so,
  poro,
  prodInd,
  perm,
  xTrain,
  yTrain,
  title,
  ifConstColor = false
) {
  const layer = 9;
  const sizeX = 46;
  const sizeY = 112;

  so = reshapeArray(
    get3DShapeValues([0, so.length], [0, so[0].length], [layer - 1, layer], so),
    [1, sizeX * sizeY]
  )[0];
  poro = reshapeArray(
    get3DShapeValues(
      [0, poro.length],
      [0, poro[0].length],
      [layer - 1, layer],
      poro
    ),
    [1, sizeX * sizeY]
  )[0];
  prodInd = reshapeArray(
    get3DShapeValues(
      [0, prodInd.length],
      [0, prodInd[0].length],
      [layer - 1, layer],
      prodInd
    ),
    [1, sizeX * sizeY]
  )[0];

  const prodMin = Math.min(...prodInd);
  const prodMax = Math.max(...prodInd);

  prodInd = prodInd.map(value => (value - prodMin) / (prodMax - prodMin));

  const perm_ = reshapeArray(
    get3DShapeValues(
      [0, perm.length],
      [0, perm[0].length],
      [layer - 1, layer],
      perm
    ),
    [1, sizeX * sizeY]
  )[0];

  const mask = perm_.map(value => value > 1e-7);

  const trace1 = createTraceWithMask(xCoord, yCoord, so, mask);
  const trace2 = createTraceWithMask(xCoord, yCoord, poro, mask);
  const trace3 = createTraceWithMask(xCoord, yCoord, prodInd, mask);

  const coordZips = arrayZipper(xTrain['x'], xTrain['y'], yTrain);

  let hovertext = coordZips.map(([x, y, value]) => {
    return `X: ${x}<br>Y: ${y}<br>NPV: ${Math.round(value * 100) / 100}`;
  });

  let colorscale = 'jet';
  let showscale = true;
  if (ifConstColor) {
    colorscale = [
      [0, '#000'],
      [0.5, '#000'],
      [1.0, '#000'],
    ];
    hovertext = undefined;
    showscale = false;
  }

  const trace4 = {
    x: xTrain['x'],
    y: xTrain['y'],
    mode: 'markers',
    name: 'wells',
    hovertext: hovertext,
    showlegend: false,
    marker: {
      size: 15,
      cmax: Math.max(...yTrain),
      cmin: Math.min(...yTrain),
      color: yTrain,
      showscale: showscale,
      colorscale: colorscale,
    },
  };

  const buttonNames = ['Quality Index', 'Oil Saturation', 'Porosity'];
  const buttons = [];
  const visible = [
    [true, false, false, true],
    [false, true, false, true],
    [false, false, true, true],
  ];

  buttonNames.forEach((value, index) => {
    buttons.push({
      label: value,
      method: 'update',
      args: [{ visible: visible[index] }],
    });
  });

  const updateMenus = [
    {
      type: 'buttons',
      active: 0,
      buttons,
    },
  ];

  const traces = [trace3, trace1, trace2, trace4];

  traces.forEach(trace => (trace['visible'] = false));
  traces[0]['visible'] = true;
  traces[3]['visible'] = true;

  const figure = wrapPlots(
    traces,
    'X Coordinate',
    'Y Coordinate',
    title,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    updateMenus
  );

  return figure;
}

export const integration2DMap = (
  attribute,
  perm,
  xCoord,
  yCoord,
  xTrain,
  yTrain,
  layer = 9,
  normalizeDataset,
  title,
  ifConstColor = false
) => {
  const xTrainClone = {
    ...xTrain,
    y: xTrain.y.map(value => 258 - value),
  };
  const layeredAttr = extractLayerFrom3D(attribute, layer, normalizeDataset);
  const mask = extractLayerFrom3D(perm, layer).map(value => value >= 1e-20);
  const coordZips = arrayZipper(xTrainClone['x'], xTrainClone['y'], yTrain);

  let hovertext = coordZips.map(([x, y, value]) => {
    return `X: ${x}<br>Y: ${y}<br>NPV: ${Math.round(value * 100) / 100}`;
  });
  let colorscale = 'jet';
  let showscale = true;
  if (ifConstColor) {
    colorscale = [
      [0, '#000'],
      [0.5, '#000'],
      [1.0, '#000'],
    ];
    hovertext = undefined;
    showscale = false;
  }

  const mainTrace = createTraceWithMask(xCoord, yCoord, layeredAttr, mask);
  const wellPointTrace = {
    x: xTrainClone['x'],
    y: xTrainClone['y'],
    mode: 'markers',
    name: 'wells',
    hovertext: hovertext,
    showlegend: false,
    marker: {
      size: 10,
      cmax: Math.max(...yTrain),
      cmin: Math.min(...yTrain),
      color: 'black',
      colorscale: colorscale,
      showscale: showscale,
      symbol: 'triangle-up',
    },
  };
  const staticPointTrace =
    xTrain['x'].length > 0
      ? {
          x: [83, 79],
          y: [258 - 162, 258 - 93],
          mode: 'markers',
          showlegend: false,
          marker: {
            size: 20,
            color: '#EE4B2B',
            symbol: 'star',
          },
        }
      : {};
  const lineTrace = {
    x: xTrainClone['x'],
    y: xTrainClone['y'],
    mode: 'lines',
    line: {
      color: 'black',
      width: 3,
    },
    showlegend: false,
  };

  return wrapPlots(
    [mainTrace, wellPointTrace, lineTrace, staticPointTrace],
    'X Coordinate',
    'Y Coordinate',
    title
  );
};
