import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../../../../Styles/WorkflowStyles';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveStaticGainValuesResult } from '../../../../../../API/Functions/CRMP';

import { BasicTable } from '../../../../../Components/TableView';
import LoadingPlot from '../../../../../Components/LoadingPlot';

import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';

import { Grid, IconButton, Link, Paper, Typography } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const GainValuesTableView = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchFieldResults() {
      try {
        const wellStaticGainValues = await retrieveStaticGainValuesResult(
          reduxSelectedWorkflow.workflow_id
        );

        if (mounted) {
          setapiRes({
            wellStaticGainValues,
          });
        }
      } catch (error) {
        dispatch(
          updateSnackBar(error?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchFieldResults();
    return () => (mounted = false);
  }, []);

  const [encodedUri, setencodedUri] = useState(null);

  /**
   * Downloads the workflow analysis result to clients machine.
   */
  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };

  return (
    <Grid item className={styles.visualContentFlex}>
      <Grid container className={styles.visualContentRow}>
        <Typography>Gain Values</Typography>
      </Grid>
      <Grid container className={styles.visualContentRow}>
        {apiRes?.wellStaticGainValues ? (
          <Paper elevation={5}>
            <BasicTable data={apiRes.wellStaticGainValues} />
          </Paper>
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download Results</Typography>
          <Link
            download="Gain_Values.csv"
            href={encodedUri}
            onClick={() => {
              onDownload(apiRes.wellStaticGainValues);
            }}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GainValuesTableView;
