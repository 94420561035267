import { filterTimelineFromDataset } from '../../../../../Utils/DatasetUtils/DataProcessing';
import { DATE } from '../../../../../constants/WellConstants';

/**
 * Creates a vertical line at the given final percentage of the timeline
 * @param {Array} timeline - The timeline of the dataset
 * @param {Number} percentage - The percentage of the timeline to place the line
 * @returns {Object} Plotly layout object
 * @example
 * const layout = createPlotLineTrace(timeline, 0.05);
 * will create a layout object with a vertical line at 95% of the timeline
 */

export const createPlotLineTrace = (timeline, percentage) => {
  const trainSplit = Number(percentage);
  // Find the minimum and maximum date values in the timeline
  const minDate = new Date(Math.min(...timeline.map(date => new Date(date))));
  const maxDate = new Date(Math.max(...timeline.map(date => new Date(date))));

  // Calculate the date that corresponds to percentage of the timeline
  const dateRange = maxDate - minDate;
  const lineDate = new Date(minDate.valueOf() + dateRange * trainSplit);

  // Add the vertical line to the plot layout
  const layout = {
    shapes: [
      {
        type: 'line',
        x0: lineDate,
        x1: lineDate,
        y0: 0,
        y1: 1,
        yref: 'paper',
        line: {
          color: 'red',
          width: 2,
        },
      },
    ],
  };

  return layout;
};

/**
 * Generates a plotly plot for the given dataset, selected producer, column names,
 * x-axis label, y-axis label, title, and training split percentage
 * @param {Array} dataset - The dataset to be plotted
 * @param {String} selectedProducer - The selected producer to be plotted
 * @param {Array} columnNames - The column names to be plotted
 * @param {String} xLabel - The x-axis label
 * @param {String} yLabel - The y-axis label
 * @param {String} title - The title of the plot
 * @param {Number} trainingSplit - The training split percentage
 * @returns {Object} - The plotly plot
 * */

export const generatePlot = (
  dataset,
  selectedProducer,
  columnNames,
  xLabel,
  yLabel,
  title,
  trainingSplit
) => {
  const fluidDataActual = {};
  const fluidDataBL = {};
  const timeline = filterTimelineFromDataset(dataset);

  timeline.forEach(key => (fluidDataActual[key] = 0));
  dataset.forEach(row => {
    fluidDataActual[row[DATE]] += Number(row[columnNames[0]]);
  });

  var trace1 = {
    x: timeline,
    y: Object.values(fluidDataActual),
    mode: 'markers',
    type: 'scatter',
    name: `${
      selectedProducer == 'Select Producer' ? 'Total' : selectedProducer
    } - Actual Rate`,
  };

  timeline.forEach(key => (fluidDataBL[key] = 0));
  dataset.forEach(row => {
    fluidDataBL[row[DATE]] += Number(row[columnNames[1]]);
  });

  var trace2 = {
    x: timeline,
    y: Object.values(fluidDataBL),
    mode: 'lines',
    type: 'scatter',
    name: `${
      selectedProducer == 'Select Producer' ? 'Total' : selectedProducer
    } - Predicted Rate`,
  };

  var data = [trace1, trace2];

  const layout = createPlotLineTrace(timeline, trainingSplit);
  const config = {
    responsive: true,
  };

  return {
    data: data,
    layout: {
      ...layout,
      title: title,
      xaxis: { title: xLabel },
      yaxis: { title: yLabel },
    },
    config: config,
  };
};

/**
 * Filters the dataset by the given producer
 * @param {Array} dataset
 * @param {String} producer
 * @returns {Array} - The filtered dataset
 */

export const filterDatasetByProducer = (dataset, producer) => {
  const filteredDataset = dataset.filter(row => row['Producer'] === producer);
  return filteredDataset;
};

/**
 * FIXME: Change function name.
 *
 * Filters the well names from the given dataset and returns a sorted array of well names.
 * @param {Array} dataset
 * @returns {Array} - The filtered and sorted well names
 * */

export const filterWellNamesFromDataset = dataset => {
  const wellNames = [];
  dataset.forEach(row => {
    if (!wellNames.includes(row['Producer'])) {
      wellNames.push(row['Producer']);
    }
  });
  // Sort the well names in ascending order
  const sortedWellNames = wellNames.sort((a, b) => {
    const aNum = parseInt(a.split('-')[1]);
    const bNum = parseInt(b.split('-')[1]);
    return aNum - bNum;
  });
  return sortedWellNames;
};

/**
 *
 * @param {*} dataset
 * @param {*} colName
 * @returns  {Array} - Filtered and sorted well names based on cumulative column rates
 */

export const filterWellNamesAndSortByCumulativeColumn = (dataset, colName) => {
  const wellNames = [];
  dataset.forEach(row => {
    if (!wellNames.includes(row['Producer'])) {
      wellNames.push(row['Producer']);
    }
  });

  const wellNamesAndCumulativeColumn = wellNames.map(wellName => {
    const wellData = dataset.filter(row => row['Producer'] === wellName);
    const cumulativeColumn = wellData.reduce(
      (acc, row) => acc + Number(row[colName]),
      0
    );
    return [wellName, cumulativeColumn];
  });

  // Sort the well names based on cumulativeColumn in ascending order
  const sortedWellNamesAndCumulativeColumn = wellNamesAndCumulativeColumn.sort(
    (a, b) => {
      return b[1] - a[1];
    }
  );

  // Map the names of sorted wells.
  const sortedWellNames = sortedWellNamesAndCumulativeColumn.map(
    wellNameAndCumulativeColumn => wellNameAndCumulativeColumn[0]
  );

  return sortedWellNames;
};
