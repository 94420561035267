import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import useStyles from '../../../../../../../Styles/WorkflowStyles';

import OilContributionPlot from './OilContributionPlot';
import LoadingPlot from '../../../../../../Components/LoadingPlot';
import { BasicTable } from '../../../../../../Components/TableView';

import {
  createLinePlot,
  wrapPlots,
} from '../../../../../../../Utils/PlotlyUtils/Plots';
import { getUnitRate } from '../../../../../../../Utils/ReservoirUtils';

import { Grid, IconButton, Link, Paper, Typography } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  GAS,
  WAG,
  DATE,
  OVER,
  WATER,
  PER_DAY,
  OIL_RATE,
  FLUID_RATE,
  SYSTEM_OF_UNITS,
} from '../../../../../../../constants/WellConstants';
import { INJECTION_CALCULATION_TYPE } from '../../../../../../../constants/WorkflowsParameterConstants';

const HistoryShutInAnalysis = ({
  fieldWellLocations,
  workflowParameters,
  injShutOffFluidData,
  injShutOffOilData,
  factors,
  staticGainValues,
  oilContribution,
  oilContrSum,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks -->
  const generateFluidRatePlot = () => {
    try {
      return createLinePlot(
        injShutOffFluidData,
        DATE,
        Object.keys(injShutOffFluidData[0]).filter(key => key != DATE),
        Object.keys(injShutOffFluidData[0]).filter(key => key != DATE),
        undefined,
        undefined,
        `${DATE} (${PER_DAY.split(OVER).at(-1)})`,
        `Field Fluid Rate (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          FLUID_RATE,
          PER_DAY
        )})`,
        'Injection Effect - Fluid Rate'
      );
    } catch (error) {
      return wrapPlots(
        [],
        `${DATE} (${PER_DAY.split(OVER).at(-1)})`,
        `Field Fluid Rate (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          FLUID_RATE,
          PER_DAY
        )})`,
        'Injection Effect - Fluid Rate'
      );
    }
  };

  const generateOilRatePlot = () => {
    try {
      return createLinePlot(
        injShutOffOilData,
        DATE,
        Object.keys(injShutOffOilData[0]).filter(key => key != DATE),
        Object.keys(injShutOffOilData[0]).filter(key => key != DATE),
        undefined,
        undefined,
        `${DATE} (${PER_DAY.split(OVER).at(-1)})`,
        `Field Oil Rate (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          OIL_RATE,
          PER_DAY
        )})`,
        'Injection Effect - Oil Rate'
      );
    } catch (err) {
      return wrapPlots(
        [],
        `${DATE} (${PER_DAY.split(OVER).at(-1)})`,
        `Field Oil Rate (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          OIL_RATE,
          PER_DAY
        )})`,
        'Injection Effect - Oil Rate'
      );
    }
  };
  // <-- Callbacks

  // Handlers -->
  const onDownload = arrayFileContent => {
    const content = [];

    arrayFileContent.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // hooks -->
  const filteredOilContrSum = useMemo(() => {
    const renameMap = {
      'water_loss, %': 'Water Loss (%)',
      'oil_loss, %': 'Oil Loss (%)',
      loss_ratio: 'Loss Ratio',
      'water_loss, V': 'Water Loss (V)',
      'oil_loss, V': 'Oil Loss (V)',
      I_ave: 'Injection Average',
      'I total': 'Total Injection',
    };

    return oilContrSum.map(row => {
      return Object.keys(row)
        .filter(key => !key.toLowerCase().includes('inj rate'))
        .reduce((acc, curr) => {
          acc[renameMap[curr] || curr] = row[curr];
          return acc;
        }, {});
    });
  }, [oilContrSum]);

  const cachedFluidRatePlot = useMemo(() => {
    return generateFluidRatePlot();
  }, [injShutOffFluidData]);

  const cachedOilRatePlot = useMemo(() => {
    return generateOilRatePlot();
  }, [injShutOffOilData]);
  // <-- hooks

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentRow}>
        <Typography variant="h5">History Injection Efficiency</Typography>
      </Grid>
      <Grid container className={styles.visualContent}>
        {cachedFluidRatePlot ? (
          <PlotlyComponent
            data={cachedFluidRatePlot.data}
            layout={cachedFluidRatePlot.layout}
            config={cachedFluidRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        {cachedOilRatePlot ? (
          <PlotlyComponent
            data={cachedOilRatePlot.data}
            layout={cachedOilRatePlot.layout}
            config={cachedOilRatePlot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentRow}>
          <Typography>Injector Factors</Typography>
        </Grid>
        {factors?.length ? (
          <React.Fragment>
            <Grid container className={styles.visualContentRow}>
              <Paper elevation={5}>
                <BasicTable data={factors} />
              </Paper>
            </Grid>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Injector Factors
                </Typography>
                <Link
                  download="injector_factors.csv"
                  href={encodedUri}
                  onClick={() => onDownload(factors)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        {workflowParameters[INJECTION_CALCULATION_TYPE] == WAG ? (
          <Grid container style={{ flexDirection: 'row', flex: '1' }}>
            <Grid item xs={6}>
              <OilContributionPlot
                fieldWellLocations={fieldWellLocations}
                workflowParameters={workflowParameters}
                staticGainValues={staticGainValues}
                oilContribution={oilContribution}
                WAG_TYPE={WATER}
              />
            </Grid>
            <Grid item xs={6}>
              <OilContributionPlot
                fieldWellLocations={fieldWellLocations}
                workflowParameters={workflowParameters}
                staticGainValues={staticGainValues}
                oilContribution={oilContribution}
                WAG_TYPE={GAS}
              />
            </Grid>
          </Grid>
        ) : (
          <OilContributionPlot
            fieldWellLocations={fieldWellLocations}
            workflowParameters={workflowParameters}
            staticGainValues={staticGainValues}
            oilContribution={oilContribution}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentRow}>
          <Typography>Oil Contribution Summary</Typography>
        </Grid>
        {filteredOilContrSum?.length ? (
          <React.Fragment>
            <Grid container className={styles.visualContentRow}>
              <Paper elevation={5}>
                <BasicTable data={filteredOilContrSum} />
              </Paper>
            </Grid>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Oil Contribution Summary
                </Typography>
                <Link
                  download="oil_contr_summary.csv"
                  href={encodedUri}
                  onClick={() => onDownload(oilContrSum)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <LoadingPlot />
        )}
      </Grid>
    </React.Fragment>
  );
};

HistoryShutInAnalysis.propTypes = {
  fieldWellLocations: PropTypes.array,
  workflowParameters: PropTypes.object,
  injShutOffFluidData: PropTypes.array,
  injShutOffOilData: PropTypes.array,
  factors: PropTypes.array,
  staticGainValues: PropTypes.array,
  oilContribution: PropTypes.array,
  oilContrSum: PropTypes.array,
};

export default HistoryShutInAnalysis;
