import {
  UPDATE_SNACKBAR,
  SET_SNACKBAR_OPEN,
  SET_SNACKBAR_TEXT,
  SET_SNACKBAR_SEVERITY,
} from '../actions/feedback/types';

const DEFAULT_STATE = {
  snackBarOpen: null,
  snackBarText: null,
  snackBarSeverity: null,
};

export const feedbackReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SNACKBAR:
      return {
        ...state,
        snackBarOpen: action.payload['snackBarOpen'],
        snackBarText: action.payload['snackBarText'],
        snackBarSeverity: action.payload['snackBarSeverity'],
      };
    case SET_SNACKBAR_OPEN:
      return {
        ...state,
        snackBarOpen: action.payload,
      };
    case SET_SNACKBAR_TEXT:
      return {
        ...state,
        snackBarText: action.payload,
      };
    case SET_SNACKBAR_SEVERITY:
      return {
        ...state,
        snackBarSeverity: action.payload,
      };
    default: {
      return state;
    }
  }
};
