import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../../../Styles/WorkflowStyles';
import { useDispatch, useSelector } from 'react-redux';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../../../Components/LoadingPlot';

import { updateSnackBar } from '../../../../../../redux/actions/feedback';
import { retrieveCumFieldAnalysis } from '../../../../../../API/Functions/Visualization';
import { generateFieldAnalysisPlot } from '../../../../../../Utils/VisualizationUtils';

import { Button, Grid } from '@material-ui/core';

import {
  PER_MONTH,
  FORMATION_VFG,
  FORMATION_VFO,
  FORMATION_VFW,
  SYSTEM_OF_UNITS,
} from '../../../../../../constants/WellConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../../constants/ComponentConstants';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const CumFieldAnalysisComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [showFluids, setshowFluids] = useState(true);

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveCumFieldAnalysis(
          reduxSelectedWorkflow['dataset_id'],
          reduxSelectedWorkflow.parameters
        );
        if (mounted) {
          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, []);

  const cachedPlotResult = useMemo(() => {
    if (apiRes) {
      return generateFieldAnalysisPlot(
        apiRes,
        reduxSelectedWorkflow.parameters[FORMATION_VFO],
        reduxSelectedWorkflow.parameters[FORMATION_VFW],
        reduxSelectedWorkflow.parameters[FORMATION_VFG],
        reduxSelectedWorkflow.parameters[SYSTEM_OF_UNITS],
        PER_MONTH,
        showFluids
      );
    }
  }, [apiRes, showFluids]);

  return (
    <Grid item container className={styles.visualContent}>
      <Grid container direction="row-reverse">
        <Button onClick={() => setshowFluids(!showFluids)}>Swap Traces</Button>
      </Grid>
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ flex: 1 }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

export default CumFieldAnalysisComponent;
