import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { datasetReducer } from './datasetReducer';
import { workflowReducer } from './workflowReducer';
import { feedbackReducer } from './feedbackReducer';
import { sideBarReducer } from './sideBarReducer';
import { USER_LOGOUT } from '../actions/user/types';

const appReducer = combineReducers({
  user: userReducer,
  dataset: datasetReducer,
  workflow: workflowReducer,
  feedback: feedbackReducer,
  sidebar: sideBarReducer,
});
// FIXME: What is the purpose of USER_LOGOUT here?
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
