import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  filterWellContentsFromDataset,
  filterWellNamesFromDataset,
} from '../../../Utils/DatasetUtils/DataProcessing';

import {
  FLUID_INJECTION_RATE,
  WELL_ID,
} from '../../../constants/WellConstants';

import { DataGrid } from '@material-ui/data-grid';

const tableColNames = [
  WELL_ID,
  'Min Rate',
  'Max Rate',
  'Min Factor',
  'Max Factor',
  'Average Injection',
];

export const AveInjTable = ({
  datasetContent,
  aveInjMonth = 6,
  wellAveInjCallback,
}) => {
  const [columns, setcolumns] = useState(null);
  const [rows, setrows] = useState(null);
  let rowId = 1;

  // Callbacks / Helpers -->
  /**
   * Find most recent average fluid injection rate.
   * @param {Array} wellData
   * @returns {Number}
   */
  const findAveInjection = wellData => {
    const aveData = [];
    let i = wellData.length - 1;

    while (aveData.length < aveInjMonth && i > 0) {
      aveData.push(wellData.at(i));
      i--;
    }

    return aveData.reduce((acc, curr) => {
      return acc + Number(curr[FLUID_INJECTION_RATE]);
    }, 0);
  };

  /**
   * Updates min/max injection rate by multiplying aveInjValue with given factor ranges.
   * @param {String} aveInjValue
   * @param {Array[String, String]} factorRange
   */
  const updateInjRatesFromFactor = (wellData, minFactor, maxFactor) => {
    wellData['Min Rate'] = (
      Number(wellData['Average Injection']) * Number(minFactor)
    ).toFixed(2);
    wellData['Max Rate'] = (
      Number(wellData['Average Injection']) * Number(maxFactor)
    ).toFixed(2);
    wellData['Min Factor'] = minFactor;
    wellData['Max Factor'] = maxFactor;
  };

  /**
   * Updates min/max factors by dividing given aveInjValue by given injRange.
   * @param {String} aveInjValue
   * @param {Array[String, String]} injRange
   */
  const updateFactorsFromInjRate = (wellData, minRate, maxRate) => {
    wellData['Min Factor'] = (
      Number(minRate) / Number(wellData['Average Injection'])
    ).toFixed(2);
    wellData['Max Factor'] = (
      Number(maxRate) / Number(wellData['Average Injection'])
    ).toFixed(2);
    wellData['Min Rate'] = Number(minRate).toFixed(2);
    wellData['Max Rate'] = Number(maxRate).toFixed(2);
  };

  /**
   * Based on updated cell value, recalculates depended other values. Inplace function.
   * @param {*} param
   */
  const onCellEditCommit = param => {
    const selectedRow = { ...rows.find(row => row['id'] == param.id) };

    if (selectedRow[param.field] !== param.value) {
      switch (param.field) {
        case 'Min Rate':
          updateFactorsFromInjRate(
            selectedRow,
            param.value,
            selectedRow['Max Rate']
          );
          break;
        case 'Max Rate':
          updateFactorsFromInjRate(
            selectedRow,
            selectedRow['Min Rate'],
            param.value
          );
          break;
        case 'Min Factor':
          updateInjRatesFromFactor(
            selectedRow,
            param.value,
            selectedRow['Max Factor']
          );
          break;
        case 'Max Factor':
          updateInjRatesFromFactor(
            selectedRow,
            selectedRow['Min Factor'],
            param.value
          );
          break;

        default:
          break;
      }

      setrows(prev => {
        const updatedRows = [
          ...prev.filter(row => row['id'] !== param.id),
          selectedRow,
        ].sort((r1, r2) => r1['id'] - r2['id']);
        wellAveInjCallback(updatedRows);
        return updatedRows;
      });
    }
  };

  const initCols = () => {
    setcolumns(
      tableColNames.map(colName => ({
        field: colName,
        headerName: colName,
        editable: colName !== WELL_ID && colName !== 'Average Injection',
        width: '160',
      }))
    );
  };

  const initRows = () => {
    const { injectors: injData } =
      filterWellContentsFromDataset(datasetContent);
    const { injectors: wellNames } = filterWellNamesFromDataset(datasetContent);

    const wellAveInjValues = wellNames.map(wellName => {
      const wellData = injData.filter(row => row[WELL_ID] == wellName);
      const aveFluidInj = findAveInjection(wellData);

      return {
        id: rowId++,
        [WELL_ID]: wellName,
        ['Min Rate']: (aveFluidInj * 0.5).toFixed(2),
        ['Max Rate']: (aveFluidInj * 1.5).toFixed(2),
        ['Min Factor']: (0.5).toFixed(2),
        ['Max Factor']: (1.5).toFixed(2),
        ['Average Injection']: aveFluidInj.toFixed(2),
      };
    });
    setrows(wellAveInjValues);
    wellAveInjCallback(wellAveInjValues);
  };
  // <-- Callbacks / Helpers

  useEffect(() => {
    if (datasetContent) {
      initRows();
      initCols();
    }
  }, [datasetContent, aveInjMonth]);

  if (columns !== null && rows != null) {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={rows.length > 5 ? 5 : rows.length}
        autoHeight={true}
        onCellEditCommit={onCellEditCommit}
      />
    );
  } else {
    return '';
  }
};

AveInjTable.propTypes = {
  datasetContent: PropTypes.array,
  aveInjMonth: PropTypes.number,
  wellAveInjCallback: PropTypes.func,
};
