import React, { useState } from 'react';

import useStyles from '../../Styles/ContactUsStyle';

import Navbar from '../Components/NavBar';
import { useDispatch } from 'react-redux';

import {
  Grid,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core';

import LogoComponent from '../Components/LogoComponent';
// import { apiClient } from '../../service/apiClient';
// import { getJWTToken } from '../base';
import { contactUs } from '../../redux/actions/contactUs';
import { useNavigate } from 'react-router-dom';

const ContactUsPage = () => {
  const styles = useStyles();

  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [description, setdescription] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNameChange = event => {
    setname(event.target.value);
  };
  const handleEmailChange = event => {
    setemail(event.target.value);
  };
  const handleDescriptionChange = event => {
    setdescription(event.target.value);
  };

  const onSuccess = async () => {};

  const onFail = async () => {};

  const handleContactUs = async event => {
    event.preventDefault();
    await dispatch(contactUs({ name, email, description }, onSuccess, onFail));
  };

  const onExpandClick = () => {
    navigate('/workspace');
  };

  const onContactUsClick = () => {
    navigate('/contactUs');
  };

  const onNewProjectClick = () => {
    navigate('/uploadSurveillance');
  };

  return (
    <Grid container className={styles.root}>
      <Grid item className={styles.navbarGrid}>
        <LogoComponent />
        <Navbar />
      </Grid>
      <Grid item container className={styles.bodyGrid}>
        <Grid item className={styles.sidebarGrid}>
          <Paper elevation={20} className={styles.sidebarPaperContainer}>
            <List className={styles.sidebarList}>
              <ListItem button onClick={() => onExpandClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>Workspace</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
              <ListItem button onClick={() => onNewProjectClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>New Project</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
              <ListItem button onClick={() => onContactUsClick()}>
                <ListItemText disableTypography={true}>
                  <Typography>Contact US</Typography>
                </ListItemText>
              </ListItem>
              <Divider variant="middle" />
            </List>
          </Paper>
        </Grid>
        <Grid item className={styles.contentGrid}>
          <Paper elevation={20} className={styles.paperContainer}>
            <form
              className={styles.form}
              noValidate
              autoComplete="off"
              onSubmit={handleContactUs}
            >
              <TextField
                className={styles.textField}
                label="Name"
                variant="standard"
                size="small"
                required
                onChange={handleNameChange}
              />
              <TextField
                className={styles.textField}
                label="Email"
                variant="standard"
                size="small"
                required
                onChange={handleEmailChange}
              />
              <TextField
                className={styles.textField}
                name="description"
                multiline
                minRows={4}
                size="small"
                variant="standard"
                label="Description"
                required
                onChange={handleDescriptionChange}
              />
              <Grid item container className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
                <Button
                  className={styles.button}
                  type="cancel"
                  variant="contained"
                  onClick={() => navigate('/homepage')}
                >
                  Cancel
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContactUsPage.propTypes = {};

export default ContactUsPage;
