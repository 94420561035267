import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../Styles/VisualizationPageStyle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';

import { retrieveFieldInjectionData } from '../../../../API/Functions/Visualization';

import {
  filterAvailaleColNamesFromDataset,
  filterWellNamesFromDataset,
} from '../../../../Utils/DatasetUtils/DataProcessing';
import { generateRatePlot } from '../../../../Utils/VisualizationUtils';

import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';

import {
  PER_DAY,
  INJECTOR,
  SYSTEM_OF_UNITS,
} from '../../../../constants/WellConstants';
import { updateSnackBar } from '../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

const selectedDatasetParameters = state => state.dataset.parameters;

const WellInjectionRateAnalysisComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedDatasetParameters = useSelector(selectedDatasetParameters);
  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);
  const [wellNames, setwellNames] = useState(undefined);
  const [attrOptions, setattrOptions] = useState(undefined);
  const [selectedAttr, setselectedAttr] = useState(undefined);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  const onAttrSelect = event => {
    setselectedAttr(event.target.value);
  };
  // <-- Handlers

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveFieldInjectionData(
          urlDatasetId,
          reduxSelectedDatasetParameters
        );
        if (mounted) {
          const names = filterWellNamesFromDataset(res).injectors;
          const cols = filterAvailaleColNamesFromDataset(Object.keys(res[0]))[
            INJECTOR
          ];

          setapiRes(res);
          setwellNames(names);
          setattrOptions(cols);
          setselectedAttr(cols[0]);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, [reduxSelectedDatasetParameters]);

  const cachedPlotResult = useMemo(() => {
    if (apiRes != undefined && selectedAttr != undefined) {
      return generateRatePlot(
        apiRes,
        selectedAttr,
        wellNames,
        reduxSelectedDatasetParameters[SYSTEM_OF_UNITS],
        PER_DAY
      );
    }
  }, [apiRes, selectedAttr]);

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        className={styles.formControl}
      >
        {attrOptions && selectedAttr && (
          <Select value={selectedAttr} onChange={onAttrSelect}>
            {attrOptions.map(option => (
              <MenuItem key={`option-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <Grid item className={styles.plotContainer}>
        {cachedPlotResult ? (
          <PlotlyComponent
            data={cachedPlotResult.data}
            layout={cachedPlotResult.layout}
            config={cachedPlotResult.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
    </>
  );
};

WellInjectionRateAnalysisComponent.propTypes = {
  header: PropTypes.string,
};

export default WellInjectionRateAnalysisComponent;
