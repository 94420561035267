import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import LoadingPage from '../../../Components/LoadingPage';
import WellAnalysisConnectivityPlot from './WellFeatureAnalysis';
import WellOrientedFeatureAnalysisPlot from './WellFeatureAnalysis/WellOrientedFeatureAnalysis';

import {
  CROSS_COMPARISON_FILE_NAMES,
  RESULT_MODEL_1,
  RESULT_MODEL_2,
} from '../../../../constants/WorkflowsParameterConstants';

import { Grid } from '@material-ui/core';
import { extractWellPairFeatureValues } from '../../../../Utils/WorkflowReportUtils/WorkflowComparisonUtils';
import { filterWellNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';
import {
  CRMP,
  FEATURE_IMPORTANCE,
  GAIN,
  INJECTOR,
  PRODUCER,
} from '../../../../constants/WellConstants';

const CrossComparisonReport = ({
  headers,
  workflowResultFiles,
  workflowParameters,
}) => {
  const styles = useStyles();

  const [workflowReady, setworkflowReady] = useState(false);

  const [resultModel1Details, setresultModel1Details] = useState({});
  const [resultModel2Details, setresultModel2Details] = useState({});

  // Callbacks -->
  const extractResultModelDetails = modelName => {
    const modelResultFiles = workflowResultFiles[modelName];
    const modelParams = workflowParameters[modelName];
    const modelFilteredDataset =
      modelResultFiles[CROSS_COMPARISON_FILE_NAMES.FILTERED_DATASET];
    const modelWorkflowAnalysisContent =
      modelResultFiles[
        CROSS_COMPARISON_FILE_NAMES[modelParams['workflow_type']]
      ];
    const modelWorkflowType = modelParams['workflow_type'];
    const modelFocusedFeature =
      modelWorkflowType == CRMP ? GAIN : FEATURE_IMPORTANCE;

    const { injectors, producers } =
      filterWellNamesFromDataset(modelFilteredDataset);

    const { wellFeatureValues, minFeatureValue, maxFeatureValue } =
      extractWellPairFeatureValues(
        modelWorkflowAnalysisContent,
        injectors,
        producers,
        modelFocusedFeature
      );

    return {
      modelFilteredDataset,
      modelWorkflowAnalysisContent,
      modelWorkflowType,
      modelFocusedFeature,
      wellFeatureValues,
      minFeatureValue,
      maxFeatureValue,
      injectors,
      producers,
    };
  };
  // <-- Callbacks

  // Handlers -->
  // <-- Handlers

  useEffect(() => {
    if (workflowResultFiles && workflowParameters) {
      const model1Files = extractResultModelDetails(RESULT_MODEL_1);
      const model2Files = extractResultModelDetails(RESULT_MODEL_2);

      setresultModel1Details(model1Files);
      setresultModel2Details(model2Files);
      setworkflowReady(true);
      return () => {
        setworkflowReady(false);
      };
    }
  }, [workflowResultFiles, workflowParameters]);

  if (workflowReady) {
    return (
      <React.Fragment>
        <Grid
          container
          id={headers.MODEL_ANALYSES}
          style={{
            width: '100%',
            height: '100%',
            padding: '1%',
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
        >
          <Grid container xs={6} className={styles.visualContentFlex}>
            <WellAnalysisConnectivityPlot
              filteredDatasetContent={resultModel1Details.modelFilteredDataset}
              wellFeatureValues={resultModel1Details.wellFeatureValues}
              minValue={resultModel1Details.minFeatureValue}
              maxValue={resultModel1Details.maxFeatureValue}
              resultProperty={resultModel1Details.modelFocusedFeature}
            />
          </Grid>
          <Grid container xs={6} className={styles.visualContent}>
            <WellAnalysisConnectivityPlot
              filteredDatasetContent={resultModel2Details.modelFilteredDataset}
              wellFeatureValues={resultModel2Details.wellFeatureValues}
              minValue={resultModel2Details.minFeatureValue}
              maxValue={resultModel2Details.maxFeatureValue}
              resultProperty={resultModel2Details.modelFocusedFeature}
            />
          </Grid>
        </Grid>
        <Grid
          container
          id={headers.PRODUCER}
          style={{
            width: '100%',
            height: '100%',
            padding: '1%',
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
        >
          <Grid container xs={6} className={styles.visualContent}>
            <WellOrientedFeatureAnalysisPlot
              focusedWellNames={resultModel1Details.producers}
              otherWellNames={resultModel1Details.injectors}
              datasetContent={resultModel1Details.modelFilteredDataset}
              wellFeatureValues={resultModel1Details.wellFeatureValues}
              minFeatureValue={resultModel1Details.minFeatureValue}
              maxFeatureValue={resultModel1Details.maxFeatureValue}
              focusedFeature={resultModel1Details.modelFocusedFeature}
              wellType={PRODUCER}
            />
          </Grid>
          <Grid container xs={6} className={styles.visualContent}>
            <WellOrientedFeatureAnalysisPlot
              focusedWellNames={resultModel2Details.producers}
              otherWellNames={resultModel2Details.injectors}
              datasetContent={resultModel2Details.modelFilteredDataset}
              wellFeatureValues={resultModel2Details.wellFeatureValues}
              minFeatureValue={resultModel2Details.minFeatureValue}
              maxFeatureValue={resultModel2Details.maxFeatureValue}
              focusedFeature={resultModel2Details.modelFocusedFeature}
              wellType={PRODUCER}
            />
          </Grid>
        </Grid>
        <Grid
          container
          id={headers.INJECTOR}
          style={{
            width: '100%',
            height: '100%',
            padding: '1%',
            flexWrap: 'nowrap',
            flexDirection: 'row',
          }}
        >
          <Grid container xs={6} className={styles.visualContent}>
            <WellOrientedFeatureAnalysisPlot
              focusedWellNames={resultModel1Details.injectors}
              otherWellNames={resultModel1Details.producers}
              datasetContent={resultModel1Details.modelFilteredDataset}
              wellFeatureValues={resultModel1Details.wellFeatureValues}
              minFeatureValue={resultModel1Details.minFeatureValue}
              maxFeatureValue={resultModel1Details.maxFeatureValue}
              focusedFeature={resultModel1Details.modelFocusedFeature}
              wellType={INJECTOR}
            />
          </Grid>
          <Grid container xs={6} className={styles.visualContent}>
            <WellOrientedFeatureAnalysisPlot
              focusedWellNames={resultModel2Details.injectors}
              otherWellNames={resultModel2Details.producers}
              datasetContent={resultModel2Details.modelFilteredDataset}
              wellFeatureValues={resultModel2Details.wellFeatureValues}
              minFeatureValue={resultModel2Details.minFeatureValue}
              maxFeatureValue={resultModel2Details.maxFeatureValue}
              focusedFeature={resultModel2Details.modelFocusedFeature}
              wellType={INJECTOR}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return <LoadingPage />;
  }
};

CrossComparisonReport.propTypes = {
  headers: PropTypes.object,
  workflowResultFiles: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default CrossComparisonReport;
