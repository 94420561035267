import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Tooltip,
  IconButton,
  Checkbox,
  Collapse,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  TOLERANCE_OPTIMISATION,
  MAX_ITERATION_OPTIMISATION,
  MAX_INJ_MULT,
  OPT_R2_THRESHOLD,
  GWR_OPT,
  GWR_MIN,
  GWR_MAX,
  WAG,
  FORMATION_VFW,
} from '../../../../../../constants/WellConstants';
import { INJECTION_CALCULATION_TYPE } from '../../../../../../constants/WorkflowsParameterConstants';

const useStyles = makeStyles({
  parameters: {
    overflow: 'hidden',
    margin: '0.5em 0em',
    '& .parameterRow': {
      justifyContent: 'space-between',
      margin: '0.2em 0em',
    },
    '& .left': { width: '55%', textAlign: 'left' },
    '& .right': { width: '45%', textAlign: 'right' },
  },
  button: {
    borderRadius: '2em',
    margin: '0 0.5em',
  },
});

const AdvancedParametersModal = ({
  modalState,
  toggle,
  applyAdvancedParameters,
  advancedParameters,
  workflowParameters,
}) => {
  const styles = useStyles();
  const [params, setparams] = useState({
    [MAX_ITERATION_OPTIMISATION]: 1000,
    [TOLERANCE_OPTIMISATION]: 100,
    [MAX_INJ_MULT]: 2,
    [OPT_R2_THRESHOLD]: -1,
    [GWR_OPT]: false,
    [GWR_MIN]: 0.1,
    [GWR_MAX]: 1.0,
  });

  // Callbacks -->
  const saveParams = (name, value) => {
    setparams(prev => ({ ...prev, [name]: value }));
  };
  // <-- Callbacks

  // Handlers -->
  // Save params to redux state
  const onSubmit = () => {
    applyAdvancedParameters(params);
    toggle();
  };
  // <-- Handlers

  useEffect(() => {
    if (advancedParameters) {
      setparams(prev => ({ ...prev, ...advancedParameters }));
    }
  }, [modalState]);

  return (
    <Dialog open={modalState} fullWidth={true} onClose={() => toggle()}>
      <DialogTitle disableTypography={true}>
        <Grid
          container
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">Advanced Settings</Typography>
          <Grid item>
            <Tooltip title="Close">
              <IconButton onClick={() => toggle()}>
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {Object.entries(params)
          .filter(entry => !entry[0].includes('GWR'))
          .map(([key, value]) => (
            <Grid key={key} container className={styles.parameters}>
              <Grid item container className="parameterRow">
                <Typography>{key}</Typography>
                <TextField
                  type="number"
                  size="small"
                  value={value}
                  onChange={e => saveParams(key, parseFloat(e.target.value))}
                />
              </Grid>
            </Grid>
          ))}
        {(workflowParameters[INJECTION_CALCULATION_TYPE] == WAG ||
          parseFloat(workflowParameters[FORMATION_VFW]) == 0.0) && (
          <Grid container className={styles.parameters}>
            <Grid item container className="parameterRow" alignItems="center">
              <Typography>{GWR_OPT}</Typography>
              <Checkbox
                checked={params[GWR_OPT]}
                onChange={e => saveParams(GWR_OPT, e.target.checked)}
              />
            </Grid>
          </Grid>
        )}
        <Collapse in={params[GWR_OPT]} timeout={500}>
          <Grid container className={styles.parameters}>
            <Grid item container className="parameterRow">
              <Typography>{GWR_MIN}</Typography>
              <TextField
                type="number"
                size="small"
                value={params[GWR_MIN]}
                onChange={e => saveParams(GWR_MIN, parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item container className="parameterRow">
              <Typography>{GWR_MAX}</Typography>
              <TextField
                type="number"
                size="small"
                value={params[GWR_MAX]}
                onChange={e => saveParams(GWR_MAX, parseFloat(e.target.value))}
              />
            </Grid>
          </Grid>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={styles.button}
          onClick={onSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvancedParametersModal.propTypes = {
  modalState: PropTypes.bool,
  toggle: PropTypes.func,
  applyAdvancedParameters: PropTypes.func,
  advancedParameters: PropTypes.object,
  workflowParameters: PropTypes.object,
};

export default AdvancedParametersModal;
