import React, { useMemo } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPage from '../../../../Components/LoadingPage';

import { generateFieldAnalysisPlot } from '../../../../../Utils/VisualizationUtils';

import { Grid, Typography } from '@material-ui/core';
import {
  FORMATION_VFG,
  FORMATION_VFO,
  FORMATION_VFW,
  SYSTEM_OF_UNITS,
} from '../../../../../constants/WellConstants';

const FieldAnalysis = ({
  header,
  filteredDatasetContent,
  workflowParameters,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const plot = useMemo(
    () =>
      generateFieldAnalysisPlot(
        filteredDatasetContent,
        workflowParameters?.[FORMATION_VFO],
        workflowParameters?.[FORMATION_VFW],
        workflowParameters?.[FORMATION_VFG],
        workflowParameters?.[SYSTEM_OF_UNITS]
      ),
    [filteredDatasetContent]
  );

  return (
    <Grid container id={header} className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography variant="h5">{header}</Typography>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Field Analysis Plot is Loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

FieldAnalysis.propTypes = {
  header: PropTypes.string,
  filteredDatasetContent: PropTypes.array,
  workflowParameters: PropTypes.array,
};

export default FieldAnalysis;
