import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#f1f4f6',
    justifyContent: 'flex-start',
  },
  navbarGrid: {
    width: '100%',
    height: '10%',
    display: 'flex',
  },
  bodyGrid: {
    width: '100%',
    height: '90%',
    padding: '0% 0%',
  },
  recentWorkflowsGrid: {
    height: '55%',
    padding: '1% 3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  workflowCardsContainer: {
    justifyContent: 'center',
  },
  selectModuleGrid: {
    height: '40%',
    padding: '1% 3%',
    margin: '1% 0%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'auto',
  },
  sidebarGrid: {
    width: '20%',
    height: '100%',
    '& hr': {
      border: '1px solid grey',
      marginBottom: '1em',
    },
  },
  paperContainer: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactUsForm: {
    // display: 'flex',
    // verflow: 'auto',
    // width: '100%',
    // height: '100%',
    // flexDirection: 'column',
  },
  form: {
    display: 'flex',
    verflow: 'auto',
    width: '50%',
    height: '50%',
    flexDirection: 'column',
  },
  sidebarPaperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: '#003333',
    color: '#c1c5d1',
  },
  sidebarList: {
    paddingTop: '25px',
  },
  intro: {
    height: '100%',
    padding: '1% 3%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'auto',
    backgroundColor: '#003333',
  },
  companyName: {
    color: '#ffffff',
    maxWidth: '100%',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
  },
  companyDescription: {
    color: '#ffffff',
    whiteSpace: 'noWrap',
    marginLeft: '3em',
    fontSize: '1.3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  introGrid: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    maxWidth: '50%',
    justifyContent: 'center',
    marginLeft: '9em',
  },
  logo: {
    maxWidth: '60%',
    height: 'auto',
    marginLeft: '-1em',
    marginTop: '14em',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-4em',
      marginTop: '8em',
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: '-7em',
      marginTop: '11em',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-1em',
      marginTop: '14em',
    },
  },
  imageContainer: {
    backgroundSize: '100% 100%',
    backgroundPosition: '50% 50%',
  },
  contentGrid: {
    width: '80%',
    height: '100%',
    padding: '1em',
  },
  header: { fontWeight: '600' },
  button: {
    borderRadius: '2em',
    textTransform: 'capitalize',
    '&.largeButton': {
      width: '20%',
    },
    '&.xlargeButton': {
      width: '25%',
      marrgin: '1%',
    },
    '&.selectedButton': {
      backgroundColor: '#000B46',
      color: 'white',
    },
    marginLeft: '1em',
    backgroundColor: '#003333',
    color: 'white',
    '&:hover': {
      backgroundColor: '#003333',
    },
  },
  primaryButton: {
    backgroundColor: '#000B46',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000B46',
    },
  },
  secondaryButton: {},
  runButton: {
    paddingLeft: '7em',
    paddingRight: '7em',
  },
  centerFlexGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    '&.column': {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  textField: {
    backgroundColor: 'white',
    paddingBottom: '0.5em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
}));

export default useStyles;
