import { USER_LOGIN, USER_LOGOUT } from '../actions/user/types';

// TODO: Are we using isAuthenticated anywhere?

const DEFAULT_STATE = {
  user: null,
  isAuthenticated: null,
};

export const userReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default: {
      return state;
    }
  }
};
