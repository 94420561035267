import { getJWTToken } from '../../../redux/actions/base';
import * as visApi from '../../Routes/visualization';
import { apiClient } from '../../../service/apiClient';

import { convertToISOFormat } from '../../../Utils/DatasetUtils/DataProcessing';

import { INJECTOR, PRODUCER } from '../../../constants/WellConstants';

export const retrieveFieldWellNames = async datasetId => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: visApi.fieldWellNamesRoute(datasetId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
};

export const retrieveActiveFieldAttrs = async datasetId => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: visApi.fieldActiveAttributesRoute(datasetId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving active field attributes.');
  }
};

export const retrieveFieldTimeline = async datasetId => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: visApi.fieldTimelineRoute(datasetId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field timeline.');
  }
};

export const retrieveFieldLayers = async datasetId => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: visApi.fieldLayersRoute(datasetId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field layers.');
  }
};

export const retrieveWellSurfaceLocations = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.wellSurfaceLocationRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving well surface location results.');
  }
};

export const retrieveWellActivitySchedule = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.wellActivityScheduleRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });

    const data = response.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving well activity schedule results.');
  }
};

export const retrieveCumFieldAnalysis = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.cumFieldAnalysisRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving cumulative field analysis results.');
  }
};

export const retrieveFieldProductionData = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.fieldProductionDataRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving production analysis results.');
  }
};

export const retrieveFieldInjectionData = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.fieldInjectionDataRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving injection analysis results.');
  }
};

export const retrieveCustomWellAnalysisData = async (
  datasetId,
  params = {}
) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.customWellAnalysisRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });

    const data = response?.data;
    convertToISOFormat(data[INJECTOR]);
    convertToISOFormat(data[PRODUCER]);

    return [...data[INJECTOR], ...data[PRODUCER]];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving custom well analysis results.');
  }
};

export const retrieveFieldResAnalysisData = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.fieldReservoirAnalysisRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response?.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field reservoir analysis results.');
  }
};

export const retrieveFieldWellCount = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.fieldFluidResRate(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response?.data;

    convertToISOFormat(data[INJECTOR]);
    convertToISOFormat(data[PRODUCER]);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field fluid reservoir rate results.');
  }
};

export const retrieveFieldVRRAnalysis = async (datasetId, params) => {
  try {
    const response = await apiClient({
      method: 'POST',
      url: visApi.fieldVRRAnalysisRoute(datasetId),
      headers: getJWTToken(),
      data: params,
    });
    const data = response?.data;
    convertToISOFormat(data);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field VRR analysis results.');
  }
};
