import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../../../../Styles/ArgumentPageStyles';

import PropTypes from 'prop-types';

import { addWorkflow } from '../../../../redux/actions/workflows';

import AdvancedParametersModal from '../../../Components/NumericAdvancedParamsModal';

import { getUnitRate } from '../../../../Utils/ReservoirUtils';

import {
  SHUT_IN,
  TOLERANCE,
  CRMP_LAYER,
  SURVEILLANCE,
  MAX_ITERATION,
  SYSTEM_OF_UNITS,
  ANGLE_THRESHOLD,
  TRAIN_TEST_SPLIT,
  BOTTOMHOLE_PRESSURE,
  WATER_CUT_THRESHOLD,
  WELL_RATE_THRESHOLD,
  TOLERANCE_FLUID_MATCHING,
  MAX_ITERATION_FLUID_MATCHING,
  MIN_CONN,
} from '../../../../constants/WellConstants';
import * as workflowParameters from '../../../../constants/WorkflowsParameterConstants';

import {
  Grid,
  Typography,
  Button,
  Paper,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  IconButton,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const CRMP_Layer = ({
  submitWorkflow,
  reduxSelectedDataset,
  reduxSelectedDatasetParams,
  injWells,
  prodWells,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [name, setname] = useState('');
  const [description, setdescription] = useState('');
  const [modalState, setmodalState] = useState(false);
  const [advancedParameters, setadvancedParameters] = useState({
    [SHUT_IN]: true,
    [MIN_CONN]: 1,
    [MAX_ITERATION_FLUID_MATCHING]: 1000,
    [TOLERANCE_FLUID_MATCHING]: 100,
    [TRAIN_TEST_SPLIT]: workflowParameters.DEFAULT_VALUE_TRAIN_TEST_SPLIT,
    [ANGLE_THRESHOLD]: 5,
    [WELL_RATE_THRESHOLD]: 0.9,
    [WATER_CUT_THRESHOLD]: 0.05,
  });
  const [parameters, setparameters] = useState({
    ...workflowParameters.CRMP_LAYER_PARAMS,
    ...reduxSelectedDatasetParams,
    ...advancedParameters,
    // TODO: Uncomment after backend ready to generate results by stages.
    // stage: workflowParameters.CRMP_LAYER_WORKFLOW_STAGES.FIELD_ANALYSIS,
    stage: workflowParameters.CRMP_LAYER_WORKFLOW_STAGES.SHUTOFF_ANALYSIS,
  });

  const [selectedWells, setselectedWells] = useState({
    [workflowParameters.SELECTED_PRODUCERS]: [],
    [workflowParameters.SELECTED_INJECTORS]: [],
    [workflowParameters.EXCLUDED_PRODUCERS]: [],
    [workflowParameters.ZERO_WATER_DRIVE]: [],
  });

  // Callbacks -->
  const applyAdvancedParameters = newParamsPairs => {
    setadvancedParameters(newParamsPairs);
    setparameters(previous => {
      return {
        ...previous,
        ...newParamsPairs,
      };
    });
  };
  // <-- Callbacks

  // Handlers -->
  const onSubmitWorkflow = async () => {
    try {
      await dispatch(
        addWorkflow(reduxSelectedDataset.dataset_id, {
          module_type: SURVEILLANCE,
          workflow_type: CRMP_LAYER,
          name,
          description,
          parameters: {
            ...parameters,
            [workflowParameters.EXCLUDED_PRODUCERS]:
              selectedWells[workflowParameters.EXCLUDED_PRODUCERS],
            [workflowParameters.ZERO_WATER_DRIVE]:
              selectedWells[workflowParameters.ZERO_WATER_DRIVE],
          },
        })
      );
      submitWorkflow(true);
    } catch (error) {
      submitWorkflow(false);
    }
  };

  const onParameterChange = (target, value) => {
    setparameters(previous => {
      return {
        ...previous,
        [target]: value,
      };
    });
  };

  const onSelectWell = (wellListName, wellIndex) => {
    wellIndex = wellIndex.toString();
    let copyList = [...selectedWells[wellListName]];
    if (selectedWells[wellListName].includes(wellIndex)) {
      copyList.splice(selectedWells[wellListName].indexOf(wellIndex), 1);
    } else {
      copyList.push(wellIndex);
    }

    setselectedWells(previous => {
      return {
        ...previous,
        [wellListName]: copyList,
      };
    });
  };

  const onPairWells = () => {
    const wells = [];
    selectedWells[workflowParameters.SELECTED_PRODUCERS].forEach(
      prodWellIndex => {
        selectedWells[workflowParameters.SELECTED_INJECTORS].forEach(
          injWellIndex => {
            wells.push(`${prodWellIndex} ${injWellIndex}`);
          }
        );
      }
    );

    onParameterChange(workflowParameters.WELL_PAIRS, wells);
  };

  const onRemoveWellPair = pair => {
    let tempWells = [...parameters[workflowParameters.WELL_PAIRS]];
    tempWells = tempWells.filter(
      wellPair => JSON.stringify(wellPair) != JSON.stringify(pair)
    );
    onParameterChange(workflowParameters.WELL_PAIRS, tempWells);
  };

  const toggle = () => {
    setmodalState(previous => !previous);
  };
  // <-- Handlers

  return (
    <Grid item className={styles.rootContainer}>
      <Grid item className={styles.argumentsContainer}>
        <Typography className={styles.header}>Workflow Details</Typography>
        <Grid item className={styles.argumentRow}>
          <Typography>Name</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '50',
            }}
            onChange={event => {
              setname(event.target.value);
            }}
          />
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Description</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '100',
            }}
            onChange={event => {
              setdescription(event.target.value);
            }}
          />
        </Grid>
        <br />
        <Typography className={styles.header}>Input Parameter</Typography>
        <Grid item className={styles.argumentContainer}>
          <Grid item className={styles.argumentRow}>
            <Typography style={{ margin: '0vh 1vw' }}>
              Choose the wells with zero connectivity
            </Typography>
            <Button
              variant="outlined"
              className={styles.button}
              onClick={onPairWells}
            >
              Pair Wells
            </Button>
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Paper elevation={1} className={`${styles.paperContainer} card`}>
              <List dense={true} style={{ backgroundColor: 'white' }}>
                <ListSubheader>Select Producer</ListSubheader>
                {prodWells.map(well => (
                  <ListItem key={well + '-ListItem'}>
                    <ListItemText>{well}</ListItemText>
                    <Checkbox
                      onChange={() => {
                        onSelectWell(
                          workflowParameters.SELECTED_PRODUCERS,
                          prodWells.indexOf(well)
                        );
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Paper elevation={1} className={`${styles.paperContainer} card`}>
              <List dense={true} style={{ backgroundColor: 'white' }}>
                <ListSubheader>Select Injector</ListSubheader>
                {injWells.map(well => (
                  <ListItem key={well + '-ListItem'}>
                    <ListItemText>{well}</ListItemText>
                    <Checkbox
                      onChange={() => {
                        onSelectWell(
                          workflowParameters.SELECTED_INJECTORS,
                          injWells.indexOf(well)
                        );
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
            <Paper elevation={1} className={`${styles.paperContainer} card`}>
              <List dense={true} style={{ backgroundColor: 'white' }}>
                <ListSubheader>Exclude Producer</ListSubheader>
                {prodWells.map(well => (
                  <ListItem key={well + '-ListItem'}>
                    <ListItemText>{well}</ListItemText>
                    <Checkbox
                      onChange={() => {
                        onSelectWell(
                          workflowParameters.EXCLUDED_PRODUCERS,
                          prodWells.indexOf(well)
                        );
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid item className={styles.argumentContainer}>
          <Collapse in={parameters[workflowParameters.WELL_PAIRS].length > 0}>
            <Grid item className={styles.argumentRow}>
              <Paper
                elevation={1}
                className={`${styles.paperContainer}  small`}
              >
                <Grid container>
                  {parameters[workflowParameters.WELL_PAIRS].map(
                    wellIndexPair => (
                      <Paper
                        key={wellIndexPair}
                        style={{
                          margin: '0.35em',
                          width: '15%',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                        elevation={3}
                      >
                        {prodWells[wellIndexPair.split(' ')[0]] +
                          ' - ' +
                          injWells[wellIndexPair.split(' ')[1]]}
                        <IconButton
                          onClick={() => {
                            onRemoveWellPair(wellIndexPair);
                          }}
                        >
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                      </Paper>
                    )
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item className={styles.argumentContainer}>
          <Grid item className={styles.argumentRow}>
            <Typography>
              {`${workflowParameters.RADIUS_OF_INVESTIGATION} (${getUnitRate(
                reduxSelectedDatasetParams[SYSTEM_OF_UNITS],
                workflowParameters.RADIUS_OF_INVESTIGATION
              )})`}
            </Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[workflowParameters.RADIUS_OF_INVESTIGATION]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(
                  workflowParameters.RADIUS_OF_INVESTIGATION,
                  parseFloat(event.target.value)
                );
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Maximum Iterations</Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[MAX_ITERATION]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(
                  MAX_ITERATION,
                  parseFloat(event.target.value)
                );
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  // max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Tolerance (1e-x)</Typography>
            <TextField
              type="Number"
              variant="outlined"
              size="small"
              value={parameters[TOLERANCE]}
              className={`${styles.numericInput} smallInput`}
              onChange={event => {
                onParameterChange(TOLERANCE, parseFloat(event.target.value));
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                  // max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item className={styles.argumentRow}>
            <Typography>Include Pseudo Injectors well</Typography>
            <Checkbox
              onClick={event => {
                onParameterChange(
                  workflowParameters.PSEUDO,
                  event.target.checked
                );
              }}
            />
          </Grid>
          {false && ( // Temporarily disabled for pdo deployment
            <>
              <Grid item className={styles.argumentRow}>
                <Typography>Include bottomhole pressure</Typography>
                <Checkbox
                  onClick={event => {
                    onParameterChange(
                      BOTTOMHOLE_PRESSURE,
                      event.target.checked
                    );
                  }}
                />
              </Grid>
              <Grid item className={styles.argumentRow}>
                <Typography>Include water drive</Typography>
                <Checkbox
                  onClick={event => {
                    onParameterChange(
                      workflowParameters.WATER_DRIVE,
                      event.target.checked
                    );
                  }}
                />
              </Grid>
            </>
          )}
          <Collapse in={parameters[workflowParameters.WATER_DRIVE]}>
            <Grid item className={styles.argumentRow}>
              {parameters[workflowParameters.WATER_DRIVE] ? (
                <Paper
                  elevation={1}
                  className={`${styles.paperContainer} card`}
                >
                  <List dense={true} style={{ backgroundColor: 'white' }}>
                    <ListSubheader>Select Zero Water Drive Wells</ListSubheader>
                    {prodWells.map(well => (
                      <ListItem key={well + '-ListItem'}>
                        <ListItemText>{well}</ListItemText>
                        <Checkbox
                          onChange={() => {
                            onSelectWell(
                              workflowParameters.ZERO_WATER_DRIVE,
                              prodWells.indexOf(well)
                            );
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              ) : (
                ''
              )}
            </Grid>
          </Collapse>
          <Grid item className={styles.argumentRow} direction="row-reverse">
            <Tooltip title="Advanced Settings">
              <IconButton onClick={toggle}>
                <SettingsRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <AdvancedParametersModal
            modalState={modalState}
            toggle={toggle}
            applyAdvancedParameters={applyAdvancedParameters}
            advancedParameterPairs={advancedParameters}
          />
        </Grid>
      </Grid>
      <Grid item className={styles.runContainer}>
        <Grid item className={styles.buttonContainer}>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.runButton} ${styles.primaryButton}`}
            onClick={onSubmitWorkflow}
          >
            Run
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

CRMP_Layer.propTypes = {
  submitWorkflow: PropTypes.func,
  reduxSelectedDataset: PropTypes.object,
  reduxSelectedDatasetParams: PropTypes.object,
  injWells: PropTypes.array,
  prodWells: PropTypes.array,
};

export default CRMP_Layer;
