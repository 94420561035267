import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import InjectorSummaryTable from './InjectorSummary';
import ProducerSummary from './ProducerSummary';
import LoadingPage from '../../../../Components/LoadingPage';

import { Grid, Slider, Typography } from '@material-ui/core';
import { generateRecoveryMap } from '../../../../../Utils/WorkflowReportUtils/PolyInjection';
import {
  R2_WATER,
  SYSTEM_OF_UNITS,
} from '../../../../../constants/WellConstants';
import FieldSummary from './FieldSummary';

const Results = ({
  filteredDatasetContent,
  prodRecovery,
  injPotential,
  gainValues,
  fieldSum,
  polyRecovery,
  prodNames,
  injNames,
  workflowParameters,
  headers,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [minR2Value, setminR2Value] = useState(null);
  const [maxR2Value, setmaxR2Value] = useState(null);
  const [R2SliderValue, setR2SliderValue] = useState(null);

  const [plot, setplot] = useState(null);

  // Handlers -->
  const R2WaterSliderHandler = value => {
    setR2SliderValue(value);

    setplot(
      generateRecoveryMap(
        filteredDatasetContent,
        prodRecovery,
        injPotential,
        gainValues,
        prodNames,
        injNames,
        value,
        undefined,
        workflowParameters[SYSTEM_OF_UNITS]
      )
    );
    // <-- Handlers
  };
  useEffect(() => {
    // TODO: Any dataset process goes here.
    if (
      filteredDatasetContent &&
      prodRecovery &&
      injPotential &&
      gainValues &&
      prodNames &&
      injNames &&
      workflowParameters
    ) {
      // const min =
      //   Math.round(
      //     Math.min(...prodRecovery.map(row => Number(row[R2_WATER]))) * 100
      //   ) / 100;
      const max =
        Math.round(
          Math.max(...prodRecovery.map(row => Number(row[R2_WATER]))) * 100
        ) / 100;

      setminR2Value(0);
      setmaxR2Value(max);
      setR2SliderValue([0, max]);

      R2WaterSliderHandler([0, max]);
    }
  }, [
    filteredDatasetContent,
    prodRecovery,
    injPotential,
    gainValues,
    prodNames,
    injNames,
    workflowParameters,
  ]);

  return (
    <React.Fragment>
      <Grid
        container
        className={styles.visualContent}
        id={headers.ADDITIONAL_RECOVERY_MAP}
      >
        <Grid item style={{ margin: '0% 5%' }}>
          <Typography>{headers.ADDITIONAL_RECOVERY_MAP}</Typography>
        </Grid>
        <Grid item className={styles.visualContentRow}>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Typography gutterBottom>R2 Water Filter</Typography>
            <Slider
              min={minR2Value}
              step={0.01}
              max={maxR2Value}
              value={R2SliderValue}
              onChange={(event, value) => R2WaterSliderHandler(value)}
              valueLabelDisplay="auto"
              style={{ width: '80%' }}
            />
          </Grid>
        </Grid>
        {plot ? (
          <PlotlyComponent
            data={plot.data}
            layout={plot.layout}
            config={plot.config}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <LoadingPage
            message="Additional Recovery Plot is Loading..."
            goHome={false}
          />
        )}
      </Grid>
      <Grid container className={styles.visualContent}>
        <FieldSummary fieldSum={fieldSum} headers={headers} />
        <ProducerSummary polyRecovery={polyRecovery} headers={headers} />
        <InjectorSummaryTable injPotential={injPotential} headers={headers} />
      </Grid>
    </React.Fragment>
  );
};

Results.propTypes = {
  filteredDatasetContent: PropTypes.array,
  prodRecovery: PropTypes.array,
  injPotential: PropTypes.array,
  gainValues: PropTypes.array,
  fieldSum: PropTypes.array,
  polyRecovery: PropTypes.array,
  prodNames: PropTypes.array,
  injNames: PropTypes.array,
  workflowParameters: PropTypes.object,
  headers: PropTypes.object,
};

export default Results;
