import { getJWTToken } from '../../../redux/actions/base';
import * as crmpApiRoutes from '../../Routes/workflowResults';
import { apiClient } from '../../../service/apiClient';

export async function retrieveFieldGainAnalysisResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.fieldGainAnalysisResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveDynamicGainValuesResult(workflowId, params = {}) {
  try {
    const response = await apiClient({
      method: 'POST',
      url: crmpApiRoutes.dynamicGainValuesResultRoute(workflowId),
      headers: getJWTToken(),
      data: params,
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveStaticGainValuesResult(workflowId, params = {}) {
  try {
    const response = await apiClient({
      method: 'POST',
      url: crmpApiRoutes.staticGainValuesResultRoute(workflowId),
      headers: getJWTToken(),
      data: params,
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveCumulativeFieldRatesResult(
  workflowId,
  params = {}
) {
  try {
    const response = await apiClient({
      method: 'POST',
      url: crmpApiRoutes.cumulativeFieldRatesResultRoute(workflowId),
      headers: getJWTToken(),
      data: params,
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveFluidProdRateResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.fluidProdRateResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveR2RMSETotalFluidResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.r2RMSETotalFluidResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveOilProdRateResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.oilProdRateResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveR2RMSEOilMatchingResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.r2RMSEOilMatchingResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveInjShutoffFluidResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.injShutoffFluidResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveInjShutoffOilResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.injShutoffOilResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveInjFactorsResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.injFactorsResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveOilContributionResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.oilContributionResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveOilContributionSummaryResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.oilContributionSummaryResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveProductionDataResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.productionDataResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveFutureProductionDataResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.futureProductionDataResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveFutureInjectionSensitivityResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.futureInjectionSensitivityResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}

export async function retrieveInjectionRatesResult(workflowId) {
  try {
    const response = await apiClient({
      method: 'GET',
      url: crmpApiRoutes.injectionRatesResultRoute(workflowId),
      headers: getJWTToken(),
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('API call error.', error);
    throw Error('Error while retrieving field well names.');
  }
}
