import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      fontWeight: '600',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',

    '& .body': {
      flex: '5 1 auto',
    },
    '& .paper': {
      padding: '1vh',

      maxHeight: '45vh',
      flex: '1 1 auto',
      overflow: 'auto',
    },
    '& .action': {
      flex: '1 1 auto',

      '& .button': {
        margin: '2vh 2vh 0 0vh',
      },
    },
    '& .inputRow': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.5vh 0',

      '& .dateInput': {
        '& > *': {
          margin: '1vh 0',
        },
      },

      '& .boxInput': {
        width: '16vh',
      },
    },
  },
});

export default useStyles;
