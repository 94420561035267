import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Results from './Results';
// import useStyles from '../../../../../Styles/WorkflowStyles';

import { updateWorkflow } from '../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../redux/actions/feedback';

// import { Grid, Button } from '@material-ui/core';

import { CRMP_LAYER_WORKFLOW_STAGES } from '../../../../../constants/WorkflowsParameterConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../constants/ComponentConstants';

const FieldResultAnalysis = ({
  reduxSelectedWorkflow,
  workflowParameters,
  filteredDatasetContent,
  oilRateHistory,
  liquidRateHistory,
  header,
}) => {
  // const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const runFieldAnalysis = async () => {
    const updatedWorkflowPayload = {
      parameters: {
        ...workflowParameters,
        stage: CRMP_LAYER_WORKFLOW_STAGES.FIELD_ANALYSIS,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Field analysis request is sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Oil Matching didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };

  runFieldAnalysis;

  return (
    <React.Fragment>
      {workflowParameters.stage == CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING &&
        // TODO: Uncomment Later
        // (
        // <Grid container className={styles.visualContentFlex}>
        //   <Grid item justifyContent="center" className={styles.inputContentRow}>
        //     <Button variant="outlined" onClick={runFieldAnalysis}>
        //       Run Field Result Analysis
        //     </Button>
        //   </Grid>
        // </Grid>
        // )
        ''}
      {workflowParameters.stage != CRMP_LAYER_WORKFLOW_STAGES.FLUID_MATCHING &&
        workflowParameters.stage != CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING && (
          <Results
            filteredDatasetContent={filteredDatasetContent}
            oilRateHistory={oilRateHistory}
            liquidRateHistory={liquidRateHistory}
            header={header}
          />
        )}
    </React.Fragment>
  );
};

FieldResultAnalysis.propTypes = {
  reduxSelectedWorkflow: PropTypes.object,
  workflowParameters: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  liquidRateHistory: PropTypes.array,
  oilRateHistory: PropTypes.array,
  header: PropTypes.string,
};

export default FieldResultAnalysis;
