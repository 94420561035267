import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
  },
  navbarGrid: {
    width: '100%',
    height: '10%',
    display: 'flex',
  },
  bodyGrid: {
    height: '85%',
    width: '100%',
  },
  sidebarGrid: {
    maxWidth: '20%',
    maxHeight: '100%',
    flex: '1 0 auto',
    display: 'flex',
    overflow: 'auto',
  },
  uploadGrid: {
    display: 'flex',
    maxWidth: '80%',
    flex: '4 0 auto',
    overflow: 'auto',
  },
  paper: {
    margin: '1em',
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflow: 'auto',
  },
  sidebarPaper: {
    width: '95%',
    height: '95%',
    margin: '1em 0em',
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundColor: '#003333',
    color: '#c1c5d1',
  },
  sidebarListSubHeader: {
    color: '#c1c5d1',
  },
  datasetControlGrid: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5em',
  },
  datasetUploadGrid: {
    height: '60%',
    display: 'flex',
    flexDirection: 'column',
  },
  datasetSelectGrid: {
    height: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  dropZone: {
    margin: '0.5em',
    '& p': {
      fontSize: '1em',
      margin: '0em',
    },
  },
  fileButton: {
    backgroundColor: '#000B46',
    color: 'white',
    marginRight: '1em',
    borderRadius: '2em',
    '&:hover': {
      backgroundColor: '#000B46',
    },
  },
  title: {
    margin: '0.5em',
    fontWeight: '600',
  },
  formControl: {
    margin: '0.5em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  continueBt: {
    paddingLeft: '5em',
    paddingRight: '5em',
    backgroundColor: '#000B46',
    color: 'white',
    marginRight: '1em',
    borderRadius: '2em',
    '&:hover': {
      backgroundColor: '#000B46',
    },
  },
  textArgument: {
    overflow: 'hidden',
    '& .left': { width: '50%', textAlign: 'left' },
    '& .right': { width: '50%', textAlign: 'right' },
    '& p': {
      fontSize: '0.75em',
    },
  },
});

export default useStyles;
