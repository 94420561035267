import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';

const INIT_PLOT_STATE = {
  data: [
    {
      y: new Array(8).fill(0).map(() => Math.random() * 10 + 5), // Can be modifed to change bar count and length
      type: 'bar',
      marker: { color: '#CAD2C5' },
    },
  ],
  layout: {
    xaxis: {
      autorange: true,
      showgrid: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
    },
    yaxis: {
      autorange: true,
      showgrid: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
    },
    annotations: [
      {
        text: 'Loading...', // Can be modified for custom message.
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
          size: 28,
          color: '#354F52',
        },
      },
    ],
    autosize: true,
    hovermode: false,
  },
  config: { responsive: true },
};

const LoadingPlot = () => {
  useEffect(() => {
    const plotDiv = document.getElementById('loading-plot');
    Plotly.newPlot(plotDiv, INIT_PLOT_STATE);

    const interval = setInterval(() => {
      Plotly.animate(
        plotDiv,
        {
          data: [
            {
              y: new Array(8).fill(0).map(() => Math.random() * 10 + 5),
            },
          ],
        },
        {
          transition: {
            duration: 500,
            easing: 'cubic-in-out',
          },
          frame: {
            duration: 500,
          },
        }
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id="loading-plot"
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

LoadingPlot.propTypes = {
  loadingText: PropTypes.string,
};

export default LoadingPlot;
