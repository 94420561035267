import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { getProdAnalysisPrediction } from '../../../../redux/actions/workflows';

import { generateEnhancedProducerAnalysisGraph } from '../../../../Utils/CommonReportUtil';

import { filterAvailaleCumulativeColNamesFromDataset } from '../../../../Utils/DatasetUtils/DataProcessing';

import { BasicTable } from '../../../Components/TableView';

import {
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
  FormGroup,
} from '@material-ui/core';

import {
  DATE,
  PER_DAY,
  SYSTEM_OF_UNITS,
  WELL_ID,
} from '../../../../constants/WellConstants';
import { ML_CONN_WORKFLOW_STAGES } from '../../../../constants/WorkflowsParameterConstants';
import { retrieveAdvancedAnalysisResult } from '../../../../API/Functions/MLConnectivity';

import { updateSnackBar } from '../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';
import LoadingPlot from '../../../Components/LoadingPlot';

const selectedWorkflowSubscriber = state => state.workflow.selectedWorkflow;

const ProducerPerformanceAnalyzer = ({
  header,
  selectedProducer,
  workflowParameters,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const PlotlyComponent = createPlotlyComponent(Plotly);
  const reduxSelectedWorkflow = useSelector(selectedWorkflowSubscriber);

  const [advancedAnalysisContent, setAdvancedAnalysisContent] = useState(null);
  const [wellFilteredDataset, setwellFilteredDataset] = useState(null);
  const [plot, setplot] = useState(null);
  const [tableData, settableData] = useState(null);
  const [xAxisFeature, setxAxisFeature] = useState(null);
  const [availablexAxisFeatures, setavailablexAxisFeatures] = useState([]);
  const [yAxisFeature, setyAxisFeature] = useState(null);
  const [availableyAxisFeatures, setavailableyAxisFeatures] = useState([]);
  const [xAxisLog, setxAxisLog] = useState(false);
  const [yAxisLog, setyAxisLog] = useState(false);
  const [chokeSizeCorrection, setChokeSizeCorrection] = useState(false);
  const [selectedPoints, setselectedPoints] = useState([]);
  const [diagnosticResultsFinal, setDiagnosticResultsFinal] = useState(null);
  const [xAxisSlicedData, setxAxisSlicedData] = useState(null);
  const [yAxisSlicedData, setyAxisSlicedData] = useState(null);
  const calculateButton = useRef(null);

  // Callbacks -->
  const generateDianositicPlot = (
    wellFilteredDataset,
    xAxisSelection,
    yAxisSelection,
    isXAxisLog,
    isYAxisLog,
    isChokeSizeCorrection,
    xAxisSlicedData,
    yAxisSlicedData
  ) => {
    setplot(
      generateEnhancedProducerAnalysisGraph(
        wellFilteredDataset,
        xAxisSelection,
        yAxisSelection,
        isXAxisLog,
        isYAxisLog,
        `${xAxisSelection} - ${yAxisSelection} (${selectedProducer})`,
        isChokeSizeCorrection,
        xAxisSlicedData,
        yAxisSlicedData,
        workflowParameters[SYSTEM_OF_UNITS],
        PER_DAY
      )
    );
  };

  const makeAPICall = async () => {
    const updatedWorkflowPayload = {
      parameters: {
        [WELL_ID]: wellFilteredDataset[0][WELL_ID],
        x_column: xAxisFeature,
        y_column: yAxisFeature,
        xscale: xAxisLog ? 'lg' : '',
        yscale: yAxisLog ? 'lg' : '',
        start_index: selectedPoints[0],
        end_index: selectedPoints[1],
        stage: ML_CONN_WORKFLOW_STAGES.DIAGNOSTIC_PLOT,
      },
    };
    calculateButton.current.disabled = true;
    let response = await dispatch(
      getProdAnalysisPrediction(
        reduxSelectedWorkflow.workflow_id,
        updatedWorkflowPayload
      )
    );
    if (response.data) {
      if ('EUR' in response.data) {
        setDiagnosticResultsFinal({
          'Fitting score': response.data['Fitting score'],
          'Model coef': response.data['Model coef'],
          'Model intercept': response.data['Model intercept'],
          EUR: response.data['EUR'],
        });
      } else {
        setDiagnosticResultsFinal({
          'Fitting score': response.data['Fitting score'],
          'Model coef': response.data['Model coef'],
          'Model intercept': response.data['Model intercept'],
        });
      }
      setxAxisSlicedData(response.data['x_slice']);
      setyAxisSlicedData(response.data['y_pred']);
    }
  };
  // <-- Callbacks

  // Handlers -->
  const onxAxisFeatureSelect = feature => {
    setxAxisFeature(feature);
    generateDianositicPlot(
      wellFilteredDataset,
      feature,
      yAxisFeature,
      xAxisLog,
      yAxisLog,
      chokeSizeCorrection,
      null,
      null
    );
  };

  const onyAxisFeatureSelect = feature => {
    setyAxisFeature(feature);
    generateDianositicPlot(
      wellFilteredDataset,
      xAxisFeature,
      feature,
      xAxisLog,
      yAxisLog,
      chokeSizeCorrection,
      null,
      null
    );
  };

  const onLogSelect = event => {
    switch (event.target.value) {
      case 'X':
        generateDianositicPlot(
          wellFilteredDataset,
          xAxisFeature,
          yAxisFeature,
          !xAxisLog,
          yAxisLog,
          chokeSizeCorrection,
          xAxisSlicedData,
          yAxisSlicedData
        );
        setxAxisLog(prev => !prev);
        break;
      case 'Y':
        generateDianositicPlot(
          wellFilteredDataset,
          xAxisFeature,
          yAxisFeature,
          xAxisLog,
          !yAxisLog,
          chokeSizeCorrection,
          xAxisSlicedData,
          yAxisSlicedData
        );
        setyAxisLog(prev => !prev);
        break;
      case 'CHOKE':
        generateDianositicPlot(
          wellFilteredDataset,
          xAxisFeature,
          yAxisFeature,
          xAxisLog,
          yAxisLog,
          !chokeSizeCorrection,
          null,
          null
        );
        setChokeSizeCorrection(!chokeSizeCorrection);
        break;
      default:
        break;
    }
  };

  const onPlotSlice = event => {
    const slicedPoints = event ? event.points : [];
    if (slicedPoints.length > 0) {
      let startIndex = slicedPoints.at(0)['pointNumber'];
      let endIndex = slicedPoints.at(-1)['pointNumber'];
      setselectedPoints([startIndex, endIndex]);
    } else {
      setselectedPoints(slicedPoints);
    }
  };

  const onRowClick = event => {
    // If multi slicing will be implemented, this function will be used to exctract selected row data.
    event.row;
  };
  // <-- Handlers

  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const advancedAnalysis = await retrieveAdvancedAnalysisResult(
          reduxSelectedWorkflow['workflow_id']
        );
        if (mounted) {
          setAdvancedAnalysisContent(advancedAnalysis);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, []);

  // Filter advanced analysis content by selected well names.
  useEffect(() => {
    if (advancedAnalysisContent && selectedProducer) {
      setwellFilteredDataset(
        advancedAnalysisContent.filter(row => row[WELL_ID] == selectedProducer)
      );
      setxAxisSlicedData(null);
      setyAxisSlicedData(null);
    }
  }, [advancedAnalysisContent, selectedProducer]);

  useEffect(() => {
    if (yAxisSlicedData != null) {
      generateDianositicPlot(
        wellFilteredDataset,
        xAxisFeature,
        yAxisFeature,
        xAxisLog,
        yAxisLog,
        chokeSizeCorrection,
        xAxisSlicedData,
        yAxisSlicedData
      );
    }
  }, [yAxisSlicedData]);

  useEffect(() => {
    if (wellFilteredDataset) {
      const { X, Y } = filterAvailaleCumulativeColNamesFromDataset();
      setavailablexAxisFeatures(X);
      setavailableyAxisFeatures(Y);
      setxAxisFeature(X[0]);
      setyAxisFeature(Y[0]);
      generateDianositicPlot(
        wellFilteredDataset,
        X[0],
        Y[0],
        xAxisLog,
        yAxisLog,
        chokeSizeCorrection,
        xAxisSlicedData,
        yAxisSlicedData
      );
    }
  }, [wellFilteredDataset]);

  useEffect(() => {
    // TODO:
    //  diagnostic results dataset is assumed to have only columnar data.
    //  key value pair that is going to be used to trace the line-trace
    //  needs to be extracted before hand.
    if (diagnosticResultsFinal) settableData([diagnosticResultsFinal]);
  }, [diagnosticResultsFinal]);

  return (
    <React.Fragment>
      <Grid id={header} container className={styles.visualContent}>
        <Grid item className={styles.visualContentRow}>
          <Typography>{header}</Typography>
        </Grid>
        <Grid item className={styles.inputContentRow + ' multipleContent'}>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel shrink={true}>X Axis Feature</InputLabel>
            <Select
              value={xAxisFeature}
              onChange={event => onxAxisFeatureSelect(event.target.value)}
              label="X Axis Feature"
            >
              {availablexAxisFeatures.map(feature => (
                <MenuItem key={'menu-item-' + feature} value={feature}>
                  {feature}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="expandInputElement"
          >
            <InputLabel shrink={true}>Y Axis Feature</InputLabel>
            <Select
              value={yAxisFeature}
              onChange={event => onyAxisFeatureSelect(event.target.value)}
              label="Y Axis Feature"
            >
              {availableyAxisFeatures.map(feature => (
                <MenuItem key={'menu-item-' + feature} value={feature}>
                  {feature}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={styles.inputContentRow + ' multipleContent'}>
          <FormControl row component="fieldset" onChange={onLogSelect}>
            <FormGroup row>
              <FormControlLabel
                value="X"
                control={<Checkbox color="secondary" />}
                label="Log X Axis"
                labelPlacement="start"
                disabled={xAxisFeature == DATE ? true : false}
              />
              <FormControlLabel
                value="Y"
                control={<Checkbox color="secondary" />}
                label="Log Y Axis"
                labelPlacement="start"
              />
              <FormControlLabel
                value="CHOKE"
                control={<Checkbox color="secondary" />}
                label="Choke Size Correction"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          {selectedPoints.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              ref={calculateButton}
              onClick={makeAPICall}
              disabled={false}
            >
              Calculate Point Results
            </Button>
          )}
        </Grid>
        {plot ? (
          <PlotlyComponent
            data={plot.data}
            layout={plot.layout}
            config={plot.config}
            style={{ width: '100%', height: '100%' }}
            onSelected={onPlotSlice}
          />
        ) : (
          <LoadingPlot />
        )}
      </Grid>
      <Grid id={header} container className={styles.visualContentFlex}>
        {tableData && (
          <Paper elevation={5} className={styles.visualContentRow}>
            <BasicTable data={tableData} props={{ onRowClick }} />
          </Paper>
        )}
      </Grid>
    </React.Fragment>
  );
};

ProducerPerformanceAnalyzer.propTypes = {
  header: PropTypes.string,
  selectedProducer: PropTypes.string,
  workflowParameters: PropTypes.object,
};

export default ProducerPerformanceAnalyzer;
