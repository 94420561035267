import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative', // Allows positioning inside the parent
    backgroundColor: '#2e4258', // Set background color for the top-left corner
    width: '25%', // Stretch to fit the content horizontally
    height: '120%', // Stretch to fit the content vertically
    padding: 0, // Remove any padding
    margin: 0, // Remove any margin
    [theme.breakpoints.down('md')]: {
      width: '20%', // Stretch to fit the content horizontally
    },
  },
  logoGrid: {
    display: 'flex',
    alignItems: 'center', // Align items vertically in the center
    justifyContent: 'flex-start', // Align items to the left
    flexDirection: 'row', // Arrange logo and name horizontally
    width: 'auto', // Allow width to adjust based on content
    height: 'auto', // Allow height to adjust based on content
    color: '#ffffff', // Fix the typo for white color
    padding: 0, // Remove padding around the grid
    margin: 0, // Remove any margin
  },
  logo: {
    cursor: 'pointer',
    width: '70px', // Set a fixed width for the logo
    height: 'auto', // Maintain the aspect ratio of the logo
    marginRight: '20px', // Space between the logo and the name
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '3e5876',
  },
  paperContainer: {
    display: 'flex', // Use flex to align children (logo and name)
    alignItems: 'center', // Align items vertically in the center
    justifyContent: 'center', // Align items to the left
    padding: '10px', // Add some padding inside the Paper
    backgroundColor: '#003333', // Background color for the container
    width: '100%', // Stretch across the container
    height: '100%', // Stretch across the container
    boxSizing: 'border-box', // Include padding in width and height calculations
  },
  name: {
    fontSize: '1.75em',
    color: '#c1c5d1',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3em', // Stretch to fit the content horizontally
    },
  },
  link: {
    display: 'inline-block', // Ensures the text link is inline but block-like
    cursor: 'pointer', // Add cursor pointer for name as well
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#ffffff', // Change this to the desired hover color
    },
  },
}));

export default useStyles;
