// CRMP Routes -->
export const fieldGainAnalysisResultRoute = workflowId => {
  return `/workflow_results/crmp/field_gain_analysis/${workflowId}`;
};

export const dynamicGainValuesResultRoute = workflowId => {
  return `/workflow_results/crmp/dynamic_gain_values/${workflowId}`;
};

export const staticGainValuesResultRoute = workflowId => {
  return `/workflow_results/crmp/static_gain_values/${workflowId}`;
};

export const cumulativeFieldRatesResultRoute = workflowId => {
  return `/workflow_results/crmp/cumulative_field_rates/${workflowId}`;
};

export const fluidProdRateResultRoute = workflowId => {
  return `/workflow_results/crmp/fluid_prod_rate/${workflowId}`;
};

export const r2RMSETotalFluidResultRoute = workflowId => {
  return `/workflow_results/crmp/r2_rmse_total_fluid/${workflowId}`;
};

export const oilProdRateResultRoute = workflowId => {
  return `/workflow_results/crmp/oil_prod_rate/${workflowId}`;
};

export const r2RMSEOilMatchingResultRoute = workflowId => {
  return `/workflow_results/crmp/r2_rmse_oil_matching/${workflowId}`;
};

export const injShutoffFluidResultRoute = workflowId => {
  return `/workflow_results/crmp/injector_shut_off_fluid/${workflowId}`;
};

export const injShutoffOilResultRoute = workflowId => {
  return `/workflow_results/crmp/injector_shut_off_oil/${workflowId}`;
};

export const injFactorsResultRoute = workflowId => {
  return `/workflow_results/crmp/injector_factors/${workflowId}`;
};

export const oilContributionResultRoute = workflowId => {
  return `/workflow_results/crmp/oil_contribution/${workflowId}`;
};

export const oilContributionSummaryResultRoute = workflowId => {
  return `/workflow_results/crmp/oil_contribution_summary/${workflowId}`;
};

export const productionDataResultRoute = workflowId => {
  return `/workflow_results/crmp/production_data/${workflowId}`;
};

export const futureProductionDataResultRoute = workflowId => {
  return `/workflow_results/crmp/future_production_data/${workflowId}`;
};

export const futureInjectionSensitivityResultRoute = workflowId => {
  return `/workflow_results/crmp/future_inj_sensitivity/${workflowId}`;
};

export const injectionRatesResultRoute = workflowId => {
  return `/workflow_results/crmp/injection_rates/${workflowId}`;
};
// <-- CRMP Routes

// ML Connectivity Routes -->
export const mlConnFilteredDatasetRoute = workflowId => {
  return `/workflow_results/ml_conn/filtered_dataset/${workflowId}`;
};

export const advancedAnalysisResultRoute = workflowId => {
  return `/workflow_results/ml_conn/advanced_analysis/${workflowId}`;
};

export const calculationScoresResultRoute = workflowId => {
  return `/workflow_results/ml_conn/calculation_scores/${workflowId}`;
};

export const mlConnWorkflowResultRoute = workflowId => {
  return `/workflow_results/ml_conn/workflow_result/${workflowId}`;
};

export const mlConnDiagnosticResultsRoute = workflowId => {
  return `/workflow_results/ml_conn/diagnostic_results/${workflowId}`;
};
// <-- ML Connectivity Routes
