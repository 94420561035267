import React, { useMemo } from 'react';

import useStyles from '../../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { genWellConnAnalysisPlot } from '../../../../../../Utils/CommonReportUtil';

import LoadingPlot from '../../../../../Components/LoadingPlot';
import CustomSlider from '../../../../../Components/WorkflowAnalysisTools/CustomSlider';

import { Grid, Typography } from '@material-ui/core';

import {
  GAIN,
  SYSTEM_OF_UNITS,
} from '../../../../../../constants/WellConstants';

const GainValueAnalysisComponent = ({
  header,
  wellsGainValues,
  wellsDetails,
  workflowParameters,
  apiCallback,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * This handler updated Correlation slider min and max value, then it invokes an update
   * to the network graph.
   * @param {Event} event
   * @param {Array} newValue
   */
  const gainSliderHandler = newValue => {
    apiCallback({ minGain: newValue[0], maxGain: newValue[1] });
  };
  // <-- Handlers

  // hooks -->
  const cachedPlotResult = useMemo(() => {
    if (wellsDetails && wellsGainValues && workflowParameters) {
      return genWellConnAnalysisPlot({
        wellsLocationDetails: wellsDetails,
        wellsGainValues: wellsGainValues,
        wellConnType: GAIN,
        title: header,
        unitSystem: workflowParameters[SYSTEM_OF_UNITS],
      });
    }
  }, [wellsDetails, wellsGainValues, workflowParameters]);
  // <-- hooks

  return (
    <React.Fragment>
      <Grid item className={styles.visualContentRow}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Gain Value Range</Typography>
          <CustomSlider min={0} max={1} onChangeCommitted={gainSliderHandler} />
        </Grid>
      </Grid>
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ flex: 1 }}
        />
      ) : (
        <LoadingPlot />
      )}
    </React.Fragment>
  );
};

GainValueAnalysisComponent.propTypes = {
  header: PropTypes.string,
  wellsGainValues: PropTypes.array,
  wellsDetails: PropTypes.array,
  workflowParameters: PropTypes.object,
  apiCallback: PropTypes.func,
};

export default GainValueAnalysisComponent;
