import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import csv from 'csvtojson';

import { Button, makeStyles } from '@material-ui/core';
import { updateSnackBar } from '../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../constants/ComponentConstants';

const useStyles = makeStyles({
  fileUploadContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',

    '& .fileName': {
      margin: 'auto 10px',
    },
  },
});

function FileUploadComponent({ saveUploadedDataset }) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (!file) return;
    const fileType = file.type;

    if (fileType !== 'text/csv') {
      dispatch(
        updateSnackBar('File must be a CSV file.', SNACK_BAR_SEVERITY.error)
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = async function (evt) {
      const content = evt.target.result;
      try {
        const jsonArray = await csv().fromString(content);
        if (!('Injector' in jsonArray[0]) || !('Producer' in jsonArray[0])) {
          dispatch(
            updateSnackBar(
              'CSV file must have "Injector" and "Producer" columns.',
              SNACK_BAR_SEVERITY.error
            )
          );
          return;
        }

        const jsonString = JSON.stringify(jsonArray);
        saveUploadedDataset(jsonString);
        setSelectedFile(file);
      } catch (err) {
        alert('Error parsing CSV file.', err);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div className={styles.fileUploadContainer}>
      <input
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="contained-button-file">
        <Button variant="outlined" component="span">
          Choose File
        </Button>
      </label>
      {selectedFile && <p className="fileName">{selectedFile.name}</p>}
    </div>
  );
}

FileUploadComponent.propTypes = {
  saveUploadedDataset: PropTypes.func.isRequired,
};

export default FileUploadComponent;
