import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Link, Paper, Typography } from '@material-ui/core';

import useStyles from '../../Styles/WorkflowCardStyle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import WorkflowModal from './WorkflowDetailsModal';

import {
  WORKFLOW_STATUS_COLORS,
  WORKFLOW_STATUS_ICONS,
} from '../../constants/WorkflowComponentConstants';

const CardView = ({ workflow }) => {
  const styles = useStyles();
  const [card, setcard] = useState();
  const [workflowModalState, setworkflowModalState] = useState(false);

  // Helper Functions -->
  const toggleWorkflowModal = () => {
    setworkflowModalState(prev => !prev);
  };
  // <-- Helper Functions

  useEffect(() => {
    setcard({
      status: workflow['status'],
      color: WORKFLOW_STATUS_COLORS[workflow['status']],
      icon: WORKFLOW_STATUS_ICONS[workflow['status']],
      name: workflow['name'],
      dataset: workflow['file_name'].split('_').slice(1).join('_'),
      workflow_type: workflow['workflow_type'],
      id: workflow['workflow_id'],
    });
  }, [workflow]);

  if (card != undefined) {
    return (
      <Paper elevation={5} className={styles.box}>
        <Grid
          item
          className="iconContainer center"
          style={{ color: card.color }}
        >
          {card.icon}
          <Typography className="status">{card.status}</Typography>
        </Grid>
        <Grid item container className="detailsContainer">
          <Typography noWrap={true} className={'typeName left'}>
            ID
          </Typography>
          <Typography noWrap={true} className={'typeName right'}>
            {card.id}
          </Typography>
          <Typography noWrap={true} className={'typeName left'}>
            Name
          </Typography>
          <Typography noWrap={true} className={'typeName right'}>
            {card.name}
          </Typography>
          <Typography noWrap={true} className={'typeName left'}>
            Dataset
          </Typography>
          <Typography noWrap={true} className={'typeName right'}>
            {card.dataset}
          </Typography>
          <Typography noWrap={true} className={'typeName left'}>
            Workflow
          </Typography>
          <Typography noWrap={true} className={'typeName right'}>
            {card.workflow_type}
          </Typography>
        </Grid>
        <Grid item className="infoContainer center">
          <Link
            component="button"
            className="status"
            onClick={toggleWorkflowModal}
          >
            <MoreHorizIcon />
          </Link>
          {/* {ReactDOM.createPortal( */}
          <WorkflowModal
            workflow={workflow}
            modalState={workflowModalState}
            toggle={toggleWorkflowModal}
          />
          {/* document.getElementById('modal-dialog-root')
          )} */}
        </Grid>
      </Paper>
    );
  } else {
    return '';
  }
};

CardView.propTypes = {
  workflow: PropTypes.object,
};

export default CardView;
