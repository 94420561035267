import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';
import { useEffect } from 'react';

const CustomSlider = ({
  disable = false,
  min = 0,
  max = 1,
  customStyle = {},
  onChangeCommitted,
}) => {
  const [sliderValue, setsliderValue] = useState([min, max]);

  useEffect(() => {
    if (min != undefined && max != undefined) {
      setsliderValue([min, max]);
    }
  }, [min, max]);

  return (
    <Slider
      disable={disable}
      min={min}
      max={max}
      value={sliderValue}
      step={0.01}
      onChange={(event, value) => setsliderValue(value)}
      onChangeCommitted={(event, value) => {
        setsliderValue(value);
        onChangeCommitted(value);
      }}
      valueLabelDisplay="auto"
      style={{ width: '80%', ...customStyle }}
    />
  );
};

CustomSlider.propTypes = {
  disable: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  customStyle: PropTypes.object,
  onChangeCommitted: PropTypes.func,
};

export default CustomSlider;
