import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Parameters from './Parameters';
import Results from './Results';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { Grid, Typography } from '@material-ui/core';

import { CRMP_LAYER_WORKFLOW_STAGES } from '../../../../../constants/WorkflowsParameterConstants';
import { updateWorkflow } from '../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../redux/actions/feedback';
import { SNACK_BAR_SEVERITY } from '../../../../../constants/ComponentConstants';

const Optimization = ({
  reduxSelectedWorkflow,
  workflowParameters,
  filteredDatasetContent,
  oilRateHistory,
  oilMaxWellData,
  IFactorMaxWellData,
  injWellNames,
  prodWellNames,
  header,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const applyOptimization = async optimizationparameters => {
    const updatedWorkflowPayload = {
      parameters: {
        ...workflowParameters,
        ...optimizationparameters,
        stage: CRMP_LAYER_WORKFLOW_STAGES.OPTIMIZATION,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Optimization request sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Optimization didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };

  return workflowParameters.stage ==
    CRMP_LAYER_WORKFLOW_STAGES.FIELD_ANALYSIS ||
    CRMP_LAYER_WORKFLOW_STAGES.SHUTOFF_ANALYSIS ||
    workflowParameters.stage == CRMP_LAYER_WORKFLOW_STAGES.OPTIMIZATION ? (
    <Grid container id={header} className={styles.visualContentFlex}>
      <Grid item className={styles.visualContentRow}>
        <Typography variant="h5">{header}</Typography>
      </Grid>
      <Parameters applyOptimization={applyOptimization} />
      {workflowParameters.stage == CRMP_LAYER_WORKFLOW_STAGES.OPTIMIZATION && (
        <Results
          filteredDatasetContent={filteredDatasetContent}
          oilRateHistory={oilRateHistory}
          oilMaxWellData={oilMaxWellData}
          IFactorMaxWellData={IFactorMaxWellData}
          injWellNames={injWellNames}
          prodWellNames={prodWellNames}
        />
      )}
    </Grid>
  ) : (
    ''
  );
};

Optimization.propTypes = {
  reduxSelectedWorkflow: PropTypes.array,
  workflowParameters: PropTypes.array,
  filteredDatasetContent: PropTypes.array,
  oilMaxWellData: PropTypes.array,
  oilRateHistory: PropTypes.array,
  IFactorMaxWellData: PropTypes.array,
  injWellNames: PropTypes.array,
  prodWellNames: PropTypes.array,
  header: PropTypes.string,
};

export default Optimization;
