import {
  RED,
  XCOL,
  YCOL,
  GREEN,
  WELL_ID,
  R2_WATER,
  LIGHTBLUE,
  INITIAL_OIL_SATURATION,
  ADDITIONAL_RECOVERY_M3,
  YELLOW,
  FIELD,
  PER_MONTH,
} from '../../constants/WellConstants';

import {
  createLinePlotTrace,
  createTrace,
  createTraceWithText,
  wrapPlots,
} from '../../Utils/PlotlyUtils/Plots';
import { getUnitRate } from '../ReservoirUtils';

const normalizeNodeSize = (value, min = 0, max = 1000) => {
  return ((Number(value) - min) / (max - min)) * 25 + 10;
};

const extractWellSatData = (prodOilSaturation, saturationColName) => {
  const min = Math.min(...prodOilSaturation.map(row => row[saturationColName]));
  const max = Math.max(...prodOilSaturation.map(row => row[saturationColName]));
  const ave =
    prodOilSaturation.reduce(
      (acc, curr) => Number(acc) + Number(curr[saturationColName]),
      0
    ) / prodOilSaturation.length;

  return {
    min,
    max,
    ave,
  };
};
/**
 * Generates well location plot where producer wells varries in size, based on oil saturation value,
 * and injector wells are placed just as is, no variation.
 *
 * @param {*} datasetContent
 * @param {*} prodOilSaturation
 * @param {*} prodNames
 * @param {*} injNames
 * @returns
 */
export const generateOilSaturationPlot = (
  datasetContent,
  prodOilSaturation,
  prodNames,
  injNames,
  r2WaterMaskValue = [0.6, 1],
  r2WaterThreshold = 0.6,
  saturationColName = INITIAL_OIL_SATURATION,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const nodeData = [];
  const { min, max, ave } = extractWellSatData(
    prodOilSaturation,
    saturationColName
  );

  prodNames.forEach(wellName => {
    const wellContentData = datasetContent.find(
      row => row[WELL_ID] == wellName
    );
    const saturationData = prodOilSaturation.find(
      row => row['Producers'] == wellName
    );

    if (
      saturationData !== undefined &&
      saturationData[R2_WATER] > r2WaterMaskValue[0] &&
      saturationData[R2_WATER] <= r2WaterMaskValue[1]
    ) {
      nodeData.push({
        'Node Id': `${wellName}<br />${saturationColName}: ${
          Math.round(saturationData[saturationColName] * 1000) / 1000
        }`,
        'Node Text': wellName,
        'Node X': wellContentData[XCOL],
        'Node Y': wellContentData[YCOL],
        'Node Color': saturationData[R2_WATER] > r2WaterThreshold ? GREEN : RED,
        'Node Size': normalizeNodeSize(
          Number(saturationData[saturationColName]),
          min,
          max
        ),
        'Node Symbol': 'circle-up',
        'Node Legend Group':
          saturationData[R2_WATER] > r2WaterThreshold
            ? `R2 Water > ${r2WaterThreshold}`
            : `R2 Water <= ${r2WaterThreshold}`,
        'Node Legend Group Title':
          saturationData[R2_WATER] > r2WaterThreshold
            ? `R2 Water > ${r2WaterThreshold}`
            : `R2 Water <= ${r2WaterThreshold}`,
      });
    }
  });

  injNames.forEach(wellName => {
    const wellContentData = datasetContent.find(
      row => row[WELL_ID] == wellName
    );

    nodeData.push({
      'Node Id': wellName,
      'Node Text': wellName,
      'Node X': wellContentData[XCOL],
      'Node Y': wellContentData[YCOL],
      'Node Color': LIGHTBLUE,
      'Node Size': normalizeNodeSize(ave, min, max),
      'Node Symbol': 'triangle-up',
    });
  });

  nodeData.forEach(node => {
    traces.push(
      createTraceWithText(
        [node['Node X']],
        [node['Node Y']],
        node['Node Text'],
        node['Node Color'],
        node['Node Symbol'],
        node['Node Id'],
        undefined,
        undefined,
        node['Node Text'] ? node['Node Text'] : node['Node Id'],
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        node['Node Legend Group'] ? true : false,
        node['Node Legend Group'],
        node['Node Legend Group Title'],
        node['Node Size']
      )
    );
  });

  return wrapPlots(
    traces,
    `${XCOL} (${getUnitRate(unitSystem, XCOL, timePeriod)})`,
    `${YCOL} (${getUnitRate(unitSystem, YCOL, timePeriod)})`,
    saturationColName
  );
};

export const generateRecoveryMap = (
  datasetContent,
  prodRecovery,
  injRevovery,
  gainValues,
  prodNames,
  injNames,
  r2WaterMaskValue = [0, 1],
  saturationColName = ADDITIONAL_RECOVERY_M3,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const traces = [];
  const nodeData = [];
  const edgeData = [];

  const { min, max, ave } = extractWellSatData(prodRecovery, saturationColName);

  prodNames.forEach(wellName => {
    const wellContentData = datasetContent.find(
      row => row[WELL_ID] == wellName
    );
    const saturationData = prodRecovery.find(
      row => row['Producers'] == wellName
    );

    if (
      saturationData !== undefined &&
      saturationData[R2_WATER] > r2WaterMaskValue[0] &&
      saturationData[R2_WATER] <= r2WaterMaskValue[1]
    ) {
      nodeData.push({
        'Node Id': `${wellName}<br />${saturationColName}: ${
          Math.round(saturationData[saturationColName] * 1000) / 1000
        }`,
        'Node Text': wellName,
        'Node X': wellContentData[XCOL],
        'Node Y': wellContentData[YCOL],
        'Node Color': wellName.endsWith?.('_P') ? LIGHTBLUE : GREEN,
        'Node Size': normalizeNodeSize(
          Number(saturationData[saturationColName]),
          min,
          max
        ),
        'Node Symbol': 'circle-up',
      });
    }
  });

  injNames.forEach(wellName => {
    const wellContentData = datasetContent.find(
      row => row[WELL_ID] == wellName
    );
    const injRevoveryData = injRevovery.find(
      row => row['Injectors'] == wellName
    );
    let nodeColor;
    if (injRevoveryData !== undefined && injRevoveryData['Ranking'] == 'L1')
      nodeColor = LIGHTBLUE;
    else if (
      injRevoveryData !== undefined &&
      injRevoveryData['Ranking'] == 'L2'
    )
      nodeColor = YELLOW;
    else if (
      injRevoveryData !== undefined &&
      injRevoveryData['Ranking'] == 'L3'
    )
      nodeColor = RED;
    if (injRevoveryData !== undefined) {
      nodeData.push({
        'Node Id': `${wellName}<br />${saturationColName}: ${
          Math.round(injRevoveryData['Add_recover'] * 1000) / 1000
        }`,
        'Node Text': wellName,
        'Node X': wellContentData[XCOL],
        'Node Y': wellContentData[YCOL],
        'Node Color': nodeColor,
        'Node Size': normalizeNodeSize(ave, min, max),
        'Node Symbol': 'triangle-up',
        'Node Legend Group': injRevoveryData['Ranking'],
        'Node Legend Group Title': injRevoveryData['Ranking'],
      });
    }
  });

  prodNames.forEach(prodWell => {
    injNames.forEach(injWell => {
      const injGainValueMap = gainValues.find(
        row => row['Injector'] == injWell
      );
      const prodSaturationData = prodRecovery.find(
        row => row['Producers'] == prodWell
      );
      if (
        injGainValueMap !== undefined &&
        prodSaturationData !== undefined &&
        injGainValueMap[prodWell] != '0' &&
        prodSaturationData[R2_WATER] > r2WaterMaskValue[0] &&
        prodSaturationData[R2_WATER] <= r2WaterMaskValue[1]
      ) {
        const prodContentData = datasetContent.find(
          row => row[WELL_ID] == prodWell
        );
        const injContentData = datasetContent.find(
          row => row[WELL_ID] == injWell
        );
        const lineWidth = injGainValueMap[prodWell] * 3;

        // if connection between two points not strong enough, should not be plotted.
        if (lineWidth > 0)
          edgeData.push({
            'Source Node X': prodContentData[XCOL],
            'Source Node Y': prodContentData[YCOL],
            'Dest Node X': injContentData[XCOL],
            'Dest Node Y': injContentData[YCOL],
            'Edge Width': lineWidth,
            'Edge HoverText': `${saturationColName}: ${lineWidth}`,
          });
      }
    });
  });

  nodeData.forEach(node => {
    traces.push(
      createTraceWithText(
        [node['Node X']],
        [node['Node Y']],
        node['Node Text'],
        node['Node Color'],
        node['Node Symbol'],
        node['Node Id'],
        undefined,
        undefined,
        node['Node Text'] ? node['Node Text'] : node['Node Id'],
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        node['Node Legend Group'] ? true : false,
        node['Node Legend Group'],
        node['Node Legend Group Title'],
        node['Node Size']
      )
    );
  });

  edgeData.forEach(edge => {
    traces.push(
      createLinePlotTrace(
        [edge['Source Node X'], edge['Dest Node X']],
        [edge['Source Node Y'], edge['Dest Node Y']],
        undefined,
        edge['Edge Width'],
        edge['Color'] ? edge['Color'] : '#1f77b4',
        undefined,
        undefined,
        undefined,
        undefined,
        false
      )
    );
    traces.push(
      createTrace(
        [(parseInt(edge['Source Node X']) + parseInt(edge['Dest Node X'])) / 2],
        [(parseInt(edge['Source Node Y']) + parseInt(edge['Dest Node Y'])) / 2],
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        edge['Edge HoverText'],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'rgba(0, 0, 0, 0)',
        undefined,
        false
      )
    );
  });

  // return wrapPlots(traces, undefined, undefined, saturationColName);
  return wrapPlots(
    traces,
    `${XCOL} (${getUnitRate(unitSystem, XCOL, timePeriod)})`,
    `${YCOL} (${getUnitRate(unitSystem, YCOL, timePeriod)})`,
    saturationColName
  );
};
