import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { getUnitRate } from '../../../../../Utils/ReservoirUtils';

import LoadingPage from '../../../../Components/LoadingPage';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  generatePlot,
  filterDatasetByProducer,
  filterWellNamesAndSortByCumulativeColumn,
} from './MatchingUtils';

import {
  DATE,
  OIL_PRODUCTION_RATE,
  SYSTEM_OF_UNITS,
  TRAIN_TEST_SPLIT,
} from '../../../../../constants/WellConstants';

const OilMatching = ({ headers, prodOilWaterRates, workflowParameters }) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setPlot] = useState(null);
  const [prodWellNames, setProdWellNames] = useState(null);
  const [selectedProducer, setSelectedProducer] = useState('Select Producer');

  const onWellSelect = event => {
    setSelectedProducer(event.target.value);
  };

  useEffect(() => {
    if (prodOilWaterRates?.length && workflowParameters) {
      setProdWellNames(
        filterWellNamesAndSortByCumulativeColumn(
          prodOilWaterRates,
          'Oil rate - Actual'
        )
      );
      const dataset =
        selectedProducer !== 'Select Producer'
          ? filterDatasetByProducer(prodOilWaterRates, selectedProducer)
          : prodOilWaterRates;
      setPlot(
        generatePlot(
          dataset,
          selectedProducer,
          ['Oil rate - Actual', 'Oil rate - BL'],
          `${DATE} (${getUnitRate(workflowParameters[SYSTEM_OF_UNITS], DATE)})`,
          `${OIL_PRODUCTION_RATE} (${getUnitRate(
            workflowParameters[SYSTEM_OF_UNITS],
            OIL_PRODUCTION_RATE
          )})`,
          'Oil Matching Plot',
          workflowParameters[TRAIN_TEST_SPLIT]
        )
      );
    } else {
      setPlot(null);
    }
  }, [prodOilWaterRates, workflowParameters, selectedProducer]);

  return (
    <Grid container className={styles.visualContent} id={headers.OIL_MATCHING}>
      <Grid item className={styles.visualContentRow}>
        <Typography>{headers.OIL_MATCHING}</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={selectedProducer}
            onChange={onWellSelect}
            className="dropdownSelection"
          >
            <MenuItem value={'Select Producer'}>Select Producer</MenuItem>
            {prodWellNames &&
              prodWellNames.map(wellName => (
                <MenuItem key={'menu-item-' + wellName} value={wellName}>
                  {wellName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage message="Oil Matching Plot is Loading..." goHome={false} />
      )}
    </Grid>
  );
};

OilMatching.propTypes = {
  headers: PropTypes.object,
  prodOilWaterRates: PropTypes.array,
  workflowParameters: PropTypes.object,
};

export default OilMatching;
