import React, { useState, useEffect } from 'react';
import useStyles from '../../../../../Styles/WorkflowStyles';

import PropTypes from 'prop-types';

import { ResultConfigurationTable } from './ResultConfiguration';
import DynamicConnectivityPlot from './FeatureAnalysisPlot';
import LoadingPage from '../../../../Components/LoadingPage';

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { extractWellPairFeatureValues } from '../../../../../Utils/WorkflowReportUtils/WorkflowComparisonUtils';

const ResultOrientedAnalysis = ({
  headers,
  injNames,
  prodNames,
  workflowResultFiles,
  datasetContent,
  focusedFeature,
  resultFileNames,
}) => {
  const styles = useStyles();
  const [workflowReady, setworkflowReady] = useState(false);

  const [minFeatureValue, setminFeatureValue] = useState(null);
  const [maxFeatureValue, setmaxFeatureValue] = useState(null);

  const [wellFeatureValues, setwellFeatureValues] = useState(null);
  const [selectedResultFile, setselectedResultFile] = useState(
    workflowResultFiles[resultFileNames[0]]
  );
  const [selectedResultFileName, setselectedResultFileName] = useState(
    resultFileNames[0]
  );

  // Callback -->
  // <-- Callback

  // Handlers -->
  const onResultFileSelect = event => {
    setselectedResultFileName(event.target.value);
    setselectedResultFile(workflowResultFiles[event.target.value]);
  };
  // <-- Handlers

  // Each time user makes result file selection, regenerate entire component.
  useEffect(() => {
    if (datasetContent && selectedResultFile) {
      const { wellFeatureValues, minFeatureValue, maxFeatureValue } =
        extractWellPairFeatureValues(
          selectedResultFile,
          injNames,
          prodNames,
          focusedFeature
        );

      setwellFeatureValues(wellFeatureValues);
      setminFeatureValue(minFeatureValue);
      setmaxFeatureValue(maxFeatureValue);

      setworkflowReady(true);
      return () => {
        setworkflowReady(false);
      };
    }
  }, [selectedResultFile]);

  return workflowReady ? (
    <React.Fragment>
      <Grid item className={styles.visualContentRow}>
        <Typography>Result Files</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={selectedResultFileName}
            onChange={onResultFileSelect}
            className="dropdownSelection"
          >
            {resultFileNames &&
              resultFileNames.map(fileName => (
                <MenuItem key={'menu-item-' + fileName} value={fileName}>
                  {fileName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <ResultConfigurationTable
        injectors={injNames}
        producers={prodNames}
        minFeatureValue={minFeatureValue}
        maxFeatureValue={maxFeatureValue}
      />
      <DynamicConnectivityPlot
        header={headers.WELL_GAIN_VALUES}
        focusedFeature={focusedFeature}
        datasetContent={datasetContent}
        minFeatureValue={minFeatureValue}
        maxFeatureValue={maxFeatureValue}
        wellFeatureValues={wellFeatureValues}
      />
    </React.Fragment>
  ) : (
    <LoadingPage goHome={false} />
  );
};

ResultOrientedAnalysis.propTypes = {
  headers: PropTypes.array,
  injNames: PropTypes.array,
  prodNames: PropTypes.array,
  workflowResultFiles: PropTypes.array,
  datasetContent: PropTypes.array,
  focusedFeature: PropTypes.string,
  resultFileNames: PropTypes.array,
};

export default ResultOrientedAnalysis;
