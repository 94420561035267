import React from 'react';

import WorkflowHelpBase from './BaseWorkflowHelpComponent';
import CustomStepper from '../CustomStepper';

import { HELP_PAGE_LIST_HEADER_NAMES } from '../../../constants/WorkflowComponentConstants';
import { Typography } from '@material-ui/core';

import WorkflowDataFormat from './WorkflowDataFormat';

const CrossCorrelation = () => {
  const workflowSteps = [
    'Go to Surveillance Section.',
    'Upload your csv dataset by clicking to green area.',
    'Select the dataset you want to visualize from the dropdown menu, and click Continue button.',
    'Select filters with different parameters like start/end date range, active days.',
    'For the advanced filtering settings, click Advanced button, then click apply button.',
    'Charts rendered with selected dates like Schedule Plots, Scatter Plots.',
    'You can use left hand side to navigate through the plots faster.',
    'Click Choose Workflow Button to run workflows on selected/filtered dataset.',
    'Select Cross Correlation from the left hand side.',
    'You can provide Name and Description to your workflow.',
    'Click on Run Button to start the workflow with provided settings.',
    'Visualzie your workflow results from the Homepage once it is Completed.',
  ];

  const workflowExplanation = {
    'Cross Correlation Overview': (
      <React.Fragment>
        <Typography variant="body1">
          Cross Correlation Analysis measures the degree of similarity between
          all pairs of injection and producer wells. Values ranges between -1,1
          where 1,-1 indicates perfect correlation and 0 indicates no
          correlation. Input Data: Formatted as mentioned in the section below
          Data Processing: Before the regression analysis for history mathcing,
          data processing/cleaning is performed in order to improve the
          analysis. Currently, it does: exclude the data outside of specified
          date range multiply 1.1 for oil production rate and 0.01 for gas
          production rate to calculate the total production rate * User gets to
          give parameters - Radius of Investigation or correlation types to find
          correlation between the wells through various plots.
        </Typography>
        <br />
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Pearson Correlation Coefficient
        </Typography>
        <Typography variant="body1">
          Pearson correlation coefficient is measure of the linear correlation
          between two variables (Injector - Producer). It has a value between -1
          and 1. The value of:
        </Typography>
        <Typography variant="body1">
          -1 indicates a perfect negative linear relationship between variables,
        </Typography>
        <Typography variant="body1">
          0 indicates no linear relationship between variables.
        </Typography>
        <Typography variant="body1">
          1 indicates a perfect positive linear relationship between variables.
        </Typography>
        <br />
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Spearman's rank correlation coefficient
        </Typography>
        <Typography variant="body1">
          Spearman's rank correlation coefficient is measure of the strength of
          a monotonic relationship between two variables (Injector - Producer).
          It has a value between -1 and 1. The value of:
        </Typography>
        <Typography variant="body1">
          -1 indicates a perfect negative monotonic correlation between
          variables,
        </Typography>
        <Typography variant="body1">
          0 indicates no monotonic correlation between variables.
        </Typography>
        <Typography variant="body1">
          1 indicates a perfect positive monotonic correlation between
          variables.
        </Typography>
      </React.Fragment>
    ),
    'Data Format': <WorkflowDataFormat />,
    'Overall Flow': (
      <CustomStepper orientation="vertical" steps={workflowSteps} />
    ),
  };

  return (
    <WorkflowHelpBase
      workflowName={HELP_PAGE_LIST_HEADER_NAMES.CROSS_CORRELATION}
      workflowExplanations={workflowExplanation}
    />
  );
};

export default CrossCorrelation;
