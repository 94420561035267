import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

/*
 * The word "thunk" is a programming term that means "a piece of code that does some delayed work".
 * For Redux specifically, "thunks" are pattern of writing functions with a logic inside that can
 * interact with a Redux store's, dispatch actions, and getState methods.
 * Thunks are the standard approach for writing async logic in Redux apps, and they are commonly used for data fetching.
 */

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['sidebar'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureReducer = () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export { configureReducer };
