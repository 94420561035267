export const getAllWorkflowsRoute = () => {
  return `/workflows`;
};
export const getWorkflowResult = workflowId => {
  return `/workflow/${workflowId}/result`;
};
export const getWorkflowParameters = workflowId => {
  return `/workflow/${workflowId}/parameters`;
};
export const createWorkflowRoute = datasetId => {
  return `/workflow/${datasetId}`;
};
export const deleteWorkflowRoute = workflowId => {
  return `/workflow/${workflowId}`;
};
export const updateWorkflowRoute = workflowId => {
  return `/workflow/${workflowId}`;
};
export const getProdAnalysisPredictionRoute = workflowId => {
  return `/workflow/${workflowId}/prod_analysis`;
};
// TODO: Remove all of these after implementing integration workflow results.
export const getSimulationNorneDataCSV = propertyFile => {
  return `/workflow/norne_5well/${propertyFile}`;
};
export const getSimulationResultDataCSV = propertyFile => {
  return `/workflow/result_file/${propertyFile}`;
};
export const postSimulationSelectedWells = workflow_id => {
  return `/workflow/${workflow_id}/updatedWellsDf`;
};
export const retrieveWorkflowNPVScore = workflow_id => {
  return `/workflow/${workflow_id}/npv_score`;
};
export const postOptimizationParameters = workflow_id => {
  return `/workflow/${workflow_id}/optimization_model`;
};
export const postSimulationParameters = workflow_id => {
  return `/workflow/${workflow_id}/simulation_model`;
};
export const getIncrementalFGPTUrl = workflow_id => {
  return `/workflow/${workflow_id}/incremental_fgpt`;
};
