import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import {
  CROSS_CORR_INJECTOR_COLS,
  CROSS_CORR_PRODUCER_COLS,
} from '../../../../constants/WorkflowComponentConstants';
import { INJECTOR, PRODUCER } from '../../../../constants/WellConstants';

const CorrelationPairSelector = ({
  filterWorkflowAnalysisContent,
  correlationPairNames,
}) => {
  const styles = useStyles();

  const [injPairValue, setinjPairValue] = useState('');
  const [prodPairValue, setprodPairValue] = useState('');
  const [injPairValueOptions, setinjPairValueOptions] = useState([]);
  const [prodPairValueOptions, setprodPairValueOptions] = useState([]);

  // Callbacks/Helpers -->
  // <-- Callbacks/Helpers

  // Handlers -->
  /**
   * Based on selected correlation pairs, invokes entire report to be regenerated based
   * on correlation pair selection by calling filterWorkflowAnalysisContent function.
   *
   * @param {String} newInjPair
   * @param {String} newProdPair
   */
  const onCorrPairSelect = (newInjPair, newProdPair) => {
    injPairValue != newInjPair && setinjPairValue(newInjPair);
    prodPairValue != newProdPair && setprodPairValue(newProdPair);
    filterWorkflowAnalysisContent([newInjPair, newProdPair]);
  };
  // <-- Handlers

  useEffect(() => {
    const filteredInjOption = CROSS_CORR_INJECTOR_COLS.filter(columnName =>
      correlationPairNames[INJECTOR].has(columnName)
    );
    const filteredProdOption = CROSS_CORR_PRODUCER_COLS.filter(columnName =>
      correlationPairNames[PRODUCER].has(columnName)
    );

    setinjPairValueOptions(filteredInjOption);
    setprodPairValueOptions(filteredProdOption);
    setinjPairValue(filteredInjOption[0]);
    setprodPairValue(filteredProdOption[0]);
    onCorrPairSelect(filteredInjOption[0], filteredProdOption[0]);
  }, []);

  return (
    <Grid container className={styles.visualContentFlex}>
      <Grid item className={styles.visualContentRow}>
        <Typography>Correlation Pairs</Typography>
      </Grid>
      <Grid item container className={styles.inputContentRow}>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <InputLabel>Injector Corelation Pair Value</InputLabel>
          <Select
            value={injPairValue}
            onChange={event =>
              onCorrPairSelect(event.target.value, prodPairValue)
            }
          >
            {injPairValueOptions.map(pair => (
              <MenuItem key={'menu-item-' + pair} value={pair}>
                {pair}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="expandInputElement"
        >
          <InputLabel>Producer Corelation Pair Value</InputLabel>
          <Select
            value={prodPairValue}
            onChange={event =>
              onCorrPairSelect(injPairValue, event.target.value)
            }
          >
            {prodPairValueOptions.map(pair => (
              <MenuItem key={'menu-item-' + pair} value={pair}>
                {pair}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

CorrelationPairSelector.propTypes = {
  filterWorkflowAnalysisContent: PropTypes.func,
  correlationPairNames: PropTypes.object,
};

export default CorrelationPairSelector;
