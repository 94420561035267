import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import WorkflowHelpBase from './BaseWorkflowHelpComponent';
import ComplexEquationVisualizer from './ComplexEquationComponent';
import CustomStepper from '../CustomStepper';

// import SelectModuleImg from '../../../Images/Select Module.png';
// import SelectDatasetImg from '../../../Images/Select Dataset.png';
// import UploadDatasetImg from '../../../Images/Upload Dataset.png';
// import FilterDataImg from '../../../Images/Filter Data.png';
// import AdvancedFilterDataImg from '../../../Images/Advanced Filter Data.png';
// import QuickNavigationImg from '../../../Images/Quick Navigation.png';

import { HELP_PAGE_LIST_HEADER_NAMES } from '../../../constants/WorkflowComponentConstants';

import WorkflowDataFormat from './WorkflowDataFormat';

const CRMP = () => {
  const workflowSteps = [
    'Go to Surveillance Section.',
    'Upload your csv dataset by clicking to green area.',
    'Select the dataset you want to visualize from the dropdown menu, and click Continue button.',
    'Select filters with different parameters like start/end date range, active days.',
    'For the advanced filtering settings, click Advanced button, then click apply button.',
    'Charts rendered with selected dates like Schedule Plots, Scatter Plots.',
    'You can use left hand side to navigate through the plots faster.',
    'Click Choose Workflow Button to run workflows on selected/filtered dataset.',
    'Select CRMP from the left hand side.',
    'You can provide Name and Description to your workflow.',
    'Select producer and injector wells from the small windows, then click Pair Wells to select wells with zero connectivity.',
    'Click on Run Button to start the workflow with provided settings.',
    'Visualzie your workflow results from the Homepage once it is Completed.',
  ];

  const workflowExplanation = {
    'CRMP Overview': (
      <React.Fragment>
        <Typography variant="body1">
          Get an idea about the Capacitance Resistance Model with
          producer-injector interaction report here with respect to input data,
          analysis and graphs involved. CRMP Formulation is:
        </Typography>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ComplexEquationVisualizer
            asciiEquationString={
              '$$q(t_k) = q(t_{k-1}) e^{-\\frac{Delta t_k}{\\tau}}  + (1-e^{-\\frac{Delta t_k}{\\tau}})(sum_i^{N_i} f_{ij}I_i^k - J_j \\tau_j \\frac{Delta p_{wf}^k}{Delta t_k}), quad j in 1,ldots,N_p$$'
            }
          />
          <ComplexEquationVisualizer
            asciiEquationString={
              '$$q(t_k) = q(t_{k-1}) mathcal{T}_j  + (1-mathcal{T}_j)(sum_i^{N_i} f_{ij}I_i^k - \\frac{J_j Delta p_{wf}^k}{ln mathcal{T}_j}), quad j in 1,ldots,N_p$$'
            }
          />
        </Grid>
        <Typography variant="body1">
          where Ni: number of injectors and Np: number of producers.
        </Typography>
      </React.Fragment>
    ),
    'Data Format': <WorkflowDataFormat />,
    'Overall Flow': (
      <CustomStepper orientation="vertical" steps={workflowSteps} />
    ),
    'Methods Involved': (
      <React.Fragment>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Subsections of CRMP Module
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          The method has been implemented in software that consists of three
          modules that can perform characterization and optimization in one
          continuous run:
        </Typography>
        <ul>
          <li>Total production fit</li>
          <li>Oil production fit</li>
          <li>Injection schedule Optimization</li>
        </ul>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Total Production Fit(History Matching)
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          This is the first element of CRMP module and evaluates
          injector-producer connectivities by finding the best match between the
          production observed and the one replicated using the CRMP equation
        </Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Oil Production Fit
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          This is the second element of CRMP module. Oil production rate of each
          well is matched using an empirical equation of oil cut model. Just
          like the previous total fit, first 70 % of the input data is used to
          solve the problem as a training set. The rest of 30 % is used for
          verification of the training (oil production fit) as a test set.
        </Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Injector Schedule Optimization
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          This is the third element of CRMP module and finds the best injection
          schedule to maximize a target variable such as cumulative oil
          production and net present value based on the parameters obtained in
          the previous two elements.
        </Typography>
      </React.Fragment>
    ),
  };

  return (
    <WorkflowHelpBase
      workflowName={HELP_PAGE_LIST_HEADER_NAMES.CRMP}
      workflowExplanations={workflowExplanation}
    />
  );
};

export default CRMP;
