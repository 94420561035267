import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OilMatching from './OilMatching';
import WaterMatching from './WaterMatching';
import R2_Table from './R2_Table';

const Matching = ({
  headers,
  prodOilWaterRates,
  prodOilSaturation,
  paramTotals,
  workflowParameters,
}) => {
  return (
    <Fragment>
      <OilMatching
        headers={headers}
        prodOilWaterRates={prodOilWaterRates}
        workflowParameters={workflowParameters}
      />
      <WaterMatching
        headers={headers}
        prodOilWaterRates={prodOilWaterRates}
        workflowParameters={workflowParameters}
      />
      <R2_Table
        headers={headers}
        prodOilSaturation={prodOilSaturation}
        paramTotals={paramTotals}
      />
    </Fragment>
  );
};

export default Matching;

Matching.propTypes = {
  headers: PropTypes.object,
  workflowParameters: PropTypes.object,
  prodOilWaterRates: PropTypes.array,
  paramTotals: PropTypes.array,
  prodOilSaturation: PropTypes.array,
};
