import moment from 'moment';
import {
  DATE,
  FIELD,
  PER_DAY,
  UNIT_SIZE,
  PER_MONTH,
  UNIT_LENGTH,
  UNIT_STD_GAS,
  UNIT_PRESSURE,
  UNIT_RES_FLUID,
  UNIT_STD_LIQUID,
  UNIT_GAS_OIL_RATIO_RATE,
  UNIT_OIL_FORMATION_FACTOR,
  UNIT_GAS_FORMATION_FACTOR,
  UNIT_WATER_FORMATION_FACTOR,
  OVER,
} from '../constants/WellConstants';

/**
 * This method finds proper unit name for the passed category selection.
 * @param {String} unit_system - Field or Metric
 * @param {String} category - Column name
 * @param {Boolean} std_condition
 */
export const getUnitRate = (
  unitSystem = FIELD,
  category,
  timePeriod = PER_MONTH,
  stdCondition = true
) => {
  let unknown = 'Unit unknown!!';
  let unit;
  const comp = category.toLowerCase();

  if (comp.includes('date')) unit = timePeriod.split(OVER).at(-1);
  else if (
    comp.includes('pressure') ||
    comp.includes('bhp') ||
    comp.includes('thp')
  )
    unit = UNIT_PRESSURE[unitSystem];
  else if (comp.includes('size')) unit = UNIT_SIZE[unitSystem];
  else if (comp.includes('cut')) unit = 'Unit Interval';
  else if (
    comp.includes('ratio') ||
    comp.includes('gor') ||
    comp.includes('wor')
  )
    unit = UNIT_GAS_OIL_RATIO_RATE[unitSystem];
  else if (comp.includes('formation')) {
    if (comp.includes('oil')) unit = UNIT_OIL_FORMATION_FACTOR[unitSystem];
    if (comp.includes('water')) unit = UNIT_WATER_FORMATION_FACTOR[unitSystem];
    if (comp.includes('gas')) unit = UNIT_GAS_FORMATION_FACTOR[unitSystem];
  } else if (
    comp.includes('oil') ||
    comp.includes('water') ||
    comp.includes('liquid') ||
    comp.includes('fluid')
  )
    unit = stdCondition
      ? UNIT_STD_LIQUID[unitSystem] + timePeriod
      : UNIT_RES_FLUID[unitSystem] + timePeriod;
  else if (comp.includes('gas'))
    unit = stdCondition
      ? UNIT_STD_GAS[unitSystem] + timePeriod
      : UNIT_RES_FLUID[unitSystem] + timePeriod;
  else if (
    comp.includes('surface') ||
    comp.includes('radius') ||
    comp.includes('distance')
  )
    unit = UNIT_LENGTH[unitSystem];
  else unit = unknown;
  return unit;
};

/**
 * If # of days between two time periods greater than 1, returns PER_MONTH, else PER_DAY.
 * @param {Array} dataset
 * @returns {String}
 */
export const getDatasetTimePeriod = dataset => {
  if (dataset.length > 1) {
    return moment(dataset[1][DATE]).diff(moment(dataset[0][DATE]), 'days') > 1
      ? PER_MONTH
      : PER_DAY;
  }
  return PER_MONTH;
};
