import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../../Styles/WorkflowStyles';

import { BasicTable } from '../../../../Components/TableView';

import { Grid, Paper, Typography, Link, IconButton } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const InjectorSummaryTable = ({ headers, injPotential }) => {
  const styles = useStyles();
  const [tableData, setTableData] = useState(null);
  const [encodedUri, setencodedUri] = useState(null);

  const filterDataForTable = injPotential => {
    const columnMapping = {
      Injectors: 'Injector',
      Ranking: 'Ranking',
    };
    const filteredColumns = Object.keys(columnMapping);
    const filteredData = injPotential.map(row => {
      const filteredRow = {};
      filteredColumns.forEach(column => {
        filteredRow[columnMapping[column]] = row[column];
      });
      return filteredRow;
    });

    const sortedDatasetByWellNames = filteredData.sort((a, b) => {
      const aNum = parseInt(a['Injector'].split('-')[1]);
      const bNum = parseInt(b['Injector'].split('-')[1]);
      return aNum - bNum;
    });

    return sortedDatasetByWellNames;
  };

  // Handlers -->
  const onDownload = () => {
    const content = [];

    tableData.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  useEffect(() => {
    if (injPotential) {
      setTableData(filterDataForTable(injPotential));
    }
  }, [injPotential]);

  return (
    <Grid
      container
      className={styles.visualContentFlex}
      id={headers.INJECTOR_SUMMARY_TABLE}
    >
      <Grid item style={{ margin: '0% 5%' }}>
        <Typography>{headers.INJECTOR_SUMMARY_TABLE}</Typography>
      </Grid>
      <Paper elevation={5} className={styles.visualContentRow}>
        <BasicTable data={tableData} />
      </Paper>
      <Grid container className={styles.visualContentRow}>
        <Grid
          item
          container
          xs={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="overline">Download Injector Summary</Typography>
          <Link
            download="Injector_Summary.csv"
            href={encodedUri}
            onClick={onDownload}
          >
            <IconButton>
              <GetAppRoundedIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InjectorSummaryTable;

InjectorSummaryTable.propTypes = {
  headers: PropTypes.object,
  injPotential: PropTypes.array,
};
