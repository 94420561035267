import React from 'react';

import { Typography, Button } from '@material-ui/core';
import { BasicTable } from '../TableView';
import { DateDialog } from '../DateDialog';
import { WellNameDialog } from '../WellNameDialog';
import { IntegerFieldRuleDialog } from '../IntegerFieldRuleDialog';
import { BooleanFieldRuleDialog } from '../BooleanFieldRuleDialog';
import { DecimalFieldRuleDialog } from '../DecimalFieldRuleDialog';

import {
  ACTIVE_DAYS,
  BHP,
  BOTTOMHOLE_PRESSURE,
  CHOKE_SIZE,
  DATE,
  FLUID_INJECTION_RATE,
  FLUID_PRODUCTION_RATE,
  GAS_INJECTION_RATE,
  GAS_PRODUCTION_RATE,
  GOR_SCF_BBL,
  IS_INJECTOR_WELL,
  OIL_CUT,
  OIL_PRODUCTION_RATE,
  THP,
  TUBING_HEAD_PRESSURE,
  WATER_CUT,
  WATER_INJECTION_RATE,
  WATER_PRODUCTION_RATE,
  WC,
  WELL_ID,
  XCOL,
  YCOL,
} from '../../../constants/WellConstants';

const WorkflowDataFormat = () => {
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
  const [wellNameDialogOpen, setWellNameDialogOpen] = React.useState(false);
  const [fieldName, setFieldName] = React.useState('');
  const [integerFieldRuleDialogOpen, setIntegerFieldRuleDialogOpen] =
    React.useState(false);
  const [booleanFieldRuleDialogOpen, setBooleanFieldRuleDialogOpen] =
    React.useState(false);
  const [decimalFieldRuleDialogOpen, setDecimalFieldRuleDialogOpen] =
    React.useState(false);

  const handleDateDialogClose = () => {
    setDateDialogOpen(false);
  };
  const handleWellNameDialogClose = () => {
    setWellNameDialogOpen(false);
  };
  const handleIntegerFieldRuleDialogClose = () => {
    setIntegerFieldRuleDialogOpen(false);
  };
  const handleBooleanFieldRuleDialogClose = () => {
    setBooleanFieldRuleDialogOpen(false);
  };
  const handleDecimalFieldRuleDialogClose = () => {
    setDecimalFieldRuleDialogOpen(false);
  };
  const onDateClick = () => {
    setDateDialogOpen(true);
  };
  const onWellNameClick = () => {
    setWellNameDialogOpen(true);
  };
  const onXColClick = () => {
    setFieldName('Surface X');
    setIntegerFieldRuleDialogOpen(true);
  };
  const onYColClick = () => {
    setFieldName('Surface Y');
    setIntegerFieldRuleDialogOpen(true);
  };
  const onIsInjectorWellClick = () => {
    setFieldName('Is Injector Well');
    setBooleanFieldRuleDialogOpen(true);
  };
  const onOilProdutionRateClick = () => {
    setFieldName('Oil Production Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onWaterProductionRateClick = () => {
    setFieldName('Water Production Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onGasProductionRateClick = () => {
    setFieldName('Gas Production Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onWaterInjectionRateClick = () => {
    setFieldName('Water Injection Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onGasInjectionRateClick = () => {
    setFieldName('Gas Injection Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onBottomHolePressureClick = () => {
    setFieldName('Bottomhole Pressure');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onActiveDaysClick = () => {
    setFieldName('Active Days');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onTHPClick = () => {
    setFieldName('THP');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onBHPClick = () => {
    setFieldName('BHP');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onChokeSizeClick = () => {
    setFieldName('Choke_Size');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onWCClick = () => {
    setFieldName('WC, %');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onGORSCFBBLlick = () => {
    setFieldName('GOR, SCF/BBL');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onTuringHeadPressureClick = () => {
    setFieldName('Tubinghead Pressure');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onFluidProductionRateClick = () => {
    setFieldName('Fluid Production Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onFluidInjectionRateClick = () => {
    setFieldName('Fluid Injection Rate');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onOilCutClick = () => {
    setFieldName('Oil cut');
    setDecimalFieldRuleDialogOpen(true);
  };
  const onWaterCutClick = () => {
    setFieldName('Water cut');
    setDecimalFieldRuleDialogOpen(true);
  };

  let renderConfig = {
    [DATE]: () => (
      <Button color="primary" onClick={onDateClick}>
        ...
      </Button>
    ),
    [WELL_ID]: () => (
      <Button color="primary" onClick={onWellNameClick}>
        ...
      </Button>
    ),
    [XCOL]: () => (
      <Button color="primary" onClick={onXColClick}>
        ...
      </Button>
    ),
    [YCOL]: () => (
      <Button color="primary" onClick={onYColClick}>
        ...
      </Button>
    ),
    [IS_INJECTOR_WELL]: () => (
      <Button color="primary" onClick={onIsInjectorWellClick}>
        ...
      </Button>
    ),
    [OIL_PRODUCTION_RATE]: () => (
      <Button color="primary" onClick={onOilProdutionRateClick}>
        ...
      </Button>
    ),
    [WATER_PRODUCTION_RATE]: () => (
      <Button color="primary" onClick={onWaterProductionRateClick}>
        ...
      </Button>
    ),
    [GAS_PRODUCTION_RATE]: () => (
      <Button color="primary" onClick={onGasProductionRateClick}>
        ...
      </Button>
    ),
    [WATER_INJECTION_RATE]: () => (
      <Button color="primary" onClick={onWaterInjectionRateClick}>
        ...
      </Button>
    ),
    [GAS_INJECTION_RATE]: () => (
      <Button color="primary" onClick={onGasInjectionRateClick}>
        ...
      </Button>
    ),
    [BOTTOMHOLE_PRESSURE]: () => (
      <Button color="primary" onClick={onBottomHolePressureClick}>
        ...
      </Button>
    ),
    [ACTIVE_DAYS]: () => (
      <Button color="primary" onClick={onActiveDaysClick}>
        ...
      </Button>
    ),
    [THP]: () => (
      <Button color="primary" onClick={onTHPClick}>
        ...
      </Button>
    ),
    [BHP]: () => (
      <Button color="primary" onClick={onBHPClick}>
        ...
      </Button>
    ),
    [CHOKE_SIZE]: () => (
      <Button color="primary" onClick={onChokeSizeClick}>
        ...
      </Button>
    ),
    [WC]: () => (
      <Button color="primary" onClick={onWCClick}>
        ...
      </Button>
    ),
    [GOR_SCF_BBL]: () => (
      <Button color="primary" onClick={onGORSCFBBLlick}>
        ...
      </Button>
    ),
    [TUBING_HEAD_PRESSURE]: () => (
      <Button color="primary" onClick={onTuringHeadPressureClick}>
        ...
      </Button>
    ),
    [FLUID_PRODUCTION_RATE]: () => (
      <Button color="primary" onClick={onFluidProductionRateClick}>
        ...
      </Button>
    ),
    [FLUID_INJECTION_RATE]: () => (
      <Button color="primary" onClick={onFluidInjectionRateClick}>
        ...
      </Button>
    ),
    [OIL_CUT]: () => (
      <Button color="primary" onClick={onOilCutClick}>
        ...
      </Button>
    ),
    [WATER_CUT]: () => (
      <Button color="primary" onClick={onWaterCutClick}>
        ...
      </Button>
    ),
  };
  return (
    <React.Fragment>
      <Typography variant="body1" gutterBottom={true}>
        This module accepts a csv file as an input data. User can upload the csv
        file with the below format,
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Click three dots to check rules for each field before uploading
      </Typography>
      <BasicTable
        data={[
          {
            [DATE]: '...',
            [WELL_ID]: '...',
            [XCOL]: '...',
            [YCOL]: '...',
            [IS_INJECTOR_WELL]: '...',
            [OIL_PRODUCTION_RATE]: '...',
            [WATER_PRODUCTION_RATE]: '...',
            [GAS_PRODUCTION_RATE]: '...',
            [WATER_INJECTION_RATE]: '...',
            [GAS_INJECTION_RATE]: '...',
            [BOTTOMHOLE_PRESSURE]: '...',
            [ACTIVE_DAYS]: '...',
            [THP]: '...',
            [BHP]: '...',
            [CHOKE_SIZE]: '...',
            [WC]: '...',
            [GOR_SCF_BBL]: '...',
            [TUBING_HEAD_PRESSURE]: '...',
            [FLUID_PRODUCTION_RATE]: '...',
            [FLUID_INJECTION_RATE]: '...',
            [OIL_CUT]: '...',
            [WATER_CUT]: '...',
          },
        ]}
        renderConfig={renderConfig}
      />
      <DateDialog open={dateDialogOpen} onClose={handleDateDialogClose} />
      <WellNameDialog
        open={wellNameDialogOpen}
        onClose={handleWellNameDialogClose}
      />
      <IntegerFieldRuleDialog
        open={integerFieldRuleDialogOpen}
        onClose={handleIntegerFieldRuleDialogClose}
        fieldName={fieldName}
      />
      <BooleanFieldRuleDialog
        open={booleanFieldRuleDialogOpen}
        onClose={handleBooleanFieldRuleDialogClose}
        fieldName={fieldName}
      />
      <DecimalFieldRuleDialog
        open={decimalFieldRuleDialogOpen}
        onClose={handleDecimalFieldRuleDialogClose}
        fieldName={fieldName}
      />
      <br />
      <Typography variant="body1" gutterBottom={true}>
        If not given, Bottomhole Pressure = 1500 is substituted once the data is
        read
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Take care of the below Additional Requirement:
      </Typography>
      <Typography variant="body1" gutterBottom={true}>
        Monthly data, Below rates(Oil Production Rate,Water Production Rate,Gas
        Production Rate, Water Injection Rate, and Gas Injection Rate) to be in
        rb/month, Consistent Surface X and Surface Y for identical wells
        strictly in rb/month.
      </Typography>
    </React.Fragment>
  );
};

export default WorkflowDataFormat;
