import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Results from './Results';

import { updateWorkflow } from '../../../../../redux/actions/workflows';
import { updateSnackBar } from '../../../../../redux/actions/feedback';

import { CRMP_LAYER_WORKFLOW_STAGES } from '../../../../../constants/WorkflowsParameterConstants';
import { SNACK_BAR_SEVERITY } from '../../../../../constants/ComponentConstants';

const ShutOffAnalysis = ({
  reduxSelectedWorkflow,
  workflowParameters,
  filteredDatasetContent,
  injEfficiencyData,
  injEfficiencyLayerData,
  header,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * This method is not being used yet. Its purpose it so send paramters to server and recieve calculated values.
   * Due to missing CRMP Layer result generator script at backend, these calculations are being done in front end,
   * hence this method left unused.
   *
   * @param {Number} waterCost
   * @param {Number} oilPrice
   */
  const runShutoffAnalysis = async (waterCost, oilPrice) => {
    const updatedWorkflowPayload = {
      parameters: {
        ...workflowParameters,
        'Water cost': waterCost,
        'Oil price': oilPrice,
        stage: CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING,
      },
    };
    try {
      await dispatch(
        updateWorkflow(
          reduxSelectedWorkflow.workflow_id,
          updatedWorkflowPayload
        )
      );
      dispatch(
        updateSnackBar(
          'Shut-Off Analysis request sent. You will direct to home page soon.',
          SNACK_BAR_SEVERITY.success
        )
      );
    } catch (error) {
      dispatch(
        updateSnackBar(
          "Shut-Off Analysis didn't run due to server error. You will direct to home page soon.",
          SNACK_BAR_SEVERITY.warning
        )
      );
    } finally {
      await new Promise(resolve => setTimeout(resolve, 2000)); // Human friendly wait.
      navigate('/homepage');
    }
  };

  runShutoffAnalysis;

  /**
  Uncomment following return when we want to fetch something from the backend. For now it is not
  neccessary to do so.
  */
  // return workflowParameters.stage !=
  //   CRMP_LAYER_WORKFLOW_STAGES.FLUID_MATCHING ||
  //   workflowParameters.stage != CRMP_LAYER_WORKFLOW_STAGES.OIL_MATCHING ? (
  //   <React.Fragment>
  //     <Grid container className={styles.visualContentFlex}>
  //       <Grid item className={styles.visualContentRow}>
  //         <Typography id={header} variant="h5">
  //           {header}
  //         </Typography>
  //       </Grid>
  //       {/* <Parameters runShutoffAnalysis={runShutoffAnalysis} /> */}
  //     </Grid>
  //     {(workflowParameters.stage ==
  //       CRMP_LAYER_WORKFLOW_STAGES.SHUTOFF_ANALYSIS ||
  //       workflowParameters.stage ==
  //         CRMP_LAYER_WORKFLOW_STAGES.OPTIMIZATION) && (
  //       <Results
  //         filteredDatasetContent={filteredDatasetContent}
  //         workflowParameters={workflowParameters}
  //         injEfficiencyData={injEfficiencyData}
  //         injEfficiencyLayerData={injEfficiencyLayerData}
  //       />
  //     )}
  //   </React.Fragment>
  // ) : (
  //   ''
  // );
  return (
    <React.Fragment>
      {(workflowParameters.stage ==
        CRMP_LAYER_WORKFLOW_STAGES.SHUTOFF_ANALYSIS ||
        workflowParameters.stage ==
          CRMP_LAYER_WORKFLOW_STAGES.OPTIMIZATION) && (
        <Results
          filteredDatasetContent={filteredDatasetContent}
          workflowParameters={workflowParameters}
          injEfficiencyData={injEfficiencyData}
          injEfficiencyLayerData={injEfficiencyLayerData}
          header={header}
        />
      )}
    </React.Fragment>
  );
};

ShutOffAnalysis.propTypes = {
  reduxSelectedWorkflow: PropTypes.object,
  workflowParameters: PropTypes.object,
  filteredDatasetContent: PropTypes.array,
  workflowAnalysisContent: PropTypes.array,
  injEfficiencyData: PropTypes.array,
  injEfficiencyLayerData: PropTypes.array,
  header: PropTypes.string,
};

export default ShutOffAnalysis;
