// import { reduxAction } from '../base';
// import { USER_LOGIN } from './types';
import { apiClient } from '../../../service/apiClient';
// import * as userApi from '../../../API/Routes/user';
import { getJWTToken } from '../base';
/**
 * This is a redux-thunk function, where it returns a dispatch function. Returned function is not executed until it is
 * dispatched by the "store.dispatch()". It makes a post request to server and tries to log in the user. On success, it
 * dispaches USER_LOGIN action and saves user token.
 *
 * @param {Object} contactUsInfo
 * @param {Function} onSuccess - Callback function that runs after successful login.
 * @param {Function} onFail - Callback function that runs after failed login. Expects raised error as argument.
 * @returns Thunk Function
 */
export const contactUs = (contactUsInfo, onSuccess, onFail) => async () => {
  try {
    // eslint-disable-next-line no-console
    console.log(contactUsInfo);
    let response = await apiClient({
      method: 'POST',
      url: '/contactUs',
      data: contactUsInfo,
      headers: getJWTToken(),
    });
    // eslint-disable-next-line no-console
    console.log(response);
    // dispatch(reduxAction(USER_LOGIN, response.data.user));
    // localStorage.setItem('x-access-token', response.data.token);
    onSuccess();
  } catch (error) {
    // localStorage.removeItem('x-access-token');
    onFail(error);
  }
};
