import React, { useEffect, useMemo, useState } from 'react';

import useStyles from '../../../../Styles/VisualizationPageStyle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../Components/LoadingPlot';

import { updateSnackBar } from '../../../../redux/actions/feedback';
import { retrieveFieldResAnalysisData } from '../../../../API/Functions/Visualization';
import { generateFieldReservoirAnalysisPlot } from '../../../../Utils/VisualizationUtils';

import { Grid } from '@material-ui/core';

import { SNACK_BAR_SEVERITY } from '../../../../constants/ComponentConstants';

const selectedDatasetParameters = state => state.dataset.parameters;

const FieldReservoirAnalysisComponent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { datasetId: urlDatasetId } = useParams();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const reduxSelectedDatasetParameters = useSelector(selectedDatasetParameters);
  // TODO: Combine with IndexedDB. Prefer chached value or undefined.
  const [apiRes, setapiRes] = useState(undefined);

  // TODO: Combine with IndexedDB, so that result of API call can be cached.
  useEffect(() => {
    let mounted = true;
    async function fetchPlotData() {
      try {
        const res = await retrieveFieldResAnalysisData(
          urlDatasetId,
          reduxSelectedDatasetParameters
        );
        if (mounted) {
          setapiRes(res);
        }
      } catch (err) {
        dispatch(
          updateSnackBar(err?.message || 'Error', SNACK_BAR_SEVERITY.error)
        );
      }
    }

    fetchPlotData();

    return () => (mounted = false);
  }, [reduxSelectedDatasetParameters]);

  const cachedPlotResult = useMemo(() => {
    if (apiRes) {
      return generateFieldReservoirAnalysisPlot(apiRes);
    }
  }, [apiRes]);

  return (
    <Grid className={styles.plotContainer}>
      {cachedPlotResult ? (
        <PlotlyComponent
          data={cachedPlotResult.data}
          layout={cachedPlotResult.layout}
          config={cachedPlotResult.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPlot />
      )}
    </Grid>
  );
};

export default FieldReservoirAnalysisComponent;
