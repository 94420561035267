import React from 'react';
import PropTypes from 'prop-types';

import MathJax from 'react-mathjax2';

const ComplexEquationVisualizer = ({ asciiEquationString }) => {
  return (
    <MathJax.Context
      input="ascii"
      // onLoad={() => console.log('Loaded MathJax script!')}
      onError={MathJax => {
        MathJax.Hub.Queue(MathJax.Hub.Typeset());
      }}
      script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
      options={{
        asciimath2jax: {
          useMathMLspacing: true,
          delimiters: [['$$', '$$']],
          preview: 'none',
        },
      }}
    >
      <MathJax.Text text={asciiEquationString} />
    </MathJax.Context>
  );
};

ComplexEquationVisualizer.propTypes = {
  asciiEquationString: PropTypes.string,
};

export default ComplexEquationVisualizer;
