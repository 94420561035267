import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#f1f4f6',
    justifyContent: 'flex-start',
  },
  navbarGrid: {
    width: '100%',
    height: '10%',
    display: 'flex',
  },
  bodyGrid: {
    width: '100%',
    height: '90%',
    padding: '0% 0%',
  },
  sideBarGrid: {
    width: '20%',
    height: '100%',
    padding: '0em',
    '& a': {
      color: 'black',
      '&:hover': {
        color: 'black',
        textDecoration: 'none',
      },
    },
    '& hr': {
      border: '1px solid grey',
    },
  },
  sidebarPaperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: '#2e4258',
    color: '#c1c5d1',
  },
  visualizationGrid: {
    display: 'flex',
    width: '80%',
    height: '100%',
    padding: '1em',
  },
  paperContainer: {
    overflow: 'auto',
    width: '100%',
    height: '100%',
  },
  flexVisualContainer: {
    padding: '1em',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  visualContainer: {
    height: '100%',
    width: '100%',
    padding: '1%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  plotContainer: {
    height: '90%',
    width: '100%',
  },
  visualContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  visualContentRow: {
    width: '75%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  dateInput: {
    width: '50%',
    display: 'flex',
  },
  header: {
    fontSize: '1em',
  },
  textInput: {
    marginLeft: '5%',
  },
  layerButton: {
    fontSize: '1em',
    padding: '0.5em',
    marginTop: '0.5em',
    textTransform: 'capitalize',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      borderRadius: '.25em',
      margin: '0 0.5em',
    },
    '& #apply': {
      '&:enabled': {
        backgroundColor: '#000B46',
        color: 'white',
      },
      '&:disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
  workflowButton: {
    borderRadius: '2em',
    margin: '10% auto',
    padding: '1% 10%',
    backgroundColor: '#000B46',
    color: 'white',
    '&:hover': {
      backgroundColor: '#000B46',
      color: 'white',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerButton: {
    borderRadius: '2em',
    textTransform: 'capitalize',
  },
  formControl: {
    width: '25%',
    margin: '1em 0em 0em 0em',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default useStyles;
