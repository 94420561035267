export const loginUserRoute = () => {
  return '/login';
};
export const registerUserRoute = () => {
  return '/register';
};
export const createUserApiRoute = () => {
  return '/user';
};
export const getUserApiRoute = user_id => {
  return `/user/${user_id}`;
};
export const updateUserApiRoute = user_id => {
  return `/user/${user_id}`;
};
export const deleteUserApiRoute = user_id => {
  return `/user/${user_id}`;
};
export const updateUserPassword = user_id => {
  return `/update_user_password/${user_id}`;
};
