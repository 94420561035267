import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { addWorkflow } from '../../../../redux/actions/workflows';
import { INTEGRATION, SOI as soi } from '../../../../constants/WellConstants';

import { Grid, Typography, Button, TextField } from '@material-ui/core';

import useStyles from '../../../../Styles/ArgumentPageStyles';

const selectedDatasetSubscriber = state => state.dataset.selectedDataset;

const SOI = ({ submitWorkflow }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const reduxSelectedDataset = useSelector(selectedDatasetSubscriber);

  const [name, setname] = useState('');
  const [description, setdescription] = useState('');
  // Handlers -->
  const onSubmitWorkflow = async () => {
    try {
      await dispatch(
        addWorkflow(reduxSelectedDataset.dataset_id, {
          module_type: INTEGRATION,
          workflow_type: soi,
          name,
          description,
          parameters: {},
        })
      );
      submitWorkflow(true);
    } catch (error) {
      submitWorkflow(false);
    }
  };
  //  <-- Handlers

  return (
    <Grid item className={styles.rootContainer}>
      <Grid item className={styles.argumentsContainer}>
        <Typography className={styles.header}>Input Parameter</Typography>
        <Grid item className={styles.argumentRow}>
          <Typography>Name</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '50',
            }}
            onChange={event => {
              setname(event.target.value);
            }}
          />
        </Grid>
        <Grid item className={styles.argumentRow}>
          <Typography>Description</Typography>
          <TextField
            placeholder="optional"
            inputProps={{
              maxLength: '100',
            }}
            onChange={event => {
              setdescription(event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item className={styles.runContainer}>
        <Grid item className={styles.buttonContainer}>
          <Button
            variant="contained"
            className={`${styles.button} ${styles.runButton} ${styles.primaryButton}`}
            onClick={onSubmitWorkflow}
          >
            Run
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SOI.propTypes = {
  submitWorkflow: PropTypes.func,
};

export default SOI;
