export const ADD_DATASET = 'ADD_DATASET';
export const DELETE_DATASET = 'DELETE_DATASET';
export const SET_DATASETS = 'SET_DATASETS';
export const SET_SELECTED_DATASET = 'SET_SELECTED_DATASET';
export const SET_WELL_TIMELINE = 'SET_WELL_TIMELINE';
export const SET_INJ_WELLNAMES = 'SET_INJ_WELLNAMES';
export const SET_PROD_WELLNAMES = 'SET_PROD_WELLNAMES';
export const SET_DATASET_LAYERS = 'SET_DATASET_LAYERS';
export const SET_DATASET_PARAMETERS = 'SET_DATASET_PARAMETERS';
export const SET_DATASET_TIME_PERIOD = 'SET_DATASET_TIME_PERIOD';
