import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useStyles from '../../Styles/NavBarStyle';

import { USER_LOGOUT } from '../../redux/actions/user/types';

import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { reduxAction } from '../../redux/actions/base';

const Navbar = props => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // TODO: handler for contact us is not implemented yet.
  // Handlers -->
  const onLogout = () => {
    localStorage.removeItem('x-access-token');
    dispatch(reduxAction(USER_LOGOUT));
    navigate('/login');
  };
  const handlemenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlemenuClose = () => {
    setAnchorEl(null);
    // onLogout('/login');
  };
  // const navigateToPage = url => {
  //   navigate(url);
  // };
  // <-- Handlers

  if (props.loggedIn == false) {
    return (
      <Grid container className={styles.root}>
        {/* <Grid item className={styles.logoGrid}>
          <img className={styles.logo} src={logo} alt="Resermine_Logo" />
          <Typography className={styles.name}>CoreIntellect</Typography>
        </Grid> */}
      </Grid>
    );
  } else {
    return (
      <Grid container className={styles.root}>
        <Grid item className={styles.controlPanelGrid}>
          {/* <Button
            variant="contained"
            className={styles.controlButton}
            onClick={() => navigateToPage('/workspace')}
          >
            Workspace
          </Button>
          <Button
            disabled
            variant="contained"
            className={styles.controlButton}
            onClick={() => navigateToPage('/contactus')}
          >
            Contact Us
          </Button> */}
          <Button
            variant="contained"
            className={styles.controlButton}
            onClick={handlemenuOpen}
          >
            Profile
            <ExpandMoreIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handlemenuClose}
          >
            <MenuItem onClick={onLogout} className={styles.menuItem}>
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    );
  }
};

Navbar.propTypes = {
  loggedIn: PropTypes.bool,
};

export default Navbar;
