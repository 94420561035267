import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';
import moment from 'moment';

import { BasicTable } from '../../../../Components/TableView';
import LoadingPage from '../../../../Components/LoadingPage';
import useStyles from '../../../../../Styles/WorkflowStyles';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Link,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  FIELD,
  WELL_ID,
  PRODUCER,
  DATE,
  LAYER,
  OIL_PRODUCTION_RATE,
  INJECTOR,
  TOTAL_WELL_RATE,
  GAS_INJECTION_RATE,
  WATER_INJECTION_RATE,
  FLUID_INJECTION_RATE,
} from '../../../../../constants/WellConstants';

import { groupByAndApply } from '../../../../../Utils/DatasetUtils/DataProcessing';
import {
  createTrace,
  LINES,
  MARKERS,
  wrapPlots,
} from '../../../../../Utils/PlotlyUtils/Plots';

const Results = ({
  filteredDatasetContent,
  oilRateHistory,
  oilMaxWellData,
  IFactorMaxWellData,
  injWellNames,
  prodWellNames,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [producerOptions, setproducerOptions] = useState([]);
  const [injectorOptions, setinjectorOptions] = useState([]);
  const [selectedProd, setselectedProd] = useState('');
  const [selectedInj, setselectedInj] = useState('');

  const [oilProdRatePlot, setoilProdRatePlot] = useState(null);
  const [cumOilProdPlot, setcumOilProdPlot] = useState(null);
  const [fluidInjRatePlot, setfluidInjRatePlot] = useState(null);

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks/Helpers -->
  /**
   * This function finds cumulative sum of an array and returns a new Array.
   * @param {Array} arr
   * @returns {Array}
   */
  const findCumulativeSum = arr => {
    const creds = arr.reduce(
      (acc, val) => {
        let { sum, res } = acc;
        sum += Number(val);
        res.push(sum);
        return { sum, res };
      },
      {
        sum: 0,
        res: [],
      }
    );
    return creds.res;
  };

  /**
   * This function generates optimized producer oil rate plots. Oil Rate and Max Well datasets
   * gets filtered by DATE and their OIL_RATE values gets summed.
   * @param {Array} df
   * @param {Array} oilRateDf
   * @param {Array} oilMaxDf
   * @param {String} selectedProducer
   * @param {Array} layers
   * @returns {Object}
   */
  const generateOptimizationOilRatesPlot = (
    df,
    oilRateDf,
    oilMaxDf,
    selectedProducer,
    layers
  ) => {
    // Group datasets by date, and find aggragate Oil Rate values
    const aggOilRate = groupByAndApply(
      oilRateDf,
      DATE,
      (oilRate1, oilRate2) => Number(oilRate1) + Number(oilRate2),
      'Oil rate'
    );
    const aggMaxWellOilRate = groupByAndApply(
      oilMaxDf,
      DATE,
      (oilRate1, oilRate2) => Number(oilRate1) + Number(oilRate2),
      'Oil rate'
    );

    const minDate = moment.min(aggOilRate.map(row => moment(row[DATE])));
    const maxDate = moment.max(aggOilRate.map(row => moment(row[DATE])));

    const dateFilteredDf = df.filter(row =>
      moment(row[DATE]).isBetween(minDate, maxDate, undefined, '[]')
    );

    const traces = [
      createTrace(
        dateFilteredDf.map(row => row[DATE]),
        dateFilteredDf.map(row => Number(row[OIL_PRODUCTION_RATE])),
        selectedProducer + ': Data',
        undefined,
        undefined,
        undefined,
        MARKERS,
        5
      ),
      createTrace(
        aggOilRate.map(row => row[DATE]),
        aggOilRate.map(row => Number(row['Oil rate'])),
        selectedProducer + ' - Total' + ': CRM',
        undefined,
        undefined,
        undefined,
        LINES
      ),
      createTrace(
        aggMaxWellOilRate.map(row => row[DATE]),
        aggMaxWellOilRate.map(row => Number(row['Oil rate'])),
        selectedProducer + ' - Total' + ': Optimized',
        undefined,
        undefined,
        undefined,
        LINES
      ),
    ];

    layers.forEach(layer => {
      const df1 = oilRateDf.filter(row => row[LAYER] == layer);
      const df2 = oilMaxDf.filter(row => row[LAYER] == layer);
      traces.append(
        createTrace(
          df1.map(row => row[DATE]),
          df1.map(row => row['Oil rate']),
          selectedProducer + ' - ' + layer + ': CRM',
          undefined,
          undefined,
          undefined,
          LINES
        )
      );
      traces.append(
        createTrace(
          df2.map(row => row[DATE]),
          df2.map(row => row['Oil rate']),
          selectedProducer + ' - ' + layer + ': Optimized',
          undefined,
          undefined,
          undefined,
          LINES
        )
      );
    });

    return wrapPlots(traces, DATE, 'rb/month', OIL_PRODUCTION_RATE);
  };
  /**
   * This function generates cumulative optimized producer oil rate plots. Oil Rate and Max Well datasets
   * gets filtered by DATE and their OIL_RATE values gets summed.
   * @param {Array} df
   * @param {Array} oilRateDf
   * @param {Array} oilMaxDf
   * @param {String} selectedProducer
   * @returns {Object}
   */
  const generateCumulativeOilRatesPlot = (
    df,
    oilRateDf,
    oilMaxDf,
    selectedProducer
  ) => {
    const aggOilRate = groupByAndApply(
      oilRateDf,
      DATE,
      (oilRate1, oilRate2) => Number(oilRate1) + Number(oilRate2),
      'Oil rate'
    );
    const aggMaxWellOilRate = groupByAndApply(
      oilMaxDf,
      DATE,
      (oilRate1, oilRate2) => Number(oilRate1) + Number(oilRate2),
      'Oil rate'
    );

    const minDate = moment.min(aggOilRate.map(row => moment(row[DATE])));
    const maxDate = moment.max(aggOilRate.map(row => moment(row[DATE])));
    const dateFilteredDf = df.filter(row =>
      moment(row[DATE]).isBetween(minDate, maxDate, undefined, '[]')
    );

    const dfOilRateCumSum = findCumulativeSum(
      dateFilteredDf.map(row => row[OIL_PRODUCTION_RATE])
    ).map((value, index) => {
      return {
        [DATE]: dateFilteredDf[index][DATE],
        [OIL_PRODUCTION_RATE]: value,
      };
    });

    const totalOilRateCumSum = findCumulativeSum(
      aggOilRate.map(row => row['Oil rate'])
    ).map((value, index) => {
      return {
        [DATE]: aggOilRate[index][DATE],
        ['Oil rate']: value,
      };
    });
    const totalMaxWellOilRateCumSum = findCumulativeSum(
      aggMaxWellOilRate.map(row => row['Oil rate'])
    ).map((value, index) => {
      return {
        [DATE]: aggMaxWellOilRate[index][DATE],
        ['Oil rate']: value,
      };
    });

    const traces = [
      createTrace(
        dfOilRateCumSum.map(row => row[DATE]),
        dfOilRateCumSum.map(row => row[OIL_PRODUCTION_RATE]),
        selectedProducer + ': Data',
        undefined,
        undefined,
        undefined,
        MARKERS,
        5
      ),
      createTrace(
        totalOilRateCumSum.map(row => row[DATE]),
        totalOilRateCumSum.map(row => row['Oil rate']),
        selectedProducer + ' - Total' + ': CRM',
        undefined,
        undefined,
        undefined,
        LINES
      ),
      createTrace(
        totalMaxWellOilRateCumSum.map(row => row[DATE]),
        totalMaxWellOilRateCumSum.map(row => row['Oil rate']),
        selectedProducer + ' - Total' + ': Optimized',
        undefined,
        undefined,
        undefined,
        LINES
      ),
    ];

    return wrapPlots(
      traces,
      DATE,
      'rb/month',
      'Cumulative ' + OIL_PRODUCTION_RATE
    );
  };
  /**
   * This function generates producer optimization plots, "Oil Production Rate", and
   * "Cumulative Oil production".
   *
   * @param {String} selectedWellName
   */
  const generateOptimizationWellProducerResults = selectedWellName => {
    if (selectedWellName == FIELD) {
      // If user wants to analyize entire field, consider all values and group by date.
    } else {
      // If user wants to analyize specific well, filter by well.
      const wellFilteredDatasetContent = filteredDatasetContent.filter(
        row => row[WELL_ID] == selectedWellName
      );
      const filteredOilRateData = oilRateHistory.filter(
        row => row[PRODUCER] == selectedWellName
      );
      const filteredOilMaxWellData = oilMaxWellData.filter(
        row => row[PRODUCER] == selectedWellName
      );
      const oilProductionPlot = generateOptimizationOilRatesPlot(
        wellFilteredDatasetContent,
        filteredOilRateData,
        filteredOilMaxWellData,
        selectedWellName,
        []
      );
      const cumulativeOilProductionPlot = generateCumulativeOilRatesPlot(
        wellFilteredDatasetContent,
        filteredOilRateData,
        filteredOilMaxWellData,
        selectedWellName
      );
      setoilProdRatePlot(oilProductionPlot);
      setcumOilProdPlot(cumulativeOilProductionPlot);
    }
  };

  const generateOptimizationInjectionRatesPlot = (
    filteredDatasetContent,
    selectedInjector
  ) => {
    const filteredIFactorMaxWellDf = IFactorMaxWellData.filter(
      row => row[INJECTOR] == selectedInjector
    );
    const wellFilteredDatasetContent = filteredDatasetContent
      .filter(row => row[WELL_ID] == selectedInjector)
      .map(row => {
        return {
          ...row,
          [TOTAL_WELL_RATE]:
            Number(row[GAS_INJECTION_RATE]) + Number(row[WATER_INJECTION_RATE]),
        };
      });

    const totalDf = groupByAndApply(
      wellFilteredDatasetContent,
      DATE,
      (value1, value2) => value1 + value2,
      TOTAL_WELL_RATE
    )
      .map(row => {
        return {
          ...row,
          'Optimized Injection':
            filteredIFactorMaxWellDf[0]['Optimized Injection Rate(Krb)'] * 1000,
        };
      })
      .sort((a, b) => {
        if (moment(a[DATE]).isBefore(moment(b[DATE]))) {
          return -1;
        } else {
          return 1;
        }
      });

    const traces = [
      createTrace(
        totalDf.map(row => row[DATE]),
        totalDf.map(row => row[TOTAL_WELL_RATE]),
        selectedInjector + ' - Total' + ': Data',
        undefined,
        undefined,
        totalDf.map(row => row[TOTAL_WELL_RATE]),
        LINES
      ),
      createTrace(
        totalDf.map(row => row[DATE]),
        totalDf.map(row => row['Optimized Injection']),
        selectedInjector + ' - Total' + ': Optimized',
        undefined,
        undefined,
        totalDf.map(row => row['Optimized Injection']),
        LINES
      ),
    ];

    setfluidInjRatePlot(
      wrapPlots(traces, DATE, 'rb/month', FLUID_INJECTION_RATE)
    );
  };
  // <-- Callbacks/Handlers

  // Handlers -->
  const onProducerSelect = wellName => {
    setselectedProd(wellName);
    generateOptimizationWellProducerResults(wellName);
  };
  const onInjectorSelect = wellName => {
    setselectedInj(wellName);
    generateOptimizationInjectionRatesPlot(filteredDatasetContent, wellName);
  };
  const onDownload = dataset => {
    const content = [];

    dataset.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // Initialize the analysis and preselect wells.
  useEffect(() => {
    if (
      filteredDatasetContent != undefined &&
      oilRateHistory != undefined &&
      oilMaxWellData != undefined &&
      IFactorMaxWellData != undefined
    ) {
      setproducerOptions(prodWellNames);
      setinjectorOptions(injWellNames);

      onProducerSelect(prodWellNames[1]);
      onInjectorSelect(injWellNames[0]);
    }
  }, [
    filteredDatasetContent,
    oilRateHistory,
    oilMaxWellData,
    IFactorMaxWellData,
  ]);

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentFlex}>
        <Grid container className={styles.visualContentFlex}>
          <Grid item className={styles.visualContentRow}>
            <Typography>Producer Values</Typography>
          </Grid>
          <Grid item className={styles.inputContentRow}>
            <FormControl
              variant="outlined"
              size="small"
              className="dropdownSelection"
            >
              <Select
                value={selectedProd}
                onChange={event => onProducerSelect(event.target.value)}
              >
                {producerOptions.map(well => (
                  <MenuItem key={well} value={well}>
                    {well}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Producer Well</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className={styles.visualContent}>
            {oilProdRatePlot ? (
              <PlotlyComponent
                data={oilProdRatePlot.data}
                layout={oilProdRatePlot.layout}
                config={oilProdRatePlot.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message="Oil Rate Plot is loading..."
                goHome={false}
              />
            )}
          </Grid>
          <Grid item className={styles.visualContent}>
            {cumOilProdPlot ? (
              <PlotlyComponent
                data={cumOilProdPlot.data}
                layout={cumOilProdPlot.layout}
                config={cumOilProdPlot.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message="Cumulative Oil plot is loading..."
                goHome={false}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={styles.visualContentFlex}>
          <Grid item className={styles.visualContentRow}>
            <Typography>Injector Values</Typography>
          </Grid>
          <Grid item className={styles.inputContentRow}>
            <FormControl
              variant="outlined"
              size="small"
              className="dropdownSelection"
            >
              <Select
                value={selectedInj}
                onChange={event => onInjectorSelect(event.target.value)}
              >
                {injectorOptions.map(well => (
                  <MenuItem key={well} value={well}>
                    {well}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select an Injector</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className={styles.visualContent}>
            {fluidInjRatePlot ? (
              <PlotlyComponent
                data={fluidInjRatePlot.data}
                layout={fluidInjRatePlot.layout}
                config={fluidInjRatePlot.config}
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <LoadingPage
                message="Fluid Injection Rate plot is loading..."
                goHome={false}
              />
            )}
          </Grid>
          {IFactorMaxWellData ? (
            <React.Fragment>
              <Paper elevation={5} className={styles.visualContentRow}>
                <BasicTable data={IFactorMaxWellData} />
              </Paper>
              <Grid container className={styles.visualContentRow}>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="overline">Download Results</Typography>
                  <Link
                    download="I_Factor_Max_Well.csv"
                    href={encodedUri}
                    onClick={() => onDownload(IFactorMaxWellData)}
                  >
                    <IconButton>
                      <GetAppRoundedIcon />
                    </IconButton>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item className={styles.visualContentRow}>
              <LoadingPage
                message="Well data table is loading..."
                goHome={false}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Results.propTypes = {
  filteredDatasetContent: PropTypes.array,
  oilMaxWellData: PropTypes.array,
  oilRateHistory: PropTypes.array,
  IFactorMaxWellData: PropTypes.array,
  injWellNames: PropTypes.array,
  prodWellNames: PropTypes.array,
};

export default Results;
