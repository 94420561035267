import moment from 'moment';
import {
  CRM,
  DATA,
  GAIN,
  LOSS,
  XCOL,
  YCOL,
  DATE,
  WATER,
  GREEN,
  FIELD,
  WELL_ID,
  INJECTOR,
  PRODUCER,
  OIL_RATE,
  LIGHTBLUE,
  PER_MONTH,
  POWER_LAW,
  FLUID_RATE,
  OIL_FIT_MODEL,
  UI_DATE_FORMAT,
  UNIT_RES_FLUID,
  TOTAL_WELL_RATE,
  TRAIN_TEST_SPLIT,
  ADVANCED_POWER_LAW,
  GAS_INJECTION_RATE,
  OIL_PRODUCTION_RATE,
  WATER_INJECTION_RATE,
  FLUID_INJECTION_RATE,
  WATER_PRODUCTION_RATE,
  FLUID_PRODUCTION_RATE,
} from '../../constants/WellConstants';
import { DEFAULT_VALUE_TRAIN_TEST_SPLIT } from '../../constants/WorkflowsParameterConstants';
import { generateNetworkGraphWithLags, set_colors } from '../CommonReportUtil';
import {
  filterTopWells,
  filterTopWellsByValue,
  findGainValueFromDataset,
  filterTimelineFromDataset,
  filterWellNamesFromDataset,
  filterWellContentsFromDataset,
} from '../DatasetUtils/DataProcessing';
import {
  LINES_WITH_MARKERS,
  wrapPlots,
  createLinePlot,
  createPieChart,
  createNetworkGraph,
  createLinePlotTrace,
  plotWithVerticalLine,
} from '../PlotlyUtils/Plots';
import { getUnitRate } from '../ReservoirUtils';

export const I_RATE = 'i';
export const Q_RATE = 'q';
export const QO_RATE = 'qo';
export const PRESSURE = 'p';

export const generateNetworkGraphForWell = (
  df,
  gainValues,
  selectedWell,
  wellType,
  alikeWells,
  oppositeWells,
  minGain = 0.0,
  maxGain = 1.0
) => {
  const nodeData = [];
  let alikeWellIndex = 0;
  let alikeWellTypeRow = 0;

  oppositeWells.forEach(row => {
    nodeData.push({
      'Node Id': row[WELL_ID],
      'Node Type': wellType == PRODUCER ? INJECTOR : PRODUCER,
      'Node X': row[XCOL],
      'Node Y': row[YCOL],
      'Node Color': wellType == PRODUCER ? LIGHTBLUE : GREEN,
      'Node Symbol': wellType == PRODUCER ? 'triangle-up' : 'circle',
    });
  });

  alikeWells.every((row, index) => {
    if (row[WELL_ID] == selectedWell[WELL_ID]) {
      nodeData.push({
        'Node Id': row[WELL_ID],
        'Node Type': wellType == PRODUCER ? PRODUCER : INJECTOR,
        'Node X': row[XCOL],
        'Node Y': row[YCOL],
        'Node Color': wellType == PRODUCER ? GREEN : LIGHTBLUE,
        'Node Symbol': wellType == PRODUCER ? 'circle' : 'triangle-up',
      });

      alikeWellIndex = index;
      alikeWellTypeRow = row;
      return false;
    }
    return true;
  });

  const baseLineWidth = 10;
  const edgeData = [];

  oppositeWells.forEach((row, index) => {
    const gainValue =
      wellType == PRODUCER
        ? Object.values(gainValues[index])[alikeWellIndex]
        : Object.values(gainValues[alikeWellIndex])[index];

    let hoverText =
      `(${alikeWellTypeRow[XCOL]}, ${alikeWellTypeRow[YCOL]}), (${row[XCOL]}, ${row[YCOL]})` +
      ` = ${Math.round(gainValue * 100) / 100}`;

    const distance =
      Math.round(
        Math.sqrt(
          Math.pow(row[XCOL] - alikeWellTypeRow[XCOL], 2) +
            Math.pow(row[YCOL] - alikeWellTypeRow[YCOL], 2)
        ) * 100
      ) / 100;

    hoverText += `<br />Distance: ${distance}`;

    if (minGain <= gainValue && gainValue <= maxGain) {
      edgeData.push({
        'Source Node X': row[XCOL],
        'Source Node Y': row[YCOL],
        'Dest Node X': alikeWellTypeRow[XCOL],
        'Dest Node Y': alikeWellTypeRow[YCOL],
        'Edge Width': Math.abs(gainValue * baseLineWidth),
        'Edge HoverText': hoverText,
      });
    }
  });

  return createNetworkGraph(
    nodeData,
    edgeData,
    'Injectors-producers Gain Values'
  );
};

/**
 * This function finds correlation value between selected injector and all other producers, and plots them.
 * @param {Array} datasetContent
 * @param {Array} wellFilteredWorkflowAnalysisContent - Selected well filtered workflow analysis content
 * @param {String} selectedWellType
 * @param {Object} selectedWell
 * @param {Array} wellNames
 * @param {Number} minGain
 * @param {Number} maxGain
 * @returns
 */
export const generateWellNetworkGraph = (
  datasetContent,
  wellFilteredWorkflowAnalysisContent,
  selectedWellType,
  selectedWell,
  wellNames,
  minGain,
  maxGain,
  unitSystem = FIELD
) => {
  const title =
    selectedWellType == INJECTOR
      ? `${INJECTOR} ${PRODUCER}s Gain Values`
      : `${PRODUCER} ${INJECTOR}s Gain Values`;

  try {
    const selectedWellGainValues = wellNames.map(wellName => {
      let wellFeaturePair = {
        [INJECTOR]: selectedWellType == INJECTOR ? selectedWell : wellName,
        [PRODUCER]: selectedWellType == INJECTOR ? wellName : selectedWell,
        [GAIN]: '',
      };

      try {
        wellFeaturePair[GAIN] =
          wellFilteredWorkflowAnalysisContent[0][wellName] || '0.0';
      } catch (error) {
        wellFeaturePair[GAIN] = '0.0';
      }

      return wellFeaturePair;
    });

    return generateNetworkGraphWithLags(
      datasetContent,
      selectedWellGainValues,
      GAIN,
      minGain,
      maxGain,
      undefined,
      undefined,
      title,
      undefined,
      unitSystem
    );
  } catch (error) {
    return wrapPlots([], undefined, undefined, title);
  }
};

/**
 * This plot is responsible of generating line trace plot for the selected well and
 * every other secondary connected well which has a gain value > 0.
 *
 * @param {Array} dynamicGainValues
 * @param {Array} staticGainValues
 * @param {String} primaryWellGroup
 * @param {String} secondaryWellGroup
 * @param {String} selectedWell
 * @param {String} title
 * @param {String} xTitle
 * @param {String} yTitle
 * @returns {Object} - CreateLinePlot
 */
export const generateWellGainConnPlot = (
  dynamicGainValues,
  staticGainValues,
  primaryWellGroup = INJECTOR,
  secondaryWellGroup = PRODUCER,
  selectedWell,
  title,
  xTitle,
  yTitle
) => {
  if (dynamicGainValues.length < 1 || staticGainValues.length < 1)
    throw Error('Invalid Gain Value Dataset');
  if (primaryWellGroup === secondaryWellGroup)
    throw Error('Primary well group cannot be equal to secondary well group.');
  if (!Object.keys(staticGainValues[0]).includes(primaryWellGroup))
    throw Error('Gain value dataset format needs to be transposed.');

  const fieldTimeline = filterTimelineFromDataset(dynamicGainValues);
  const selectedWellData =
    staticGainValues.find(row => row[primaryWellGroup] === selectedWell) || {};

  // Find secondary connected wells that has gain value > 0.
  const connectedWells = Object.entries(selectedWellData)
    .filter(entry => entry[0] !== primaryWellGroup && entry[1] != 0)
    .map(entry => entry[0]);

  const connectedResults = [];
  // Save gain value of each connected well at every date.
  fieldTimeline.forEach(date => {
    const wellConnData = { [DATE]: date };

    connectedWells.forEach(well => {
      wellConnData[well] =
        dynamicGainValues.find(
          fieldData =>
            fieldData[DATE] === date &&
            fieldData[primaryWellGroup] === selectedWell &&
            fieldData[secondaryWellGroup] === well
        )?.[GAIN] || 0;
    });

    connectedResults.push(wellConnData);
  });

  return createLinePlot(
    connectedResults,
    DATE,
    connectedWells,
    connectedWells,
    undefined,
    undefined,
    xTitle ?? DATE,
    yTitle ?? 'Dynamic Connectivity',
    title ?? `Connected Well Gain Galues - ${selectedWell}`,
    undefined,
    undefined,
    undefined,
    undefined,
    LINES_WITH_MARKERS
  );
};
/**
 * FIXME: Total injection rate is going to be generated from the backend. Should
 * update this method afterwards.
 *
 * This function finds Total Fluid Injection Rate, (Gas + Water Injection Rate), and
 * Total Fluid Production Rate, (Water + Oil Production Rate).  Then plots them into a pie chart.
 * @param {Array} datasetContent
 * @param {Array} wellNames
 * @param {String} wellType
 * @returns {Object} - Plotly Data
 */
export const generateCumFluidPieChart = (
  wellFilteredDataset,
  wellType,
  unitSystem = FIELD
) => {
  try {
    let cumFluidValues = {};
    let properties =
      wellType == INJECTOR
        ? [WATER_INJECTION_RATE, GAS_INJECTION_RATE]
        : [WATER_PRODUCTION_RATE, OIL_PRODUCTION_RATE];

    wellFilteredDataset.forEach(wellData => {
      if (!(wellData[WELL_ID] in cumFluidValues)) {
        cumFluidValues[wellData[WELL_ID]] = 0;
      }
      cumFluidValues[wellData[WELL_ID]] +=
        parseFloat(wellData[properties[0]]) +
        parseFloat(wellData[properties[1]]);
    });

    if (Object.keys(cumFluidValues).length > 5) {
      cumFluidValues = filterTopWellsByValue(cumFluidValues, 5);
    }

    return createPieChart(
      Object.keys(cumFluidValues),
      Object.values(cumFluidValues),
      `Cumulative Fluid ${wellType == PRODUCER ? 'Production' : 'Injection'} (${
        UNIT_RES_FLUID[unitSystem]
      })`
    );
  } catch (error) {
    return wrapPlots(
      [],
      undefined,
      undefined,
      `Cumulative Fluid ${wellType == PRODUCER ? 'Production' : 'Injection'} (${
        UNIT_RES_FLUID[unitSystem]
      })`
    );
  }
};

/**
 * This function takes the ratio of gain values between selected well and
 * other wells in wellNames parameter, then plots them in a pie chart.
 * @param {Array} workflowAnalysisContent
 * @param {String} selectedWellName
 * @param {String} selectedWellType
 * @param {Array} wellNames
 * @returns {Object} - Plotly Data
 */
export const generateContributionPieChart = (
  gainValuesDataset,
  selectedWell,
  wellNames,
  wellType,
  unitSystem = FIELD
) => {
  try {
    let pieData = {};

    wellNames.forEach(wellName => {
      pieData[wellName] =
        wellType == INJECTOR
          ? findGainValueFromDataset(gainValuesDataset, selectedWell, wellName)
          : findGainValueFromDataset(gainValuesDataset, wellName, selectedWell);
    });
    // Merge smaller gain data that couldn't make it top 5.
    if (Object.keys(pieData).length > 5) {
      pieData = filterTopWellsByValue(pieData, 5);
    }
    // Add Loss column if present in dataset.
    if (LOSS in gainValuesDataset[0]) {
      pieData[LOSS] = gainValuesDataset[0][LOSS];
    }

    return createPieChart(
      Object.keys(pieData),
      Object.values(pieData),
      `${
        wellType == INJECTOR ? PRODUCER : INJECTOR
      } Contribution ${selectedWell} (${UNIT_RES_FLUID[unitSystem]})`
    );
  } catch (error) {
    return wrapPlots(
      [],
      undefined,
      undefined,
      `${
        wellType == INJECTOR ? PRODUCER : INJECTOR
      } Contribution ${selectedWell} (${UNIT_RES_FLUID[unitSystem]})`
    );
  }
};

export const getDfColNames = (
  componentType,
  wellNames,
  observedData = true
) => {
  let columns = [];
  const datatype = observedData ? DATA : CRM;
  if (componentType == I_RATE) {
    columns = wellNames.map(
      name => `${I_RATE}: ${name}: ${FLUID_RATE}` + datatype
    );
  } else if (componentType == Q_RATE) {
    columns = wellNames.map(name => `${name}: ${FLUID_RATE}` + datatype);
  } else if (componentType == PRESSURE) {
    columns = wellNames.map(
      name => `${PRESSURE}: ${name}: ${FLUID_RATE}` + datatype
    );
  } else if (componentType == QO_RATE) {
    columns = wellNames.map(name => `${name}: ${OIL_RATE}` + datatype);
  }
  return columns;
};

export const outputColumnNames = (dataset, fieldIncluded = false) => {
  let { producers: producerNames } = filterWellNamesFromDataset(dataset);
  let numProducers = fieldIncluded
    ? producerNames.length + 1
    : producerNames.length;

  let colors = set_colors(numProducers);

  let colsOilRateObserved = getDfColNames(QO_RATE, producerNames);
  let colsOilRateCrm = getDfColNames(QO_RATE, producerNames, false);
  let colsOilRateSum = [OIL_RATE + DATA, OIL_RATE + CRM];

  if (fieldIncluded) {
    colsOilRateObserved.push(OIL_RATE + DATA);
    colsOilRateCrm.push(OIL_RATE + CRM);
  }

  return {
    producerNames,
    colors,
    colsOilRateObserved,
    colsOilRateCrm,
    colsOilRateSum,
  };
};

export const getLegendsAndColor = (a, b, colors) => {
  const legendsOrder = [];
  const newColors = [];
  Object.keys(a).forEach((key, index) => {
    legendsOrder.push(a[index]);
    legendsOrder.push(b[index]);
    newColors.push(colors[index]);
    newColors.push(colors[index]);
  });
  return { legendsOrder, newColors };
};

export const generateRateConstraintTable = (
  dataset,
  maximumPotentialFactor = undefined
) => {
  const { injectors: injectorWellsContent } =
    filterWellContentsFromDataset(dataset);
  const { injectors: injectorNames } = filterWellNamesFromDataset(dataset);

  if (maximumPotentialFactor == undefined) {
    maximumPotentialFactor = new Array(injectorNames.length).fill(3.0);
  }

  const dataRows = [];
  injectorNames.forEach((wellName, index) => {
    let filteredInjectorDataset = injectorWellsContent.filter(
      row => row[WELL_ID] == wellName
    );
    let maxRate = Math.max(
      ...filteredInjectorDataset.map(row => row[TOTAL_WELL_RATE])
    );
    maxRate = Math.round(maxRate * 100) / 100;
    dataRows.push({
      [INJECTOR]: wellName,
      'Maximum rate': maxRate,
      'Maximum potential factor': maximumPotentialFactor[index],
      'Maximum potential rate': maxRate * maximumPotentialFactor[index],
    });
  });

  return dataRows;
};

export const generateOptimizeInjectionValue = (df, factors) => {
  let { injectors: injectorNames } = filterWellNamesFromDataset(df);

  const factorValueDf = {};
  injectorNames.forEach(name => {
    let factorValue = +factors[name];
    factorValueDf[name] = Math.round(factorValue * 100) / 100;
  });

  return [factorValueDf];
};

export const gainValueExtracter = workflowContent => {
  const gainValues = [];

  workflowContent.forEach(row => {
    Object.entries(row).forEach(pair => {
      pair[0] != INJECTOR && gainValues.push(pair[1]);
    });
  });

  return gainValues;
};

export const getWellPairFeatures1D = (
  workflowAnalysisContent,
  prodWellNames,
  featureName
) => {
  const wellGainValues = [];
  workflowAnalysisContent.forEach(injRow => {
    prodWellNames.forEach(prodWellName => {
      wellGainValues.push({
        [INJECTOR]: injRow[INJECTOR],
        [PRODUCER]: prodWellName,
        [featureName]: injRow[prodWellName] ? injRow[prodWellName] : '0.0',
      });
    });
  });
  return wellGainValues;
};

/**
 * Transforms an array of workflow data into a specific structure based on the selected date.
 * If the selected date is not provided, the function will use the most common date in the workflow content.
 *
 * @param {Array} workflowContent - The workflow content to transform. Each object in the array should have
 * the following structure: { "injector name", "producer name", "date", "gain value" }.
 * @param {string} [selectedDate] - The selected date to filter the workflow content by. If not provided,
 * the function will use the most common date in the workflow content.
 * @returns {Object} An object containing the selected date and an array of objects, each representing an
 * injector. Each object has a key for the injector name and a key for each producer. The value for each
 * producer key is the gain value for that producer on the selected date, or 0 if no gain value is available.
 *
 * @example
 * const workflowContent = [
 *   { "injector name": "i1", "producer name": "p1", "date": "2022-01-01", "gain value": 10 },
 *   { "injector name": "i1", "producer name": "p2", "date": "2022-01-01", "gain value": 20 },
 *   { "injector name": "i2", "producer name": "p1", "date": "2022-01-01", "gain value": 30 },
 * ];
 * const selectedDate = "2022-01-01";
 * const result = extractGainValuesByDate(workflowContent, selectedDate);
 * // result: { selectedDate: "2022-01-01", result: [
 * //   { "injector name": "i1", "p1": 10, "p2": 20 },
 * //   { "injector name": "i2", "p1": 30, "p2": 0 },
 * // ]}
 */
export function extractGainValuesByDate(
  workflowContent,
  selectedDate = undefined,
  primaryWellGroup = INJECTOR
) {
  const secondaryWellGroup =
    primaryWellGroup === INJECTOR ? PRODUCER : INJECTOR;

  // If selectedDate is not defined, find the most common date
  if (!selectedDate) {
    const dateCounts = workflowContent.reduce((acc, item) => {
      acc[item[DATE]] = (acc[item[DATE]] || 0) + 1;
      return acc;
    }, {});

    selectedDate = Object.keys(dateCounts).reduce((a, b) =>
      dateCounts[a] > dateCounts[b] ? a : b
    );
  }

  // Filter data by selected date
  const filteredData = workflowContent.filter(item =>
    moment(item[DATE], UI_DATE_FORMAT).isSame(
      moment(selectedDate, UI_DATE_FORMAT),
      'month'
    )
  );

  // Get all unique producer names
  const secondaryWellNames = [
    ...new Set(filteredData.map(item => item[secondaryWellGroup])),
  ];

  const result = [];
  // Create an object for each injector
  filteredData.forEach(item => {
    // If the injector is not already in result files,
    if (!result.find(row => row[primaryWellGroup] === item[primaryWellGroup])) {
      const obj = { [primaryWellGroup]: item[primaryWellGroup] };

      // For each producer, add a key to the object with the gain value
      secondaryWellNames.forEach(well => {
        const secondaryWellDate = filteredData.find(
          i =>
            i[primaryWellGroup] === item[primaryWellGroup] &&
            i[secondaryWellGroup] === well
        );
        obj[well] = secondaryWellDate ? secondaryWellDate[GAIN] : 0;
      });

      result.push(obj);
    }
  });

  return { selectedDate, result };
}

/**
 * Inverses the gain value dataset based on primary and secondary well groups.
 * @param {Array} gainValues - The array of gain values.
 * @param {string} primaryWellGroup - The primary well group. Default is INJECTOR.
 * @param {string} secondaryWellGroup - The secondary well group. Default is PRODUCER.
 * @returns {Array} - The inversed gain value dataset.
 * @throws {Error} - If the gainValues array is empty or if primaryWellGroup is equal to secondaryWellGroup.
 */
export const inverseGainValueDataset = (
  gainValues,
  primaryWellGroup = INJECTOR,
  secondaryWellGroup = PRODUCER
) => {
  if (gainValues.length < 1) throw Error('Empty Gain Values');
  if (primaryWellGroup === secondaryWellGroup)
    throw Error('Primary well group cannot be equal to secondary well group');

  const inversedGainValues = [];
  const secondaryWellNames = Object.keys(gainValues[0]).filter(
    key => key !== INJECTOR && key !== GAIN && key !== LOSS
  );

  secondaryWellNames.forEach(secondaryWell => {
    const inversedRow = { [secondaryWellGroup]: secondaryWell };
    gainValues.forEach(row => {
      inversedRow[row[primaryWellGroup]] = row[secondaryWell] || 0;
    });
    inversedGainValues.push(inversedRow);
  });

  return inversedGainValues;
};

/**
 * If the feature is WATER, it filters the gainValues array to include only rows where the INJECTOR ends with '_w'.
 * If the feature is not WATER, it filters the gainValues array to include only rows where the INJECTOR ends with '_g'.
 *
 * @param {Array} gainValues - The array of gain values to filter.
 * @param {string} [feature=WATER] - The feature to filter by. Defaults to WATER.
 * @returns {Array} - The filtered array of gain values.
 */
export const filterInjectorsByWAG = (gainValues, feature = WATER) => {
  return feature == WATER
    ? gainValues.filter(row => row[INJECTOR].endsWith('_w'))
    : gainValues.filter(row => row[INJECTOR].endsWith('_g'));
};

export const genDynamicGainValuePlot = ({
  dynamicGainValues = [],
  selectedWell = '',
  primaryWellGroup = INJECTOR,
  title = 'Dynamic Gain Values',
  xTitle = DATE,
  yTitle = GAIN,
}) => {
  const traces = [];
  const wellData = {};
  const secondaryWellGroup =
    primaryWellGroup === INJECTOR ? PRODUCER : INJECTOR;
  // Extract connected wells gain values.
  dynamicGainValues.forEach(row => {
    if (!wellData[row[secondaryWellGroup]]) {
      wellData[row[secondaryWellGroup]] = {
        x: [],
        y: [],
        name: row[secondaryWellGroup],
      };
    }

    wellData[row[secondaryWellGroup]].x.push(row[DATE]);
    wellData[row[secondaryWellGroup]].y.push(row[GAIN]);
  });

  Object.entries(wellData).forEach(([key, value]) => {
    traces.push(
      createLinePlotTrace(
        value.x,
        value.y,
        key,
        undefined,
        undefined,
        undefined,
        LINES_WITH_MARKERS
      )
    );
  });

  return wrapPlots(
    traces,
    xTitle ?? DATE,
    yTitle ?? 'Dynamic Connectivity',
    title ?? `Connected Well Gain Galues - ${selectedWell}`
  );
};

export const genCumFluidPieChart = ({
  cumFluidRates = [],
  wellType = INJECTOR,
  unitSystem = FIELD,
}) => {
  const fluidRate =
    wellType == INJECTOR ? FLUID_INJECTION_RATE : FLUID_PRODUCTION_RATE;

  if (cumFluidRates.length > 5) {
    cumFluidRates = filterTopWells(cumFluidRates, WELL_ID, fluidRate);
  }

  return createPieChart(
    cumFluidRates.map(row => row[WELL_ID]),
    cumFluidRates.map(row => row[fluidRate]),
    `Cumulative Fluid ${wellType == PRODUCER ? 'Production' : 'Injection'} (${
      UNIT_RES_FLUID[unitSystem]
    })`
  );
};

export const genGainValuePieChart = ({
  selectedWell = '',
  gainValues = [],
  wellType = INJECTOR,
  unitSystem = FIELD,
}) => {
  const secondaryWellGroup = wellType === INJECTOR ? PRODUCER : INJECTOR;

  if (gainValues.length > 5) {
    gainValues = filterTopWells(gainValues, secondaryWellGroup, GAIN);
  }

  return createPieChart(
    gainValues.map(row => row[secondaryWellGroup]),
    gainValues.map(row => row[GAIN]),
    `${
      wellType == INJECTOR ? PRODUCER : INJECTOR
    } Contribution - ${selectedWell} (${UNIT_RES_FLUID[unitSystem]})`
  );
};

export const getFluidProductionProducers = fluidProductionData => [
  ...new Set(
    Object.keys(fluidProductionData[0])
      .filter(val => val.includes(FLUID_RATE))
      .map(val => val.split(':')[0])
  ),
];

export const generateFieldProductionData = (fluidProductionData, prods) => {
  const colsFluidRateObserved = getDfColNames(Q_RATE, prods);
  const colsFluidRateCrm = getDfColNames(Q_RATE, prods, false);

  const fieldFluidData = fluidProductionData.map(row => {
    const fieldObservedSum = colsFluidRateObserved
      .map(colname => row[colname])
      .reduce((prev, curr) => Number(prev) + (Number(curr) || 0), 0);
    const fieldCRMSum = colsFluidRateCrm
      .map(colname => row[colname])
      .reduce((prev, curr) => Number(prev) + (Number(curr) || 0), 0);
    return {
      ...row,
      [`${FIELD}: ${FLUID_RATE + CRM}`]: fieldCRMSum,
      [`${FIELD}: ${FLUID_RATE + DATA}`]: fieldObservedSum,
    };
  });

  return fieldFluidData;
};

export const generateFluidProductionPlot = ({
  fieldFluidProductionData,
  workflowParameters,
  prods,
  unitSystem = FIELD,
  timePeriod = PER_MONTH,
}) => {
  try {
    const colsFluidRateObserved = getDfColNames(Q_RATE, [FIELD, ...prods]);
    const colsFluidRateCrm = getDfColNames(Q_RATE, [FIELD, ...prods], false);
    const fluidRateColors = set_colors([FIELD, ...prods].length);
    const { legendsOrder, newColors } = getLegendsAndColor(
      colsFluidRateObserved,
      colsFluidRateCrm,
      fluidRateColors
    );
    const trainSize =
      workflowParameters[TRAIN_TEST_SPLIT] || DEFAULT_VALUE_TRAIN_TEST_SPLIT;
    const seperationDate =
      fieldFluidProductionData[
        parseInt(fieldFluidProductionData.length * trainSize)
      ][DATE];

    return plotWithVerticalLine(
      fieldFluidProductionData,
      DATE,
      legendsOrder,
      legendsOrder,
      colsFluidRateObserved,
      newColors,
      undefined,
      DATE,
      `${FLUID_PRODUCTION_RATE} (${getUnitRate(
        unitSystem,
        FLUID_PRODUCTION_RATE,
        timePeriod
      )})`,
      FLUID_PRODUCTION_RATE,
      undefined,
      undefined,
      undefined,
      undefined,
      seperationDate
    );
  } catch (error) {
    return wrapPlots(
      [],
      DATE,
      `${FLUID_PRODUCTION_RATE} (${getUnitRate(
        unitSystem,
        FLUID_PRODUCTION_RATE,
        timePeriod
      )})`,
      FLUID_PRODUCTION_RATE
    );
  }
};

export const generateOilProductionPlot = (
  workflowParameters,
  oilMatchingData,
  colsOilRateObserved,
  colsOilRateCrm,
  colors,
  unitSystem = FIELD,
  timePeriod = PER_MONTH
) => {
  const title =
    workflowParameters[OIL_FIT_MODEL] == POWER_LAW
      ? `${OIL_PRODUCTION_RATE} (${POWER_LAW})`
      : `${OIL_PRODUCTION_RATE} (${ADVANCED_POWER_LAW})`;
  try {
    const { legendsOrder, newColors } = getLegendsAndColor(
      colsOilRateObserved,
      colsOilRateCrm,
      colors
    );

    const trainSize =
      workflowParameters?.[TRAIN_TEST_SPLIT] || DEFAULT_VALUE_TRAIN_TEST_SPLIT;
    const seperationDate =
      oilMatchingData[parseInt(oilMatchingData.length * trainSize)][DATE];

    return plotWithVerticalLine(
      oilMatchingData,
      DATE,
      legendsOrder,
      legendsOrder,
      colsOilRateObserved,
      newColors,
      undefined,
      DATE,
      `${OIL_PRODUCTION_RATE} (${getUnitRate(
        unitSystem,
        OIL_PRODUCTION_RATE,
        timePeriod
      )})`,
      title,
      undefined,
      undefined,
      undefined,
      undefined,
      seperationDate
    );
  } catch (error) {
    return wrapPlots(
      [],
      DATE,
      `${OIL_PRODUCTION_RATE} (${getUnitRate(
        unitSystem,
        OIL_PRODUCTION_RATE,
        timePeriod
      )})`,
      title
    );
  }
};

export const futureOilProdOutputColumnNames = (
  producerNames = [],
  fieldIncluded = false
) => {
  let numProducers = fieldIncluded
    ? producerNames.length + 1
    : producerNames.length;

  let colors = set_colors(numProducers);

  let colsOilRateObserved = getDfColNames(QO_RATE, producerNames);
  let colsOilRateCrm = getDfColNames(QO_RATE, producerNames, false);
  let colsOilRateSum = [OIL_RATE + DATA, OIL_RATE + CRM];

  if (fieldIncluded) {
    colsOilRateObserved.push(OIL_RATE + DATA);
    colsOilRateCrm.push(OIL_RATE + CRM);
  }

  return {
    producerNames,
    colors,
    colsOilRateObserved,
    colsOilRateCrm,
    colsOilRateSum,
  };
};
