import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../../../Styles/WorkflowStyles';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import { generateNetworkGraphWithLags } from '../../../../Utils/CommonReportUtil';

import LoadingPage from '../../../Components/LoadingPage';

import { Grid, Typography } from '@material-ui/core';

import {
  PRODUCER,
  OVERALL_SCORE,
  FEATURE_IMPORTANCE,
} from '../../../../constants/WellConstants';
import CustomSlider from '../../../Components/WorkflowAnalysisTools/CustomSlider';

const FieldNetworkGraph = ({
  filteredDatasetContent,
  workflowAnalysisContent,
  calculationScores,
  minImp,
  maxImp,
  minWellScore,
  maxWellScore,
  header,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);
  const [impSliderValue, setimpSliderValue] = useState(null);
  const [filteredWorkflowAnalysisContent, setfilteredWorkflowAnalysisContent] =
    useState(workflowAnalysisContent);

  // Callbacks/Helpers -->
  const updateNetworkGraph = (
    minImp,
    maxImp,
    filteredWorkflowAnalysisContent
  ) => {
    setplot(
      generateNetworkGraphWithLags(
        filteredDatasetContent,
        filteredWorkflowAnalysisContent,
        FEATURE_IMPORTANCE,
        minImp,
        maxImp,
        undefined,
        undefined,
        `Injectors-Producers ${FEATURE_IMPORTANCE}`
      )
    );
  };

  const filterworkflowAnalysisContentByProdScore = (minScore, maxScore) => {
    const filteredProducerNames = calculationScores
      .filter(
        row => row[OVERALL_SCORE] >= minScore && row[OVERALL_SCORE] <= maxScore
      )
      .map(row => row[PRODUCER]);

    return workflowAnalysisContent.filter(row =>
      filteredProducerNames.includes(row[PRODUCER])
    );
  };
  // <-- Callbacks/Helpers

  // Handlers -->
  const wellScoreSliderHandler = newValue => {
    const filteredWorkflowAnalysisContent =
      filterworkflowAnalysisContentByProdScore(newValue[0], newValue[1]);
    setfilteredWorkflowAnalysisContent(filteredWorkflowAnalysisContent);
    updateNetworkGraph(
      impSliderValue[0],
      impSliderValue[1],
      filteredWorkflowAnalysisContent
    );
  };

  const impSliderHandler = newValue => {
    setimpSliderValue(newValue);
    updateNetworkGraph(
      newValue[0],
      newValue[1],
      filteredWorkflowAnalysisContent
    );
  };

  // <-- Handlers

  // Slider value side effect.
  useEffect(() => {
    if (
      filteredDatasetContent &&
      workflowAnalysisContent &&
      calculationScores &&
      minImp != undefined &&
      maxImp != undefined &&
      minWellScore != undefined &&
      maxWellScore != undefined
    ) {
      setimpSliderValue([minImp, maxImp]);

      updateNetworkGraph(
        minImp,
        maxImp,
        filterworkflowAnalysisContentByProdScore(minWellScore, maxWellScore)
      );
    }
  }, [
    filteredDatasetContent,
    workflowAnalysisContent,
    calculationScores,
    minImp,
    maxImp,
    minWellScore,
    maxWellScore,
  ]);

  return (
    <Grid id={header} container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Well Score Range</Typography>
          <CustomSlider
            disable={minWellScore == maxWellScore}
            min={minWellScore}
            max={maxWellScore}
            onChangeCommitted={wellScoreSliderHandler}
          />
        </Grid>
        <br />
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Typography gutterBottom>Feature Importance Range</Typography>
          <CustomSlider
            disable={minImp == maxImp}
            min={minImp}
            max={maxImp}
            onChangeCommitted={impSliderHandler}
          />
        </Grid>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Feature Importance Plot is Loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

FieldNetworkGraph.propTypes = {
  filteredDatasetContent: PropTypes.array,
  workflowAnalysisContent: PropTypes.array,
  calculationScores: PropTypes.array,
  minImp: PropTypes.number,
  maxImp: PropTypes.number,
  minWellScore: PropTypes.number,
  maxWellScore: PropTypes.number,
  header: PropTypes.string,
};

export default FieldNetworkGraph;
