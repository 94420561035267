import React, { useState } from 'react';
import useStyles from '../../../../../../Styles/WorkflowStyles';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingPlot from '../../../../../Components/LoadingPlot';
import { BasicTable } from '../../../../../Components/TableView';

import { generateCombinedLineTracePlot } from '../../../../../../Utils/PlotlyUtils/Plots';
import { getUnitRate } from '../../../../../../Utils/ReservoirUtils';

import { Grid, IconButton, Paper, Typography, Link } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import {
  OIL,
  DATE,
  RATE,
  PER_DAY,
  SYSTEM_OF_UNITS,
  TRAIN_TEST_SPLIT,
  CALC_TOTAL_OIL_RATE,
  ACTUAL_TOTAL_CUM_OIL,
  ACTUAL_TOTAL_OIL_RATE,
  CALC_TOTAL_CUM_OIL_OPT,
  CALC_TOTAL_OIL_RATE_OPT,
  ACTUAL_TOTAL_CUM_OIL_ORG,
  CALC_TOTAL_CUM_OIL_FTR_YRS,
  CALC_TOTAL_OIL_RATE_FTR_YRS,
} from '../../../../../../constants/WellConstants';
import { DEFAULT_VALUE_TRAIN_TEST_SPLIT } from '../../../../../../constants/WorkflowsParameterConstants';
import { useMemo } from 'react';

const FuturePrediction = ({
  productionData,
  futureProductionData,
  futureInjSens,
  injectionRates,
  workflowParameters,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const isCombOilRatePlotDisabled = false;
  const isCumRateDisabled = false;

  const [encodedUri, setencodedUri] = useState(null);

  // Callbacks -->
  const findSeperationDate = (
    data,
    trainSize = DEFAULT_VALUE_TRAIN_TEST_SPLIT
  ) => {
    return data?.[parseInt(data.length * trainSize)]?.[DATE];
  };
  // <-- Callbacks

  // Handlers -->
  const onDownload = arrayFileContent => {
    const content = [];

    arrayFileContent.forEach(row => {
      if (content.length === 0) {
        content.push('"' + Object.keys(row).join('","') + '"');
      }
      content.push('"' + Object.values(row).join('","') + '"');
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + content.join('\n');
    setencodedUri(encodeURI(csvContent));
  };
  // <-- Handlers

  // hooks -->
  const cumOilRatePlot = useMemo(() => {
    if (productionData?.length && futureProductionData?.length) {
      const seperationDate = findSeperationDate(
        productionData,
        workflowParameters?.[TRAIN_TEST_SPLIT]
      );
      const lastProductionDate = productionData.at(-1)?.[DATE];

      return generateCombinedLineTracePlot(
        productionData,
        futureProductionData,
        DATE,
        [ACTUAL_TOTAL_CUM_OIL, ACTUAL_TOTAL_CUM_OIL_ORG],
        [CALC_TOTAL_CUM_OIL_FTR_YRS, CALC_TOTAL_CUM_OIL_OPT],
        [ACTUAL_TOTAL_CUM_OIL, ACTUAL_TOTAL_CUM_OIL_ORG],
        [CALC_TOTAL_CUM_OIL_FTR_YRS, CALC_TOTAL_CUM_OIL_OPT],
        DATE,
        `Field Cum. Oil (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          OIL,
          PER_DAY
        )})`,
        'Field Cum. Oil',
        [seperationDate, lastProductionDate]
      );
    }
  }, [productionData, futureProductionData]);

  const combOilRatePlot = useMemo(() => {
    if (productionData?.length && futureProductionData?.length) {
      const seperationDate = findSeperationDate(
        productionData,
        workflowParameters?.[TRAIN_TEST_SPLIT]
      );
      const lastProductionDate = productionData.at(-1)?.[DATE];

      return generateCombinedLineTracePlot(
        productionData,
        futureProductionData,
        DATE,
        [ACTUAL_TOTAL_OIL_RATE, CALC_TOTAL_OIL_RATE],
        [CALC_TOTAL_OIL_RATE_FTR_YRS, CALC_TOTAL_OIL_RATE_OPT],
        [ACTUAL_TOTAL_OIL_RATE, CALC_TOTAL_OIL_RATE],
        [CALC_TOTAL_OIL_RATE_FTR_YRS, CALC_TOTAL_OIL_RATE_OPT],
        DATE,
        `${OIL} ${RATE} (${getUnitRate(
          workflowParameters[SYSTEM_OF_UNITS],
          OIL,
          PER_DAY
        )})`,
        `${OIL} ${RATE}`,
        [seperationDate, lastProductionDate]
      );
    }
  }, [productionData, futureProductionData]);
  // <-- hooks

  return (
    <React.Fragment>
      <Grid container className={styles.visualContentRow}>
        <Typography variant="h5">
          Future Injection Efficiency & Optimization
        </Typography>
      </Grid>
      {futureInjSens?.length > 0 && (
        <Grid container className={styles.visualContentFlex}>
          <Grid container className={styles.visualContentRow}>
            <Typography>Future Injection Sensitivity</Typography>
          </Grid>
          <Grid container className={styles.visualContentRow}>
            <Paper elevation={5}>
              <BasicTable data={futureInjSens} />
            </Paper>
          </Grid>
          <Grid container className={styles.visualContentRow}>
            <Grid
              item
              container
              xs={6}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="overline">
                Download Future Injection Sensitivity
              </Typography>
              <Link
                download="futureInjSens.csv"
                href={encodedUri}
                onClick={() => onDownload(futureInjSens)}
              >
                <IconButton>
                  <GetAppRoundedIcon />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isCombOilRatePlotDisabled && (
        <Grid container className={styles.visualContent}>
          {combOilRatePlot ? (
            <PlotlyComponent
              data={combOilRatePlot.data}
              layout={combOilRatePlot.layout}
              config={combOilRatePlot.config}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <LoadingPlot />
          )}
        </Grid>
      )}
      {!isCumRateDisabled && (
        <Grid container className={styles.visualContent}>
          {cumOilRatePlot ? (
            <PlotlyComponent
              data={cumOilRatePlot.data}
              layout={cumOilRatePlot.layout}
              config={cumOilRatePlot.config}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <LoadingPlot />
          )}
        </Grid>
      )}
      {injectionRates?.length > 0 && (
        <Grid container className={styles.visualContentFlex}>
          <Grid container className={styles.visualContentRow}>
            <Typography>Injection Rates</Typography>
          </Grid>
          <React.Fragment>
            <Grid container className={styles.visualContentRow}>
              <Paper elevation={5}>
                <BasicTable data={injectionRates} />
              </Paper>
            </Grid>
            <Grid container className={styles.visualContentRow}>
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline">
                  Download Injection Rattes
                </Typography>
                <Link
                  download="injection_rates.csv"
                  href={encodedUri}
                  onClick={() => onDownload(injectionRates)}
                >
                  <IconButton>
                    <GetAppRoundedIcon />
                  </IconButton>
                </Link>
              </Grid>
            </Grid>
          </React.Fragment>
        </Grid>
      )}
    </React.Fragment>
  );
};

FuturePrediction.propTypes = {
  productionData: PropTypes.array,
  futureProductionData: PropTypes.array,
  futureInjSens: PropTypes.array,
  injectionRates: PropTypes.array,
  workflowParameters: PropTypes.object,
};

export default FuturePrediction;
