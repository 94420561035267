import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Grid,
  Paper,
  Button,
  Dialog,
  ListItem,
  Checkbox,
  makeStyles,
  Typography,
  IconButton,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  DialogContent,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { INJECTOR, PRODUCER } from '../../constants/WellConstants';

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      fontWeight: '600',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',

    '& .body': {
      flex: '5 1 auto',
    },
    '& .paper': {
      margin: '2.5vh',

      maxHeight: '45vh',
      flex: '1 1 auto',
      overflow: 'auto',
    },
    '& .action': {
      flex: '1 1 auto',
      flexDirection: 'row-reverse',

      '& .button': {
        margin: '1.5vh 2.5vh',
      },
    },
  },
  listRoot: {
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    '& .listSection': {
      backgroundColor: 'inherit',
    },
  },
});

const WellSelectionModal = ({
  toggle,
  modalState,
  configObject,
  applyConfigurations,
}) => {
  const styles = useStyles();

  const [injectors, setinjectors] = useState({});
  const [producers, setproducers] = useState({});
  const [selectAllInjWells, setselectAllInjWells] = useState(false);
  const [selectAllProdWells, setselectAllProdWells] = useState(false);
  const [deselectAllInjWells, setdeselectAllInjWells] = useState(false);
  const [deselectAllProdWells, setdeselectAllProdWells] = useState(false);

  // Callbacks -->
  const saveConfigDetails = config => {
    setinjectors(config[INJECTOR].wellNamePairs);
    setproducers(config[PRODUCER].wellNamePairs);
  };
  // <-- Callbacks

  // Handlers -->
  const onSelectWell = wellName => {
    if (wellName in injectors) {
      setinjectors(previous => {
        return { ...previous, [wellName]: !previous[wellName] };
      });
      setselectAllInjWells(false);
      setdeselectAllInjWells(false);
    } else {
      setproducers(previous => {
        return { ...previous, [wellName]: !previous[wellName] };
      });
      setselectAllProdWells(false);
      setdeselectAllProdWells(false);
    }
  };

  const isSelectingAllWells = (selectFlag, wellType) => {
    if (wellType === 'Injectors') {
      setinjectors(previous => {
        return Object.keys(previous).reduce((acc, wellName) => {
          acc[wellName] = selectFlag;
          return acc;
        }, {});
      });

      setselectAllInjWells(selectFlag);
      setdeselectAllInjWells(!selectFlag);
    } else {
      setproducers(previous => {
        return Object.keys(previous).reduce((acc, wellName) => {
          acc[wellName] = selectFlag;
          return acc;
        }, {});
      });

      setselectAllProdWells(selectFlag);
      setdeselectAllProdWells(!selectFlag);
    }
  };

  const onClose = () => {
    saveConfigDetails(configObject);
    setselectAllInjWells(false);
    setdeselectAllInjWells(false);
    setselectAllProdWells(false);
    setdeselectAllProdWells(false);
    toggle();
  };

  const onApply = () => {
    const config = {
      [INJECTOR]: {
        wellNamePairs: injectors,
      },
      [PRODUCER]: {
        wellNamePairs: producers,
      },
    };
    applyConfigurations(config);
    toggle();
  };
  // Handlers <--

  useEffect(() => {
    saveConfigDetails(configObject);
  }, [configObject]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={modalState} onClose={onClose}>
      <DialogTitle disableTypography className={styles.dialogTitle}>
        <Typography className="title">Well Selections</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Grid container direction="row">
          <WellSelector
            styles={styles}
            wells={producers}
            wellType="Producers"
            selectAllWells={selectAllProdWells}
            deselectAllWells={deselectAllProdWells}
            isSelectingAllWells={isSelectingAllWells}
            onSelectWell={onSelectWell}
          />
          <WellSelector
            styles={styles}
            wells={injectors}
            wellType="Injectors"
            selectAllWells={selectAllInjWells}
            deselectAllWells={deselectAllInjWells}
            isSelectingAllWells={isSelectingAllWells}
            onSelectWell={onSelectWell}
          />
        </Grid>
        <Grid item container className="action">
          <Button variant="contained" className="button" onClick={onApply}>
            Apply
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

WellSelectionModal.propTypes = {
  toggle: PropTypes.func,
  modalState: PropTypes.bool,
  configObject: PropTypes.object,
  applyConfigurations: PropTypes.func,
};

export default WellSelectionModal;

const WellSelector = ({
  styles,
  wells,
  wellType,
  selectAllWells,
  deselectAllWells,
  isSelectingAllWells,
  onSelectWell,
}) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = event => {
    setSearchInput(event.target.value.toLowerCase());
  };

  const filteredWells = Object.entries(wells).filter(([isChecked]) =>
    isChecked.toLowerCase().includes(searchInput)
  );

  return (
    <Grid item style={{ flex: '1 1 auto' }}>
      <Paper className="paper" elevation={20}>
        <List className={styles.listRoot}>
          <ListSubheader>Select {wellType}</ListSubheader>
          <ListItem
            dense
            button
            onClick={() => isSelectingAllWells(true, wellType)}
          >
            <ListItemIcon>
              <Checkbox checked={selectAllWells} />
            </ListItemIcon>
            <ListItemText primary="Select all" />
          </ListItem>
          <ListItem
            dense
            button
            onClick={() => isSelectingAllWells(false, wellType)}
          >
            <ListItemIcon>
              <Checkbox checked={deselectAllWells} />
            </ListItemIcon>
            <ListItemText primary="Deselect all" />
          </ListItem>
          <ListItem>
            <TextField
              label="Search Wells"
              margin="normal"
              variant="standard"
              value={searchInput}
              onChange={handleSearch}
            />
          </ListItem>
          {filteredWells.map(([wellName, isChecked]) => (
            <ListItem
              key={`prod-name-${wellName}`}
              name={wellName}
              dense
              button
              onClick={() => onSelectWell(wellName)}
            >
              <ListItemIcon>
                <Checkbox checked={isChecked} id={wellName} />
              </ListItemIcon>
              <ListItemText primary={wellName} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

WellSelector.propTypes = {
  styles: PropTypes.object,
  wells: PropTypes.object,
  wellType: PropTypes.string,
  selectAllWells: PropTypes.bool,
  deselectAllWells: PropTypes.bool,
  isSelectingAllWells: PropTypes.func,
  onSelectWell: PropTypes.func,
};
