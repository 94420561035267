import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';

import {
  LINES,
  createLinePlotTrace,
  wrapPlots,
} from '../../../../Utils/PlotlyUtils/Plots';

import {
  WATER_CUT,
  OIL_CUT,
  WELL_ID,
  DATE,
} from '../../../../constants/WellConstants';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import useStyles from '../../../../Styles/WorkflowStyles';
import LoadingPage from '../../../Components/LoadingPage';

const ProductionCut = ({
  workflowAnalysisContent,
  prodWellNames,
  headerName,
}) => {
  const styles = useStyles();
  const PlotlyComponent = createPlotlyComponent(Plotly);

  const [plot, setplot] = useState(null);
  const [productionOption, setproductionOption] = useState(WATER_CUT);

  useEffect(() => {
    const traces = [];

    prodWellNames.forEach(well => {
      traces.push(
        createLinePlotTrace(
          workflowAnalysisContent
            .filter(row => row[WELL_ID] == well)
            .map(prod => prod[DATE]),
          workflowAnalysisContent
            .filter(row => row[WELL_ID] == well)
            .map(prod => prod[productionOption]),
          well,
          undefined,
          undefined,
          undefined,
          LINES,
          undefined
        )
      );
    });
    setplot(wrapPlots(traces, DATE, productionOption, productionOption));
  }, [workflowAnalysisContent, productionOption]);

  return (
    <Grid container className={styles.visualContent}>
      <Grid item className={styles.visualContentRow}>
        <Typography id={headerName}>{headerName}</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={productionOption}
            onChange={event => setproductionOption(event.target.value)}
            className="dropdownSelection"
          >
            <MenuItem value={WATER_CUT}>{WATER_CUT}</MenuItem>
            <MenuItem value={OIL_CUT}>{OIL_CUT}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {plot ? (
        <PlotlyComponent
          data={plot.data}
          layout={plot.layout}
          config={plot.config}
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <LoadingPage
          message="Production Cut Plot is Loading..."
          goHome={false}
        />
      )}
    </Grid>
  );
};

ProductionCut.propTypes = {
  workflowAnalysisContent: PropTypes.array,
  prodWellNames: PropTypes.array,
  headerName: PropTypes.string,
};

export default ProductionCut;
