import {
  DATE,
  FUTURE_PREDICTION,
  HISTORY_MATCH,
  LAG,
  METHOD,
  OVERALL_SCORE,
  PREDICTION,
  PRODUCER,
  SIZE,
  TEST_SCORE,
  TRAIN_SCORE,
  VAL_SCORE,
} from '../../constants/WellConstants';
import { createLinePlot } from '../../Utils/PlotlyUtils/Plots';

const arrayStringToListParser = stringStream => {
  const regex = /\s+/g;
  const startIndex = stringStream.indexOf('[') + 1;
  const endIndex = stringStream.indexOf(']');

  const string = stringStream
    .substring(startIndex, endIndex)
    .trim()
    .replaceAll(regex, ',');

  return JSON.parse('[' + string + ']');
};

export const filterWorkflowAnalysisByWell = (
  workflowAnalysisContent,
  producer
) => {
  return workflowAnalysisContent
    .filter(row => row[PRODUCER] == producer)
    .map(row => {
      return {
        [PRODUCER]: row[PRODUCER],
        [LAG]: row[LAG],
        [SIZE]: row[SIZE],
        [METHOD]: row[METHOD],
        // [PARAMETER]: row[PARAMETER],    // Can't show object notations for now.
        [TRAIN_SCORE]: row[TRAIN_SCORE],
        [VAL_SCORE]: row[VAL_SCORE],
        [TEST_SCORE]: row[TEST_SCORE],
        [OVERALL_SCORE]: row[OVERALL_SCORE],
      };
    });
};

export const generateSummaryTable = workflowAnalysisContent => {
  return workflowAnalysisContent
    .map(producerData => {
      return {
        [PRODUCER]: producerData[PRODUCER],
        [TRAIN_SCORE]: +producerData[TRAIN_SCORE],
        [TEST_SCORE]: +producerData[TEST_SCORE],
      };
    })
    .sort((a, b) => b[TEST_SCORE] - a[TEST_SCORE]);
};

export const generateHistoryMatchingPlot = (
  filteredContent,
  historyData,
  producer,
  numT
) => {
  const dataset = {};
  const historyMatching = arrayStringToListParser(
    filteredContent[HISTORY_MATCH]
  );
  const date = [];
  for (let i = 0; i < numT; i++) {
    date.push(i);
  }

  dataset[producer] = historyData;
  dataset[HISTORY_MATCH] = historyMatching;
  dataset[DATE] = date;

  let plot = createLinePlot(
    dataset,
    DATE,
    [producer, HISTORY_MATCH],
    undefined,
    [producer],
    undefined,
    DATE,
    'Production rate',
    'Production Rate History Matching'
  );
  return plot;
};
export const generateProductionRateTestingPlot = (
  filteredContent,
  predictionData,
  producer,
  numT
) => {
  const dataset = {};
  const testing = arrayStringToListParser(filteredContent[PREDICTION]);
  const date = [];
  for (let i = 0; i < numT; i++) {
    date.push(i);
  }

  dataset[producer] = predictionData;
  dataset['Testing'] = testing;
  dataset[DATE] = date;

  return createLinePlot(
    dataset,
    DATE,
    [producer, 'Testing'],
    undefined,
    undefined,
    undefined,
    DATE,
    'Production rate',
    'Production Rate - Testing'
  );
};
export const generateFuturePredictionPlot = (
  filteredContent,
  numT,
  futureYears
) => {
  const dataset = {};
  const futurePrediction = arrayStringToListParser(
    filteredContent[FUTURE_PREDICTION]
  );
  const date = [];
  for (let i = numT; i < numT * futureYears; i++) {
    date.push(i);
  }

  dataset[FUTURE_PREDICTION] = futurePrediction;
  dataset[DATE] = date;

  return createLinePlot(
    dataset,
    DATE,
    [FUTURE_PREDICTION],
    undefined,
    undefined,
    undefined,
    DATE,
    'Production rate',
    'Production Rate - Future Prediction'
  );
};
